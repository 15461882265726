import {EndpointNameStoppedPhone} from '@/constants/endpoints'
import {baseApi} from '..'
import {RTKTagName} from '@/constants/rtk-tag-name'
import {
  GetStoppedPhonesRequest,
  GetStoppedPhonesResponse,
  UpdateStoppedPhoneRequest,
  UpdateStoppedPhoneResponse,
} from './types'

const RoadmapNotesExtendedEndpoints = baseApi.injectEndpoints({
  endpoints: build => ({
    getStoppedPhones: build.query<GetStoppedPhonesResponse, GetStoppedPhonesRequest>({
      query: () => ({
        url: EndpointNameStoppedPhone.STOPPED_PHONES,
        method: 'GET',
      }),
      providesTags: [RTKTagName.STOPPED_PHONE],
    }),

    updateStoppedPhone: build.mutation<UpdateStoppedPhoneResponse, UpdateStoppedPhoneRequest>({
      query: ({id, body}) => ({
        url: EndpointNameStoppedPhone.STOPPED_PHONE + id,
        method: 'PUT',
        body,
      }),
    }),
  }),
})

export const {useGetStoppedPhonesQuery, useUpdateStoppedPhoneMutation} = RoadmapNotesExtendedEndpoints
