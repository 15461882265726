import {Props} from './types'
import {SubmitHandler} from 'react-hook-form'
import {CreateProfileGroupResponse} from '@/features/profile-group/types'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useCreateProfileGroupMutation} from '@/features/profile-group'
import {GroupType} from '@/constants/status'
import {useUpdateProfiles} from '@/hooks/use-update-profiles'
import {BaseGroupModal} from '..'
import {BaseGroupFormData} from '../base-group-modal/types'

export const CreateGroupModal: React.FC<Props> = ({open, close}) => {
  const [createGroup, {isLoading: isCreatingGroup}] = useCreateProfileGroupMutation()
  const handleRequest = useHandleRequest()
  const [updateProfilesGroup, isUpdatingProfilesGroup] = useUpdateProfiles()

  const onSubmit: SubmitHandler<BaseGroupFormData> = async formData => {
    await handleRequest({
      request: async () => {
        const result = await createGroup({
          groupType: GroupType.NORMAL,
          groupName: formData.groupName,
        })

        return result
      },
      onSuccess: async ({data}: {data: CreateProfileGroupResponse}) => {
        await updateProfilesGroup('groupID', data.data, formData.profiles)
        close()
      },
    })
  }

  return (
    <BaseGroupModal
      open={open}
      close={close}
      onSubmit={onSubmit}
      isSubmitting={isUpdatingProfilesGroup || isCreatingGroup}
    />
  )
}
