import {Icon} from '@/components/common/icon'
import {IconProps} from '@/components/common/icon/types'

export const PencilIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 149 149">
    <circle cx="74.1898" cy="74.1898" r="64.1898" fill="#2CD776" stroke="white" strokeWidth="20" />
    <path
      d="M66.6846 97.4974C66.0726 98.1127 65.2379 98.4564 64.3706 98.4564C63.5032 98.4564 62.6686 98.1127 62.0565 97.4974L43.5767 79.0143C41.6587 77.0963 41.6587 73.9869 43.5767 72.0721L45.8908 69.7581C47.8088 67.84 50.9149 67.84 52.833 69.7581L64.3706 81.2957L95.5466 50.1196C97.4647 48.2016 100.574 48.2016 102.489 50.1196L104.803 52.4336C106.721 54.3517 106.721 57.4611 104.803 59.3758L66.6846 97.4974Z"
      fill="white"
    />
    <circle cx="74.1898" cy="74.1898" r="74.1898" fill="#2CD776" />
    <g clipPath="url(#clip0_2202_8)">
      <path
        d="M69.0629 94.5277C70.4964 93.702 71.6882 92.7622 72.2626 92.2334C73.1205 91.4406 74.4289 91.3962 75.388 92.0186L81.2627 78.6295L72.349 76.2796L69.0629 94.5277Z"
        fill="white"
      />
      <path
        d="M96.766 75.5443C96.216 71.9308 93.8117 68.696 90.2387 66.9783L95.0054 51.3679C95.3762 50.1537 94.6167 48.8904 93.3092 48.5461L79.1037 44.8053C77.7962 44.461 76.4357 45.166 76.0647 46.38L71.2962 61.9883C68.905 61.812 66.5161 62.2984 64.3772 63.4098C61.5078 64.8961 59.4337 67.3329 58.5373 70.2697C58.5373 70.2697 51.5581 93.1218 51.5366 93.1924C52.0557 92.7128 52.5747 92.2331 52.5747 92.2331C53.536 91.3449 55.0885 91.3449 56.0499 92.2331C57.1831 93.2766 60.6972 95.8998 63.7892 96.0988L67.9484 73.0079C68.0637 72.3674 68.4675 71.8029 69.0588 71.4548C69.6548 71.1044 70.3757 71.0084 71.0487 71.1869L85.2519 74.9293C85.9249 75.1055 86.4848 75.5408 86.79 76.1255C87.0976 76.7102 87.1216 77.3885 86.8573 77.9888L79.4736 94.8145C80.8563 95.5485 82.4049 96.1363 83.8436 96.1363C87.0184 96.1363 90.7721 93.3178 91.9497 92.2332C92.23 91.9744 92.5656 91.814 92.9162 91.706L96.4175 80.2429C96.8995 78.6664 96.9984 77.0715 96.766 75.5443Z"
        fill="white"
      />
      <path
        d="M94.6515 44.1502L96.6652 37.5548C97.0354 36.3408 96.2759 35.0777 94.971 34.734L85.5019 32.0867C84.8795 31.9216 84.2017 31.9952 83.6298 32.2898C83.0602 32.5844 82.6397 33.0776 82.4619 33.6623L80.447 40.4093C80.4462 40.409 80.4437 40.409 80.4427 40.4087L94.6515 44.1502Z"
        fill="white"
      />
      <path
        d="M103.531 100.707C99.537 100.707 95.8024 98.4551 93.6875 96.8907C91.5726 98.4551 87.838 100.707 83.8438 100.707C79.8495 100.707 76.1149 98.4551 74 96.8907C73.8717 96.9856 69.0714 100.707 64.1562 100.707C60.162 100.707 56.4274 98.4551 54.3125 96.8907C52.1976 98.4551 48.299 100.707 44.3047 100.707C41.8018 100.707 37.9963 99.1131 34.8532 97.0199C34.3036 96.6539 33.5796 96.6041 32.9781 96.8911C32.3765 97.1783 32 97.7536 32 98.3817V107.715C32 108.978 33.1007 110 34.4609 110H113.539C114.899 110 116 108.978 116 107.715V98.4876C116 97.8511 115.612 97.2697 114.997 96.9871C114.383 96.7045 113.649 96.7691 113.104 97.155C110.243 99.1809 106.877 100.707 103.531 100.707Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2202_8">
        <rect width="84" height="78" fill="white" transform="translate(32 32)" />
      </clipPath>
    </defs>
  </Icon>
)
