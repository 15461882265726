/* eslint-disable react-hooks/exhaustive-deps */
import {useLazyGetProfileQuery} from '@/features/profile'
import {useSearchParams} from 'react-router-dom'
import {useHandleRequest} from '../use-handle-request'
import {useEffect} from 'react'
import {UseCurrentProfileReturn} from './types'

export const useCurrentProfile = (): UseCurrentProfileReturn => {
  const [searchParams] = useSearchParams()
  const [getProfile, {data: {data: profile = undefined} = {}, isLoading, isFetching}] = useLazyGetProfileQuery()
  const handleRequest = useHandleRequest()

  const fetchProfile = async () => {
    await handleRequest({
      request: async () => {
        const result = await getProfile({id: searchParams.get('phoneID') || ''})
        return result
      },
    })
  }

  useEffect(() => {
    if (searchParams.get('phoneID')) {
      fetchProfile()
    }
  }, [searchParams])

  return [profile || {}, {isFetching, isLoading, isExisted: Boolean(profile)}]
}
