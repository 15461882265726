/* eslint-disable react-hooks/exhaustive-deps */
import {CreateTaskForm, RoadmapNotes, RoadmapTasks} from './components'
import {useEffect, useState} from 'react'
import {useLazyGetRoadmapTasksQuery} from '@/features/roadmap-tasks'
import {Loader} from '@/components/common'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {GetRoadmapTasksResponse} from '@/features/roadmap-tasks/types'

export const Roadmap = () => {
  const [getTasks, {data: {data: tasksData = []} = {}, isLoading}] = useLazyGetRoadmapTasksQuery()
  const [openCreateForm, setOpenCreateForm] = useState(false)
  const [tasks, setTasks] = useState(tasksData)
  const handleRequest = useHandleRequest()

  const fetchTasks = async () => {
    await handleRequest({
      request: async () => {
        const result = await getTasks({})
        return result
      },
      onSuccess: ({data}: {data: GetRoadmapTasksResponse}) => {
        setTasks([...data.data].sort((a, b) => a.order - b.order))
      },
    })
  }

  useEffect(() => {
    fetchTasks()
  }, [])

  return (
    <>
      <h1 className="text-4xl font-bold mb-5">Roadmap</h1>
      {isLoading ? (
        <Loader parentClassName="h-40" />
      ) : (
        <>
          <RoadmapTasks
            tasks={tasks}
            onOpenCreateForm={() => setOpenCreateForm(true)}
            setTasks={setTasks}
            fetchTasks={fetchTasks}
          />
          <RoadmapNotes />
        </>
      )}

      <CreateTaskForm open={openCreateForm} close={() => setOpenCreateForm(false)} fetchTasks={fetchTasks} />
    </>
  )
}
