import React, {useState} from 'react'
import {Props} from './types'
import {EditProfileModal} from '../../../side-bar/components/edit-profile-modal'

import {Button} from '@/components/ui/button'
import {IoMdSettings} from 'react-icons/io'
import {ChildAccounts, ConfigureDM, Reports, Scraping} from './components'
import {FaUser} from 'react-icons/fa'
import {GiPostStamp} from 'react-icons/gi'
import {IoPhonePortrait} from 'react-icons/io5'
import {MdOutlineContentCopy} from 'react-icons/md'
import {useTheme} from '@/components/ui/theme-provider'
import {QuitIcon} from '@/icons'
import {cn} from '@/lib/utils'

export const SettingsTop: React.FC<Props> = ({profile, settingsTopRef}) => {
  const [openEditModal, setOpenEditModal] = useState(false)
  const {isDarkMode} = useTheme()

  const onClickBadge = (link?: string) => {
    if (link) {
      window.open(link)
    }
  }

  return (
    <div
      ref={settingsTopRef}
      className="flex gap-5 fixed top-[105px] w-[calc(100%-414px)] z-10 border border-[#ebedf2] dark:border-secondary rounded-2xl"
    >
      <div className="w-full h-full py-4 px-[17px] bg-white dark:bg-background text-black dark:text-white rounded-2xl flex items-center justify-between">
        <div className="flex items-center gap-5">
          <div className="relative w-16 h-16 bg-[#e84b23] rounded-full">
            <img
              src={profile.picture || (isDarkMode ? '/default-dark.jpg' : '/default.jpeg')}
              alt="Profile"
              className="w-full h-full rounded-full"
            />
          </div>
          <div className="flex flex-col items-start">
            <div className="relative">
              <h2 className="text-3xl font-semibold">{profile?.profileName}</h2>
              {/* {profile.verified && (
                <VerifiedIcon className="text-[#0095f6] w-[14px] h-[14px] absolute top-[2px] right-[-14px]" />
              )} */}
              <div className="flex items-center gap-2">
                {profile?.niche && (
                  <>
                    <span>{profile?.niche.nicheName}</span>
                    <div className="w-1 h-1 bg-black rounded-full" />
                  </>
                )}
                <span className="flex items-center gap-1">
                  <IoPhonePortrait />
                  <span>{profile.phoneID}</span>
                  <button
                    type="button"
                    className="hover:scale-[1.1] active:scale-[.9] duration-150"
                    onClick={() => navigator.clipboard.writeText(profile.phoneID)}
                  >
                    <MdOutlineContentCopy className="text-xs" />
                  </button>
                </span>
                {typeof profile.followers === 'number' && (
                  <div className="flex items-center gap-2 text-sm font-semibold">
                    <FaUser />
                    <span className="text-[13px] min-w-max">
                      {profile.followers?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                    </span>
                  </div>
                )}
                {typeof profile.posts === 'number' && (
                  <div className="flex items-center gap-2 text-lg font-semibold">
                    <GiPostStamp />
                    <span className="text-[13px] min-w-max">
                      {profile.posts?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                    </span>
                  </div>
                )}
              </div>
              {profile.stoppedPhone || profile.tags?.length ? (
                <div className="flex flex-wrap items-center gap-2 my-2 max-w-[400px]">
                  {profile.stoppedPhone && (
                    <div className="px-3 py-1 rounded-sm w-max text-white text-xs bg-red-600 flex items-center gap-1.5">
                      <QuitIcon className="w-3 h-3" />
                      Stopped
                    </div>
                  )}
                  {profile.tags?.map((tag, index) => (
                    <div
                      key={index}
                      style={{background: tag.color}}
                      className={cn(
                        'px-3 py-1 rounded-sm w-max text-white text-xs',
                        tag.link ? 'cursor-pointer hover:underline' : '',
                      )}
                      onClick={() => onClickBadge(tag.link)}
                    >
                      {tag.label}
                    </div>
                  ))}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>

        <div className="flex items-center gap-3">
          {/* <MultiAccountsActivation profile={profile} /> */}
          <ChildAccounts profile={profile} />
          <ConfigureDM profile={profile} />
          <Reports profile={profile} />
          <Scraping profile={profile} />
          <Button
            onClick={() => setOpenEditModal(true)}
            variant="outline"
            size="icon"
            className="flex gap-2 rounded h-[34px] text-xs"
          >
            <IoMdSettings className="text-sm" />
          </Button>
          {/* <Button variant="outline" className="flex gap-2 rounded h-[34px] text-xs">
            <FaRegClone className="text-xs" />
            <span>Make a copy</span>
          </Button> */}

          {/* <Button variant="outline" className="flex gap-2 rounded h-[34px] text-xs">
            <FaEdit className="text-xs" />
            <span>Edit</span>
          </Button>

          <Button variant="outlined-destructive" className="hover:text-white flex gap-2 rounded h-8 text-xs">
            <FaRegTrashCan className="text-xs" />
            <span>Archive</span>
          </Button> */}
          {/* <Dropdown
            triggerBtnProps={{
              size: 'lg',
              className: 'bg-[#1677ff] text-white border-[#1677ff] hover:bg-[#046afa]',
            }}
            contentClassName="bg-white border-white text-[#1677ff]"
            buttonClassName="hover:!bg-[#1677ff] hover:!text-white"
            label="Actions"
            buttons={[
              {label: 'Edit Profile', onClick: () => setOpenEditModal(true)},
              {
                label: 'Delete',
                className: 'bg-destructive hover:!bg-red-600 !text-white',
                onClick: () => setOpenDeleteAlert(true),
              },
            ]}
          /> */}
        </div>
      </div>
      <EditProfileModal
        open={openEditModal}
        close={() => setOpenEditModal(false)}
        profile={{
          ...(profile || {}),
          profileName: profile.profileName,
          group: profile.group?._id,
          phoneID: profile.phoneID,
          _id: profile._id,
          profileType: profile.profileType,
          niche: profile.niche?._id,
        }}
      />
    </div>
  )
}
