import {EndpointNameSubaccount} from '@/constants/endpoints'
import {baseApi} from '..'
import {RTKTagName} from '@/constants/rtk-tag-name'
import {
  CreateSubaccountRequest,
  CreateSubaccountResponse,
  DeleteSubaccountRequest,
  DeleteSubaccountResponse,
  GetSubAccountsResponse,
  GetSubaccountsRequest,
  UpdateSubaccountRequest,
  UpdateSubaccountResponse,
} from './types'

const SubaccountsExtendedEndpoints = baseApi.injectEndpoints({
  endpoints: build => ({
    getSubaccounts: build.query<GetSubAccountsResponse, GetSubaccountsRequest>({
      query: () => ({
        url: EndpointNameSubaccount.GET_SUBACCOUNTS,
        method: 'GET',
      }),
      providesTags: [RTKTagName.SUBACCOUNT],
    }),

    createSubaccount: build.mutation<CreateSubaccountResponse, CreateSubaccountRequest>({
      query: body => ({
        url: EndpointNameSubaccount.CREATE_SUBACCOUNT,
        method: 'POST',
        body,
      }),
      invalidatesTags: [RTKTagName.SUBACCOUNT],
    }),

    updateSubaccount: build.mutation<UpdateSubaccountResponse, UpdateSubaccountRequest>({
      query: ({id, body}) => ({
        url: EndpointNameSubaccount.SUBACCOUNT + id,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [RTKTagName.SUBACCOUNT],
    }),

    deleteSubaccount: build.mutation<DeleteSubaccountResponse, DeleteSubaccountRequest>({
      query: ({id}) => ({
        url: EndpointNameSubaccount.SUBACCOUNT + id,
        method: 'DELETE',
      }),
      invalidatesTags: [RTKTagName.SUBACCOUNT],
    }),
  }),
})

export const {
  useGetSubaccountsQuery,
  useCreateSubaccountMutation,
  useUpdateSubaccountMutation,
  useDeleteSubaccountMutation,
} = SubaccountsExtendedEndpoints
