import React from 'react'
import {Props} from './types'
import {BaseScriptForm} from '..'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useToast} from '@/components/ui/use-toast'
import {SubmitHandler} from 'react-hook-form'
import {BaseScriptFormData} from '../base-script-form/types'
import {useAppDispatch} from '@/store/hooks'
import {baseApi} from '@/features'
import {RTKTagName} from '@/constants/rtk-tag-name'
import {useUpdateVPSScriptMutation} from '@/features/vps-scripts'

export const EditScriptForm: React.FC<Props> = ({open, close, script}) => {
  const [updateScriptDetails, {isLoading}] = useUpdateVPSScriptMutation()
  const dispatch = useAppDispatch()
  const handleRequest = useHandleRequest()
  const {toast} = useToast()

  const onSubmit: SubmitHandler<BaseScriptFormData> = async formData => {
    await handleRequest({
      request: async () => {
        const result = await updateScriptDetails({id: script._id, body: formData})
        return result
      },
      onSuccess: () => {
        dispatch(baseApi.util.invalidateTags([RTKTagName.VPS_SCRIPT]))
        close()
        toast({title: 'Script successfully updated!'})
      },
    })
  }

  return (
    <BaseScriptForm
      open={open}
      close={close}
      onSubmit={onSubmit}
      isSubmitting={isLoading}
      title={`Update Script (${script.username})`}
      submitText="Update"
      defaultValues={{
        username: script.username,
      }}
    />
  )
}
