import {DataStatus, RoadMapTaskStatus} from '@/constants/status'

export enum RoadmapTaskIcon {
  CHECK = 'check',
  CODE = 'code',
  DEBUG = 'debug',
  FILES = 'files',
  GLOBAL = 'global',
  HEART = 'heart',
  PARTY = 'party',
  PENCIL = 'pencil',
  RESIZE = 'resize',
  TRANSLATOR = 'translator',
}

export interface RoadmapTask {
  _id: string
  taskName: string
  description: string
  icon: RoadmapTaskIcon
  completed: RoadMapTaskStatus
  status: DataStatus
  order: number
}
