import dayjs from 'dayjs'
import {AiOutlineClose, AiOutlinePlus} from 'react-icons/ai'
import {Props} from './types'

export const NicheTask: React.FC<Props> = ({task, onClick, isAdded}) => (
  <div className="relative border border-muted-foreground px-2 py-1 rounded-md">
    <div className="flex items-center justify-between gap-2">
      <div>
        <p>{task.file_name?.replace('.txt', '')}</p>
        <div className="flex items-center gap-2 my-1">
          <span className="font-normal text-xs leading-[1em]">
            FBR: <b>{task.FBR}%</b>
          </span>
          <span className="font-normal text-xs leading-[1em]">
            DMed: <b>{task.user_dm_sended}</b>
          </span>
        </div>
      </div>
      {isAdded ? (
        <button
          onClick={onClick}
          type="button"
          title="Add to selecteds"
          className="w-8 h-8 rounded-lg border border-destructive text-destructive hover:bg-destructive hover:text-white duration-150 grid place-content-center"
        >
          <AiOutlineClose />
        </button>
      ) : (
        <button
          onClick={onClick}
          type="button"
          title="Add to selecteds"
          className="w-8 h-8 rounded-lg border border-main-blue text-main-blue hover:bg-main-blue hover:text-white duration-150 grid place-content-center"
        >
          <AiOutlinePlus />
        </button>
      )}
    </div>
    <p className="text-xs leading-[1em] mb-0.5">
      {task.availability === '1' ? '✅ Good to use, enough time has passed' : '❌ Avoid adding it, itʼs too early'}
    </p>
    <span className="text-xs absolute -top-2 right-1 bg-white">{dayjs(task.uploaded_at).format('MM.DD.YYYY')}</span>
  </div>
)
