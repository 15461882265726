/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Props } from './types'
import { AccordionItem, AccordionTrigger } from '@/components/ui/accordion'

import { ScriptContent } from './components'
import { DeleteModal, Loader, Tooltip } from '@/components/common'
import { useArchiveVPSScriptMutation, useDeleteVPSScriptMutation, useLazyGetVPSScriptConfigQuery } from '@/features/vps-scripts'
import { useHandleRequest } from '@/hooks/use-handle-request'
import { useToast } from '@/components/ui/use-toast'
import { Button } from '@/components/ui/button'
import { FaRegTrashCan } from 'react-icons/fa6'
import { ArchiveX, Edit, Snowflake } from 'lucide-react'
import { EditScriptForm } from '..'
import { useDispatch } from 'react-redux'
import { baseApi } from '@/features'
import { RTKTagName } from '@/constants/rtk-tag-name'

export const ScriptCard: React.FC<Props> = ({ script, isTargetsLoading, targets, isDailyScriptsRuning }) => {
  const [getScriptConfig, { data: { data: config = {} } = {}, isLoading: isLoadingScriptConfig }] =
    useLazyGetVPSScriptConfigQuery()
  const [archiveScript, { isLoading: isArchiving }] = useArchiveVPSScriptMutation()
  const [deleteScript, { isLoading: isDeleting }] = useDeleteVPSScriptMutation()
  const [open, setOpen] = useState(false)
  const [configFetched, setConfigFetched] = useState(false)
  const [openEditForm, setOpenEditForm] = useState(false)
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)
  const [openArchiveConfirm, setOpenArchiveConfirm] = useState(false)
  const dispatch = useDispatch()
  const handleRequest = useHandleRequest()
  const { toast } = useToast()

  const fetchScriptConfig = async () => {
    await handleRequest({
      request: async () => {
        const result = await getScriptConfig({ id: script._id || script.username })
        return result
      },
      onSuccess: () => {
        setConfigFetched(true)
      },
    })
  }

  const onDelete = async () => {
    await handleRequest({
      request: async () => {
        const result = await deleteScript({ id: script._id })
        return result
      },
      onSuccess: () => {
        dispatch(baseApi.util.invalidateTags([RTKTagName.VPS_SCRIPT]))
        setOpenDeleteConfirm(false)
        toast({ title: 'Script successfully deleted!' })
      },
    })
  }

  const onArchive = async () => {
    await handleRequest({
      request: async () => {
        const result = await archiveScript({ id: script.username })
        return result
      },
      onSuccess: () => {
        dispatch(baseApi.util.invalidateTags([RTKTagName.VPS_SCRIPT]))
        setOpenArchiveConfirm(false)
        toast({ title: 'Script successfully archived!' })
      },
    })
  }

  useEffect(() => {
    if (open && !configFetched) {
      fetchScriptConfig()
    }
  }, [open])

  return (
    <>
      <AccordionItem value={script.username} className="mb-3 border rounded">
        <AccordionTrigger
          onClick={() => setOpen(true)}
          rightContent={
            <div onClick={event => event.preventDefault()} className="flex items-center gap-3">
              {script._id ? (
                <>
                  <Button
                    asChild
                    size="icon"
                    className="w-[35px] h-[35px]"
                    variant="outline"
                    onClick={() => setOpenEditForm(true)}
                  >
                    <span>
                      <Edit className="w-4 h-4" />
                    </span>
                  </Button>
                  <Button
                    asChild
                    size="icon"
                    className="w-[35px] h-[35px]"
                    variant="outlined-destructive"
                    onClick={() => setOpenDeleteConfirm(true)}
                  >
                    <span>
                      <FaRegTrashCan />
                    </span>
                  </Button>
                </>
              ) :
                <Button
                  asChild
                  size="icon"
                  className="w-[35px] h-[35px]"
                  variant="outlined-destructive"
                  onClick={() => setOpenArchiveConfirm(true)}
                >
                  <span>
                    <ArchiveX className='w-4 h-4' />
                  </span>
                </Button>}
            </div>
          }
          className="px-6 hover:no-underline [&[data-state=open]]:border-b"
        >
          <div className="flex items-center gap-3">
            <span className="min-w-max">{script.username}</span>
            <div className="flex items-center">
              ({isTargetsLoading ? <Loader className="!w-5 !h-5 border-2" /> : targets || 0})
            </div>
            {script.converted && (
              <Tooltip trigger={<Snowflake className="text-blue w-5 h-5 min-w-[20px]" />}>Accounts frozen</Tooltip>
            )}
            {script.running ? <Loader className="!w-5 !h-5 border-2" /> : ''}
          </div>
        </AccordionTrigger>
        <ScriptContent
          script={script}
          isTargetsLoading={isTargetsLoading}
          isLoading={isLoadingScriptConfig}
          targets={targets}
          config={config}
          isDailyScriptsRuning={isDailyScriptsRuning}
        />
      </AccordionItem>
      {script._id ? (
        <>
          <EditScriptForm open={openEditForm} close={() => setOpenEditForm(false)} script={script} />
          <DeleteModal
            open={openDeleteConfirm}
            close={() => setOpenDeleteConfirm(false)}
            title="Are you sure?"
            description={`Are you sure to delete this script (${script.username}) ?`}
            onDelete={onDelete}
            isDeleting={isDeleting}
          />
        </>
      ) : (
        <DeleteModal
          open={openArchiveConfirm}
          close={() => setOpenArchiveConfirm(false)}
          title="Are you sure?"
          description={`Are you sure to archive this script (${script.username}) ?`}
          onDelete={onArchive}
          isDeleting={isArchiving}
          deleteButtonText='Archive'
        />
      )}
    </>
  )
}
