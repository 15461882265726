import {Role} from '@/constants/role'

export const NAV_BUTTONS = [
  {
    label: 'Manage',
    path: '/manage',
  },
  {
    label: 'Review',
    path: '/review',
  },
  // {
  //   label: 'Dashboard',
  //   path: '/dashboard',
  // },
  {
    label: 'Niches',
    path: '/niches',
  },
  {
    label: 'Accounts',
    path: '/accounts',
    roles: [Role.SUPER_ACCOUNT],
  },
  {
    label: 'Subaccounts',
    path: '/subaccounts',
    roles: [Role.SUPER_SUBACCOUNT],
  },
  // {
  //   label: 'Blocks',
  //   path: '/blocks',
  // },
  {
    label: 'Statistics',
    path: '/statistics',
  },
]
