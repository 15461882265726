/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import { Props } from './types'
import { Modal } from '@/components/common'
import {
  useGetVPSScriptsDailyRunStatusQuery,
  useLazyGetVPSScriptsDailyRunsQuery,
  useStartVPSScriptsDailyRunMutation,
} from '@/features/vps-scripts'
import { useHandleRequest } from '@/hooks/use-handle-request'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { DailyRunCard } from './components'
import { DailyRunStatus } from '@/constants/status'
import { Button } from '@/components/ui/button'
import { useDispatch } from 'react-redux'
import { RTKTagName } from '@/constants/rtk-tag-name'
import { baseApi } from '@/features'
import { Play } from 'lucide-react'
import { GetVPSScriptsDailyRunsResponse } from '@/features/vps-scripts/types'

dayjs.extend(relativeTime)

export const DailyRunsModal: React.FC<Props> = ({ open, close, scriptNamesObject }) => {
  const [startDailyRun, { isLoading: isStartingDailyRun }] = useStartVPSScriptsDailyRunMutation()
  const { data: { data: dailyRunStatus = DailyRunStatus.NOT_STARTED } = {} } = useGetVPSScriptsDailyRunStatusQuery({})
  const [getRunningScripts, { data: { data: dailyRuns = [] } = {}, isFetching }] = useLazyGetVPSScriptsDailyRunsQuery()
  const [isLatestDailyRunInProgress, setIsLatestDailyRunInPorgress] = useState(false);

  const sortedDailyRuns = useMemo(() => {
    const sortedDailyRunsObj: any = {}

    for (const dailyRun of dailyRuns) {
      const dailyRunName = dailyRun.date.replace('.json', '')
      const dailyRunDate = dailyRun.date.split('T')[0]
      if (Array.isArray(sortedDailyRunsObj[dailyRunDate])) {
        sortedDailyRunsObj[dailyRunDate].push({
          date: dailyRunName,
          running: Boolean(dailyRun.isRunning),
        })
      } else {
        sortedDailyRunsObj[dailyRunDate] = [
          {
            dateName: dailyRunName,
            date: (dailyRun.date.replace('.json', '') as any).replaceAll('--', ':'),
            running: Boolean(dailyRun.isRunning),
          },
        ]
      }
    }

    const result = Object.entries(sortedDailyRunsObj)
      .map(([date, runs]) => ({
        date,
        dailyRuns: (runs as any).sort((a: any, b: any) => (new Date(b.date) as any) - (new Date(a.date) as any)),
      }))
      .sort((a, b) => (new Date(b.date) as any) - (new Date(a.date) as any))

    if (result?.[0]?.dailyRuns?.[0]) {
      result[0].dailyRuns[0].isInProgress = true
      result[0].dailyRuns[0].status = dailyRunStatus
    }

    return result
  }, [dailyRuns])

  const [fetched, setFetched] = useState(false)
  const dispatch = useDispatch()
  const handleRequest = useHandleRequest()

  const fetchDailyRuns = async () => {
    await handleRequest({
      request: async () => {
        const result = await getRunningScripts({})
        return result
      },
      onSuccess: ({ data }: { data: GetVPSScriptsDailyRunsResponse }) => {
        setIsLatestDailyRunInPorgress(Boolean(data.data?.[data.data?.length - 1]?.isRunning))
        setFetched(true)
      },
    })
  }

  const onSatrtDailyRun = async () => {
    await handleRequest({
      request: async () => {
        const result = await startDailyRun({})
        return result
      },
      onSuccess: async () => {
        await fetchDailyRuns()
        dispatch(baseApi.util.invalidateTags([RTKTagName.VPS_SCRIPT_DAILY_STATUS]))
      },
    })
  }

  useEffect(() => {
    if (open && !fetched) {
      fetchDailyRuns()
    }
  }, [open])

  return (
    <Modal open={open} close={close} title="Daily runs" isLoading={isFetching} contentClassName="min-w-[600px]">
      <div>
        <Button
          disabled={isLatestDailyRunInProgress || ![DailyRunStatus.NOT_STARTED, DailyRunStatus.COMPLETED].includes(dailyRunStatus)}
          size="lg"
          className="flex items-center gap-2 text-xs mb-4 ml-auto"
          loading={isStartingDailyRun}
          onClick={onSatrtDailyRun}
        >
          <Play className="w-3.5 h-3.5" />
          <span>Start All Scripts</span>
        </Button>
        <div className="space-y-5">
          {sortedDailyRuns.length ? (
            sortedDailyRuns.map((dailyRunEl: any) => (
              <div key={dailyRunEl.date}>
                <p className="text-sm text-slate-500 mb-2">
                  {dayjs(dailyRunEl.date).from(new Date().toISOString().split('T')[0]) === 'a few seconds ago'
                    ? 'today'
                    : dayjs(dailyRunEl.date).from(new Date().toISOString().split('T')[0])}
                </p>
                {dailyRunEl.dailyRuns.map((dailyRun: any) => (
                  <DailyRunCard key={dailyRun.date} dailyRun={dailyRun} scriptNamesObject={scriptNamesObject} />
                ))}
              </div>
            ))
          ) : (
            <div className="text-sm py-20 text-center text-slate-600">No any daily runs yet</div>
          )}
        </div>
      </div>
    </Modal>
  )
}
