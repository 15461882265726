import {EndpointNameProfileNiche} from '@/constants/endpoints'
import {baseApi} from '..'
import {RTKTagName} from '@/constants/rtk-tag-name'
import {
  CreateNicheRequest,
  CreateNicheResponse,
  DeleteNicheRequest,
  DeleteNicheResponse,
  GetNicheProfilesRequest,
  GetNicheProfilesResponse,
  GetNicheProfilesTasksRequest,
  GetNicheProfilesTasksResponse,
  GetNichesRequest,
  GetNichesResponse,
  UpdateNicheRequest,
  UpdateNicheResponse,
} from './types'

const ProfileNicheExtendedEndpoints = baseApi.injectEndpoints({
  endpoints: build => ({
    getNiches: build.query<GetNichesResponse, GetNichesRequest>({
      query: () => ({
        url: EndpointNameProfileNiche.GET_NICHES,
        method: 'GET',
      }),
      providesTags: [RTKTagName.NICHE],
    }),

    getNicheProfiles: build.query<GetNicheProfilesResponse, GetNicheProfilesRequest>({
      query: ({id}) => ({
        url: EndpointNameProfileNiche.GET_NICHE_PROFILES + id,
        method: 'GET',
      }),
      providesTags: [RTKTagName.NICHE],
    }),

    getNicheProfilesTasks: build.query<GetNicheProfilesTasksResponse, GetNicheProfilesTasksRequest>({
      query: ({id}) => ({
        url: EndpointNameProfileNiche.NICHE + id,
        method: 'GET',
      }),
      providesTags: [RTKTagName.NICHE],
    }),

    createProfileNiche: build.mutation<CreateNicheResponse, CreateNicheRequest>({
      query: body => ({
        url: EndpointNameProfileNiche.CREATE_NICHE,
        method: 'POST',
        body,
      }),
      invalidatesTags: [RTKTagName.NICHE],
    }),

    updateProfileNiche: build.mutation<UpdateNicheResponse, UpdateNicheRequest>({
      query: ({id, body}) => ({
        url: EndpointNameProfileNiche.NICHE + id,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [RTKTagName.NICHE],
    }),

    deleteProfileNiche: build.mutation<DeleteNicheResponse, DeleteNicheRequest>({
      query: ({id}) => ({
        url: EndpointNameProfileNiche.NICHE + id,
        method: 'DELETE',
      }),
      invalidatesTags: [RTKTagName.NICHE],
    }),
  }),
})

export const {
  useGetNichesQuery,
  useLazyGetNichesQuery,
  useLazyGetNicheProfilesQuery,
  useLazyGetNicheProfilesTasksQuery,
  useCreateProfileNicheMutation,
  useUpdateProfileNicheMutation,
  useDeleteProfileNicheMutation,
} = ProfileNicheExtendedEndpoints
