import {EndpointNameVPSScraperTask} from '@/constants/endpoints'
import {baseApi} from '..'
import {
  ArchiveVPSSCraperTaskRequest,
  ArchiveVPSSCraperTaskResponse,
  CheckVPSScraperTaskCompletionRequest,
  CheckVPSScraperTaskCompletionResponse,
  CreateVPSScraperTaskRequest,
  CreateVPSScraperTaskResponse,
  GetAllScraperTasksRequest,
  GetAllScraperTasksResponse,
  GetVPSScraperTasksRequest,
  GetVPSScraperTasksResponse,
} from './types'
import {RTKTagName} from '@/constants/rtk-tag-name'

const VPSScraperTasksExtendedEndpoints = baseApi.injectEndpoints({
  endpoints: build => ({
    getVPSScraperTasks: build.query<GetVPSScraperTasksResponse, GetVPSScraperTasksRequest>({
      query: ({id}) => ({
        url: EndpointNameVPSScraperTask.VPS_SCRAPER_TASK + id,
        method: 'GET',
      }),
      providesTags: [RTKTagName.VPS_SCRAPER_TASK],
    }),

    checkVPSScraperTaskCompletion: build.mutation<
      CheckVPSScraperTaskCompletionResponse,
      CheckVPSScraperTaskCompletionRequest
    >({
      query: ({id, params}) => ({
        url: EndpointNameVPSScraperTask.VPS_SCRAPER_TASK_CHECK + id,
        method: 'POST',
        params,
      }),
      invalidatesTags: [RTKTagName.VPS_SCRAPER_TASK],
    }),

    createVPSScraperTask: build.mutation<CreateVPSScraperTaskResponse, CreateVPSScraperTaskRequest>({
      query: ({id, body}) => ({
        url: EndpointNameVPSScraperTask.VPS_SCRAPER_TASK + id,
        method: 'POST',
        body,
      }),
      invalidatesTags: [RTKTagName.VPS_SCRAPER_TASK],
    }),

    archiveVPSScraperTask: build.mutation<ArchiveVPSSCraperTaskResponse, ArchiveVPSSCraperTaskRequest>({
      query: ({id, params}) => ({
        url: EndpointNameVPSScraperTask.VPS_SCRAPER_TASK + id,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: [RTKTagName.VPS_SCRAPER_TASK],
    }),

    getAllVPSScraperTasks: build.query<GetAllScraperTasksResponse, GetAllScraperTasksRequest>({
      query: () => ({
        url: EndpointNameVPSScraperTask.VPS_SCRAPER_TASKS,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useCreateVPSScraperTaskMutation,
  useGetVPSScraperTasksQuery,
  useArchiveVPSScraperTaskMutation,
  useCheckVPSScraperTaskCompletionMutation,
  useLazyGetAllVPSScraperTasksQuery,
} = VPSScraperTasksExtendedEndpoints
