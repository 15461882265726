import React, {useMemo} from 'react'
import {IconSelectValue, Props} from './types'
import {cn} from '@/lib/utils'
import {Select as UISelect, SelectContent, SelectItem, SelectTrigger, SelectValue} from '@/components/ui/select'
import {RoadmapTaskIcon} from '@/types/roadmap-task'
import {RoadmapIcon} from '@/icons/roadmap'

export const IconSelect: React.FC<Props> = ({
  label,
  placeholder,
  value,
  defaultValue,
  onValueChange,
  labelClassName,
}) => {
  const valueProps = useMemo(() => {
    const props: IconSelectValue = {}

    if (onValueChange) {
      props.value = value
      props.onValueChange = onValueChange
    }

    if (defaultValue) {
      props.defaultValue = defaultValue
    }

    return props
  }, [value, defaultValue, onValueChange])

  return (
    <label className={cn('w-full', labelClassName)}>
      {label && <span className="text-xs capitalize">{label}</span>}
      <UISelect {...valueProps}>
        <SelectTrigger className="w-full">
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent className="max-h-[200px]">
          {Object.values(RoadmapTaskIcon).map(iconType => (
            <SelectItem key={iconType} value={iconType}>
              <div className="flex items-center gap-2">
                <RoadmapIcon type={iconType} />
                <span>{iconType}</span>
              </div>
            </SelectItem>
          ))}
        </SelectContent>
      </UISelect>
    </label>
  )
}
