import {Icon} from '@/components/common/icon'
import {IconProps} from '@/components/common/icon/types'

export const PartyIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 149 149">
    <circle cx="74.1898" cy="74.1898" r="64.1898" fill="#2CD776" stroke="white" strokeWidth="20" />
    <path
      d="M66.6846 97.4974C66.0726 98.1127 65.2379 98.4564 64.3706 98.4564C63.5032 98.4564 62.6686 98.1127 62.0565 97.4974L43.5767 79.0143C41.6587 77.0963 41.6587 73.9869 43.5767 72.0721L45.8908 69.7581C47.8088 67.84 50.9149 67.84 52.833 69.7581L64.3706 81.2957L95.5466 50.1196C97.4647 48.2016 100.574 48.2016 102.489 50.1196L104.803 52.4336C106.721 54.3517 106.721 57.4611 104.803 59.3758L66.6846 97.4974Z"
      fill="white"
    />
    <circle cx="74.1898" cy="74.1898" r="74.1898" fill="#FE4E9A" />
    <path
      d="M74.1781 98.4562C70.75 99.7187 66.3969 101.319 60.8906 103.359C51.4875 102.047 41.6062 98.9875 37.3344 91.9656C38.8906 87.775 40.2469 84.1031 41.4469 80.8625C49.4969 90.1625 63.5625 96.0687 74.1781 98.4562Z"
      fill="white"
    />
    <path
      d="M76.9094 67.0906C67.7594 57.9438 55.9781 51.1875 51.175 56C50.15 57.025 50.4594 56.4125 43.3375 75.7406C50.9313 86.7 70.1188 94.3531 83.4844 95.025C87.4813 93.5219 87.3875 93.4469 88.0032 92.8281C93.8938 86.9375 82.7969 72.975 76.9094 67.0906ZM84.6844 89.5094C84.2375 89.9625 82.1594 89.9844 78.4688 88.1625C74.4563 86.1781 69.8781 82.7188 65.5781 78.4188C55.6157 68.4563 53.2875 60.5125 54.4875 59.3125C54.675 59.125 55.025 59.025 55.5219 59.025C58.2125 59.025 65.1844 61.9969 73.5969 70.4062C77.8969 74.7063 81.3594 79.2844 83.3438 83.2969C85.1656 86.9813 85.1344 89.0625 84.6844 89.5094Z"
      fill="white"
    />
    <path
      d="M52.3094 106.544C46.8969 108.556 40.6562 110.887 33.4094 113.603C31.525 114.297 29.7 112.466 30.3969 110.591C32.2219 105.703 33.8687 101.284 35.3687 97.2531C39.4875 101.984 45.6812 104.853 52.3094 106.544Z"
      fill="white"
    />
    <path
      d="M85.4656 32.1719C84.0063 32.4281 82.5969 32.6594 81.6406 33.1406C83.0781 35.3469 86.0813 38.1562 84.2469 41.825C82.6063 45.1062 79.1969 44.9125 76.2375 45.0156C78.0531 47.4969 80.1531 50.0125 78.5344 53.2531C76.8563 56.6125 72.4563 57.3094 69.8594 57.7312C69.0188 57.8687 68.2375 57.3062 68.0813 56.4719L67.5125 53.4031C67.3531 52.55 67.9281 51.7281 68.7813 51.5781C70.2594 51.3187 71.6438 51.0937 72.6063 50.6094C71.1625 48.4187 68.1719 45.5812 69.9969 41.9281C71.6344 38.6562 75.0406 38.8406 78.0063 38.7375C76.1875 36.2562 74.0906 33.7406 75.7094 30.5C77.3875 27.1437 81.7906 26.4437 84.3813 26.0219C85.2219 25.8844 86.0031 26.4469 86.1563 27.2812L86.725 30.35C86.8906 31.2031 86.3219 32.0219 85.4656 32.1719Z"
      fill="white"
    />
    <path
      d="M113.653 57.2656L116.722 57.8344C117.559 57.9906 118.119 58.7719 117.981 59.6094C117.559 62.2 116.859 66.6031 113.503 68.2812C110.262 69.9 107.747 67.8031 105.266 65.9844C105.162 68.95 105.347 72.3594 102.075 73.9937C98.4219 75.8187 95.5844 72.8281 93.3937 71.3844C92.9094 72.3469 92.6844 73.7312 92.425 75.2094C92.275 76.0656 91.4531 76.6375 90.6 76.4781L87.5312 75.9094C86.6937 75.7531 86.1344 74.9719 86.2719 74.1312C86.6937 71.5375 87.3937 67.1375 90.75 65.4562C93.9875 63.8375 96.5062 65.9375 98.9875 67.7531C99.0906 64.7937 98.8969 61.3812 102.178 59.7437C105.847 57.9094 108.656 60.9125 110.862 62.35C111.344 61.3937 111.575 59.9844 111.831 58.525C111.978 57.6781 112.797 57.1094 113.653 57.2656Z"
      fill="white"
    />
    <path
      d="M104.313 85.625H98.375C97.8562 85.625 97.4375 85.2063 97.4375 84.6875V81.875C97.4375 81.3562 97.8562 80.9375 98.375 80.9375H104.313C104.831 80.9375 105.25 81.3562 105.25 81.875V84.6875C105.25 85.2063 104.831 85.625 104.313 85.625Z"
      fill="white"
    />
    <path
      d="M110.837 30.2281L106.637 34.4281C106.272 34.7937 105.678 34.7937 105.312 34.4281L103.325 32.4375C102.959 32.0719 102.959 31.4781 103.325 31.1125L107.525 26.9125C107.891 26.5469 108.484 26.5469 108.85 26.9125L110.837 28.9C111.203 29.2656 111.203 29.8594 110.837 30.2281Z"
      fill="white"
    />
    <path
      d="M62.125 40.3125H59.3125C58.7938 40.3125 58.375 39.8937 58.375 39.375V33.4375C58.375 32.9188 58.7938 32.5 59.3125 32.5H62.125C62.6437 32.5 63.0625 32.9188 63.0625 33.4375V39.375C63.0625 39.8937 62.6437 40.3125 62.125 40.3125Z"
      fill="white"
    />
    <path
      d="M84.5719 61.4219L82.2281 59.8594C81.7875 59.5656 81.6594 58.9719 81.9625 58.5375C88.3406 49.4625 100.637 40.0031 113.647 39.5469C114.181 39.5313 114.625 39.975 114.625 40.5063V43.3219C114.625 43.8219 114.231 44.2125 113.731 44.2344C102.847 44.6656 91.6969 52.8625 85.8312 61.1844C85.5406 61.6 84.9906 61.7031 84.5719 61.4219Z"
      fill="white"
    />
  </Icon>
)
