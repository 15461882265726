import React, {useState} from 'react'
import {Props} from './types'
import CountUp from 'react-countup'
import {Modal} from '@/components/common'
import {FaExternalLinkAlt} from 'react-icons/fa'

const usernames = [
  'aaronmorgante',
  'aboveandbeyond',
  'adandspike01',
  'anthonymorel1',
  'antoniocuellarph',
  'aoneal',
  'apollogramss',
  'arthomegarden',
]

export const TrackCard: React.FC<Props> = ({growth}) => {
  const [openUsernames, setOpenUsernames] = useState(false)

  return (
    <div className="flex flex-col gap-2">
      <p className="text-xs font-bold ml-3">{growth.title}</p>
      <div className="relative flex items-center gap-2">
        <div className="relative rounded-full bg-slate-200 dark:bg-secondary h-9 w-full overflow-hidden">
          {[...new Array(20)].map((_, subIdx) => (
            <div
              key={subIdx}
              className="absolute top-1/2 -translate-y-1/2 w-1.5 h-1.5 rounded-full bg-slate-300 dark:bg-slate-600 opacity-50"
              style={{left: subIdx * 5 + '%'}}
            />
          ))}
          <div
            className="relative h-full rounded-full border border-white dark:border-secondary z-10"
            style={{background: growth.background, width: `${growth.percent}%`}}
          >
            <div className="w-[3px] h-2.5 bg-white rounded-full absolute top-1/2 -translate-y-1/2 right-3" />
          </div>
          {typeof growth.count === 'number' && (
            <span className="absolute top-1/2 -translate-y-1/2 right-2.5 text-xl font-bold">
              <CountUp end={growth.count} />
            </span>
          )}
        </div>
        <button
          type="button"
          onClick={() => setOpenUsernames(true)}
          className="min-w-max text-4xl hover:bg-slate-200 p-1 rounded-sm duration-300"
        >
          📃
        </button>
      </div>
      <Modal open={openUsernames} close={() => setOpenUsernames(false)} title={`${growth.title} (${usernames.length})`}>
        <div className="flex flex-col gap-2">
          {usernames.map(username => (
            <a
              href={`/manage?phoneID=${username}`}
              target="_blank"
              key={username}
              className="flex items-center justify-between px-3 py-1.5 rounded-md bg-slate-100 group hover:text-blue"
            >
              <span className="group-hover:underline duration-200">{username}</span>
              <span className="duration-200">
                <FaExternalLinkAlt />
              </span>
            </a>
          ))}
        </div>
      </Modal>
    </div>
  )
}
