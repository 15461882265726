import React from 'react'
import {Props} from './types'
import {Loader} from '@/components/common'

export const ScriptCard: React.FC<Props> = ({script, isRunning, result}) => (
  <div className="border rounded-md px-4 py-2 flex items-center justify-between">
    <p>{script.username}</p>
    {result ? (
      <div className="flex items-center gap-1 text-sm">
        {result.failed ? (
          <span className="text-xs font-extrabold text-red-600">FAILED</span>
        ) : (
          <>
            <span>{result.total}</span>
            <span>/</span>
            <span className="text-red-500 font-bold">{result.banned}</span>
          </>
        )}
      </div>
    ) : isRunning ? (
      <Loader className="!w-5 !h-5 border-2" parentClassName="w-max" />
    ) : (
      <span className="text-xs">waiting...</span>
    )}
  </div>
)
