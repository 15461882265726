import React from 'react'
import {Loader} from '@/components/common'
import {Props} from './types'

export const TaskCard: React.FC<Props> = ({task, isRunning, result}) => (
  <div className="border rounded-md px-4 py-2 flex items-center justify-between">
    <p>{task.username}</p>
    {result ? (
      <div className="flex items-center gap-1 text-sm">
        {result.failed ? (
          <span className="text-xs font-extrabold text-red-600">FAILED</span>
        ) : result.completed ? (
          <span className="text-xs font-extrabold text-green-600">COMPLETED</span>
        ) : (
          <span className="text-xs font-extrabold text-blue-600">STILL IN PROGRESS</span>
        )}
      </div>
    ) : isRunning ? (
      <Loader className="!w-5 !h-5 border-2" parentClassName="w-max" />
    ) : (
      <span className="text-xs">waiting...</span>
    )}
  </div>
)
