import React, {useState} from 'react'
import {CreateFormData, Props} from './types'
import {Field, Switch, Textarea} from '@/components/common'
import {SubmitHandler, useFieldArray, useForm} from 'react-hook-form'
import {Form} from '@/components/ui/form'
import {Button} from '@/components/ui/button'
import {cn} from '@/lib/utils'
import {ScrapingTaskACT, ScrapingTaskMainType, ScrapingTaskType} from '@/constants/status'
import {UsernameCard} from './components'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useCreateTaskMutation} from '@/features/scraping-task'
import {useToast} from '@/components/ui/use-toast'

export const CreateForm: React.FC<Props> = ({profile}) => {
  const [createTask] = useCreateTaskMutation()
  const form = useForm<CreateFormData>({
    defaultValues: {
      taskUsernames: [{value: ''}],
      act: ScrapingTaskACT.NORMAL,
    },
  })
  const {
    fields: usernames,
    append: appendUsername,
    remove: removeUsername,
  } = useFieldArray({control: form.control, name: 'taskUsernames'})
  const [textEditor, setTextEditor] = useState(false)
  const taskUsernames = form.watch('taskUsernames') || []
  const isBlacklisted = form.watch('isBlacklisted')
  const handleRequest = useHandleRequest()
  const [isCreating, setIsCreating] = useState(false)
  const {toast} = useToast()

  const onSubmit: SubmitHandler<CreateFormData> = async formData => {
    const apiKey = formData.apiKey
    const act = formData.act
    if (!apiKey) {
      return form.setError('apiKey', {message: 'API key is required'})
    } else {
      form.setError('apiKey', {message: ''})
    }

    if (!act) {
      return form.setError('act', {message: 'act is required'})
    } else {
      form.setError('act', {message: ''})
    }
    let success = false

    let taskType = ScrapingTaskType.NORMAL
    switch (act) {
      case ScrapingTaskACT.HASHTAG:
        taskType = ScrapingTaskType.HASHTAG
        break
      case ScrapingTaskACT.NORMAL:
        taskType = ScrapingTaskType.NORMAL
        break
      case ScrapingTaskACT.LOCATION:
        taskType = ScrapingTaskType.LOCATION
        break
    }

    setIsCreating(true)
    for (let index = 0; index < taskUsernames.length; index++) {
      const taskUsername = taskUsernames[index]
      if (taskUsername.value) {
        await handleRequest({
          request: async () => {
            const result = await createTask({
              username: taskUsername.value,
              mainType: isBlacklisted ? ScrapingTaskMainType.BLACKLISTED : ScrapingTaskMainType.NORMAL,
              profileID: profile._id,
              act: +act,
              apiKey,
              warned: taskUsername.warned,
              taskType,
            })
            return result
          },
          onSuccess: () => {
            removeUsername(index)
            success = true
          },
          onError: error => {
            if (error.error.statusCode === 409) {
              form.setValue(`taskUsernames.${index}.warned`, true)
            }
          },
        })
      }
    }
    if (success) {
      toast({title: 'Tasks successfully created!'})
    }
    setIsCreating(false)
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <h3 className="text-2xl font-semibold mb-2">Create Form</h3>
        <Field
          type="input"
          name="apiKey"
          control={form.control}
          componentProps={{label: 'Api key', placeholder: 'Enter api key...'}}
        />
        <div className="flex items-center justify-between mt-2">
          <Switch checked={textEditor} onChange={setTextEditor} label="Text Editor" />
          <button
            onClick={() => form.setValue('taskUsernames', [{value: '', warned: false}])}
            type="button"
            className="text-red-600"
          >
            Empty
          </button>
        </div>
        <div className="flex flex-col gap-1 my-3">
          {textEditor ? (
            <Textarea
              placeholder="Enter usernames..."
              className="h-32"
              value={taskUsernames.map(username => username.value).join('\n')}
              onChange={event =>
                form.setValue(
                  'taskUsernames',
                  event.target.value.split('\n').map(value => ({value, warned: false})),
                )
              }
            />
          ) : (
            <>
              {usernames.map((username, index) => (
                <UsernameCard
                  key={username.id}
                  form={form}
                  index={index}
                  onRemove={() => removeUsername(index)}
                  isBlacklisted={isBlacklisted}
                  profile={profile}
                />
              ))}
            </>
          )}
        </div>
        <div className="flex flex-col mb-3 gap-3">
          <Field type="switch" name="isBlacklisted" control={form.control} componentProps={{label: 'Black List'}} />
          <Field
            type="radio-group"
            name="act"
            control={form.control}
            componentProps={{
              options: [
                {label: 'Account', value: ScrapingTaskACT.NORMAL},
                {label: 'Location', value: ScrapingTaskACT.LOCATION},
                {label: 'Hashtag', value: ScrapingTaskACT.HASHTAG},
              ],
            }}
          />
        </div>
        <div className={cn('grid gap-2', taskUsernames.length > 1 ? 'grid-cols-2' : 'grid-cols-1')}>
          <Button variant="outline" onClick={() => appendUsername({value: '', warned: false})}>
            Add Task
          </Button>
          {taskUsernames.length > 1 && (
            <Button
              loading={isCreating}
              htmlType="submit"
              className={isBlacklisted ? 'bg-black hover:bg-zinc-900' : ''}
            >
              Create All
            </Button>
          )}
        </div>
      </form>
    </Form>
  )
}
