import Select, {Option} from 'rc-select'
import React, {useMemo} from 'react'
import {Props, ValueProps} from './types'

export const MultiSelect: React.FC<Props> = ({label, options = [], value, onChange, defaultValue, placeholder}) => {
  const valueProps = useMemo(() => {
    const props: ValueProps = {}

    if (onChange) {
      props.value = value || ([] as any)
      props.onChange = onChange
    }

    if (defaultValue) {
      props.defaultValue = defaultValue || ([] as any)
    }

    return props
  }, [value, onChange, defaultValue])

  return (
    <label>
      {label && <span className="text-xs mb-0.5 capitalize">{label}</span>}
      <Select
        choiceTransitionName="rc-select-selection__choice-zoom"
        className="!w-full blueTheme"
        animation="slide-up"
        mode="multiple"
        allowClear
        optionFilterProp="children"
        optionLabelProp="children"
        dropdownClassName="blueTheme"
        placeholder={placeholder || 'Please select'}
        tokenSeparators={[' ', ',']}
        {...valueProps}
      >
        {options?.map(option => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </label>
  )
}
