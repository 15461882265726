import {Navigate, useLocation} from 'react-router-dom'
import {Loader} from './components/common'
import {PublicRoute} from './constants/public-routes/public-routes'
import {useGetUserQuery} from './features/auth'
import {AppRouter} from './router'
import {Layout} from './components/layout'
import {ThemeProvider} from './components/ui/theme-provider'

export const App = () => {
  const {data: user, isLoading} = useGetUserQuery('')
  const location = useLocation()

  if (isLoading) {
    return (
      <ThemeProvider>
        <Loader parentClassName="h-screen w-screen dark:bg-background" />
      </ThemeProvider>
    )
  }

  if (!user && !Object.values(PublicRoute).includes(location.pathname as PublicRoute)) {
    return <Navigate to="/auth/login" />
  }

  if (user && Object.values(PublicRoute).includes(location.pathname as PublicRoute)) {
    return <Navigate to="/" />
  }

  return (
    <Layout>
      <AppRouter />
    </Layout>
  )
}
