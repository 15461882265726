import {AccordionContent, AccordionItem, AccordionTrigger} from '@/components/ui/accordion'
import {NicheTask} from '..'
import {Props} from './types'

export const NicheBlock: React.FC<Props> = ({nicheBlock, onClick, isAdded}) => (
  <AccordionItem value={nicheBlock.phoneID}>
    <AccordionTrigger>
      {nicheBlock.phoneID} ({nicheBlock?.tasks?.length || 0})
    </AccordionTrigger>
    <AccordionContent>
      <div className="flex flex-col gap-2 py-1">
        {nicheBlock.tasks.map((task: any) => (
          <NicheTask key={task.id} task={task} onClick={() => onClick(nicheBlock.phoneID, task)} isAdded={isAdded} />
        ))}
      </div>
    </AccordionContent>
  </AccordionItem>
)
