import React from 'react'
import {Field, Modal} from '@/components/common'
import {ClientProfileFormData, Props} from './types'
import {Form} from '@/components/ui/form'
import {useForm} from 'react-hook-form'
import {validateEmail} from '@/utils/validate-email'
import {Button} from '@/components/ui/button'

export const BaseClientProfileForm: React.FC<Props> = ({
  open,
  close,
  onSubmit,
  isSubmitting,
  submitText,
  title,
  isModalLoading,
  defaultValues,
}) => {
  // const [getAllGroups, {data: {data: groups = []} = {}, isLoading}] = useLazyGetAllClientGroupsQuery()
  const form = useForm<ClientProfileFormData>({defaultValues})

  // const fetchAllGroups = async () => {
  //   await handleRequest({
  //     request: async () => {
  //       const result = await getAllGroups({})
  //       return result
  //     },
  //   })
  // }

  // useEffect(() => {
  //   if (open && !groups.length) {
  //     fetchAllGroups()
  //   }
  // }, [open])

  return (
    <Modal open={open} close={close} title={title || 'Create Profile'} isLoading={isModalLoading}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <Field
            type="input"
            control={form.control}
            name="profileName"
            rules={{required: 'Please enter the profile name'}}
            componentProps={{label: 'Profile Name', placeholder: 'Client #1', disabled: true}}
          />
          <Field
            name="clientEmail"
            control={form.control}
            type="input"
            componentProps={{
              label: 'Client Email',
              placeholder: 'info@gmail.com',
            }}
            rules={{
              validate: (value: string) =>
                value ? (validateEmail(value) ? undefined : 'Please enter correct email') : undefined,
            }}
          />
          <Field
            name="expectedResult"
            control={form.control}
            type="input"
            componentProps={{type: 'number', label: 'Expected result', placeholder: '9999'}}
          />
          {/* <Field
            type="select"
            control={form.control}
            name="groupID"
            componentProps={{
              label: 'Group',
              placeholder: 'Select group',
              options: groups.map(group => ({label: group.groupName, value: group._id})),
            }}
          /> */}
          <Button loading={isSubmitting} htmlType="submit" className="mt-2">
            {submitText || 'Create Profile'}
          </Button>
        </form>
      </Form>
    </Modal>
  )
}
