import {EndpointNameProfile} from '@/constants/endpoints'
import {baseApi} from '..'
import {RTKTagName} from '@/constants/rtk-tag-name'
import {
  CreateProfilePostRequest,
  CreateProfilePostResponse,
  CreateProfileRequest,
  CreateProfileResponse,
  DeleteProfileImagesRequest,
  DeleteProfileImagesResponse,
  DeleteProfilePostRequest,
  DeleteProfilePostResponse,
  DeleteProfileRequest,
  DeleteProfileResponse,
  GetAllProfilesRequest,
  GetAllProfilesResponse,
  GetCSVRequest,
  GetCSVResponse,
  GetProfileChildAccountsRequest,
  GetProfileChildAccountsResponse,
  GetProfileImagesRequest,
  GetProfileImagesResponse,
  GetProfilePackageRequest,
  GetProfilePackageResponse,
  GetProfilePostsRequest,
  GetProfilePostsResponse,
  GetProfileReportRequest,
  GetProfileReportResponse,
  GetProfileRequest,
  GetProfileResponse,
  GetProfilesAndGroupsRequest,
  GetProfilesAndGroupsResponse,
  GetReviewProfilesRequest,
  GetReviewProfilesResponse,
  SaveProfileAccountsRequest,
  SaveProfileAccountsResponse,
  UpdateProfileDataRequest,
  UpdateProfileDataResponse,
  UpdateProfileDetailsRequest,
  UpdateProfileDetailsResponse,
  UpdateProfilePackageRequest,
  UpdateProfilePackageResponse,
  UploadCSVRequest,
  UploadCSVResponse,
  UploadProfileImagesZIPRequest,
  UploadProfileImagesZIPResponse,
} from './types'

const ProfileExtendedEndpoints = baseApi.injectEndpoints({
  endpoints: build => ({
    getProfilesAndGroups: build.query<GetProfilesAndGroupsResponse, GetProfilesAndGroupsRequest>({
      query: params => ({
        url: EndpointNameProfile.GET_PROFILES_AND_GROUPS,
        method: 'GET',
        params,
      }),
      providesTags: [RTKTagName.PROFILES, RTKTagName.GROUP],
    }),

    getReviewProfiles: build.query<GetReviewProfilesResponse, GetReviewProfilesRequest>({
      query: params => ({
        url: EndpointNameProfile.GET_REVIEW_PROFILES,
        method: 'GET',
        params,
      }),
      providesTags: [RTKTagName.PROFILES],
    }),

    getAllProfiles: build.query<GetAllProfilesResponse, GetAllProfilesRequest>({
      query: params => ({
        url: EndpointNameProfile.GET_ALL_PROFILES,
        method: 'GET',
        params,
      }),
      providesTags: [RTKTagName.PROFILES],
    }),

    getProfileReport: build.query<GetProfileReportResponse, GetProfileReportRequest>({
      query: ({id}) => ({
        url: EndpointNameProfile.GET_PROFILE_REPORT + id,
        method: 'GET',
      }),
      providesTags: [RTKTagName.PROFILE],
    }),

    getProfilePackage: build.query<GetProfilePackageResponse, GetProfilePackageRequest>({
      query: ({id}) => ({
        url: EndpointNameProfile.PROFILE_PACKAGE + id,
        method: 'GET',
      }),
      providesTags: [RTKTagName.PROFILE],
    }),

    updateProfilePackage: build.mutation<UpdateProfilePackageResponse, UpdateProfilePackageRequest>({
      query: ({id, body}) => ({
        url: EndpointNameProfile.PROFILE_PACKAGE + id,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [RTKTagName.PROFILE],
    }),

    getProfileChildAccounts: build.query<GetProfileChildAccountsResponse, GetProfileChildAccountsRequest>({
      query: ({id, params}) => ({
        url: EndpointNameProfile.GET_PROFILE_CHILD_ACCOUNTS + id,
        method: 'GET',
        params,
      }),
      providesTags: [RTKTagName.PROFILE],
    }),

    getProfile: build.query<GetProfileResponse, GetProfileRequest>({
      query: ({id}) => ({
        url: EndpointNameProfile.PROFILE + id,
        method: 'GET',
      }),
      providesTags: [RTKTagName.PROFILE, RTKTagName.PROFILE_DATA],
    }),

    createProfile: build.mutation<CreateProfileResponse, CreateProfileRequest>({
      query: body => ({
        url: EndpointNameProfile.CREATE_PROFILE,
        method: 'POST',
        body,
      }),
      invalidatesTags: [RTKTagName.PROFILES],
    }),

    updateProfileDetails: build.mutation<UpdateProfileDetailsResponse, UpdateProfileDetailsRequest>({
      query: ({id, body}) => ({
        url: EndpointNameProfile.UPDATE_PROFILE_DETAILS + id,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [RTKTagName.PROFILE, RTKTagName.PROFILES, RTKTagName.STOPPED_PHONE],
    }),

    updateProfileData: build.mutation<UpdateProfileDataResponse, UpdateProfileDataRequest>({
      query: ({id, body}) => ({
        url: EndpointNameProfile.UPDATE_PROFILE_DATA + id,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [RTKTagName.PROFILE_DATA],
    }),

    deleteProfile: build.mutation<DeleteProfileResponse, DeleteProfileRequest>({
      query: ({id}) => ({
        url: EndpointNameProfile.PROFILE + id,
        method: 'DELETE',
      }),
      invalidatesTags: [RTKTagName.PROFILE, RTKTagName.PROFILES],
    }),

    uploadCSV: build.mutation<UploadCSVResponse, UploadCSVRequest>({
      query: ({id, body}) => ({
        url: EndpointNameProfile.CSV + id,
        method: 'POST',
        body,
      }),
    }),

    getCSV: build.query<GetCSVResponse, GetCSVRequest>({
      query: ({id}) => ({
        url: EndpointNameProfile.CSV + id,
        method: 'GET',
      }),
      providesTags: [RTKTagName.PROFILE],
    }),

    getProfileImages: build.query<GetProfileImagesResponse, GetProfileImagesRequest>({
      query: ({id}) => ({
        url: EndpointNameProfile.IMAGES + id,
        method: 'GET',
      }),
      providesTags: [RTKTagName.PROFILE, RTKTagName.PROFILE_IMAGES],
    }),

    deleteProfileImages: build.mutation<DeleteProfileImagesResponse, DeleteProfileImagesRequest>({
      query: ({id, params}) => ({
        url: EndpointNameProfile.IMAGES + id,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: [RTKTagName.PROFILE_IMAGES],
    }),

    uploadProfileImagesZIP: build.mutation<UploadProfileImagesZIPResponse, UploadProfileImagesZIPRequest>({
      query: ({id, body}) => ({
        url: EndpointNameProfile.IMAGES + id,
        method: 'POST',
        body,
      }),
      invalidatesTags: [RTKTagName.PROFILE_IMAGES],
    }),

    getProfilePosts: build.query<GetProfilePostsResponse, GetProfilePostsRequest>({
      query: ({id}) => ({
        url: EndpointNameProfile.POSTS + id,
        method: 'GET',
      }),
      providesTags: [RTKTagName.PROFILE_POSTS],
    }),

    createProfilePosts: build.mutation<CreateProfilePostResponse, CreateProfilePostRequest>({
      query: ({id, body}) => ({
        url: EndpointNameProfile.POSTS + id,
        method: 'POST',
        body,
      }),
      invalidatesTags: [RTKTagName.PROFILE_POSTS],
    }),

    deleteProfilePost: build.mutation<DeleteProfilePostResponse, DeleteProfilePostRequest>({
      query: ({id}) => ({
        url: EndpointNameProfile.POSTS + id,
        method: 'DELETE',
      }),
      invalidatesTags: [RTKTagName.PROFILE_POSTS],
    }),

    saveProfileAccounts: build.mutation<SaveProfileAccountsResponse, SaveProfileAccountsRequest>({
      query: ({id, body}) => ({
        url: EndpointNameProfile.ACCOUNTS + id,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useLazyGetProfilesAndGroupsQuery,
  useLazyGetAllProfilesQuery,
  useGetProfileQuery,
  useLazyGetProfileQuery,
  useGetProfilePackageQuery,
  useLazyGetProfileReportQuery,
  useGetProfileChildAccountsQuery,
  useLazyGetProfileChildAccountsQuery,
  useUpdateProfilePackageMutation,
  useUpdateProfileDataMutation,
  useUpdateProfileDetailsMutation,
  useCreateProfileMutation,
  useDeleteProfileMutation,
  useLazyGetReviewProfilesQuery,
  useUploadCSVMutation,
  useGetCSVQuery,
  useGetProfileImagesQuery,
  useDeleteProfileImagesMutation,
  useUploadProfileImagesZIPMutation,
  useGetProfilePostsQuery,
  useCreateProfilePostsMutation,
  useDeleteProfilePostMutation,
  useSaveProfileAccountsMutation,
} = ProfileExtendedEndpoints
