import React from 'react'
import {cn} from '@/lib/utils'
import {Props} from './types'
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from '@/components/ui/dropdown-menu'

export const SmallDropdown: React.FC<Props> = ({label, contentClassName, buttonClassName, buttons = []}) => (
  <DropdownMenu>
    <DropdownMenuTrigger asChild>{label}</DropdownMenuTrigger>
    <DropdownMenuContent className={cn('w-36', contentClassName)}>
      {buttons?.map((button, idx) => (
        <DropdownMenuItem
          key={idx}
          onClick={button.onClick as any}
          className={cn(
            `flex items-center gap-2 cursor-pointer text-xs ${idx !== buttons.length - 1 && 'mb-2'}`,
            buttonClassName,
            button.className,
          )}
        >
          {button.icon}
          <span>{button.label}</span>
        </DropdownMenuItem>
      ))}
    </DropdownMenuContent>
  </DropdownMenu>
)
