import {useUpdateClientProfileMutation} from '@/features/client-profiles'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {SubmitHandler} from 'react-hook-form'
import {ClientProfileFormData} from '../base-client-profile-form/types'
import React from 'react'
import {Props} from './types'
import {BaseClientProfileForm} from '..'
import {useToast} from '@/components/ui/use-toast'

export const EditClientProfileForm: React.FC<Props> = ({open, close, clientProfile}) => {
  const [updateProfile, {isLoading}] = useUpdateClientProfileMutation()
  const handleRequest = useHandleRequest()
  const {toast} = useToast()

  const onSubmit: SubmitHandler<ClientProfileFormData> = async formData => {
    await handleRequest({
      request: async () => {
        delete (formData as any).profileName
        const result = await updateProfile({
          id: clientProfile._id,
          body: {
            expectedResult: formData.expectedResult,
            clientEmail: formData.clientEmail,
          },
        })
        return result
      },
      onSuccess: () => {
        toast({title: 'Profile successfully updated'})
        close()
      },
    })
  }

  return (
    <BaseClientProfileForm
      open={open}
      close={close}
      onSubmit={onSubmit}
      submitText="Update Profile"
      title={`Edit profile (${clientProfile.profileName})`}
      isSubmitting={isLoading}
      defaultValues={{
        profileName: clientProfile.profileName,
        expectedResult: clientProfile.expectedResult,
        clientEmail: clientProfile.clientEmail,
        groupID: clientProfile.group,
      }}
    />
  )
}
