/* eslint-disable react-hooks/exhaustive-deps */
import {DeleteModal, Loader, SideMenu} from '@/components/common'
import {Button} from '@/components/ui/button'
import React, {useEffect, useMemo, useState} from 'react'
import {Props} from './types'
import {
  CompletionModal,
  CreateForm,
  NichesSection,
  ScrapingTasksSection,
  ServerTasksSection,
  UploadFileToBlacklist,
} from './components'
import {useDeleteFromBotServerMutation, useLazyGetProfileTasksQuery} from '@/features/scraping-task'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useToast} from '@/components/ui/use-toast'
import {FaCloudDownloadAlt, FaCloudUploadAlt, FaTasks} from 'react-icons/fa'
import {SERVER_URI, SERVER_URL} from '@/constants/server-url'
import {useHandleError} from '@/hooks/use-handle-error'
import {storage} from '@/utils/storage'

export const Scraping: React.FC<Props> = ({profile}) => {
  const [deleteTask, {isLoading: isDeletingAll}] = useDeleteFromBotServerMutation()
  const [getTasks, {data: {data: profileTasks = {}} = {}, isFetching}] = useLazyGetProfileTasksQuery()
  const [open, setOpen] = useState(false)
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false)
  const [openCompletionModal, setOpenCompletionModal] = useState(false)
  const [fetchingBlacklist, setFetchingBlacklist] = useState(false)
  const [fetchingGroupBlacklist, setFetchingGroupBlacklist] = useState(false)
  const [openUploadBlacklist, setOpenUploadBlacklist] = useState(false)
  const handleRequest = useHandleRequest()
  const handleError = useHandleError()
  const {toast} = useToast()
  const serverTasksExisted = useMemo(
    () =>
      Boolean(
        (profileTasks.orders?.pending?.length || 0) +
          (profileTasks.orders?.processing?.length || 0) +
          (profileTasks.orders?.testing?.length || 0) +
          (profileTasks.orders?.tested?.length || 0) +
          (profileTasks.orders?.completed?.length || 0) +
          (profileTasks.orders?.notFounded?.length || 0) +
          (profileTasks.orders?.rejected?.length || 0),
      ),
    [profileTasks],
  )

  const fetchTasks = async () => {
    await handleRequest({
      request: async () => {
        const result = await getTasks({id: profile._id})
        return result
      },
    })
  }

  const onDeleteAll = async () => {
    await handleRequest({
      request: async () => {
        const result = await deleteTask({id: profile._id, params: {deleteAll: true}})
        return result
      },
      onSuccess: () => {
        toast({title: 'All the tasks successfully deleted!'})
        setOpenDeleteAlert(false)
      },
    })
  }

  const fetchBlacklist = async () => {
    setFetchingBlacklist(true)

    try {
      let response = await fetch(`${SERVER_URL}/scraping-tasks/blacklist/${profile._id}`, {
        headers: {
          Authorization: storage.getAccessToken(),
        },
      } as any)
      const result = await response.json()

      response = await fetch(`${SERVER_URI}/${result.data}`, {
        headers: {
          Authorization: storage.getAccessToken(),
        },
      } as any)
      const blacklistedUsernames = await response.text()

      try {
        const error = JSON.parse(blacklistedUsernames)
        handleError(error)
      } catch (ex) {
        await fetch(`${SERVER_URL}/scraping-tasks/blacklist/${result.data}`, {
          method: 'DELETE',
          headers: {
            Authorization: storage.getAccessToken(),
          },
        } as any)

        const blob = new Blob([blacklistedUsernames], {type: 'text/plain'})
        const a = document.createElement('a')
        a.href = URL.createObjectURL(blob)
        a.download = `${profile.phoneID}.txt`
        document.body.appendChild(a)
        a.click()

        document.body.removeChild(a)
      }
    } catch (ex) {
      handleError(ex)
    }

    setFetchingBlacklist(false)
  }

  const fetchGroupBlacklist = async () => {
    if (profile.group?._id) {
      setFetchingGroupBlacklist(true)

      try {
        let response = await fetch(`${SERVER_URL}/scraping-tasks/group-blacklist/${profile.group._id}`, {
          headers: {
            Authorization: storage.getAccessToken(),
          },
        } as any)

        const result = await response.json()

        response = await fetch(`${SERVER_URI}/${result.data}`, {
          headers: {
            Authorization: storage.getAccessToken(),
          },
        } as any)
        const blacklistedUsernames = await response.text()
        try {
          const error = JSON.parse(blacklistedUsernames)
          handleError(error)
        } catch (ex) {
          await fetch(`${SERVER_URL}/scraping-tasks/blacklist/${result.data}`, {
            method: 'DELETE',
            headers: {
              Authorization: storage.getAccessToken(),
            },
          } as any)

          const blob = new Blob([blacklistedUsernames], {type: 'text/plain'})
          const a = document.createElement('a')
          a.href = URL.createObjectURL(blob)
          a.download = `${profile.group.groupName}-group.txt`
          document.body.appendChild(a)
          a.click()

          document.body.removeChild(a)
        }
      } catch (ex) {
        handleError(ex)
      }

      setFetchingGroupBlacklist(false)
    }
  }

  useEffect(() => {
    if (open) {
      fetchTasks()
    }
  }, [open])

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="outline" className="flex gap-2 rounded h-[34px] text-xs">
        <FaTasks className="text-sm" />
        <span>Scraping</span>
      </Button>
      <SideMenu
        open={open}
        close={() => setOpen(false)}
        title={`Manage Scraping (${profile.phoneID})`}
        contentClassName="!min-w-[100vw]"
      >
        <div className="flex items-center justify-end gap-3">
          <Button onClick={() => setOpenUploadBlacklist(true)} className="flex items-center gap-2 mr-5">
            <FaCloudUploadAlt />
            <span>Upload Blacklist File</span>
          </Button>
          <Button onClick={fetchBlacklist} loading={fetchingBlacklist} className="flex items-center gap-2">
            <FaCloudDownloadAlt />
            <span>Download Blacklist</span>
          </Button>
          {profile.group && (
            <Button onClick={fetchGroupBlacklist} loading={fetchingGroupBlacklist} className="flex items-center gap-2">
              <FaCloudDownloadAlt />
              <span>Download Group Blacklist</span>
            </Button>
          )}
        </div>
        <NichesSection profile={profile} />
        <div className="max-w-[500px] mx-auto mt-4 mb-20">
          <CreateForm profile={profile} />
        </div>
        {isFetching ? (
          <Loader parentClassName="h-64" />
        ) : !(serverTasksExisted || profileTasks.normalTasks?.length || profileTasks.blacklistedTasks?.length) ? (
          <div className="h-20 w-full grid place-content-center text-slate-400 mb-20">No any tasks created yet...</div>
        ) : (
          <div className="flex flex-col gap-6">
            {profileTasks.normalTasks?.length || profileTasks.blacklistedTasks?.length ? (
              <Button onClick={() => setOpenCompletionModal(true)} className="w-max ml-auto">
                Check Tasks Completion
              </Button>
            ) : (
              ''
            )}
            {profileTasks.normalTasks?.length ? (
              <ScrapingTasksSection label="Scraper Tasks" tasks={profileTasks.normalTasks} />
            ) : (
              ''
            )}
            {profileTasks.blacklistedTasks?.length ? (
              <ScrapingTasksSection label="Scraper Blacklisted Tasks" tasks={profileTasks.blacklistedTasks} />
            ) : (
              ''
            )}
            {profileTasks.orders?.pending?.length ? (
              <ServerTasksSection label="Pending Tasks" tasks={profileTasks.orders.pending} profile={profile} />
            ) : (
              ''
            )}
            {profileTasks.orders?.processing?.length ? (
              <ServerTasksSection label="Processing Tasks" tasks={profileTasks.orders.processing} profile={profile} />
            ) : (
              ''
            )}
            {profileTasks.orders?.testing?.length ? (
              <ServerTasksSection label="Testing Tasks" tasks={profileTasks.orders.testing} profile={profile} />
            ) : (
              ''
            )}
            {profileTasks.orders?.tested?.length ? (
              <ServerTasksSection label="Tested Tasks" tasks={profileTasks.orders.tested} profile={profile} />
            ) : (
              ''
            )}
            {profileTasks.orders?.completed?.length ? (
              <ServerTasksSection
                label="Completed Tasks"
                tasks={profileTasks.orders.completed}
                restorable
                profile={profile}
              />
            ) : (
              ''
            )}
            {profileTasks.orders?.notFounded?.length ? (
              <ServerTasksSection label="Not found Tasks" tasks={profileTasks.orders.notFounded} profile={profile} />
            ) : (
              ''
            )}
            {profileTasks.orders?.rejected?.length ? (
              <ServerTasksSection label="Rejected Tasks" tasks={profileTasks.orders.rejected} profile={profile} />
            ) : (
              ''
            )}
          </div>
        )}
        {serverTasksExisted && (
          <div className="mt-5 flex items-center justify-end">
            <Button onClick={() => setOpenDeleteAlert(true)} variant="outlined-destructive">
              Delete All Tasks
            </Button>
            <DeleteModal
              open={openDeleteAlert}
              close={() => setOpenDeleteAlert(false)}
              title={`Are you sure to delete all tasks of this phone (${profile.phoneID}) ?`}
              onDelete={onDeleteAll}
              isDeleting={isDeletingAll}
            />
          </div>
        )}
      </SideMenu>
      <CompletionModal
        open={openCompletionModal}
        close={() => setOpenCompletionModal(false)}
        tasks={[...(profileTasks.normalTasks || []), ...(profileTasks.blacklistedTasks || [])]}
      />
      <UploadFileToBlacklist open={openUploadBlacklist} close={() => setOpenUploadBlacklist(false)} profile={profile} />
    </>
  )
}
