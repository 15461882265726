import React from 'react'
import {Props} from './types'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {SubmitHandler} from 'react-hook-form'
import {useToast} from '@/components/ui/use-toast'
import {AccountBaseFormData} from '../base-form/types'
import {BaseForm} from '..'
import {useCreateAccountMutation} from '@/features/accounts'
import {Role} from '@/constants/role'

export const CreateForm: React.FC<Props> = ({open, close}) => {
  const [createAccount, {isLoading}] = useCreateAccountMutation()
  const handleRequest = useHandleRequest()
  const {toast} = useToast()

  const onSubmit: SubmitHandler<AccountBaseFormData> = async formData => {
    await handleRequest({
      request: async () => {
        const result = await createAccount({
          username: formData.username,
          password: formData.password,
          role: formData.isSuperAccount ? Role.SUPER_ACCOUNT : Role.ACCOUNT,
        })
        return result
      },
      onSuccess: () => {
        toast({title: 'Account successfully created!'})
        close()
      },
    })
  }

  return <BaseForm open={open} close={close} onSubmit={onSubmit} isSubmitting={isLoading} />
}
