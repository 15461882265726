import {Button} from '@/components/ui/button'
import React, {useCallback, useState} from 'react'
import {Props} from './types'
import {useGetProfileChildAccountsQuery} from '@/features/profile'
import {Spinner} from '@/components/common'
import {BiSolidUserAccount} from 'react-icons/bi'
import {AccountsModal} from './components'

export const ChildAccounts: React.FC<Props> = ({profile}) => {
  const {data: {data: childAccounts = ''} = {}, isFetching} = useGetProfileChildAccountsQuery({id: profile._id})
  const [open, setOpen] = useState(false)
  const getLessOrMoreResults = useCallback((key: string) => (childAccounts as any)?.[key], [childAccounts])

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="outline" className="flex gap-2 rounded h-[34px] text-xs">
        <BiSolidUserAccount className="text-sm" />
        <span className="flex items-center gap-2">
          <span>Accounts</span>
          {isFetching ? (
            <Spinner className="!w-2.5 !h-2.5 !border-2 border-black" />
          ) : (
            (getLessOrMoreResults('less')?.length || 0) + (getLessOrMoreResults('more')?.length || 0) || 0
          )}
        </span>
      </Button>
      <AccountsModal open={open} close={() => setOpen(false)} phoneID={profile.phoneID} />
    </>
  )
}
