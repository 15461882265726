import React, {MouseEvent, useMemo, useState} from 'react'
import {Props} from './types'
import {RoadmapIcon} from '@/icons/roadmap'
import {BiDotsVerticalRounded} from 'react-icons/bi'
import {DeleteModal, SmallDropdown} from '@/components/common'
import {FaEdit} from 'react-icons/fa'
import {FaCheck, FaRegTrashCan} from 'react-icons/fa6'
import {TaskDetailsModal} from './components'
import {EditTaskForm} from '..'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useDeleteRoadmapTaskMutation, useUpdateRoadmapTaskMutation} from '@/features/roadmap-tasks'
import {useToast} from '@/components/ui/use-toast'
import {RoadMapTaskStatus} from '@/constants/status'
import {cn} from '@/lib/utils'
import {useUserData} from '@/hooks/use-user-data'
import {Role} from '@/constants/role'
import {useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import {MdDragIndicator} from 'react-icons/md'
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from '@/components/ui/tooltip'

export const TaskCard: React.FC<Props> = ({task, index, fetchTasks}) => {
  const user = useUserData()
  const [updateTask] = useUpdateRoadmapTaskMutation()
  const [deleteTask] = useDeleteRoadmapTaskMutation()
  const [openDetails, setOpenDetails] = useState(false)
  const [openEditForm, setOpenEditForm] = useState(false)
  const [openCompleteConfirm, setOpenCompleteConfirm] = useState(false)
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)
  const handleRequest = useHandleRequest()
  const {toast} = useToast()
  const isCompleted = useMemo(() => task.completed === RoadMapTaskStatus.COMPLETED, [task])
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id: task._id})
  const [completing, setCompleting] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const onDropdownButtonClick = (event: MouseEvent<HTMLButtonElement>, callback: () => void) => {
    callback()
    event.stopPropagation()
  }

  const onComplete = async () => {
    setCompleting(true)
    await handleRequest({
      request: async () => {
        const result = await updateTask({
          id: task._id,
          body: {
            completed:
              task.completed === RoadMapTaskStatus.IN_PROGRESS
                ? RoadMapTaskStatus.COMPLETED
                : RoadMapTaskStatus.IN_PROGRESS,
          },
        })
        return result
      },
      onSuccess: () => {
        setTimeout(async () => {
          toast({title: 'Task successfully updated!'})
          setOpenCompleteConfirm(false)
          setOpenDetails(false)
          await fetchTasks()
          setCompleting(false)
        }, 1000)
      },
    })
  }

  const onDelete = async () => {
    setDeleting(true)
    await handleRequest({
      request: async () => {
        const result = await deleteTask({id: task._id})
        return result
      },
      onSuccess: () => {
        setTimeout(async () => {
          close()
          setOpenDetails(false)
          await fetchTasks()
          setDeleting(false)
          toast({title: 'Task successfully deleted!'})
        }, 1000)
      },
    })
  }

  return (
    <>
      <div
        ref={setNodeRef}
        style={{transform: CSS.Transform?.toString(transform), transition}}
        {...attributes}
        // onClick={() => setOpenDetails(true)}
        className={cn(
          'relative rounded-lg bg-slate-100 dark:bg-background dark:border dark:border-secondary cursor-auto duration-150',
          isCompleted ? 'grayscale opacity-60' : '',
        )}
      >
        <div className="flex flex-col justify-between relative overflow-hidden h-full py-4 px-3">
          <div className="flex flex-col items-center justify-start gap-3 pl-4 pr-8">
            <RoadmapIcon type={task.icon} className="min-w-[60px] min-h-[60px]" />
            <p className={cn('font-semibold text-center', isCompleted ? 'line-through text-slate-400' : '')}>
              {task.taskName}
            </p>
          </div>
          {/* <p className={cn('px-4 pt-2 text-sm line-clamp-2', isCompleted ? 'line-through text-slate-400' : '')}>
          {task.description}
        </p> */}
          {task.description ? (
            <TooltipProvider>
              <Tooltip delayDuration={100}>
                <TooltipTrigger>
                  <span
                    // type="button"
                    // onMouseOver={() => setShowDescription(true)}
                    className={cn('mx-4 mt-2 text-sm', isCompleted ? 'line-through text-slate-400' : '')}
                  >
                    Hover to read description
                  </span>
                </TooltipTrigger>
                <TooltipContent className="max-w-[500px] max-h-[300px] overflow-auto">
                  {task.description}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : (
            ''
          )}
          {/* <div
            onMouseLeave={() => setShowDescription(false)}
            className={cn(
              'z-10 absolute top-0 left-0 w-[300%] h-full bg-slate-100 rounded-lg py-2 duration-300',
              showDescription ? 'top-0' : 'top-full',
            )}
          >
            <div className="py-3 px-8 overflow-auto h-full break-all">{task.description}</div>
          </div> */}
        </div>

        <span
          className={cn(
            'z-10 absolute -top-2 -left-2 text-xl bg-slate-200 dark:bg-secondary rounded-full w-10 h-10 grid place-content-center font-bold',
            isCompleted ? 'line-through text-slate-400' : '',
          )}
        >
          {task.order}
        </span>
        {user.subaccount.role === Role.SUPER_SUBACCOUNT && (
          <div className="absolute top-2 right-2 flex items-center gap-0.5 text-lg">
            <button type="button" className="cursor-grab focus:cursor-grabbing" {...listeners}>
              <MdDragIndicator />
            </button>
            <SmallDropdown
              contentClassName={isCompleted ? 'grayscale w-40' : ''}
              buttons={[
                {
                  label: `${isCompleted ? 'Uncomplete' : 'Complete'} Task`,
                  icon: <FaCheck className="text-sm" />,
                  className: 'text-blue hover:!bg-blue hover:!text-white',
                  onClick: event => onDropdownButtonClick(event, () => setOpenCompleteConfirm(true)),
                },
                {
                  label: 'Edit Task',
                  icon: <FaEdit />,
                  onClick: event => onDropdownButtonClick(event, () => setOpenEditForm(true)),
                },
                {
                  label: 'Delete Task',
                  icon: <FaRegTrashCan />,
                  className: 'text-red-600 hover:!bg-red-100 hover:!text-red-600',
                  onClick: event => onDropdownButtonClick(event, () => setOpenDeleteConfirm(true)),
                },
              ]}
              label={
                <button
                  type="button"
                  className="text-lg duration-150 w-6 h-6 rounded-full grid place-content-center hover:bg-slate-200"
                >
                  <BiDotsVerticalRounded />
                </button>
              }
            />
          </div>
        )}
      </div>
      <TaskDetailsModal
        open={openDetails}
        close={() => setOpenDetails(() => false)}
        index={index}
        task={task}
        onEditClick={() => setOpenEditForm(true)}
        onDeleteClick={() => setOpenDeleteConfirm(true)}
        onCompleteClick={() => setOpenCompleteConfirm(true)}
        contentClassName={isCompleted ? 'grayscale' : ''}
        editable={user.subaccount.role === Role.SUPER_SUBACCOUNT}
      />
      <EditTaskForm
        open={openEditForm}
        close={() => setOpenEditForm(false)}
        task={task}
        contentClassName={isCompleted ? 'grayscale' : ''}
        fetchTasks={fetchTasks}
      />
      <DeleteModal
        open={openDeleteConfirm}
        close={() => setOpenDeleteConfirm(false)}
        onDelete={onDelete}
        isDeleting={deleting}
        title="Are you sure ?"
        description={`Are you sure to delete this task ? ${task.taskName}`}
        contentClassName={isCompleted ? 'grayscale' : ''}
      />
      <DeleteModal
        open={openCompleteConfirm}
        close={() => setOpenCompleteConfirm(false)}
        onDelete={onComplete}
        isDeleting={completing}
        title="Are you sure ?"
        description={`Are you sure to ${isCompleted ? 'uncomplete' : 'complete'} this task ? ${task.taskName}`}
        deleteButtonText={isCompleted ? 'Uncomplete' : 'Complete'}
        deleteButtonVariant="outline"
        contentClassName={isCompleted ? 'grayscale' : ''}
      />
    </>
  )
}
