import {TableCell, TableRow} from '@/components/ui/table'
import React, {useState} from 'react'
import {Props} from './types'
import {Button} from '@/components/ui/button'
import {FaRegTrashCan} from 'react-icons/fa6'
import {ScrapingTaskType} from '@/constants/status'
import {cn} from '@/lib/utils'
import {FaCheck, FaExternalLinkAlt} from 'react-icons/fa'
import {useCompleteTaskMutation, useDeleteFromScraperMutation} from '@/features/scraping-task'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {DeleteModal} from '@/components/common'
import dayjs from 'dayjs'

export const ScrapingTaskRow: React.FC<Props> = ({task}) => {
  const [completeTask, {isLoading: isCompleting}] = useCompleteTaskMutation()
  const [deleteTask, {isLoading: isDeleting}] = useDeleteFromScraperMutation()
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false)
  const handleRequest = useHandleRequest()

  const onCompleteTask = async () => {
    await handleRequest({
      request: async () => {
        const result = await completeTask({id: task._id})
        return result
      },
    })
  }

  const onDelete = async () => {
    await handleRequest({
      request: async () => {
        const result = await deleteTask({id: task._id})
        return result
      },
      onSuccess: () => {
        setOpenDeleteAlert(false)
      },
    })
  }

  return (
    <TableRow>
      <TableCell className={tdClassName}>{task.username}</TableCell>
      <TableCell className={tdClassName}>
        {{
          [ScrapingTaskType.FILTERING]: 'Filtering',
          [ScrapingTaskType.HASHTAG]: 'Hashtag',
          [ScrapingTaskType.NORMAL]: 'Normal',
          [ScrapingTaskType.LOCATION]: 'Location',
        }[task.taskType] || 'Normal'}
      </TableCell>
      <TableCell className={tdClassName}>{task.taskID}</TableCell>
      <TableCell className={tdClassName}>{task.count}</TableCell>
      <TableCell className={tdClassName}>{dayjs(task.createdAt).format('MMM DD, YYYY hh:mm A')}</TableCell>
      <TableCell className={cn(tdClassName, 'w-48')}>
        <div className="flex items-center justify-center gap-3">
          <Button loading={isCompleting} onClick={onCompleteTask} size="icon">
            <FaCheck />
          </Button>
          <Button
            onClick={() => window.open(`https://parser.im/api.php/?key=${task.apiKey}&tid=${task.taskID}`)}
            size="icon"
            variant="outline"
          >
            <FaExternalLinkAlt />
          </Button>
          <Button onClick={() => setOpenDeleteAlert(true)} size="icon" variant="outlined-destructive">
            <FaRegTrashCan />
          </Button>
        </div>
        <DeleteModal
          open={openDeleteAlert}
          close={() => setOpenDeleteAlert(false)}
          onDelete={onDelete}
          isDeleting={isDeleting}
          title={`Are you sure to delete this task (${task.username}) ?`}
        />
      </TableCell>
    </TableRow>
  )
}

const tdClassName = `!border !border-gray-400 dark:!border-secondary text-center text-gray-800 dark:text-white bg-white dark:bg-background !p-1`
