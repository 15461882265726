/* eslint-disable react-hooks/exhaustive-deps */
import {Accordion, AccordionContent, AccordionItem, AccordionTrigger} from '@/components/ui/accordion'
import React, {useEffect, useMemo, useState} from 'react'
import {Props} from './types'
import {Loader} from '@/components/common'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {CircleUserRound, LockKeyhole} from 'lucide-react'
import {useLazyGetVPSScriptAccountsQuery} from '@/features/vps-scripts'

export const AccountsCard: React.FC<Props> = ({script}) => {
  const [getScriptAccounts, {data: {data: accounts = {}} = {}, isFetching}] = useLazyGetVPSScriptAccountsQuery()
  const accountsList = useMemo(() => Object.entries(accounts), [accounts])
  const [fetchCompleted, setFetchCompleted] = useState(false)
  const [open, setOpen] = useState(false)
  const handleRequest = useHandleRequest()

  const fetchScriptAccounts = async () => {
    await handleRequest({
      request: async () => {
        const result = await getScriptAccounts({
          params: {
            profiles: script.profiles.join(','),
          },
        })
        return result
      },
      onSuccess: () => {
        setFetchCompleted(true)
      },
    })
  }

  useEffect(() => {
    if (open && !fetchCompleted) {
      fetchScriptAccounts()
    }
  }, [open])

  return (
    <Accordion type="multiple" className="w-full">
      <AccordionItem value="accounts" className="mb-3 border rounded">
        <AccordionTrigger
          onClick={() => setOpen(prev => !prev)}
          className="px-6 hover:no-underline [&[data-state=open]]:border-b"
        >
          Accounts{accountsList.length ? ` (${accountsList.length})` : ''}
        </AccordionTrigger>
        <AccordionContent className="px-6 py-4 max-h-[200px] overflow-auto">
          {isFetching ? (
            <Loader parentClassName="py-10" />
          ) : accountsList.length ? (
            <div className="flex flex-col gap-2">
              {accountsList?.map(([account, password]) => (
                <div key={account} className="grid grid-cols-2 gap-3">
                  <div className="flex items-center gap-2">
                    <CircleUserRound className="w-4 h-4" />
                    <span>{account}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <LockKeyhole className="w-4 h-4" />
                    <span>{password}</span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-slate-500 text-center py-10">No any accounts available</div>
          )}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}
