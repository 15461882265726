import React, {useState} from 'react'
import {Props} from './types'
import {TableCell, TableRow} from '@/components/ui/table'
import {Button} from '../../../../ui/button'
import {FaExternalLinkAlt} from 'react-icons/fa'
import {IoMdSettings} from 'react-icons/io'
import {DeleteModal, Dropdown, Loader} from '../../../../common'
import {EditClientProfileForm, StatsModal} from '..'
import {useDeleteClientProfileMutation, useSendClientReportMutation} from '@/features/client-profiles'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useToast} from '@/components/ui/use-toast'
import {ReportsModal} from '@/components/pages/manage/components/settings/components/settings-top/components/reports/components'
import {cn} from '@/lib/utils'
import {BiSolidUserAccount} from 'react-icons/bi'
import {AccountsModal} from '@/components/pages/manage/components/settings/components/settings-top/components/child-accounts/components'
import {FaEye} from 'react-icons/fa6'

export const ClientProfileRow: React.FC<Props> = ({clientProfile, cellClassName}) => {
  const [sendReport] = useSendClientReportMutation()
  const [deleteProfile, {isLoading: isDeleting}] = useDeleteClientProfileMutation()
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false)
  const [openEditForm, setOpenEditForm] = useState(false)
  const [openReport, setOpenReport] = useState(false)
  const [openAccounts, setOpenAccounts] = useState(false)
  const handleRequest = useHandleRequest()
  const {toast} = useToast()
  const [openStatsModal, setOpenStatsModal] = useState(false)

  const onDelete = async () => {
    await handleRequest({
      request: async () => {
        const result = await deleteProfile({id: clientProfile._id})
        return result
      },
      onSuccess: () => {
        toast({title: 'Profile successfully archived!'})
        setOpenDeleteAlert(false)
      },
    })
  }

  const onSendRport = async () => {
    await handleRequest({
      request: async () => {
        toast({
          description: (
            <div className="flex items-center gap-2 min-w-max">
              <Loader className="!w-5 !h-5 border-2" />
              <span className="block min-w-max font-semibold">Emailing reports to client...</span>
            </div>
          ),
        })
        const result = await sendReport({id: clientProfile._id})
        return result
      },
      onSuccess: () => {
        toast({title: 'Reports successfully emailed to client!'})
      },
    })
  }

  return (
    <TableRow>
      <TableCell onClick={() => setOpenReport(true)} className={cn(cellClassName, 'cursor-pointer')}>
        {clientProfile.profileName || <span className="text-sm text-slate-400">N/A</span>}
      </TableCell>
      <TableCell onClick={() => setOpenReport(true)} className={cn(cellClassName, 'cursor-pointer')}>
        {clientProfile.clientEmail || <span className="text-sm text-slate-400">N/A</span>}
      </TableCell>
      <TableCell onClick={() => setOpenReport(true)} className={cn(cellClassName, 'cursor-pointer')}>
        {clientProfile.last24hrs || <span className="text-sm text-slate-400">N/A</span>}
      </TableCell>
      <TableCell onClick={() => setOpenReport(true)} className={cn(cellClassName, 'cursor-pointer')}>
        {clientProfile.last48hrs || <span className="text-sm text-slate-400">N/A</span>}
      </TableCell>
      <TableCell onClick={() => setOpenReport(true)} className={cn(cellClassName, 'cursor-pointer')}>
        {clientProfile.last7days || <span className="text-sm text-slate-400">N/A</span>}
      </TableCell>
      <TableCell onClick={() => setOpenReport(true)} className={cn(cellClassName, 'cursor-pointer')}>
        {clientProfile.last30days || <span className="text-sm text-slate-400">N/A</span>}
      </TableCell>
      <TableCell onClick={() => setOpenReport(true)} className={cn(cellClassName, 'cursor-pointer')}>
        {clientProfile.last60days || <span className="text-sm text-slate-400">N/A</span>}
      </TableCell>
      <TableCell onClick={() => setOpenReport(true)} className={cn(cellClassName, 'cursor-pointer')}>
        {clientProfile.totalFollowers || <span className="text-sm text-slate-400">N/A</span>}
      </TableCell>
      <TableCell>
        <div className="flex items-center gap-2">
          <Button onClick={() => setOpenStatsModal(true)} variant="outline" size="icon">
            <FaEye />
          </Button>
          <Dropdown
            triggerBtnProps={{
              size: 'icon',
            }}
            buttons={[
              ...(clientProfile.clientEmail ? [{label: 'Send Report', onClick: onSendRport}] : []),
              // {label: 'View Results'},
              {label: 'Edit Details', onClick: () => setOpenEditForm(true)},
              // {
              //   label: 'Archive',
              //   className: 'border border-red-600 text-red-600 hover:!bg-red-600 hover:!text-white',
              //   onClick: () => setOpenDeleteAlert(true),
              // },
            ]}
            label={<IoMdSettings />}
          />
        </div>
        <EditClientProfileForm open={openEditForm} close={() => setOpenEditForm(false)} clientProfile={clientProfile} />
        <DeleteModal
          open={openDeleteAlert}
          close={() => setOpenDeleteAlert(false)}
          title={`Are you sure to archive this profile (${clientProfile.profileName}) ?`}
          onDelete={onDelete}
          isDeleting={isDeleting}
        />
        <ReportsModal
          open={openReport}
          close={() => setOpenReport(false)}
          phoneID={clientProfile.profileName}
          footer={
            <div className="grid grid-cols-2 gap-2 mt-3">
              <Button
                onClick={() => setOpenAccounts(true)}
                variant="outline"
                className="flex gap-2 rounded h-[34px] text-xs"
              >
                <BiSolidUserAccount className="text-sm" />
                <span className="flex items-center gap-2">
                  <span>Accounts</span>
                </span>
              </Button>
              <Button
                onClick={() => window.open(`/manage?phoneID=${clientProfile.profileName}`)}
                variant="outline"
                className="flex gap-2 rounded h-[34px] text-xs"
              >
                <FaExternalLinkAlt />
                <span>Go to profile</span>
              </Button>
            </div>
          }
        />
        {openAccounts && (
          <AccountsModal open={openAccounts} close={() => setOpenAccounts(false)} phoneID={clientProfile.profileName} />
        )}
        <StatsModal
          open={openStatsModal}
          close={() => setOpenStatsModal(false)}
          growths={clientProfile.growths}
          username={clientProfile.username}
        />
      </TableCell>
    </TableRow>
  )
}
