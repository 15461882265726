import {SubmitHandler} from 'react-hook-form'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {ProfileType} from '@/constants/status'
import {Props} from './types'
import {useCreateProfileMutation} from '@/features/profile'
import {BaseProfileFormData} from '../base-profile-modal/types'
import {BaseProfileModal} from '..'
import {useCloneTaskMutation} from '@/features/scraping-task'
import {useState} from 'react'
import {CreateProfileResponse} from '@/features/profile/types'
import {useNavigate} from 'react-router-dom'

export const CreateProfileModal: React.FC<Props> = ({open, close}) => {
  const [cloneTask] = useCloneTaskMutation()
  const [createProfile, {isLoading: isCreatingProfile}] = useCreateProfileMutation()
  const handleRequest = useHandleRequest()
  const [cloning, setCloning] = useState(false)
  const navigate = useNavigate()

  const onClone = async (profileID: string, selectedBlocks: any[]) => {
    setCloning(true)

    for (const selectedBlock of selectedBlocks || []) {
      for (const blockTask of selectedBlock.tasks) {
        await handleRequest({
          request: async () => {
            const result = await cloneTask({
              id: profileID,
              body: {fileName: blockTask.file_name, filePath: blockTask.file_path},
            })
            return result
          },
        })
      }
    }

    setCloning(false)
  }

  const onSubmit: SubmitHandler<BaseProfileFormData> = async formData => {
    await handleRequest({
      request: async () => {
        const result = await createProfile({
          profileType: ProfileType.NORMAL,
          profileName: formData.profileName,
          phoneID: formData.phoneID,
          groupID: formData.groupID,
          nicheID: formData.nicheID,
        })

        return result
      },
      onSuccess: async ({data}: {data: CreateProfileResponse}) => {
        await onClone(data.data, formData.selectedBlocks)
        close()
        navigate(`/manage?phoneID=${formData.phoneID}`)
      },
    })
  }

  return (
    <BaseProfileModal
      open={open}
      close={close}
      onSubmit={onSubmit}
      isSubmitting={isCreatingProfile || cloning}
      nicheFunctions
    />
  )
}
