/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {Props, RoadmapTaskFormData} from './types'
import {Field, Modal} from '@/components/common'
import {Form} from '@/components/ui/form'
import {useForm} from 'react-hook-form'
import {Button} from '@/components/ui/button'

export const BaseTaskForm: React.FC<Props> = ({
  open,
  close,
  title,
  defaultValues,
  onSubmit,
  submitText,
  isSubmitting,
  isModalLoading,
  contentClassName,
}) => {
  const form = useForm<RoadmapTaskFormData>({defaultValues})

  useEffect(() => {
    if (!open) {
      form.reset()
    }
  }, [open])

  return (
    <Modal
      open={open}
      close={close}
      title={title || 'New task'}
      isLoading={isModalLoading}
      contentClassName={contentClassName}
    >
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-3">
          <Field
            type="input"
            control={form.control}
            name="taskName"
            rules={{required: 'Please enter task name!'}}
            componentProps={{label: 'Task name', placeholder: 'Make it happen!'}}
          />
          <Field
            type="icon-select"
            control={form.control}
            name="icon"
            rules={{required: 'Please select category!'}}
            componentProps={{label: 'Category', placeholder: 'Select Category'}}
          />
          <Field
            type="textarea"
            control={form.control}
            name="description"
            componentProps={{label: 'Description', placeholder: "We're born to make it!"}}
          />
          <Button htmlType="submit" loading={isSubmitting}>
            {submitText || 'Create'}
          </Button>
        </form>
      </Form>
    </Modal>
  )
}
