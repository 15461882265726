import React, {useMemo, useState} from 'react'
import {FaEye, FaEyeSlash} from 'react-icons/fa'
import {Props, ValueProps} from './types'
import {cn} from '@/lib/utils'
import {Input as UIInput} from '@/components/ui/input'
import {FiSearch} from 'react-icons/fi'

export const Input: React.FC<Props> = ({
  label,
  placeholder,
  parentClassName,
  labelClassName,
  inputClassName,
  searchIconClassName,
  defaultValue,
  value,
  onChange,
  type = 'text',
  disabled,
  startingText,
}) => {
  const [valueVisible, setValueVisible] = useState(false)
  const valueProps = useMemo(() => {
    const props: ValueProps = {}

    if (onChange) {
      props.value = value
      props.onChange = event => {
        if (type === 'number') {
          if (/^(?![\D]+$)\d+$/.test(event.target.value) || event.target.value === '') {
            onChange(event)
          }
        } else {
          onChange(event)
        }
      }
    }

    if (defaultValue) {
      props.defaultValue = defaultValue
    }

    return props
  }, [value, defaultValue, onChange, type])

  return (
    <label className={cn('flex flex-col w-full', parentClassName)}>
      {label && <span className={cn('text-xs mb-0.5 capitalize', labelClassName)}>{label}</span>}
      <div className="relative">
        {type === 'search' && (
          <FiSearch className={cn('absolute text-[16px] top-[10px] left-3 text-[#9a9da1]', searchIconClassName)} />
        )}
        {startingText && <span className="absolute top-1/2 -translate-y-1/2 left-3 text-sm">{startingText}</span>}
        <UIInput
          type={type === 'password' ? (valueVisible ? 'text' : 'password') : type}
          placeholder={placeholder}
          className={cn(
            'border rounded h-max !ring-0 !outline-0 !ring-offset-0',
            type === 'password' && 'pr-9',
            type === 'search' && '!pl-9 text-xs font-medium max-h-9 rounded-lg',
            startingText && 'pl-[57px]',
            inputClassName,
          )}
          disabled={disabled}
          {...valueProps}
        />
        {type === 'password' && (
          <button
            type="button"
            onClick={() => setValueVisible(prev => !prev)}
            className="absolute h-[80%] grid place-content-center top-1 right-1 px-2"
          >
            {valueVisible ? <FaEye /> : <FaEyeSlash />}
          </button>
        )}
      </div>
    </label>
  )
}
