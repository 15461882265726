/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {Field, Modal} from '../../../../common'
import {ClientGroupFormData, Props} from './types'
import {useForm} from 'react-hook-form'
import {Form} from '../../../../ui/form'
import {validateEmail} from '@/utils/validate-email'
import {Button} from '@/components/ui/button'

export const BaseClientGroupForm: React.FC<Props> = ({
  open,
  close,
  title,
  onSubmit,
  submitText,
  isSubmitting,
  isModalLoading,
  defaultValues,
  editing,
}) => {
  // const [getClientProfiles, {data: {data: profiles = []} = {}, isLoading}] = useLazyGetAllClientProfilesQuery()
  const form = useForm<ClientGroupFormData>({
    defaultValues,
  })
  // const handleRequest = useHandleRequest()

  // const fetchClientProfiles = async () => {
  //   await handleRequest({
  //     request: async () => {
  //       const result = await getClientProfiles({})
  //       return result
  //     },
  //   })
  // }

  // useEffect(() => {
  //   if (open && !profiles.length) {
  //     fetchClientProfiles()
  //   } else if (!open) {
  //     form.reset()
  //   }
  // }, [open])

  useEffect(() => {
    if (defaultValues?.profiles) {
      form.setValue('profiles', (defaultValues?.profiles || []) as any)
    }
  }, [defaultValues])

  return (
    <Modal open={open} close={close} title={title || 'Create Group'} isLoading={isModalLoading}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <Field
            name="groupName"
            control={form.control}
            type="input"
            rules={{required: 'Please enter the group name'}}
            componentProps={{label: 'Group name', placeholder: 'Smth incredible', disabled: editing}}
          />
          <Field
            name="clientEmail"
            control={form.control}
            type="input"
            componentProps={{
              label: 'Client Email',
              placeholder: 'info@gmail.com',
            }}
            rules={{
              validate: (value: string) =>
                value ? (validateEmail(value) ? undefined : 'Please enter correct email') : undefined,
            }}
          />
          <Field
            name="expectedResult"
            control={form.control}
            type="input"
            componentProps={{type: 'number', label: 'Expected result', placeholder: '9999'}}
          />
          {/* <Field
            name="profiles"
            control={form.control}
            type="multi-select"
            componentProps={{
              label: 'Profiles',
              placeholder: 'Select profiles',
              options: profiles.map(profile => ({label: profile.username, value: profile._id})),
            }}
          /> */}
          <Button loading={isSubmitting} htmlType="submit" className="mt-2">
            {submitText || 'Create Group'}
          </Button>
        </form>
      </Form>
    </Modal>
  )
}
