import React from 'react'
import {Props} from './types'
import {TableCell, TableRow} from '@/components/ui/table'
import dayjs from 'dayjs'
import {Button} from '@/components/ui/button'
import {FaExternalLinkAlt} from 'react-icons/fa'

export const PendingTaskRow: React.FC<Props> = ({task}) => (
  <>
    <TableRow key={task._id}>
      <TableCell className={tdClassName}>{task.username}</TableCell>
      <TableCell className={tdClassName}>{task.taskID}</TableCell>
      <TableCell className={tdClassName}>{dayjs(task.createdAt).format('MMM DD, YYYY hh:mm A')}</TableCell>
      <TableCell className={tdClassName}>
        <div className="flex items-center justify-center gap-3">
          <Button
            onClick={() => window.open(`https://parser.im/api.php/?key=${task.apiKey}&tid=${task.taskID}`)}
            size="icon"
            variant="outline"
            className="w-8 h-8"
          >
            <FaExternalLinkAlt className="text-xs" />
          </Button>
        </div>
      </TableCell>
    </TableRow>
  </>
)

const tdClassName = `!border !border-gray-400 dark:!border-secondary text-center text-gray-800 dark:text-white bg-white dark:bg-background !p-1`
