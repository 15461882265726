import * as React from 'react'
import {Slot} from '@radix-ui/react-slot'
import {cva, type VariantProps} from 'class-variance-authority'

import {cn} from '@/lib/utils'

import {ReloadIcon} from '@radix-ui/react-icons'

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 text-white',
  {
    variants: {
      variant: {
        default: 'bg-blue text-primary-foreground hover:bg-blue/90 dark:text-white',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        'outlined-destructive':
          'bg-transparent border border-destructive dark:border-red-600 text-destructive hover:bg-destructive hover:dark:bg-red-600 hover:dark:text-white dark:text-red-600 hover:text-white',
        outline:
          'border border-[#ebedf2] dark:border-secondary dark:text-white text-[#0d0e1c] hover:bg-[#ebedf2] dark:hover:bg-secondary',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground text-black dark:text-white',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  loading?: boolean
  asChildTag?: string
  loadingText?: string
  iconClassName?: string
  htmlType?: 'button' | 'submit' | 'reset'
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      iconClassName,
      variant,
      size,
      loading,
      loadingText,
      htmlType = 'button',
      asChild = false,
      asChildTag,
      ...props
    },
    ref,
  ) => {
    const Comp = asChildTag || (asChild ? Slot : 'button')
    return (
      <Comp
        {...{
          className: cn(
            buttonVariants({variant, size, className}),
            loading || props.disabled ? 'pointer-events-none' : '',
          ),
          ref,
          type: htmlType,
          disabled: loading || props.disabled,
          ...props,
        }}
      >
        {loading ? (
          <>
            <ReloadIcon className={cn('h-4 w-4 animate-spin', size === 'icon' ? '' : 'mr-2', iconClassName)} />
            {size === 'icon' ? '' : loadingText || 'Please wait'}
          </>
        ) : (
          props.children
        )}
      </Comp>
    )
  },
)
Button.displayName = 'Button'

export {Button, buttonVariants}
