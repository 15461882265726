import React from 'react'
import {useForm, SubmitHandler} from 'react-hook-form'
import {IoIosArrowBack} from 'react-icons/io'
import {Field} from '@/components/common'
import {Button} from '@/components/ui/button'
import {Form} from '@/components/ui/form'
import {useLoginSubaccountMutation} from '@/features/auth'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {LoginFormData} from '../../types'
import {Props} from './types'
import {LoginSubaccountResponse} from '@/features/auth/types'
import {storage} from '@/utils/storage'

export const SubaccountLogin: React.FC<Props> = ({subaccounts, setSubaccounts}) => {
  const [loginSubaccount, {isLoading}] = useLoginSubaccountMutation()
  const form = useForm<LoginFormData>()
  const handleRequest = useHandleRequest()

  const onSubmit: SubmitHandler<LoginFormData> = async formData => {
    await handleRequest({
      request: async () => {
        const result = await loginSubaccount(formData)
        return result
      },
      onSuccess: ({data}: {data: LoginSubaccountResponse}) => {
        storage.setAccessToken(data.token)
        window.location.href = '/'
      },
    })
  }

  const onBack = () => {
    storage.removeAccessToken()
    setSubaccounts(undefined)
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="w-96 px-7 py-8 rounded-xl min-h-max bg-white dark:bg-background dark:border dark:border-secondary shadow-sm"
      >
        <div className="flex items-center justify-between">
          <h2 className="text-center font-semibold text-xl">Select Your Subaccount</h2>
          <button type="button" onClick={onBack} className="flex items-center gap-1 text-blue">
            <IoIosArrowBack />
            <span className="text-sm">Back</span>
          </button>
        </div>
        <div className="flex flex-col gap-2 mt-4">
          <Field
            type="select"
            control={form.control}
            name="username"
            rules={{required: 'Username is required'}}
            componentProps={{
              label: 'username',
              placeholder: 'Select subaccount',
              options: subaccounts.map(subaccount => ({value: subaccount.username, label: subaccount.username})),
            }}
          />
          <Field
            type="input"
            control={form.control}
            name="password"
            rules={{
              required: 'password is required',
              minLength: {value: 3, message: 'Minimum 6 characters required'},
              maxLength: {value: 100, message: 'Maximum 100 characters allowed'},
            }}
            componentProps={{type: 'password', label: 'password', placeholder: '* * * * * * *'}}
          />
          <Button htmlType="submit" loading={isLoading} className="mb-3 mt-2">
            Log in
          </Button>
        </div>
      </form>
    </Form>
  )
}
