export enum DataStatus {
  ARCHIVED = 0,
  ACTIVE = 1,
}

export enum ProfileType {
  NORMAL = 0,
  POSTING = 1,
}

export enum GroupType {
  NORMAL = 0,
  POSTING = 1,
}

export enum ScrapingTaskStatus {
  IN_PROGRESS = 0,
  COMPLETED = 1,
}

export enum ScrapingTaskMainType {
  BLACKLISTED = 0,
  NORMAL = 1,
}

export enum ScrapingTaskType {
  NORMAL = 0,
  HASHTAG = 1,
  FILTERING = 2,
  LOCATION = 3,
}

export enum ScrapingTaskACT {
  NORMAL = 1,
  HASHTAG = 5,
  LOCATION = 6,
}

export enum RoadMapTaskStatus {
  IN_PROGRESS = 0,
  COMPLETED = 1,
}

export enum DailyRunStatus {
  NOT_STARTED = 0,
  PAUSED = 1,
  RUNNING = 2,
  COMPLETED = 3,
}
export enum DailyRunScriptStatus {
  PENDING = 0,
  RUNNING = 1,
  COMPLETED = 2,
  FAILED = 3,
}

export enum VPSScraperTaskCompletedStatus {
  IN_PROGRESS = 0,
  COMPLETED = 1,
}
