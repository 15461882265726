import {Input, Loader, TableNotFoundRow} from '@/components/common'
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from '@/components/ui/table'
import {cn} from '@/lib/utils'
import React, {useEffect, useState} from 'react'
import {StoppedPhone} from '@/types/stopped-phone'
import {Button} from '@/components/ui/button'
import {IoMdEye, IoMdEyeOff} from 'react-icons/io'
import {Props} from './types'
import {StoppedPhoneRow} from '..'
import {useDebounce} from 'use-debounce'

export const StoppedPhonesTable: React.FC<Props> = ({stoppedPhones: stoppedPhonesData, isFetchingStoppedPhones}) => {
  const [stoppedPhones, setStoppedPhones] = useState<StoppedPhone[]>([])
  const [showHiddenRows, setShowHiddenRows] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [debouncedQuery] = useDebounce(searchQuery, 300)

  useEffect(() => {
    setStoppedPhones(
      stoppedPhonesData
        .filter(stoppedPhone => (showHiddenRows ? true : !stoppedPhone.hidden))
        .filter(
          stoppedPhone =>
            stoppedPhone.username.toLowerCase().includes(debouncedQuery.toLowerCase()) ||
            stoppedPhone.reason.toLowerCase().includes(debouncedQuery.toLowerCase()),
        ),
    )
  }, [stoppedPhonesData, showHiddenRows, searchQuery, debouncedQuery])

  return (
    <div className="container mx-auto mt-16 mb-4 py-6 bg-white dark:bg-background rounded-xl dark:border dark:border-secondary">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-xl font-semibold">
          StoppedPhones {stoppedPhones.length ? `(${stoppedPhones.length})` : ''}
        </h3>
        <div className="flex items-center gap-2">
          <Input
            type="search"
            placeholder="Search..."
            searchIconClassName="left-3 top-[13px]"
            parentClassName="w-64"
            inputClassName="py-5"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
          <Button
            onClick={() => setShowHiddenRows(prev => !prev)}
            variant="outline"
            className="flex items-center gap-2"
          >
            {showHiddenRows ? <IoMdEyeOff /> : <IoMdEye />}
            <span>{showHiddenRows ? 'Hide' : 'Show'} Hidden Rows</span>
          </Button>
        </div>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className={thClassName}>Username</TableHead>
            <TableHead className={thClassName}>Badges</TableHead>
            <TableHead className={thClassName}>Reason</TableHead>
            <TableHead className={cn(thClassName, 'text-center')}>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {isFetchingStoppedPhones ? (
            <TableRow className="relative">
              <TableCell className="h-80">
                <Loader parentClassName="absolute top-0 left-0 w-full h-full" />
              </TableCell>
            </TableRow>
          ) : stoppedPhones.length ? (
            stoppedPhones.map(stoppedPhone => <StoppedPhoneRow key={stoppedPhone._id} stoppedPhone={stoppedPhone} />)
          ) : (
            <TableNotFoundRow
              text={debouncedQuery ? 'No any stopped phones for this query' : 'No any stopped phones 🎉'}
            />
          )}
        </TableBody>
      </Table>
    </div>
  )
}

const thClassName = 'sticky top-0 z-10 bg-white dark:bg-secondary'
