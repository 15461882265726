import {EndpointNameGrowthTracker} from '@/constants/endpoints'
import {baseApi} from '..'
import {GetGrowthTrackerDataRequest, GetGrowthTrackerDataResponse} from './types'

const GrowthTrackerExtendedEndpoints = baseApi.injectEndpoints({
  endpoints: build => ({
    getGrowthTrackerData: build.query<GetGrowthTrackerDataResponse, GetGrowthTrackerDataRequest>({
      query: () => ({
        url: EndpointNameGrowthTracker.GROWTH_TRACKER_DATA,
        method: 'GET',
      }),
    }),
  }),
})

export const {useGetGrowthTrackerDataQuery} = GrowthTrackerExtendedEndpoints
