/* eslint-disable react-hooks/exhaustive-deps */
import {useForm} from 'react-hook-form'
import {AccountBaseFormData, Props} from './types'
import React, {useEffect} from 'react'
import {Field, Modal} from '@/components/common'
import {Form} from '@/components/ui/form'
import {Button} from '@/components/ui/button'

export const BaseForm: React.FC<Props> = ({
  title,
  submitText,
  isSubmitting,
  onSubmit,
  isModalLoading,
  defaultValues,
  open,
  close,
}) => {
  const form = useForm<AccountBaseFormData>({
    defaultValues,
  })

  useEffect(() => {
    if (!open) {
      form.reset()
    }
  }, [open])

  return (
    <Modal isLoading={isModalLoading} title={title || 'Create Subaccount'} open={open} close={close}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-3 mt-3">
          <Field
            type="input"
            name="username"
            control={form.control}
            rules={{required: true}}
            componentProps={{label: 'Subaccount username', placeholder: 'Enter subaccount username...'}}
          />
          <Field
            type="input"
            name="password"
            control={form.control}
            componentProps={{type: 'password', label: 'Password', placeholder: 'Enter password...'}}
          />
          <Field
            type="switch"
            name="isSuperSubaccount"
            control={form.control}
            componentProps={{
              label: 'Super Subaccount',
            }}
          />
          <Button loading={isSubmitting} htmlType="submit" className="mt-5">
            {submitText || 'Create'}
          </Button>
        </form>
      </Form>
    </Modal>
  )
}
