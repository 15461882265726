import React from 'react'
import {Props} from './types'
import {Modal} from '@/components/common'
import {Button} from '@/components/ui/button'

export const TaskDetailsModal: React.FC<Props> = ({
  open,
  close,
  task,
  index,
  onEditClick,
  onDeleteClick,
  onCompleteClick,
  contentClassName,
  editable,
}) => (
  <Modal open={open} close={close} title={`Task #${index + 1}`} contentClassName={contentClassName}>
    <h3 className="text-lg font-semibold">{task.taskName}</h3>
    <p className="mt-2 mb-3">{task.description || <span className="text-slate-400">No Description...</span>}</p>
    {editable && (
      <div className="grid grid-cols-3 mt-6 gap-2">
        <Button onClick={onCompleteClick} size="sm" className="rounded">
          Complete
        </Button>
        <Button onClick={onEditClick} variant="outline" size="sm" className="rounded">
          Edit
        </Button>
        <Button onClick={onDeleteClick} variant="outlined-destructive" size="sm" className="rounded">
          Delete
        </Button>
      </div>
    )}
  </Modal>
)
