import {Field} from '@/components/common'
import {Button} from '@/components/ui/button'
import {Form} from '@/components/ui/form'
import {useLoginAccountMutation} from '@/features/auth'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useForm, SubmitHandler} from 'react-hook-form'
import {LoginFormData} from '../../types'
import React from 'react'
import {Props} from './types'
import {LoginAccountResponse} from '@/features/auth/types'
import {storage} from '@/utils/storage'

export const AccountLogin: React.FC<Props> = ({setSubaccounts}) => {
  const form = useForm<LoginFormData>()
  const [loginAccount, {isLoading}] = useLoginAccountMutation()
  const handleRequest = useHandleRequest()

  const onSubmit: SubmitHandler<LoginFormData> = async formData => {
    await handleRequest({
      request: async () => {
        const result = await loginAccount(formData)
        return result
      },
      onSuccess: ({data}: {data: LoginAccountResponse}) => {
        storage.setAccessToken(data.token)
        setSubaccounts(data.data)
      },
    })
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="w-96 px-7 py-8 rounded-xl min-h-max bg-white dark:bg-background dark:border dark:border-secondary shadow-sm"
      >
        <h2 className="text-center font-semibold text-xl">Login to JumperBot</h2>
        <div className="flex flex-col gap-2 mt-4">
          <Field
            type="input"
            control={form.control}
            name="username"
            rules={{
              required: 'username is required',
              minLength: {value: 3, message: 'Minimum 3 characters required'},
              maxLength: {value: 100, message: 'Maximum 100 characters allowed'},
            }}
            componentProps={{label: 'username', placeholder: 'Enter username...'}}
          />
          <Field
            type="input"
            control={form.control}
            name="password"
            rules={{
              required: 'password is required',
              minLength: {value: 3, message: 'Minimum 6 characters required'},
              maxLength: {value: 100, message: 'Maximum 100 characters allowed'},
            }}
            componentProps={{type: 'password', label: 'password', placeholder: '* * * * * * *'}}
          />
          <Button htmlType="submit" loading={isLoading} className="mb-3 mt-2">
            Log in
          </Button>
        </div>
      </form>
    </Form>
  )
}
