/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import {Input, Loader} from '@/components/common'
import {useDebounce} from 'use-debounce'
import {CreateGroupModal, CreateProfileModal, DMSearchModal, GroupCard, ProfileCard} from './components'
import {Props} from './types'
import {Button} from '@/components/ui/button'
import {FaPlus} from 'react-icons/fa'
import {TbReload} from 'react-icons/tb'
import {CompletionModal} from '../settings/components/settings-top/components/scraping/components'
import {FiFilter} from 'react-icons/fi'
import {Checkbox} from '@/components/ui/checkbox'
import {cn} from '@/lib/utils'
import {Search} from 'lucide-react'

export const Sidebar: React.FC<Props> = ({
  refetchProfiles,
  isLoading,
  profiles,
  currentPage,
  searchQuery,
  setSearchQuery,
  isFetching,
  filters,
  setFilters,
}) => {
  const [openCreateProfileModal, setOpenCreateProfileModal] = useState(false)
  const [openCreateGroupModal, setOpenCreateGroupModal] = useState(false)
  const [openCompletionModal, setOpenCompletionModal] = useState(false)
  const [debouncedQuery] = useDebounce(searchQuery, 500)
  const profilesWrapperRef = useRef<HTMLDivElement>(null)
  const [openFilters, setOpenFilters] = useState(false)
  const [openDMSearch, setOpenDMSearch] = useState(false)

  const onChangeFilter = (key: string, value: boolean) => {
    setFilters((prev: any) => ({...prev, [key]: value}))
    refetchProfiles(1, {...filters, [key]: value})
  }

  useEffect(() => {
    refetchProfiles()
  }, [debouncedQuery])

  return (
    <div className="bg-white dark:bg-background h-[84%] px-6 py-5 rounded-2xl min-w-[350px] fixed top-[105px]">
      <div className="flex gap-2">
        <Button
          onClick={() => setOpenCreateProfileModal(true)}
          variant="outline"
          className="flex gap-1.5 font-semibold min-w-max duration-200 text-xs"
          size="sm"
        >
          <FaPlus className="text-xs" />
          <span>Create Profile</span>
        </Button>
        <Button
          onClick={() => setOpenCreateGroupModal(true)}
          variant="outline"
          className="flex gap-1.5 font-semibold min-w-max duration-200 text-xs"
          size="sm"
        >
          <FaPlus className="text-xs" />
          <span>Create Group</span>
        </Button>
        <Button
          onClick={() => setOpenCompletionModal(true)}
          variant="outline"
          className="flex gap-2 font-semibold min-w-max duration-200 text-xs h-9"
          size="icon"
        >
          <TbReload className="text-sm" />
        </Button>
      </div>
      <div className="flex items-center gap-2 mt-3 mb-4">
        <Input
          type="search"
          placeholder="Search"
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          inputClassName="h-10 px-4 bg-white dark:bg-background border border-[#ebeef4] dark:border-secondary"
        />
        <Button
          onClick={() => setOpenFilters(prev => !prev)}
          variant={openFilters ? 'default' : 'outline'}
          className="flex gap-2 font-semibold min-w-[36px] duration-200 text-xs h-9"
          size="icon"
        >
          <FiFilter />
        </Button>
        <Button
          onClick={() => setOpenDMSearch(prev => !prev)}
          variant={openDMSearch ? 'default' : 'outline'}
          className="flex gap-2 font-semibold min-w-[36px] duration-200 text-xs h-9"
          size="icon"
        >
          <Search className="w-3.5 h-3.5" />
        </Button>
      </div>
      <div
        className={cn(
          'flex flex-col gap-2 pl-3 overflow-hidden duration-150',
          openFilters ? 'h-[96px] pb-5' : 'h-[1px] pb-0',
        )}
      >
        <label className="flex items-center gap-2">
          <Checkbox
            checked={filters.accountCreation}
            onCheckedChange={(checked: boolean) => onChangeFilter('accountCreation', checked)}
          />
          <span className="text-sm">Account creation activated</span>
        </label>
        <label className="flex items-center gap-2">
          <Checkbox
            checked={filters.multiAccounts}
            onCheckedChange={(checked: boolean) => onChangeFilter('multiAccounts', checked)}
          />
          <span className="text-sm">Multi accounts activated</span>
        </label>
        <label className="flex items-center gap-2">
          <Checkbox
          // checked={filters.dmOnly}
          // onCheckedChange={(checked: boolean) => onChangeFilter('dmOnly', checked)}
          />
          <span className="text-sm">"DM only" activated</span>
        </label>
      </div>
      <p className="text-black dark:text-white text-xs">
        Profiles: <b>{profiles.profilesCount}</b>&nbsp;&nbsp;&&nbsp;&nbsp;Groups: <b>{profiles.groupsCount}</b>
      </p>
      <div
        id="scrollableDiv"
        ref={profilesWrapperRef}
        className="border border-[#ebeef4] dark:border-secondary rounded-2xl px-3 py-2 overflow-y-auto mt-2 h-[calc(100%-144px)] relative overflow-auto"
      >
        {isLoading || (isFetching && currentPage === 1) ? (
          <Loader parentClassName="h-[100px]" />
        ) : (
          <InfiniteScroll
            dataLength={profiles.groupsCount + profiles.profilesCount}
            hasMore={
              profiles.groupsCount !== profiles.groups.length ||
              profiles.ungroupedProfilesCount !== profiles.profiles.length
            }
            next={() => refetchProfiles(currentPage + 1)}
            scrollableTarget="scrollableDiv"
            style={{overflow: 'unset'}}
            loader={<Loader parentClassName="h-[110px]" />}
            endMessage={<p className="text-center py-3 font-semibold text-muted-foreground">You Loaded All Profiles</p>}
          >
            {profiles.groups.map(group => (
              <GroupCard key={group._id} group={group} filters={filters} />
            ))}
            {profiles.profiles.map(profile => (
              <ProfileCard key={profile._id} profile={profile} />
            ))}
          </InfiniteScroll>
        )}
      </div>
      <CreateGroupModal open={openCreateGroupModal} close={() => setOpenCreateGroupModal(false)} />
      <CreateProfileModal open={openCreateProfileModal} close={() => setOpenCreateProfileModal(false)} />
      <CompletionModal open={openCompletionModal} close={() => setOpenCompletionModal(false)} profileID="all" />
      <DMSearchModal open={openDMSearch} close={() => setOpenDMSearch(false)} />
    </div>
  )
}
