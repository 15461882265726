import {Loader} from '@/components/common'
import {Button} from '@/components/ui/button'
import {useCreateRoadmapNoteMutation, useGetRoadmapNotesQuery} from '@/features/roadmap-notes'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useEffect, useRef, useState} from 'react'
import {IoIosSend} from 'react-icons/io'
import {RoadmapNoteCard} from './components'

export const RoadmapNotes = () => {
  const {data: {data: notes = []} = {}, isFetching} = useGetRoadmapNotesQuery({})
  const [createNote, {isLoading: isCreatingNote}] = useCreateRoadmapNoteMutation()
  const [value, setValue] = useState('')
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const handleRequest = useHandleRequest()

  const onSubmit = async () => {
    if (!value.trim()) {
      return
    }
    const valueText = value
    setValue('')

    await handleRequest({
      request: async () => {
        const result = await createNote({description: valueText})
        return result
      },
      onSuccess: () => {
        setValue('')
      },
    })
  }

  const resizeTextArea = () => {
    if (!textareaRef.current) {
      return
    }

    textareaRef.current.style.height = 'auto'
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
  }

  useEffect(() => {
    resizeTextArea()
    window.addEventListener('resize', resizeTextArea)
  }, [])

  return (
    <div className="mt-10 border border-slate-200 dark:border-secondary rounded-md px-5 py-4">
      {isFetching ? (
        <Loader parentClassName="h-40" />
      ) : notes.length ? (
        <div className="flex flex-col gap-3">
          {notes.map(note => (
            <RoadmapNoteCard key={note._id} note={note} />
          ))}
        </div>
      ) : (
        <div className="my-16 text-center text-slate-400">No notes yet...</div>
      )}
      <div className="flex items-stretch gap-4 mt-4">
        <textarea
          onKeyDown={event => {
            if (event.key === 'Enter' && event.shiftKey) {
              onSubmit()
            }
          }}
          placeholder="Found a bug on first task!"
          value={value}
          className="w-full border border-[#ebedf2] dark:border-secondary dark:bg-background px-4 py-2 rounded-md resize-none"
          ref={textareaRef}
          onChange={event => {
            resizeTextArea()
            setValue(event.target.value)
          }}
        />
        <Button loading={isCreatingNote} onClick={onSubmit} className="flex items-center gap-2 min-w-max">
          <IoIosSend className="text-lg" />
          <span>Send</span>
        </Button>
      </div>
    </div>
  )
}
