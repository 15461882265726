/* eslint-disable react-hooks/exhaustive-deps */
import React, {ChangeEvent, useEffect, useMemo, useState} from 'react'
import {Props} from './types'
import {Button} from '@/components/ui/button'
import {cn} from '@/lib/utils'
import {SubmitHandler, useForm} from 'react-hook-form'
import {Form} from '@/components/ui/form'
import {DeleteModal, Field} from '@/components/common'
import {useHandleError} from '@/hooks/use-handle-error'
import {getFileText} from '@/utils/get-file-text'
import {FaRegTrashCan} from 'react-icons/fa6'
import {FaChevronLeft} from 'react-icons/fa'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {
  useCreateProfileDMMutation,
  useDeleteProfileDMMutation,
  useSetActiveDMMutation,
  useUpdateProfileDMMutation,
} from '@/features/profile-dm'
import {useToast} from '@/components/ui/use-toast'
import {IoMdClose} from 'react-icons/io'
import {ObjectI} from '@/types/default'

export const DMCard: React.FC<Props> = ({profileDMID = 'unsaved', profileDM, profile, onRemove, refetchDMs}) => {
  const [createDM, {isLoading: isCreating}] = useCreateProfileDMMutation()
  const [updateDM, {isLoading: isUpdating}] = useUpdateProfileDMMutation()
  const [deleteDM, {isLoading: isDeleting}] = useDeleteProfileDMMutation()
  const [activateDM, {isLoading: isActivating}] = useSetActiveDMMutation()
  const form = useForm<any>()
  const [open, setOpen] = useState(false)
  const [openEditor, setOpenEditor] = useState(false)
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false)
  const [generatedExample, setGeneratedExample] = useState('')
  const handleRequest = useHandleRequest()
  const handleError = useHandleError()
  const {toast} = useToast()
  const isUnSaved = useMemo(() => profileDMID.includes('unsaved'), [profileDMID])

  const onCreate = async (savingProfileDM: ObjectI) => {
    await handleRequest({
      request: async () => {
        const result = await createDM({
          id: profile._id,
          body: {
            dm: savingProfileDM,
          },
        })
        return result
      },
    })
  }

  const onSave = async (savingProfileDM: ObjectI) => {
    await handleRequest({
      request: async () => {
        const result = await updateDM({
          id: profile._id,
          body: {
            dm: savingProfileDM,
            dmID: profileDMID,
          },
        })
        return result
      },
    })
  }

  const onSubmit: SubmitHandler<any> = async formData => {
    try {
      formData.profileDM = JSON.parse(formData.profileDM)
      if (isUnSaved) {
        await onCreate(formData.profileDM)
      } else {
        await onSave(formData.profileDM)
      }

      setTimeout(() => {
        refetchDMs()
      }, 1000)
    } catch (error) {
      handleError(error)
    }
  }

  const onDelete = async () => {
    await handleRequest({
      request: async () => {
        const result = await deleteDM({id: profile._id, params: {dmID: profileDMID}})
        return result
      },
      onSuccess: () => {
        toast({title: 'DM successfully deleted!'})
        setOpenDeleteAlert(false)
        setTimeout(() => {
          refetchDMs()
        }, 1000)
      },
    })
  }

  const onActivate = async () => {
    await handleRequest({
      request: async () => {
        const result = await activateDM({id: profile._id, params: {dmID: profileDMID}})
        return result
      },
      onSuccess: () => {
        toast({title: 'DM successfully activated!'})
        setTimeout(() => {
          refetchDMs()
        }, 1000)
      },
    })
  }

  const onUploadJSON = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]?.type !== 'application/json') {
      event.target.value = ''
      return handleError('Only JSON files accepted')
    }

    getFileText(event.target.files[0], result => {
      try {
        JSON.parse(result)
        form.setValue('profileDM', result)
      } catch (ex) {
        event.target.value = ''
        handleError('Invalid JSON file')
      }
    })
  }

  const generateExample = () => {
    let dmConfig = form.getValues('profileDM')
    if (!dmConfig) {
      setGeneratedExample('')
      return handleError('DM Configure is empty')
    }

    try {
      dmConfig = JSON.parse(dmConfig)
      const messages = Object.entries(dmConfig).filter(([key]) => !isNaN(+key))

      let demoExample = dmConfig?.template || 'Wrong DM Format!'

      for (let messageIdx = 0; messageIdx < messages.length; messageIdx++) {
        const messagesArr = messages[messageIdx][1] as any
        const randomMsg = messagesArr[Math.floor(Math.random() * messagesArr.length)]
        demoExample = demoExample.replaceAll(`{${messages[messageIdx][0]}}`, randomMsg)
      }

      setGeneratedExample(demoExample)
    } catch (ex) {
      handleError('Wrong DM Config JSON')
    }
  }

  useEffect(() => {
    const formattedDM = {...(profileDM || {})}

    delete formattedDM.active
    delete formattedDM.rate
    delete formattedDM.id_spin

    form.setValue('profileDM', JSON.stringify(formattedDM, undefined, 2))
  }, [profileDM])

  return (
    <div
      className={cn(
        'relative border rounded-lg duration-150',
        open
          ? cn('border-blue overflow-auto', openEditor ? 'h-[400px]' : generatedExample ? 'h-[260px]' : 'h-[200px]')
          : 'border-transparent h-10',
      )}
    >
      <Button
        onClick={() => setOpen(prev => !prev)}
        className={cn('w-full', open ? 'border-0 border-b' : '')}
        variant="outline"
      >
        {profileDMID} - {(profileDM.rate as string) || 0}%
      </Button>
      {open && (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="p-3">
            <div className={cn('duration-150 overflow-hidden mb-2', !openEditor ? 'h-[34px]' : 'h-[260px]')}>
              <button
                onClick={() => setOpenEditor(prev => !prev)}
                type="button"
                className={cn(
                  'flex items-center justify-between w-full pb-1 border-b duration-150',
                  !openEditor ? 'border-b-zinc-500' : 'border-b-transparent',
                )}
              >
                <span>DM Config</span>
                <FaChevronLeft className={cn('text-lg duration-150', openEditor ? '-rotate-90' : '')} />
              </button>
              {openEditor && (
                <Field
                  type="textarea"
                  name="profileDM"
                  control={form.control}
                  componentProps={{placeholder: 'DM Configurations...', className: 'h-56'}}
                />
              )}
            </div>
            <div className="flex items-center justify-end py-2 gap-4">
              <button type="button" className="text-sm text-blue" onClick={generateExample}>
                Generate Example
              </button>
              <label className="text-sm text-blue">
                Upload JSON
                <input type="file" accept="application/json" hidden onChange={onUploadJSON} />
              </label>
            </div>
            {generatedExample && <p className="text-sm">{generatedExample}</p>}
            <div className="flex items-center gap-2 mt-3">
              <Button
                onClick={isUnSaved ? onRemove : () => setOpenDeleteAlert(true)}
                variant="outlined-destructive"
                size="icon"
                className="min-w-max px-3"
              >
                {isUnSaved ? <IoMdClose className="text-xl" /> : <FaRegTrashCan />}
              </Button>
              {!isUnSaved && !profileDM.active && (
                <Button onClick={onActivate} loading={isActivating} className="w-1/2" variant="outline">
                  Activate
                </Button>
              )}
              <Button
                htmlType="submit"
                loading={isUpdating || isCreating}
                className={cn(isUnSaved || profileDM.active ? 'w-full' : 'w-1/2')}
              >
                Save
              </Button>
            </div>
          </form>
        </Form>
      )}
      <div className="absolute -top-1 right-0 text-xs flex gap-2">
        {profileDM.active ? <span className="bg-[#046afa] text-white rounded-sm px-2 py-0.5">Active</span> : ''}
      </div>
      <DeleteModal
        open={openDeleteAlert}
        close={() => setOpenDeleteAlert(false)}
        title={`Are you sure to delete this DM (${profileDMID}) ?`}
        onDelete={onDelete}
        isDeleting={isDeleting}
      />
    </div>
  )
}
