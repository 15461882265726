import React, {useState} from 'react'
import {Props} from './types'
import {TableCell, TableRow} from '@/components/ui/table'
import {DeleteModal, Dropdown} from '@/components/common'
import {Button} from '@/components/ui/button'
import dayjs from 'dayjs'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useDeleteFromBotServerMutation, useRestoreTaskMutation} from '@/features/scraping-task'
import {useToast} from '@/components/ui/use-toast'

export const ServerTaskRow: React.FC<Props> = ({task, restorable, profile}) => {
  const [deleteTask, {isLoading: isDeleting}] = useDeleteFromBotServerMutation()
  const [restoreTask, {isLoading: isRestoring}] = useRestoreTaskMutation()
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false)
  const [openRestoreAlert, setOpenRestoreAlert] = useState(false)
  const handleRequest = useHandleRequest()
  const {toast} = useToast()

  const onDelete = async () => {
    await handleRequest({
      request: async () => {
        const result = await deleteTask({id: task.id})
        return result
      },
      onSuccess: () => {
        toast({title: 'Task successfully deleted!'})
        setOpenDeleteAlert(false)
      },
    })
  }

  const onRestore = async () => {
    await handleRequest({
      request: async () => {
        const result = await restoreTask({
          id: profile._id,
          body: {
            taskID: task.id,
          },
        })
        return result
      },
      onSuccess: () => {
        toast({title: 'Task successfully restored!'})
        setOpenRestoreAlert(false)
      },
    })
  }

  return (
    <TableRow>
      <TableCell className={tdClassName}>
        <a
          href={`https://myinstaapi.lat/Usernames%20to%20Follow/APIv2/Profiles%20Files/${profile.phoneID}/${task.filename}`}
          target="_blank"
          className="text-blue underline hover:text-blue-600"
        >
          {task.filename}
        </a>
      </TableCell>
      <TableCell className={tdClassName}>{task.used_percentage}%</TableCell>
      <TableCell className={tdClassName}>{task.follows}</TableCell>
      <TableCell className={tdClassName}>{task.dms}</TableCell>
      <TableCell className={tdClassName}>{task.users || 0}</TableCell>
      <TableCell className={tdClassName}>{task.FBR}%</TableCell>
      <TableCell className={tdClassName}>{dayjs(task.uploaded_at).format('MMM DD, YYYY hh:mm A')}</TableCell>
      <TableCell className={tdClassName}>{task.created_by || 'N/A'}</TableCell>
      <TableCell className={tdClassName}>
        {restorable ? (
          <Dropdown
            triggerBtnProps={{
              className: 'text-[#1677ff] bg-white border-0 hover:bg-white dark:bg-secondary hover:text-[#046afa]',
            }}
            contentClassName="bg-white dark:!bg-secondary border-white dark:border-secondary text-[#1677ff]"
            buttonClassName="hover:!bg-[#1677ff] hover:!text-white"
            label="Actions"
            buttons={[
              {label: 'Restore', onClick: () => setOpenRestoreAlert(true), className: 'dark:text-white'},
              {
                label: 'Delete',
                className: 'bg-destructive hover:!bg-red-600 !text-white',
                onClick: () => setOpenDeleteAlert(true),
              },
            ]}
          />
        ) : (
          <Button onClick={() => setOpenDeleteAlert(true)} variant="outlined-destructive" className="h-8">
            Delete
          </Button>
        )}
        <DeleteModal
          open={openDeleteAlert}
          close={() => setOpenDeleteAlert(false)}
          title={`Are you sure to delete this task (${task.filename}) ?`}
          onDelete={onDelete}
          isDeleting={isDeleting}
        />
        <DeleteModal
          open={openRestoreAlert}
          close={() => setOpenRestoreAlert(false)}
          title={`Are you sure to restore this task (${task.filename}) ?`}
          onDelete={onRestore}
          isDeleting={isRestoring}
          deleteButtonText="Restore"
          deleteButtonVariant="outline"
        />
      </TableCell>
    </TableRow>
  )
}

const tdClassName = `!border !border-gray-400 dark:!border-secondary text-center text-gray-800 dark:text-white bg-white dark:bg-background !p-1`
