/* eslint-disable react-hooks/exhaustive-deps */
import {useLazyGetAllProfilesQuery} from '@/features/profile'
import {useForm} from 'react-hook-form'
import {NicheBaseFormData, Props} from './types'
import React, {useEffect} from 'react'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {Field, Modal} from '@/components/common'
import {Form} from '@/components/ui/form'
import {Button} from '@/components/ui/button'

export const BaseForm: React.FC<Props> = ({
  title,
  submitText,
  isSubmitting,
  onSubmit,
  isModalLoading,
  defaultValues,
  open,
  close,
}) => {
  const [getAllProfiles, {data: {data: profiles = []} = {}, isLoading}] = useLazyGetAllProfilesQuery()
  const form = useForm<NicheBaseFormData>({
    defaultValues,
  })
  const handleRequest = useHandleRequest()

  const fetchAllProfiles = async () => {
    await handleRequest({
      request: async () => {
        const result = await getAllProfiles({})
        return result
      },
    })
  }

  useEffect(() => {
    if (open) {
      fetchAllProfiles()
    } else {
      form.reset()
    }
  }, [open])

  useEffect(() => {
    if (defaultValues?.profiles) {
      form.setValue('profiles', (defaultValues?.profiles || []) as any)
    }
  }, [defaultValues])

  return (
    <Modal isLoading={isLoading || isModalLoading} title={title || 'Create Niche'} open={open} close={close}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-3 mt-3">
          <Field
            type="input"
            name="nicheName"
            control={form.control}
            rules={{required: true}}
            componentProps={{label: 'Niche name', placeholder: 'Enter niche name...'}}
          />
          <Field
            type="multi-select"
            name="profiles"
            control={form.control}
            componentProps={{
              options: profiles.map(profile => ({label: profile.profileName, value: profile._id})),
              label: 'Profiles',
              placeholder: 'Select profiles',
            }}
          />
          <Button loading={isSubmitting} htmlType="submit" className="mt-5">
            {submitText || 'Create'}
          </Button>
        </form>
      </Form>
    </Modal>
  )
}
