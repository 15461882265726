import React, {useState} from 'react'
import {Button} from '@/components/ui/button'
import {IoMdSettings} from 'react-icons/io'
import {Props} from './types'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {EditProfileModal} from '../edit-profile-modal'

export const ProfileCard: React.FC<Props> = ({profile}) => {
  const [openEditForm, setOpenEditForm] = useState(false)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()

  const selectProfile = async () => {
    navigate(`${location.pathname}?phoneID=${profile?.phoneID}`)
  }

  return (
    <div className="mt-2 rounded-md flex items-center justify-between gap-2 border border-[#ebedf2] dark:border-secondary">
      <Button
        onClick={selectProfile}
        variant="outline"
        className={`text-black relative flex items-center justify-start h-9 w-full border-0 ${
          searchParams.get('phoneID') === profile?.phoneID && '!bg-[#ebedf2] dark:!bg-secondary'
        }`}
      >
        <span className={`line-clamp-1 w-full block text-start text-xs`}>{profile?.phoneID}</span>
      </Button>
      <div className="flex gap-2">
        <Button onClick={() => setOpenEditForm(true)} variant="outline" className="h-9 border-0" size="icon">
          <IoMdSettings />
        </Button>
      </div>
      <EditProfileModal open={openEditForm} close={() => setOpenEditForm(false)} profile={profile} />
    </div>
  )
}
