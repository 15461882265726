import {Tabs} from '@/components/common'
import {Accounts, Niches, Subaccounts} from './components'
import {useUserData} from '@/hooks/use-user-data'
import {Role} from '@/constants/role'

export const MoreSettingsPage = () => {
  const userData = useUserData()

  return (
    <div className="container max-w-[900px] mx-auto mt-16 mb-4 py-6 bg-white dark:bg-background dark:border dark:border-secondary rounded-xl">
      <Tabs
        tabs={[
          ...(userData.account.role === Role.SUPER_ACCOUNT && userData.subaccount.role === Role.SUPER_SUBACCOUNT
            ? [{label: 'Accounts', value: 'accounts', children: <Accounts />}]
            : []),
          ...(userData.account.role === Role.SUPER_ACCOUNT && userData.subaccount.role === Role.SUPER_SUBACCOUNT
            ? [{label: 'Subaccounts', value: 'subaccounts', children: <Subaccounts />}]
            : []),
          {label: 'Niches', value: 'niches', children: <Niches />},
        ]}
        contentClassName="mt-7"
      />
    </div>
  )
}
