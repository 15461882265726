import React from "react";
import { Props } from "./types";
import { Modal } from "@/components/common";
import { Button } from "@/components/ui/button";

export const DMWarningModal: React.FC<Props> = ({ open, close }) => (
  <Modal open={open} close={close} title="">
    <div className="flex flex-col items-center">
      <h2 className="text-7xl">❌</h2>
      <h2 className="text-2xl mt-3 mb-2 font-bold">DM got invalid format!</h2>
      <p className="text-xs text-center">Please recheck your dm template and make sure its a valid JSON template that matches to correct format of dm template</p>
      <Button className="mt-5 w-full" onClick={close}>Let me recheck</Button>
    </div>
  </Modal>
)