import React, {ChangeEvent, useState} from 'react'
import {Props} from './types'
import {Modal} from '@/components/common'
import {Button} from '@/components/ui/button'
import {useHandleError} from '@/hooks/use-handle-error'
import {formatBytes} from '@/utils/util-functions'
import {X} from 'lucide-react'
import {useUploadFileToBlacklistMutation} from '@/features/scraping-task'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useToast} from '@/components/ui/use-toast'

export const UploadFileToBlacklist: React.FC<Props> = ({open, close, profile}) => {
  const [uploadFile, {isLoading}] = useUploadFileToBlacklistMutation()
  const [file, setFile] = useState<File | undefined>(undefined)
  const handleRequest = useHandleRequest()
  const handleError = useHandleError()
  const {toast} = useToast()

  const onSubmit = async () => {
    if (file) {
      await handleRequest({
        request: async () => {
          const formData = new FormData()
          formData.append('file', file)
          const result = await uploadFile({id: profile._id, body: formData})
          return result
        },
        onSuccess: () => {
          close()
          setFile(undefined)
          toast({title: 'File successfully uploaded!'})
        },
      })
    }
  }

  const onFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files?.[0]
    if (!uploadedFile) {
      return
    }

    if (!uploadedFile?.name?.includes('.txt')) {
      return handleError('Only TXT files accepted')
    }

    setFile(uploadedFile)
  }

  return (
    <Modal open={open} close={close} title={`Upload Blacklist file (${profile.profileName})`}>
      <div className="flex flex-col gap-5">
        <div className="relative">
          {file && (
            <button onClick={() => setFile(undefined)} type="button" className="absolute top-2 right-2 text-xl">
              <X />
            </button>
          )}
          <label className="h-40 w-full border border-dashed border-neutral-300 rounded-lg grid place-content-center cursor-cell group hover:border-neutral-400 duration-150">
            {file ? (
              <span>
                {file.name} - {formatBytes(file.size)}
              </span>
            ) : (
              <span className="text-slate-400 text-sm group-hover:scale-[1.1] duration-150">Upload Blacklist File</span>
            )}
            <input type="file" accept=".txt" onChange={onFileUpload} hidden />
          </label>
        </div>
        <Button onClick={onSubmit} disabled={!file} loading={isLoading} size="lg">
          Upload
        </Button>
      </div>
    </Modal>
  )
}
