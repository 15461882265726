export enum RTKTagName {
  USER = 'USER',
  PROFILE = 'PROFILE',
  PROFILES = 'PROFILES',
  GROUP = 'GROUP',
  NICHE = 'NICHE',
  PROFILE_DATA = 'PROFILE_DATA',
  PROFILE_DM = 'PROFILE_DM',
  SCRAPING_TASK = 'SCRAPING_TASK',
  ACCOUNT = 'ACCOUNT',
  SUBACCOUNT = 'SUBACCOUNT',
  PROFILE_IMAGES = 'PROFILE_IMAGES',
  PROFILE_POSTS = 'PROFILE_POSTS',
  CLIENT_PROFILE = 'CLIENT_PROFILE',
  CLIENT_GROUP = 'CLIENT_GROUP',
  ROADMAP_TASK = 'ROADMAP_TASK',
  ROADMAP_NOTE = 'ROADMAP_NOTE',
  STOPPED_PHONE = 'STOPPED_PHONE',
  VPS_SCRIPT = 'VPS_SCRIPT',
  VPS_SCRIPT_DAILY_STATUS = 'VPS_SCRIPT_DAILY_STATUS',
  VPS_SCRAPER_TASK = 'VPS_SCRAPER_TASK',
}
