/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {DefaultProxyFormData, Props} from './types'
import {Field, Modal} from '@/components/common'
import {Button} from '@/components/ui/button'
import {SubmitHandler, useForm} from 'react-hook-form'
import {Form} from '@/components/ui/form'
import {getValidations} from '@/utils/util-functions'
import {useLazyGetVPSScriptsDefaultProxyQuery, useSaveVPSScriptsDefaultProxyMutation} from '@/features/vps-scripts'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useToast} from '@/components/ui/use-toast'
import {GetVPSScriptsDefaultProxyResponse} from '@/features/vps-scripts/types'

export const DefaultProxiesModal: React.FC<Props> = ({open, close}) => {
  const [getDefaultProxy, {isFetching}] = useLazyGetVPSScriptsDefaultProxyQuery()
  const [saveDefaultProxy, {isLoading}] = useSaveVPSScriptsDefaultProxyMutation()
  const form = useForm<DefaultProxyFormData>()
  const [fetched, setFetched] = useState(false)
  const handleRequest = useHandleRequest()
  const {toast} = useToast()

  const fetchDefaultProxy = async () => {
    await handleRequest({
      request: async () => {
        const result = await getDefaultProxy({})
        return result
      },
      onSuccess: ({data}: {data: GetVPSScriptsDefaultProxyResponse}) => {
        form.setValue('enabled', data.data.enabled)
        form.setValue('proxies', data.data.proxies)
        setFetched(true)
      },
    })
  }

  const onSubmit: SubmitHandler<DefaultProxyFormData> = async formData => {
    await handleRequest({
      request: async () => {
        const result = await saveDefaultProxy({
          body: {proxies: formData.proxies, enabled: formData.enabled},
        })
        return result
      },
      onSuccess: () => {
        close()
        toast({title: 'Proxies successfully saved!'})
      },
    })
  }

  useEffect(() => {
    if (open && !fetched) {
      fetchDefaultProxy()
    }
  }, [open])

  return (
    <Modal open={open} close={close} title="Default Proxies" contentClassName="min-w-[600px]" isLoading={isFetching}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Field
            type="textarea"
            control={form.control}
            name="proxies"
            rules={getValidations(true)}
            componentProps={{
              label: 'Default Proxies',
              placeholder: `http://hkyyytf:8detkwt@mobile.shadeflare.io:6000\nhttps://i.fxdx.in/api-rt/changeip/l6inngbn2f/xTGW8AWNBM3G35YANWY98\n\nhttp://hkyyytf:8detkwt@mobile.shadeflare.io:6000\nhttps://i.fxdx.in/api-rt/changeip/l6inngbn2f/xTGW8AWNBM3G35YANWY98`,
              className: 'h-64',
            }}
          />
          <div className="mt-2 mb-4 flex items-center justify-end">
            <Field
              type="switch"
              control={form.control}
              name="enabled"
              componentProps={{label: 'Default proxies enabled'}}
            />
          </div>
          <Button loading={isLoading} htmlType="submit" className="w-full">
            Save
          </Button>
        </form>
      </Form>
    </Modal>
  )
}
