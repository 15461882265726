import React from 'react'
import {Props} from './types'
import {Accordion, AccordionContent, AccordionItem, AccordionTrigger} from '@/components/ui/accordion'
import {Button} from '@/components/ui/button'
import {ExternalLink} from 'lucide-react'

export const ProfilesCard: React.FC<Props> = ({profiles}) => (
  <Accordion type="multiple" className="w-full">
    <AccordionItem value="profiles" className="mb-3 border rounded">
      <AccordionTrigger className="px-6 hover:no-underline [&[data-state=open]]:border-b">
        Profiles ({profiles.length})
      </AccordionTrigger>
      <AccordionContent className="flex flex-col gap-2 px-6 py-4">
        {profiles.map(profile => (
          <Button
            onClick={() => window.open(`/manage?phoneID=${profile}`)}
            variant="outline"
            size="sm"
            key={profile}
            className="flex items-center gap-2"
          >
            <span>{profile}</span>
            <ExternalLink className="w-4 h-4" />
          </Button>
        ))}
      </AccordionContent>
    </AccordionItem>
  </Accordion>
)
