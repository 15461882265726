import {useCreateClientGroupsMutation} from '@/features/client-groups'
import {BaseClientGroupForm} from '..'
import React from 'react'
import {Props} from './types'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {SubmitHandler} from 'react-hook-form'
import {ClientGroupFormData} from '../base-client-group-form/types'
import {useToast} from '@/components/ui/use-toast'
import {useUpdateProfiles} from '../../hooks/use-update-profiles'
import {CreateClientGroupResponse} from '@/features/client-groups/types'

export const CreateClientGroupForm: React.FC<Props> = ({open, close}) => {
  const [createClientGroup, {isLoading}] = useCreateClientGroupsMutation()
  const [updateProfiles, isUpdating] = useUpdateProfiles()
  const handleRequest = useHandleRequest()
  const {toast} = useToast()

  const onSubmit: SubmitHandler<ClientGroupFormData> = async formData => {
    await handleRequest({
      request: async () => {
        if (typeof formData.expectedResult !== 'number') {
          delete formData.expectedResult
        }

        if (!formData.clientEmail) {
          delete formData.clientEmail
        }

        const result = await createClientGroup(formData)
        return result
      },
      onSuccess: async ({data}: {data: CreateClientGroupResponse}) => {
        await updateProfiles('groupID', data.data, formData.profiles, [])
        toast({title: 'Group successfully created'})
        close()
      },
    })
  }

  return <BaseClientGroupForm open={open} close={close} onSubmit={onSubmit} isSubmitting={isLoading || isUpdating} />
}
