import {Table, TableBody, TableHead, TableHeader, TableRow} from '@/components/ui/table'
import React from 'react'
import {Props} from './types'
import {ClientProfileRow} from '../../..'
import {cn} from '@/lib/utils'

export const ClientGroupProfiles: React.FC<Props> = ({clientGroup}) => {
  if (!clientGroup.profiles.length) {
    return (
      <div className="h-40 text-xs text-slate-400 flex items-center justify-center gap-1">
        <b>{clientGroup.groupName}</b>
        <span>doesn't have any profiles</span>
      </div>
    )
  }

  return (
    <div className="px-5 py-2">
      <span className="text-xs mb-2 block">
        Profiles <b>({clientGroup.profiles.length})</b>
      </span>
      <div className="h-max overflow-y-auto">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className={thClassName}>Username</TableHead>
              <TableHead className={thClassName}>Client Email</TableHead>
              <TableHead className={thClassName}>Last 24hrs</TableHead>
              <TableHead className={thClassName}>Last 48hrs</TableHead>
              <TableHead className={thClassName}>Last 7days</TableHead>
              <TableHead className={thClassName}>Last 30days</TableHead>
              <TableHead className={thClassName}>Last 60days</TableHead>
              <TableHead className={thClassName}>Total Follows</TableHead>
              <TableHead className={cn(thClassName, '!border-b text-center')}>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {clientGroup.profiles.map((profile: any) => (
              <ClientProfileRow key={profile._id} clientProfile={profile} cellClassName="text-xs" />
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

const thClassName = 'sticky top-0 z-[5] bg-slate-100 dark:bg-secondary text-xs'
