import React from 'react'
import {Props} from './types'
import {Header} from './components'
import {useLocation} from 'react-router-dom'
import {PublicRoute} from '@/constants/public-routes/public-routes'
import {ThemeProvider} from '../ui/theme-provider'

export const Layout: React.FC<Props> = ({children}) => {
  const location = useLocation()

  return (
    <ThemeProvider>
      {!Object.values(PublicRoute).includes(location.pathname as PublicRoute) && <Header />}
      {children}
    </ThemeProvider>
  )
}
