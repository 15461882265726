import React from 'react'
import {Field} from '@/components/common'
import {Button} from '@/components/ui/button'
import {FaRegTrashCan} from 'react-icons/fa6'
import {Props} from './types'
import {useCreateTaskMutation} from '@/features/scraping-task'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {ScrapingTaskMainType} from '@/constants/status'
import {cn} from '@/lib/utils'
import {useToast} from '@/components/ui/use-toast'

export const UsernameCard: React.FC<Props> = ({form, index, isBlacklisted, onRemove, profile}) => {
  const [createTask, {isLoading: isCreating}] = useCreateTaskMutation()
  const handleRequest = useHandleRequest()
  const username = form.watch(`taskUsernames.${index}.value`)
  const warned = form.watch(`taskUsernames.${index}.warned`)
  const {toast} = useToast()

  const onCreate = async () => {
    const apiKey = form.getValues('apiKey')
    const act = form.getValues('act')
    if (!apiKey) {
      return form.setError('apiKey', {message: 'API key is required'})
    } else {
      form.setError('apiKey', {message: ''})
    }

    if (!act) {
      return form.setError('act', {message: 'act is required'})
    } else {
      form.setError('act', {message: ''})
    }

    await handleRequest({
      request: async () => {
        const result = await createTask({
          username: form.getValues(`taskUsernames.${index}.value`),
          mainType: isBlacklisted ? ScrapingTaskMainType.BLACKLISTED : ScrapingTaskMainType.NORMAL,
          profileID: profile._id,
          act: +act,
          apiKey,
          warned,
        })
        return result
      },
      onSuccess: () => {
        onRemove()
        toast({title: 'Task successfully created!'})
      },
      onError: error => {
        if (error.error.statusCode === 409) {
          form.setValue(`taskUsernames.${index}.warned`, true)
        }
      },
    })
  }

  return (
    <div className="flex items-center gap-2">
      <Field
        type="input"
        name={`taskUsernames.${index}.value`}
        rules={{required: true}}
        control={form.control}
        fieldClassName="w-full"
        componentProps={{parentClassName: 'w-full', placeholder: 'Enter username...'}}
      />
      <Button
        disabled={!username}
        loading={isCreating}
        onClick={onCreate}
        variant="outline"
        className={cn(
          'h-[38px] px-4 rounded min-w-max',
          isBlacklisted ? 'border-black text-black hover:bg-black hover:text-white' : '',
        )}
        size="sm"
      >
        Create {warned && 'Anyway'}
      </Button>
      <Button onClick={onRemove} size="icon" variant="outlined-destructive" className="px-3 min-w-max h-[38px]">
        <FaRegTrashCan />
      </Button>
    </div>
  )
}
