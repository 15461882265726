/* eslint-disable react-hooks/exhaustive-deps */
import {SelectNicheTasks} from '@/components/pages/manage/components'
import {Accordion, AccordionContent, AccordionItem, AccordionTrigger} from '@/components/ui/accordion'
import React, {useEffect, useState} from 'react'
import {Props} from './types'
import {useUpdateProfileDetailsMutation} from '@/features/profile'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useCloneTaskMutation} from '@/features/scraping-task'
import {useToast} from '@/components/ui/use-toast'

export const NichesSection: React.FC<Props> = ({profile}) => {
  const [cloneTask] = useCloneTaskMutation()
  const [updateProfile] = useUpdateProfileDetailsMutation()
  const [open, setOpen] = useState(false)
  const [selectedNicheID, setSelectedNicheID] = useState<undefined | string>(profile.niche?._id)
  const [selectedBlocks, setSelectedBlocks] = useState<any[]>([])
  const handleRequest = useHandleRequest()
  const [cloning, setCloning] = useState(false)
  const {toast} = useToast()

  const onClone = async () => {
    setCloning(true)

    let success = true
    for (const selectedBlock of selectedBlocks || []) {
      for (const blockTask of selectedBlock.tasks) {
        await handleRequest({
          request: async () => {
            const result = await cloneTask({
              id: profile._id,
              body: {fileName: blockTask.file_name, filePath: blockTask.file_path},
            })
            return result
          },
          onError: () => {
            success = false
          },
        })
      }
    }

    if (success) {
      toast({title: 'Tasks successfully cloned!'})
      setOpen(false)
      setSelectedBlocks([])
    }

    setCloning(false)
  }

  const onNicheSelect = async () => {
    await handleRequest({
      request: async () => {
        const result = await updateProfile({id: profile._id, body: {nicheID: selectedNicheID || 'false'}})
        return result
      },
    })
  }

  useEffect(() => {
    if (selectedNicheID !== profile.niche?._id) {
      onNicheSelect()
    }
  }, [selectedNicheID])

  return (
    <Accordion
      value={open ? 'default' : ''}
      onValueChange={value => setOpen(value === 'default')}
      type="single"
      collapsible
      className="border border-black dark:border-secondary rounded-md px-5 mt-4 mb-8"
    >
      <AccordionItem value="default" className="border-b-0">
        <AccordionTrigger className="py-3">Niches Feature</AccordionTrigger>
        <AccordionContent>
          <SelectNicheTasks
            selectedNicheID={selectedNicheID}
            selectedBlocks={selectedBlocks}
            setSelectedNicheID={setSelectedNicheID}
            setSelectedBlocks={setSelectedBlocks}
            profile={profile}
            onClone={onClone}
            isCloning={cloning}
          />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}
