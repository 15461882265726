import React, {useEffect, useMemo, useState} from 'react'
import {Props, ValidationProps} from './types'
import {MinusIcon, PlusIcon} from '@/icons'

export const Counter: React.FC<Props> = ({
  size = 'default',
  defaultValue = 0,
  value: value2 = 0,
  onChange: onValueChange,
  incDisabled,
  decDisabled,
  max,
  min,
}) => {
  const [value, setValue] = useState(+(value2 || defaultValue))
  const validationProps = useMemo(() => {
    const props: ValidationProps = {}

    if (typeof max === 'number') {
      props.max = max
    }

    if (typeof min === 'number') {
      props.min = min
    }

    return props
  }, [min, max])

  const onChange = (newValue: number) => {
    setValue(newValue)

    if (onValueChange) {
      onValueChange(newValue)
    }
  }

  useEffect(() => {
    setValue(value2 || defaultValue)
  }, [value2, defaultValue])

  return (
    <div
      className={`rounded-[8.1px] p-1.5 flex items-center bg-white dark:bg-background text-black border border-[#ebedf2] dark:border-secondary ${
        size === 'lg'
          ? 'w-[162px] shadow-[8.1px_8.1px_16.2px_#00000019]'
          : 'w-[110px] shadow-[4.1px_4.1px_9.2px_#00000019]'
      }`}
    >
      <button
        disabled={decDisabled}
        onClick={() => onChange(+value - 1)}
        type="button"
        className={`border border-destructive dark:border-red-600 text-destructive dark:text-red-600 bg-transparent hover:bg-destructive dark:hover:bg-red-600 hover:text-white dark:hover:text-white disabled:hover:text-destructive disabled:hover:bg-white disabled:opacity-40 disabled:cursor-not-allowed duration-150 ${getBtnClassName(
          size === 'lg',
        )}`}
      >
        <MinusIcon className={size === 'lg' ? 'w-[18.23px]' : 'w-[14.8px]'} />
      </button>
      <input
        disabled={decDisabled || incDisabled}
        type="number"
        className={`w-full mx-2 text-center font-semibold bg-transparent dark:text-white ${
          size === 'lg' ? 'text-[16.88px]' : 'text-[12px]'
        }`}
        value={value}
        onChange={e => onChange(+e.target.value)}
        {...validationProps}
      />
      <button
        disabled={incDisabled}
        onClick={() => onChange(+value + 1)}
        type="button"
        className={`border border-[#1677ff] text-[#1677ff] bg-transparent hover:bg-[#1677ff] hover:text-white disabled:hover:text-white disabled:opacity-40 disabled:cursor-not-allowed duration-150 ${getBtnClassName(
          size === 'lg',
        )}`}
      >
        <PlusIcon className={size === 'lg' ? 'w-[18.23px]' : 'w-[14.8px]'} />
      </button>
    </div>
  )
}

const getBtnClassName = (lgSize: boolean) =>
  `rounded-[4.05px] grid place-content-center ${lgSize ? 'min-w-[38.47px] h-[38.47px]' : 'min-w-[25.8px] h-[25.8px]'}`
