/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {Props} from './types'
import {BaseForm} from '..'
import {useLazyGetNicheProfilesQuery, useUpdateProfileNicheMutation} from '@/features/profile-niche'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {SubmitHandler} from 'react-hook-form'
import {NicheBaseFormData} from '../base-form/types'
import {useToast} from '@/components/ui/use-toast'
import {useUpdateProfiles} from '@/hooks/use-update-profiles'
import {CreateNicheResponse} from '@/features/profile-niche/types'

export const EditForm: React.FC<Props> = ({open, close, niche}) => {
  const [getNicheProfiles, {data: {data: profiles = []} = {}, isLoading: isFetchingProfiles}] =
    useLazyGetNicheProfilesQuery()
  const [updateNiche, {isLoading}] = useUpdateProfileNicheMutation()
  const handleRequest = useHandleRequest()
  const {toast} = useToast()
  const [updateProfiles, isUpdatingProfiles] = useUpdateProfiles()

  const onSubmit: SubmitHandler<NicheBaseFormData> = async formData => {
    await handleRequest({
      request: async () => {
        const result = await updateNiche({
          id: niche._id,
          body: {
            nicheName: formData.nicheName,
          },
        })
        return result
      },
      onSuccess: ({data}: {data: CreateNicheResponse}) => {
        toast({title: 'Niche successfully updated!'})
        updateProfiles('nicheID', data.data, formData.profiles)
        close()
      },
    })
  }

  const fetchNicheProfiles = async () => {
    await handleRequest({
      request: async () => {
        const result = await getNicheProfiles({id: niche._id})
        return result
      },
    })
  }

  useEffect(() => {
    if (open) {
      fetchNicheProfiles()
    }
  }, [open])

  return (
    <BaseForm
      open={open}
      close={close}
      onSubmit={onSubmit}
      isSubmitting={isLoading || isUpdatingProfiles}
      title={`Edit ${niche.nicheName}`}
      submitText="Update"
      isModalLoading={isFetchingProfiles}
      defaultValues={{
        nicheName: niche.nicheName,
        profiles: profiles.map(profile => profile._id),
      }}
    />
  )
}
