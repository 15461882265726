/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable unicorn/no-null */
import React, {useEffect} from 'react'
import {Props} from './types'
import {Modal} from '@/components/common'
import {useLazyGetBlacklistedAccountsQuery} from '@/features/vps-scripts'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {Accordion, AccordionContent, AccordionItem, AccordionTrigger} from '@/components/ui/accordion'

export const AccountsBlacklistModal: React.FC<Props> = ({open, close}) => {
  const [getBlacklistedAccounts, {data: {data: accounts = []} = {}, isLoading}] = useLazyGetBlacklistedAccountsQuery()
  const handleRequest = useHandleRequest()

  const fetchBlacklistedAccounts = async () => {
    await handleRequest({
      request: async () => {
        const result = await getBlacklistedAccounts({})
        return result
      },
    })
  }

  useEffect(() => {
    if (open) {
      fetchBlacklistedAccounts()
    }
  }, [open])

  return (
    <Modal
      open={open}
      close={close}
      title="Blacklisted Accounts"
      isLoading={isLoading}
      contentClassName="min-w-[600px]"
    >
      {accounts.length ? (
        <Accordion type="multiple">
          {accounts.map(account => (
            <AccordionItem value={account.account} className="mb-3 border rounded">
              <AccordionTrigger className="px-6 hover:no-underline [&[data-state=open]]:border-b">
                {account.account}
              </AccordionTrigger>
              <AccordionContent className="px-6 py-4">
                <div className="space-y-3">
                  {account.failures.map((failure, index) => (
                    <div key={index} className="border rounded-md px-4 py-3">
                      {JSON.stringify(failure, null, 4)}
                    </div>
                  ))}
                </div>
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      ) : (
        <div className="py-20 text-center text-sm text-slate-500">No any blacklisted accounts yet...</div>
      )}
    </Modal>
  )
}
