/* eslint-disable react-hooks/exhaustive-deps */
import { Accordion } from '@/components/ui/accordion'
import {
  AccountsBlacklistModal,
  ArchivedScriptsModal,
  CheckBannedAccountsModal,
  CheckScraperTasksModal,
  CreateScriptForm,
  DailyRunsModal,
  DefaultProxiesModal,
  ScriptCard,
} from './components'
import { useEffect, useMemo, useState } from 'react'
import {
  useGetVPSScriptsDailyRunStatusQuery,
  useGetVPSScriptsQuery,
  useGetVPSScriptsTargetsQuery,
} from '@/features/vps-scripts'
import { Input, Loader } from '@/components/common'
import { useDebounce } from 'use-debounce'
import { Button } from '@/components/ui/button'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { FiFilter } from 'react-icons/fi'
import { DailyRunStatus } from '@/constants/status'
import { CalendarCheck } from 'lucide-react'
import { TbReload } from 'react-icons/tb'

export const VPSTool = () => {
  const { data: { data: dailyRunStatus = DailyRunStatus.NOT_STARTED } = {}, isFetching: isFetchingDailyRunStatus } =
    useGetVPSScriptsDailyRunStatusQuery({})
  const { data: { data: targets = {} } = {}, isFetching: isFetchingTargets } = useGetVPSScriptsTargetsQuery({})
  const { data: { data: scriptsList = [] } = {}, isFetching } = useGetVPSScriptsQuery({})
  const [openCreateForm, setOpenCreateForm] = useState(false)
  const [scripts, setScripts] = useState(scriptsList)
  const [searchQuery, setSearchQuery] = useState('')
  const [debouncedQuery] = useDebounce(searchQuery, 300)
  const [openDefaultProxies, setOpenDefaultProxies] = useState(false)
  const [openAccountsBlacklist, setOpenAccountBlacklist] = useState(false)
  const [filterScripts, setFilterScripts] = useState(false)
  const [openDailyRuns, setOpenDailyRuns] = useState(false)
  const [openArchivedScripts, setOpenArchivedScripts] = useState(false)
  const [openCheckBannedAccsModal, setOpenCheckBannedAccsModal] = useState(false)
  const [openCheckScraperTasksModal, setOpenCheckScraperTasksModal] = useState(false)
  const scriptNamesObject = useMemo(() => Object.fromEntries(scripts.map(script => [script._id || script.username, script.username])), [scripts])
  const customScripts = useMemo(() => scripts.filter(script => script.converted || script._id), [scripts])

  useEffect(() => {
    if (!isFetching) {
      setScripts(
        scriptsList.filter(script =>
          debouncedQuery ? script?.username?.toLowerCase().includes(debouncedQuery.toLowerCase()) : true,
        ),
      )
    }
  }, [debouncedQuery, isFetching])

  useEffect(() => {
    if (!isFetchingTargets) {
      if (filterScripts) {
        setScripts(
          scriptsList.filter(
            script =>
              targets[script.username] < 75000 &&
              (debouncedQuery ? script?.username?.toLowerCase().includes(debouncedQuery.toLowerCase()) : true),
          ),
        )
      } else {
        setScripts(scriptsList)
      }
    }
  }, [isFetchingTargets, filterScripts])

  if (isFetchingDailyRunStatus) {
    return <Loader parentClassName="py-20" />
  }

  return (
    <>
      <div className="flex items-center justify-between">
        <h3 className="text-2xl font-semibold min-w-max">Scripts {scripts.length ? `(${scripts.length})` : ''}</h3>
        <div className="grid grid-cols-2 gap-2">
          <Button onClick={() => setOpenDailyRuns(true)} className="flex items-center gap-2 text-xs px-6">
            <CalendarCheck className="w-4s h-4" />
            <span>See Daily Runs</span>
          </Button>
          <Button
            variant="outline"
            onClick={() => setOpenArchivedScripts(true)}
            className="flex items-center gap-2 text-xs px-6"
          >
            <span>Archived Scripts</span>
          </Button>
          <Button
            disabled={!scripts.length}
            variant="outline"
            onClick={() => setOpenCheckBannedAccsModal(true)}
            className="flex items-center gap-2 text-xs px-6"
          >
            <span>Check Banned Accounts</span>
          </Button>
          <Button
            disabled={!scripts.length}
            variant="outline"
            onClick={() => setOpenCheckScraperTasksModal(true)}
            className="flex items-center gap-2 text-xs px-6"
          >
            <TbReload className="text-sm" />
            <span>Check Scraper Tasks</span>
          </Button>
        </div>
      </div>
      <div className="flex items-center justify-between gap-3 mb-6 mt-4">
        <div className="flex items-center gap-3">
          <Input
            parentClassName="max-w-[300px] min-w-[300px]"
            placeholder="Search..."
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  onClick={() => setFilterScripts(prev => !prev)}
                  variant={filterScripts ? 'default' : 'outline'}
                  className="flex gap-2 font-semibold min-w-[36px] duration-200 text-xs h-[38px]"
                  size="icon"
                  disabled={isFetchingTargets}
                >
                  <FiFilter />
                </Button>
              </TooltipTrigger>
              <TooltipContent className="max-w-[200px]">
                <p>Show only scripts with less than 75,000 targets</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="flex gap-2">
          <Button onClick={() => setOpenCreateForm(true)} size="sm" className="text-xs px-5">
            Create Script
          </Button>
          <Button onClick={() => setOpenDefaultProxies(true)} variant="outline" size="sm" className="text-xs px-5">
            Default Proxies
          </Button>
          <Button onClick={() => setOpenAccountBlacklist(true)} variant="outline" size="sm" className="text-xs px-5">
            Blacklisted Accounts
          </Button>
        </div>
      </div>
      <Accordion type="multiple" className="w-full">
        {isFetching ? (
          <Loader parentClassName="py-28" />
        ) : scripts.length ? (
          scripts.map(script => (
            <ScriptCard
              key={script._id || script.username}
              script={script}
              isTargetsLoading={isFetchingTargets}
              targets={targets[script._id || script.username]}
              isDailyScriptsRuning={![DailyRunStatus.COMPLETED, DailyRunStatus.NOT_STARTED].includes(dailyRunStatus)}
            />
          ))
        ) : (
          <div className="text-xs text-gray-400 text-center py-28">No scripts created yet</div>
        )}
      </Accordion>
      <CreateScriptForm open={openCreateForm} close={() => setOpenCreateForm(false)} />
      <DailyRunsModal open={openDailyRuns} close={() => setOpenDailyRuns(false)} scriptNamesObject={scriptNamesObject} />
      <DefaultProxiesModal open={openDefaultProxies} close={() => setOpenDefaultProxies(false)} />
      <AccountsBlacklistModal open={openAccountsBlacklist} close={() => setOpenAccountBlacklist(false)} />
      <ArchivedScriptsModal open={openArchivedScripts} close={() => setOpenArchivedScripts(false)} />
      <CheckBannedAccountsModal
        open={openCheckBannedAccsModal}
        close={() => setOpenCheckBannedAccsModal(false)}
        scripts={customScripts}
      />
      <CheckScraperTasksModal open={openCheckScraperTasksModal} close={() => setOpenCheckScraperTasksModal(false)} />
    </>
  )
}
