/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { ArchivedScriptsFormData, Props } from "./types";
import { Field, Modal } from "@/components/common";
import { SubmitHandler, useForm } from "react-hook-form";
import { Form } from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { useGetArchivedVPSScriptsQuery, useUpdateArchivedVPSScriptsMutation } from "@/features/vps-scripts";
import { useHandleRequest } from "@/hooks/use-handle-request";
import { useToast } from "@/components/ui/use-toast";

export const ArchivedScriptsModal: React.FC<Props> = ({ open, close }) => {
  const [updateArchivedScripts, { isLoading: isUpdating }] = useUpdateArchivedVPSScriptsMutation()
  const { data: { data: archivedScripts } = {}, isLoading } = useGetArchivedVPSScriptsQuery({});
  const form = useForm<ArchivedScriptsFormData>();
  const handleRequest = useHandleRequest();
  const { toast } = useToast();

  const onSubmit: SubmitHandler<ArchivedScriptsFormData> = async formData => {
    await handleRequest({
      request: async () => {
        const result = await updateArchivedScripts({ body: { archivedScripts: formData.archivedScripts } });
        return result;
      },
      onSuccess: () => {
        close();
        toast({ title: "Success", description: "Archived scripts successfully updated!" })
      }
    })
  }

  useEffect(() => {
    if (archivedScripts) {
      form.setValue("archivedScripts", archivedScripts)
    }
  }, [archivedScripts]);

  return <Modal open={open} close={close} title="Archived scripts" isLoading={isLoading}>
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-2">
        <Field type="textarea" control={form.control} name="archivedScripts" componentProps={{ label: "Unarchived scripts", placeholder: "Enter unarchived scripts...", className: "h-56" }} />
        <Button loading={isUpdating} type="submit">Save</Button>
      </form>
    </Form>
  </Modal>
}