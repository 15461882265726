/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {Props} from './types'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useLazyGetProfileReportQuery} from '@/features/profile'
import {Modal} from '@/components/common'

export const ReportsModal: React.FC<Props> = ({open, close, phoneID, footer}) => {
  const [getProfileReport, {data: {data: reportData = ''} = {}, isLoading}] = useLazyGetProfileReportQuery()
  const handleRequest = useHandleRequest()

  const fetchProfileReport = async () => {
    await handleRequest({
      request: async () => {
        const result = await getProfileReport({id: phoneID})
        return result
      },
      onError: () =>
        ((errors: any) => {
          console.warn(errors)
        }) as any,
    })
  }

  useEffect(() => {
    if (open) {
      fetchProfileReport()
    }
  }, [open])

  return (
    <Modal isLoading={isLoading} open={open} close={close} title={`Latest Reports (${phoneID})`}>
      {reportData ? (
        <div className="whitespace-pre-wrap h-[300px] overflow-y-auto">{reportData as any}</div>
      ) : (
        <div className="w-full h-20 grid place-content-center text-zinc-800">No Reports</div>
      )}
      {footer}
    </Modal>
  )
}
