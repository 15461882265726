import React from 'react'
import {Props} from './types'
import {Modal} from '@/components/common'
import {Button} from '@/components/ui/button'

export const ClosingWarningModal: React.FC<Props> = ({open, close, onOk}) => {
  const onStop = () => {
    close()
    onOk()
  }

  return (
    <Modal open={open} close={close} title="" contentClassName="sm:max-w-[450px]">
      <h2 className="text-7xl text-center">✋</h2>
      <h3 className="text-2xl font-bold text-center mt-3">Are you sure to stop the process ?</h3>
      <p className="text-sm text-center mt-3 mb-5">
        Stopping the process will stop checking scraper tasks, so tasks which aren't checked yet might left as
        uncompleted!
      </p>
      <div className="flex items-center gap-3">
        <Button className="w-1/2" onClick={close}>
          Cancel
        </Button>
        <Button variant="outlined-destructive" className="w-1/2" onClick={onStop}>
          Stop Checking
        </Button>
      </div>
    </Modal>
  )
}
