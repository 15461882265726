import {Button} from '@/components/ui/button'
import React, {useState} from 'react'
import {Props} from './types'
import {TbReportSearch} from 'react-icons/tb'
import {ReportsModal} from './components'

export const Reports: React.FC<Props> = ({profile}) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="outline" className="flex gap-2 rounded h-[34px] text-xs">
        <TbReportSearch className="text-sm" />
        <span>Reports</span>
      </Button>
      <ReportsModal open={open} close={() => setOpen(false)} phoneID={profile.phoneID} />
    </>
  )
}
