import {Icon} from '@/components/common/icon'
import {IconProps} from '@/components/common/icon/types'

export const ResizeIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 149 149">
    <circle cx="74.1898" cy="74.1898" r="74.1898" fill="#FFA367" />
    <g clipPath="url(#clip0_2202_2)">
      <path
        d="M71.0755 79.7807V91.9725C71.0755 93.405 69.9141 94.5662 68.4817 94.5662C67.0493 94.5662 65.888 93.405 65.888 91.9725V86.0423L53.3065 98.624C52.7999 99.1304 52.1363 99.3838 51.4724 99.3838C50.8086 99.3838 50.1449 99.1304 49.6385 98.624C48.6255 97.6111 48.6255 95.9688 49.6385 94.9559L62.2198 82.3745H56.29C54.8576 82.3745 53.6962 81.2133 53.6962 79.7807C53.6962 78.3482 54.8576 77.187 56.29 77.187H68.4819C69.9141 77.187 71.0755 78.3482 71.0755 79.7807ZM94.2184 50.3759L81.6369 62.9573V57.0274C81.6369 55.5948 80.4756 54.4336 79.0432 54.4336C77.6108 54.4336 76.4494 55.5948 76.4494 57.0274V69.2191C76.4494 70.6517 77.6108 71.8129 79.0432 71.8129H91.2351C92.6675 71.8129 93.8288 70.6517 93.8288 69.2191C93.8288 67.7866 92.6675 66.6254 91.2351 66.6254H85.3051L97.8866 54.0439C98.8995 53.031 98.8995 51.3887 97.8866 50.3759C96.8737 49.363 95.2314 49.363 94.2184 50.3759ZM115 46.1803V102.82C115 110.087 109.087 116 101.82 116H45.1801C37.9126 116 32 110.087 32 102.82V46.1803C32 38.9126 37.9126 33 45.1801 33H101.82C109.087 33 115 38.9126 115 46.1803ZM109.812 46.1803C109.812 41.773 106.227 38.1875 101.82 38.1875H45.1801C40.773 38.1875 37.1875 41.773 37.1875 46.1803V102.82C37.1875 107.227 40.773 110.812 45.1801 110.812H101.82C106.227 110.812 109.812 107.227 109.812 102.82V46.1803Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2202_2">
        <rect width="83" height="83" fill="white" transform="translate(32 33)" />
      </clipPath>
    </defs>
  </Icon>
)
