import React, {useMemo} from 'react'
import {Select as UISelect, SelectContent, SelectItem, SelectTrigger, SelectValue} from '@/components/ui/select'

import {Props, ValueProps} from './types'
import {cn} from '@/lib/utils'

export const Select: React.FC<Props> = ({
  options,
  label,
  placeholder,
  value,
  defaultValue,
  onValueChange,
  labelClassName,
}) => {
  const valueProps = useMemo(() => {
    const props: ValueProps = {}

    if (onValueChange) {
      props.value = value
      props.onValueChange = onValueChange
    }

    if (defaultValue) {
      props.defaultValue = defaultValue
    }

    return props
  }, [value, defaultValue, onValueChange])

  return (
    <label className={cn('w-full', labelClassName)}>
      {label && <span className="text-xs capitalize">{label}</span>}
      <UISelect {...valueProps}>
        <SelectTrigger className="w-full">
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent className="max-h-[200px]">
          {options.map(option => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </UISelect>
    </label>
  )
}
