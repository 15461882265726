import React from 'react'
import {Props} from './types'
import {Counter} from '..'

export const DoubleCounter: React.FC<Props> = ({onChange, value, rangeValidation}) => (
  <div className="flex items-center gap-5 max-w-[270px]">
    <Counter
      incDisabled={rangeValidation ? (value?.int1 || value?.int2 ? value?.int1 >= value?.int2 : false) : false}
      value={value?.int1}
      onChange={val => {
        if (onChange) {
          onChange({int2: value?.int2 || 0, int1: val})
        }
      }}
    />
    <span className="text-black dark:text-white">to</span>
    <Counter
      decDisabled={rangeValidation ? (value?.int2 || value?.int1 ? value?.int2 <= value?.int1 : false) : false}
      value={value?.int2}
      onChange={val => {
        if (onChange) {
          onChange({int1: value?.int1 || 0, int2: val})
        }
      }}
    />
  </div>
)
