import React, {ChangeEvent, useEffect, useRef, useState} from 'react'
import {Props} from './types'
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from '@/components/ui/table'
import {Button} from '@/components/ui/button'
import {FaPlus} from 'react-icons/fa'
import {MdDriveFolderUpload} from 'react-icons/md'
import {useHandleError} from '@/hooks/use-handle-error'
import {getFileText} from '@/utils/get-file-text'

const convertLoginsToValue = (logins: string) => logins.split('\n').map(login => login.split(':'))

export const RowEditor: React.FC<Props> = ({form}) => {
  const logins = form.watch('logins') || ''
  const [accounts, setAccounts] = useState(convertLoginsToValue(logins))
  const tableRef = useRef<HTMLDivElement>(null)
  const handleError = useHandleError()

  const onAddRow = () => {
    form.setValue('logins', form.getValues('logins') + '\n')

    if (tableRef.current) {
      tableRef.current.scrollTo({top: tableRef.current.clientHeight * 1000})
    }
  }

  const onFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (!(file?.type?.includes('text/plain') || file?.type?.includes('txt'))) {
      event.target.value = ''
      return handleError('Only TXT files accepted')
    }

    getFileText(file, (value: string) => {
      form.setValue('logins', value)
      event.target.value = ''
    })
  }

  const onChange = (index: number, subIndex: number, value: string) => {
    const updatedAccounts = [...accounts]
    updatedAccounts[index][subIndex] = value
    form.setValue('logins', updatedAccounts.map(account => account.join(':')).join('\n'))
  }

  useEffect(() => {
    setAccounts(convertLoginsToValue(logins))
  }, [logins])

  return (
    <>
      <div ref={tableRef} className="max-h-[300px] overflow-y-auto border">
        <Table className="w-full border-collapse overflow-y-auto">
          <TableHeader>
            <TableRow>
              <TableHead className={thClassName}>Username</TableHead>
              <TableHead className={thClassName}>Password</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {accounts.map((account, index) => (
              <TableRow key={index}>
                <TableCell className={tableCellClassName}>
                  <input
                    value={account[0] || ''}
                    onChange={event => onChange(index, 0, event.target.value)}
                    className={inputClassName}
                    placeholder="Username"
                  />
                </TableCell>
                <TableCell className={tableCellClassName}>
                  <input
                    value={account[1] || ''}
                    onChange={event => onChange(index, 1, event.target.value)}
                    className={inputClassName}
                    placeholder="Password"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end gap-4 mt-5">
        <Button onClick={onAddRow} variant="outline" size="sm" className="flex gap-2 text-xs">
          <FaPlus />
          <span>Add Row</span>
        </Button>
        <label className="cursor-pointer">
          <span className="inline-flex items-center justify-center font-medium transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 gap-2 text-xs border border-[#ebedf2] dark:border-secondary dark:text-white dark:hover:bg-secondary text-[#0d0e1c] hover:bg-[#ebedf2] h-9 rounded-md px-3">
            <MdDriveFolderUpload className="text-sm" />
            <span>Upload</span>
          </span>
          <input type="file" accept=".txt" onChange={onFileUpload} hidden />
        </label>
      </div>
    </>
  )
}

const inputClassName =
  'bg-white dark:bg-background border border-transparent px-3 py-2 focus:border-blue dark:focus w-full text-xs'
const thClassName = `sticky top-0 bg-white dark:bg-background border-b border border-gray-400 dark:border-secondary dark:text-white text-center text-black text-xs h-9`
const tableCellClassName = '!border dark:border-secondary border-zinc-400 !p-0 bg-white dark:bg-background'
