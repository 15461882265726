import {Button} from '@/components/ui/button'
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from '@/components/ui/table'
import {cn} from '@/lib/utils'
import {Loader, TableNotFoundRow} from '@/components/common'
import {useState} from 'react'
import {useGetAccountsQuery} from '@/features/accounts'
import {useGetUserQuery} from '@/features/auth'
import {Role} from '@/constants/role'
import {Navigate} from 'react-router-dom'
import {AccountRow, CreateForm} from './components'

export const Accounts = () => {
  const {data: {data: user} = {}} = useGetUserQuery('')
  const {data: {data: accounts = []} = {}, isFetching} = useGetAccountsQuery('')
  const [openCreateForm, setOpenCreateForm] = useState(false)

  if (user?.account.role !== Role.SUPER_ACCOUNT || user?.subaccount.role !== Role.SUPER_SUBACCOUNT) {
    return <Navigate to="/" />
  }

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-2xl font-semibold">Accounts {accounts.length ? `(${accounts.length})` : ''}</h3>
        <Button size="sm" className="px-5 text-xs" onClick={() => setOpenCreateForm(true)}>
          Create New
        </Button>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className={tableTHClassNames}>Name</TableHead>
            <TableHead className={tableTHClassNames}>Role</TableHead>
            <TableHead className={cn(tableTHClassNames, 'text-center')}>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {!isFetching ? (
            accounts?.length ? (
              accounts.map(account => (
                <AccountRow key={account._id} account={account} isCurrentAccount={user?.account._id === account._id} />
              ))
            ) : (
              <TableNotFoundRow text="Accounts not found" />
            )
          ) : (
            <TableRow className="relative">
              <TableCell className="h-40">
                <Loader parentClassName="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <CreateForm open={openCreateForm} close={() => setOpenCreateForm(false)} />
    </>
  )
}

const tableTHClassNames = 'text-black dark:text-white dark:bg-secondary'
