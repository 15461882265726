/* eslint-disable react-hooks/exhaustive-deps */
import {Loader, Tabs} from '@/components/common'
import {CSVEditor, ProfilePhotos, Posting, SettingsMain, SettingsTop} from './components'
import {useCurrentProfile} from '@/hooks/use-current-profile'
import {useSearchParams} from 'react-router-dom'
import {useEffect, useRef, useState} from 'react'

export const Settings = () => {
  const [searchParams] = useSearchParams()
  const [selectedTab, setSelectedTab] = useState('settings')
  const [profile, {isFetching, isExisted}] = useCurrentProfile()
  const settingsTopRef = useRef<null | HTMLDivElement>(null)
  const [topMargin, setTopMargin] = useState(156)

  useEffect(() => {
    if (settingsTopRef.current?.clientHeight) {
      setTopMargin(settingsTopRef.current?.clientHeight + 20)
    }
  }, [settingsTopRef.current])

  if (isFetching) {
    return <Loader />
  }

  if (!searchParams.get('phoneID') || !isExisted) {
    return (
      <div className="w-full h-full grid place-content-center text-xl text-zinc-900 dark:text-white">
        Select Profile
      </div>
    )
  }

  return (
    <div className="w-full rounded-lg">
      <SettingsTop profile={profile} settingsTopRef={settingsTopRef} />
      <div style={{marginTop: topMargin + 'px'}} className="mt-[116px] pb-10">
        <Tabs
          value={selectedTab}
          onValueChange={setSelectedTab}
          tabs={[
            {label: 'Settings', value: 'settings', children: <SettingsMain profile={profile} />},
            {label: 'CSV Editor', value: 'csv-editor', children: <CSVEditor profile={profile} />},
            {label: 'Profile Photos', value: 'profile-photos', children: <ProfilePhotos profile={profile} />},
            {label: 'Posting', value: 'posting', children: <Posting profile={profile} />},
            // {label: 'VPS Script', value: 'vps-script', children: <VPSScript profile={profile} />},
          ]}
        />
      </div>
    </div>
  )
}
