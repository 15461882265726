import {Accordion, AccordionContent, AccordionItem, AccordionTrigger} from '@/components/ui/accordion'
import React, {ChangeEvent, useState} from 'react'
import {Props} from './types'
import {Button} from '@/components/ui/button'
import {ExternalLink} from 'lucide-react'
import {useHandleError} from '@/hooks/use-handle-error'
import {cn} from '@/lib/utils'
import {useUpdateVPSScriptFileDataByUsernameMutation} from '@/features/vps-scripts'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useToast} from '@/components/ui/use-toast'
import {VPS_SERVER} from '@/constants/server-url'
import {Loader} from '@/components/common'
import {useGetUserQuery} from '@/features/auth'
import {PKSScraper} from './components'

export const PKSCard: React.FC<Props> = ({script, isTargetsLoading, targets}) => {
  const [updateVPSScriptFileData, {isLoading: isUpdatingFileData}] = useUpdateVPSScriptFileDataByUsernameMutation()
  const [file, setFile] = useState<File | undefined>()
  const handleRequest = useHandleRequest()
  const handleError = useHandleError()
  const {toast} = useToast()
  const {data: {data: user} = {}} = useGetUserQuery({})

  const onSaveFile = async () => {
    if (file) {
      await handleRequest({
        request: async () => {
          const formData = new FormData()
          formData.append('file', file)
          const result = await updateVPSScriptFileData({
            username: script._id || script.username,
            body: formData,
            params: {key: 'pks'},
          })
          return result
        },
        onSuccess: () => {
          setFile(undefined)
          toast({title: 'Script file data successfully updated!'})
        },
      })
    }
  }

  const onFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0]

    if (uploadedFile?.type !== 'text/plain') {
      return handleError('Only TXT files accepted!')
    }

    setFile(uploadedFile)
  }

  return (
    <Accordion type="multiple" className="w-full">
      <AccordionItem value="accounts" className="mb-3 border rounded">
        <AccordionTrigger className="px-6 hover:no-underline [&[data-state=open]]:border-b">
          <div className="flex items-center gap-3">
            <span>PKS</span>
            <div className="flex items-center">
              ({isTargetsLoading ? <Loader className="!w-5 !h-5 border-2" /> : targets || 0})
            </div>
          </div>
        </AccordionTrigger>
        <AccordionContent className="px-6 py-4">
          <PKSScraper script={script} />
          <a
            target="_blank"
            href={`${VPS_SERVER}/scripts-get/${script._id || script.username}?fileName=pks&account=${
              user?.account?._id
            }`}
          >
            <Button variant="outline" className="w-full gap-2">
              <span>pks/{script._id || script.username}.txt</span>
              <ExternalLink className="w-3.5 h-3.5" />
            </Button>
          </a>
          <div
            className={cn(
              'w-full border rounded-lg mt-4 text-slate-400',
              !file && 'border-dashed',
              !file && !script.running && 'cursor-pointer',
              script.running && 'cursor-not-allowed',
            )}
          >
            {script.running ? (
              <div className="w-full h-20 grid place-content-center">
                <span>PKS Upload is disabled while script is running!</span>
              </div>
            ) : file ? (
              <div className="text-center py-3 px-4">
                <p className="pt-5 pb-8">{file.name}</p>
                <div className="grid grid-cols-2 gap-3">
                  <Button loading={isUpdatingFileData} onClick={onSaveFile}>
                    Save
                  </Button>
                  <Button disabled={isUpdatingFileData} onClick={() => setFile(undefined)} variant="destructive">
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <label className="w-full h-20 grid place-content-center">
                <span>Change to New File</span>
                <input type="file" accept=".txt" onChange={onFileUpload} hidden />
              </label>
            )}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}
