import React, {useMemo, useState} from 'react'
import {Props, SettingsFormData} from './types'
import {useDeleteProfilePostMutation, useGetProfilePostsQuery, useUpdateProfileDataMutation} from '@/features/profile'
import {DeleteModal, Field, Loader, Switch} from '@/components/common'
import {Button} from '@/components/ui/button'
import {MdCreateNewFolder} from 'react-icons/md'
import {CreatePostsModal} from './components'
import {cn} from '@/lib/utils'
import {FaRegTrashCan} from 'react-icons/fa6'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useToast} from '@/components/ui/use-toast'
import {FaCheck, FaLock} from 'react-icons/fa'
import {SubmitHandler, useForm} from 'react-hook-form'
import {Form} from '@/components/ui/form'

export const Posting: React.FC<Props> = ({profile}) => {
  const [updateProfile] = useUpdateProfileDataMutation()
  const [deletePost] = useDeleteProfilePostMutation()
  const {data: {data: posts = []} = {}, isFetching} = useGetProfilePostsQuery({id: profile._id})
  const form = useForm<SettingsFormData>({
    defaultValues: {
      max_posts_count: profile.data.static.posting_values?.max_posts_count || 0,
      post_session: profile.data.static.posting_values?.post_session || 0,
    },
  })
  const [openCreateForm, setOpenCreateForm] = useState(false)
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false)
  const [selectedPosts, setSelectedPosts] = useState<string[]>([])
  const [deleting, setDeleting] = useState(false)
  const handleRequest = useHandleRequest()
  const postingEnabled = useMemo(() => profile.data.static.posting_values?.enabled, [profile])
  const [savingSettings, setSavingSettings] = useState(false)
  const [loadingLock, setLoadingLock] = useState(false)
  const {toast} = useToast()
  const maxPosts = form.watch('max_posts_count')
  const postSession = form.watch('post_session')

  const onPostClick = (post: string) => {
    setSelectedPosts(prev => {
      const updatedSelecteds = [...prev]
      const existedIndex = updatedSelecteds.findIndex(selectedPost => selectedPost === post)
      if (existedIndex !== -1) {
        updatedSelecteds.splice(existedIndex, 1)
        return updatedSelecteds
      }

      updatedSelecteds.push(post)
      return updatedSelecteds
    })
  }

  const onDeleteSelecteds = async () => {
    setDeleting(true)

    let success = false

    for (const selectedPost of selectedPosts) {
      await handleRequest({
        request: async () => {
          const result = await deletePost({id: selectedPost})
          return result
        },
        onSuccess: () => {
          success = true
        },
      })
    }

    if (success) {
      toast({title: 'Posts successfully deleted!'})
      setOpenDeleteAlert(false)
    }

    setSelectedPosts([])
    setDeleting(false)
  }

  const onTogglePostingLock = async () => {
    setLoadingLock(true)

    await handleRequest({
      request: async () => {
        const result = await updateProfile({
          id: profile._id,
          body: {
            data: {
              static: {
                ...profile.data.static,
                posting_values: {
                  ...(profile.data.static.posting_values || {}),
                  enabled: !postingEnabled,
                },
              },
            },
          },
        })

        return result
      },
    })

    setLoadingLock(false)
  }

  const onSaveSettings: SubmitHandler<SettingsFormData> = async formData => {
    setSavingSettings(true)

    await handleRequest({
      request: async () => {
        const result = await updateProfile({
          id: profile._id,
          body: {
            data: {
              static: {
                ...profile.data.static,
                posting_values: {
                  ...(profile.data.static.posting_values || {}),
                  max_posts_count: formData.max_posts_count,
                  post_session: formData.post_session,
                },
              },
            },
          },
        })

        return result
      },
      onSuccess: () => {
        toast({title: 'Settings successfully saved!'})
      },
    })

    setSavingSettings(true)
  }

  return (
    <div className="bg-white dark:bg-background dark:border dark:border-secondary px-9 py-7 rounded-lg mt-5 h-screen max-h-[69.5vh] relative">
      {isFetching ? (
        <Loader parentClassName="absolute top-0 left-0 w-full h-full" />
      ) : (
        <div>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <h2 className="text-xl font-semibold">Posts {posts.length ? <>({posts.length})</> : ''}</h2>
              <Switch
                label="Activate Posting"
                checked={postingEnabled}
                onChange={onTogglePostingLock}
                labelClassname="text-sm"
                loading={loadingLock}
              />
            </div>
            <div className="flex items-center gap-2">
              <Button
                disabled={!postingEnabled}
                onClick={() => setOpenCreateForm(true)}
                size="sm"
                className="gap-1.5 px-3 h-8 text-xs rounded"
              >
                <MdCreateNewFolder className="text-sm" />
                <span>Create Posts</span>
              </Button>
              <Button
                onClick={() => setOpenDeleteAlert(true)}
                disabled={!selectedPosts.length || !postingEnabled}
                size="sm"
                variant="outlined-destructive"
                className="hover:text-white flex items-center gap-1.5 text-xs px-3 h-8 rounded"
              >
                <FaRegTrashCan />
                <span>Delete Selecteds ({selectedPosts.length})</span>
              </Button>
            </div>
          </div>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSaveSettings)}
              className="flex items-center gap-5 my-5 px-4 py-3 border border-[#ebedf2] dark:border-secondary rounded-xl"
            >
              <div className="w-2/5 flex items-center justify-between bg-[#f5f6f9] dark:bg-background px-6 py-2 rounded-lg">
                <span className="text-sm font-semibold">Post Session</span>
                <Field
                  type="counter"
                  control={form.control}
                  name="post_session"
                  componentProps={{
                    incDisabled: !postingEnabled || postSession === maxPosts,
                    decDisabled: !postingEnabled || postSession === 0,
                    max: maxPosts,
                    min: 0,
                  }}
                />
              </div>
              <div className="w-2/5 flex items-center justify-between bg-[#f5f6f9] dark:bg-background px-6 py-2 rounded-lg">
                <span className="text-sm font-semibold">Max Posts</span>
                <Field
                  type="counter"
                  control={form.control}
                  name="max_posts_count"
                  componentProps={{
                    incDisabled: !postingEnabled || maxPosts >= 99,
                    decDisabled: !postingEnabled || maxPosts === 0,
                    max: 99,
                    min: 0,
                  }}
                />
              </div>
              <Button
                htmlType="submit"
                loading={savingSettings}
                disabled={!postingEnabled}
                className="w-1/5 h-[53px] text-xl"
              >
                Save
              </Button>
            </form>
          </Form>
          <div className="max-h-[55vh] overflow-y-auto grid grid-cols-5 gap-3 border border-[#ebedf2] dark:border-secondary p-4 rounded-lg relative">
            {!postingEnabled ? (
              <div className="h-[30vh] w-full bg-transparent flex items-center justify-center gap-2 col-span-5 backdrop-blur text-sm text-slate-300">
                <FaLock /> <span>Activities Locked</span>
              </div>
            ) : posts.length ? (
              posts.map(post => (
                <div
                  key={post}
                  onClick={() => onPostClick(post)}
                  className={cn(
                    'relative border rounded-md h-64 cursor-pointer duration-150',
                    selectedPosts.includes(post)
                      ? 'border-blue border-[8px]'
                      : 'border-[#ebedf2] dark:border-secondary',
                  )}
                >
                  <img
                    src={`https://st31539.ispot.cc/posting/Data/${profile.phoneID}/${post}`}
                    alt={post}
                    className="w-full h-full object-cover rounded-md"
                    crossOrigin="anonymous"
                  />
                  <span
                    className={cn(
                      'absolute -top-2 -right-2 text-white bg-blue rounded-full w-7 h-7 grid place-content-center duration-150',
                      selectedPosts.includes(post) ? 'opacity-100 scale-100' : 'opacity-0 scale-90',
                    )}
                  >
                    <FaCheck />
                  </span>
                </div>
              ))
            ) : (
              <div className="grid col-span-5 place-content-center h-40 w-full text-sm text-slate-400">
                No Posts Created yet
              </div>
            )}
          </div>
        </div>
      )}
      <CreatePostsModal open={openCreateForm} close={() => setOpenCreateForm(false)} profile={profile} />
      <DeleteModal
        open={openDeleteAlert}
        close={() => setOpenDeleteAlert(false)}
        onDelete={onDeleteSelecteds}
        isDeleting={deleting}
        title={`Are you sure to delete these ${selectedPosts.length} posts ?`}
      />
    </div>
  )
}
