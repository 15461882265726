import {Icon} from '@/components/common/icon'
import {IconProps} from '@/components/common/icon/types'

export const HeartIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 149 149">
    <circle cx="74.1898" cy="74.1898" r="64.1898" fill="#2CD776" stroke="white" strokeWidth="20" />
    <path
      d="M66.6846 97.4974C66.0726 98.1127 65.2379 98.4564 64.3706 98.4564C63.5032 98.4564 62.6686 98.1127 62.0565 97.4974L43.5767 79.0143C41.6587 77.0963 41.6587 73.9869 43.5767 72.0721L45.8908 69.758C47.8088 67.84 50.9149 67.84 52.833 69.758L64.3706 81.2956L95.5466 50.1196C97.4647 48.2015 100.574 48.2015 102.489 50.1196L104.803 52.4336C106.721 54.3517 106.721 57.4611 104.803 59.3758L66.6846 97.4974Z"
      fill="white"
    />
    <circle cx="74.1898" cy="74.1898" r="74.1898" fill="#FF424E" />
    <path
      d="M69.1406 123.641H77.8594V112.016C77.8594 111.63 78.0125 111.261 78.285 110.988C78.5575 110.716 78.9271 110.562 79.3125 110.562H89.4844V101.844C89.4844 101.458 89.6375 101.089 89.91 100.816C90.1825 100.544 90.5521 100.391 90.9375 100.391H102.562V87.3125C102.562 86.9271 102.716 86.5575 102.988 86.285C103.261 86.0125 103.63 85.8594 104.016 85.8594H115.641V55.3438H108.375C107.99 55.3438 107.62 55.1907 107.347 54.9181C107.075 54.6456 106.922 54.276 106.922 53.8906V46.625H99.6562C99.2709 46.625 98.9012 46.4719 98.6287 46.1994C98.3562 45.9269 98.2031 45.5573 98.2031 45.1719V39.3594H89.4844V45.1719C89.4844 45.5573 89.3313 45.9269 89.0588 46.1994C88.7863 46.4719 88.4166 46.625 88.0312 46.625H80.7656V50.9844C80.7656 51.3698 80.6125 51.7394 80.34 52.0119C80.0675 52.2844 79.6979 52.4375 79.3125 52.4375H67.6875C67.3021 52.4375 66.9325 52.2844 66.66 52.0119C66.3875 51.7394 66.2344 51.3698 66.2344 50.9844V46.625H58.9688C58.5834 46.625 58.2137 46.4719 57.9412 46.1994C57.6687 45.9269 57.5156 45.5573 57.5156 45.1719V39.3594H48.7969V45.1719C48.7969 45.5573 48.6438 45.9269 48.3713 46.1994C48.0988 46.4719 47.7291 46.625 47.3438 46.625H40.0781V53.8906C40.0781 54.276 39.925 54.6456 39.6525 54.9181C39.38 55.1907 39.0104 55.3438 38.625 55.3438H31.3594V85.8594H42.9844C43.3698 85.8594 43.7394 86.0125 44.0119 86.285C44.2844 86.5575 44.4375 86.9271 44.4375 87.3125V100.391H56.0625C56.4479 100.391 56.8175 100.544 57.09 100.816C57.3625 101.089 57.5156 101.458 57.5156 101.844V110.562H67.6875C68.0729 110.562 68.4425 110.716 68.715 110.988C68.9875 111.261 69.1406 111.63 69.1406 112.016V123.641Z"
      fill="white"
    />
  </Icon>
)
