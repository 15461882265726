import React from 'react'
import {Props} from './types'
import {useCreateVPSScriptMutation} from '@/features/vps-scripts'
import {BaseScriptForm} from '..'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useToast} from '@/components/ui/use-toast'
import {SubmitHandler} from 'react-hook-form'
import {BaseScriptFormData} from '../base-script-form/types'
import {useAppDispatch} from '@/store/hooks'
import {baseApi} from '@/features'
import {RTKTagName} from '@/constants/rtk-tag-name'

export const CreateScriptForm: React.FC<Props> = ({open, close}) => {
  const [createScript, {isLoading}] = useCreateVPSScriptMutation()
  const dispatch = useAppDispatch()
  const handleRequest = useHandleRequest()
  const {toast} = useToast()

  const onSubmit: SubmitHandler<BaseScriptFormData> = async formData => {
    await handleRequest({
      request: async () => {
        const result = await createScript({body: formData})
        return result
      },
      onSuccess: () => {
        dispatch(baseApi.util.invalidateTags([RTKTagName.VPS_SCRIPT]))
        close()
        toast({title: 'Script successfully created!'})
      },
    })
  }

  return <BaseScriptForm open={open} close={close} onSubmit={onSubmit} isSubmitting={isLoading} />
}
