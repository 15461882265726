import {Icon} from '@/components/common/icon'
import {IconProps} from '@/components/common/icon/types'

export const TranslatorIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 149 149">
    <circle cx="74.1898" cy="74.1898" r="64.1898" fill="#2CD776" stroke="white" strokeWidth="20" />
    <path
      d="M66.6846 97.4974C66.0726 98.1127 65.2379 98.4564 64.3706 98.4564C63.5032 98.4564 62.6686 98.1127 62.0565 97.4974L43.5767 79.0143C41.6587 77.0963 41.6587 73.9869 43.5767 72.0721L45.8908 69.7581C47.8088 67.84 50.9149 67.84 52.833 69.7581L64.3706 81.2957L95.5466 50.1196C97.4647 48.2016 100.574 48.2016 102.489 50.1196L104.803 52.4336C106.721 54.3517 106.721 57.4611 104.803 59.3758L66.6846 97.4974Z"
      fill="white"
    />
    <circle cx="74.1898" cy="74.1898" r="74.1898" fill="#3784E9" />
    <g clipPath="url(#clip0_2202_9)">
      <path d="M54.028 50.7441H52.9622L50.9705 60.7051H56.0197L54.028 50.7441Z" fill="white" />
      <path
        d="M94.314 70.666C95.3893 73.5545 96.8195 75.8927 98.4023 77.8822C99.9851 75.8927 101.581 73.5544 102.657 70.666H94.314Z"
        fill="white"
      />
      <path
        d="M108.529 43.1074H77.6034L84.0518 94.851C84.1658 96.9674 83.5873 98.9599 82.206 100.52L71.2858 113H108.529C112.649 113 116 109.649 116 105.529V50.7441C116 46.6246 112.649 43.1074 108.529 43.1074ZM108.529 70.666H107.864C106.447 75.212 104.2 78.7692 101.865 81.5736C103.694 83.246 105.651 84.618 107.595 86.1546C108.668 87.0131 108.843 88.5791 107.982 89.6541C107.125 90.7273 105.553 90.9013 104.483 90.0407C102.369 88.3727 100.386 86.9766 98.4023 85.1564C96.4188 86.9766 94.6012 88.3727 92.488 90.0407C91.4177 90.9013 89.8456 90.7273 88.9886 89.6541C88.1276 88.5791 88.3028 87.0131 89.3752 86.1546C91.3201 84.618 93.1103 83.246 94.9396 81.5736C92.6044 78.7694 90.5232 75.2122 89.1065 70.666H88.4414C87.065 70.666 85.9512 69.5522 85.9512 68.1758C85.9512 66.7993 87.065 65.6855 88.4414 65.6855H95.9121V63.1953C95.9121 61.8189 97.0259 60.7051 98.4023 60.7051C99.7788 60.7051 100.893 61.8189 100.893 63.1953V65.6855H108.529C109.906 65.6855 111.02 66.7993 111.02 68.1758C111.02 69.5522 109.906 70.666 108.529 70.666Z"
        fill="white"
      />
      <path
        d="M71.535 34.5442C71.0705 30.8136 67.8824 28 64.1228 28H38.4707C34.3512 28 31 31.3512 31 35.4707V90.5879C31 94.7074 34.3512 98.0586 38.4707 98.0586C53.3573 98.0586 64.0665 98.0586 77.7419 98.0586C78.4683 97.2282 79.0773 96.7082 79.103 95.6243C79.1093 95.3525 71.5687 34.8138 71.535 34.5442ZM61.5376 75.5978C60.2171 75.8696 58.8795 75.0123 58.6072 73.645L57.0156 65.6855H49.9748L48.3832 73.645C48.1157 74.9922 46.817 75.8774 45.4528 75.5978C44.1056 75.3279 43.23 74.0172 43.5 72.6675L48.4803 47.7652C48.7137 46.6027 49.7352 45.7637 50.9219 45.7637H56.0684C57.255 45.7637 58.2765 46.6027 58.51 47.7652L63.4904 72.6675C63.7604 74.0172 62.885 75.3279 61.5376 75.5978Z"
        fill="white"
      />
      <path
        d="M60.0961 103.039L60.5234 106.456C60.8083 108.744 62.3286 111.077 64.8295 112.242C69.549 107.047 66.6937 110.19 73.1899 103.039H60.0961Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2202_9">
        <rect width="85" height="85" fill="white" transform="translate(31 28)" />
      </clipPath>
    </defs>
  </Icon>
)
