import {TableNotFoundRow} from '@/components/common'
import {Table, TableBody, TableHead, TableHeader, TableRow} from '@/components/ui/table'
import React from 'react'
import {Props} from './types'
import {PendingTaskRow} from './components'

export const CompletedTasks: React.FC<Props> = ({tasks}) => (
  <div>
    <h3 className="text-xl font-semibold">Completed Tasks {tasks.length ? `(${tasks.length})` : ''}</h3>
    <Table className="border-collapse mt-3">
      <TableHeader>
        <TableRow>
          <TableHead className={thClassName}>Username</TableHead>
          <TableHead className={thClassName}>ID</TableHead>
          <TableHead className={thClassName}>Date</TableHead>
          <TableHead className={thClassName}>Results</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {tasks.length ? (
          tasks.map(task => <PendingTaskRow key={task._id} task={task} />)
        ) : (
          <TableNotFoundRow text="No completed tasks yet..." />
        )}
      </TableBody>
    </Table>
  </div>
)

const thClassName = `sticky top-0 h-11 bg-white dark:bg-background border-b border border-gray-400 dark:border-secondary text-center text-black dark:text-white font-bold`
