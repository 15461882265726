import React, {SetStateAction, useEffect, useRef, useState} from 'react'
import {FaChevronLeft} from 'react-icons/fa'
import {IoMdClose} from 'react-icons/io'
import {Props} from './types'
import {AccordionContent, AccordionItem, AccordionTrigger} from '@/components/ui/accordion'

export const ChartSection: React.FC<Props> = ({value, label, images}) => {
  const [openedImg, setOpenedImg] = useState<SetStateAction<number | undefined>>()
  const containerRef = useRef<HTMLDivElement>(null)

  const onKeyDown = (e: any) => {
    if (e.key === 'ArrowLeft' && openedImg === 0) {
      setOpenedImg((prev: any) => prev - 1)
    } else if (e.key === 'ArrowRight' && openedImg === images?.length - 1) {
      setOpenedImg((prev: any) => prev + 1)
    }
  }

  useEffect(() => {
    document.body.style.overflowY = typeof openedImg === 'number' ? 'hidden' : 'auto'
  }, [openedImg])

  return (
    <>
      <AccordionItem defaultChecked value={value} className="border border-slate-400 rounded-md mb-4 px-4">
        <AccordionTrigger>
          <span className="capitalize text-xl">
            {label} ({images.length})
          </span>
        </AccordionTrigger>
        <AccordionContent>
          <div className="grid grid-cols-3 gap-3">
            {images.map((image, index) => (
              <button
                onClick={() => setOpenedImg(index)}
                type="button"
                key={image}
                className="relative group overflow-hidden rounded border border-slate-300"
              >
                <img
                  src={`https://myinstaapi.lat/Graphs/Graphs${image}`}
                  alt="Default Image"
                  className="w-full h-full duration-150 group-hover:scale-110"
                />
                <span className="absolute top-0 left-0 w-full h-full bg-white/60 text-blue grid place-content-center group-hover:opacity-100 opacity-0 duration-150">
                  Click to see larger
                </span>
              </button>
            ))}
          </div>
        </AccordionContent>
      </AccordionItem>
      {typeof openedImg === 'number' && (
        <div
          ref={containerRef}
          onKeyDown={onKeyDown}
          className="fixed top-0 left-0 w-full h-full bg-black/40 z-[100] flex justify-between"
        >
          <button
            onClick={() => setOpenedImg((prev: any) => prev - 1)}
            type="button"
            disabled={openedImg === 0}
            className="grid place-content-center z-50 opacity-60 hover:opacity-100 bg-white dark:bg-secondary duration-150 text-blue dark:text-white text-[80px] w-1/6 disabled:opacity-0 disabled:cursor-auto"
          >
            <FaChevronLeft />
          </button>
          <div className="relative w-4/6 h-full overflow-hidden">
            <button
              onClick={() => setOpenedImg(undefined)}
              type="button"
              className="absolute top-0 right-0 text-[100px] z-50 text-black dark:text-white bg-white dark:bg-secondary rounded-md m-1 p-0"
            >
              <IoMdClose />
            </button>
            <div className="flex duration-300 w-max h-full" style={{transform: `translateX(${-66.3 * openedImg}vw)`}}>
              {images.map(image => (
                <div key={image} className="flex items-center justify-center w-[66.3vw]">
                  <img
                    src={`https://myinstaapi.lat/Graphs/Graphs${image}`}
                    alt={image}
                    className="w-[80%] h-[90%] object-contain"
                  />
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={() => setOpenedImg((prev: any) => prev + 1)}
            type="button"
            disabled={openedImg === images?.length - 1}
            className="grid place-content-center z-50 opacity-60 hover:opacity-100 bg-white dark:bg-secondary duration-150 text-blue dark:text-white text-[80px] w-1/6 disabled:opacity-0 disabled:cursor-auto"
          >
            <FaChevronLeft className="rotate-180" />
          </button>
        </div>
      )}
    </>
  )
}
