import {Icon} from '@/components/common/icon'
import {IconProps} from '@/components/common/icon/types'

export const FilesIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 149 149">
    <circle cx="74.1898" cy="74.1898" r="74.1898" fill="#FFA367" />
    <path
      d="M113.775 50.225L98.775 35.225C98.5414 34.9933 98.2644 34.81 97.9598 34.6856C97.6552 34.5612 97.329 34.4981 97 34.5H92V54.5C92 55.8261 91.4732 57.0979 90.5355 58.0355C89.5979 58.9732 88.3261 59.5 87 59.5H67C65.6739 59.5 64.4021 58.9732 63.4645 58.0355C62.5268 57.0979 62 55.8261 62 54.5V34.5H47C45.0109 34.5 43.1032 35.2902 41.6967 36.6967C40.2902 38.1032 39.5 40.0109 39.5 42V102C39.5 103.989 40.2902 105.897 41.6967 107.303C43.1032 108.71 45.0109 109.5 47 109.5H52V87C52 85.0109 52.7902 83.1032 54.1967 81.6967C55.6032 80.2902 57.5109 79.5 59.5 79.5H94.5C96.4891 79.5 98.3968 80.2902 99.8033 81.6967C101.21 83.1032 102 85.0109 102 87V109.5H107C108.989 109.5 110.897 108.71 112.303 107.303C113.71 105.897 114.5 103.989 114.5 102V52C114.502 51.671 114.439 51.3448 114.314 51.0402C114.19 50.7357 114.007 50.4586 113.775 50.225Z"
      fill="white"
    />
    <path d="M67 34.5H87V54.5H67V34.5Z" fill="white" />
    <path
      d="M94.5 84.5H59.5C58.837 84.5 58.2011 84.7634 57.7322 85.2322C57.2634 85.7011 57 86.337 57 87V109.5H97V87C97 86.337 96.7366 85.7011 96.2678 85.2322C95.7989 84.7634 95.163 84.5 94.5 84.5Z"
      fill="white"
    />
  </Icon>
)
