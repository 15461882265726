import {Loader} from '@/components/common'
import {useGetGrowthTrackerDataQuery} from '@/features/growth-tracker'
import {useMemo} from 'react'
import CountUp from 'react-countup'
import {TrackCard} from './component'

export const GrowthTracker = () => {
  const {data: {data: growthData} = {}, isLoading} = useGetGrowthTrackerDataQuery({})

  const growthMeterRows = useMemo(
    () => [
      // {
      //   title: (((growthData?.growthData.bad || 0) / (growthData?.totalClients || 0)) * 100).toFixed(),
      //   color: '#FE464B',
      //   value: growthData?.growthData.bad,
      //   text: 'Bad',
      //   // percent: ((growthData?.growthData.bad || 0) / (growthData?.totalClients || 0)) * 100,
      // },
      // {
      //   title: (((growthData?.growthData.error || 0) / (growthData?.totalClients || 0)) * 100).toFixed(),
      //   color: '#F25F33',
      //   value: growthData?.growthData.error,
      //   text: 'Error',
      //   // percent: ((growthData?.growthData.error || 0) / (growthData?.totalClients || 0)) * 100,
      // },
      // {
      //   title: (((growthData?.growthData.warning || 0) / (growthData?.totalClients || 0)) * 100).toFixed(),
      //   color: '#FEBD38',
      //   value: growthData?.growthData.warning,
      //   text: 'Warning',
      //   // percent: ((growthData?.growthData.warning || 0) / (growthData?.totalClients || 0)) * 100,
      // },
      // {
      //   title: (((growthData?.growthData.good || 0) / (growthData?.totalClients || 0)) * 100).toFixed(),
      //   color: '#68EE76',
      //   value: growthData?.growthData.good,
      //   text: 'Good',
      //   // percent: ((growthData?.growthData.good || 0) / (growthData?.totalClients || 0)) * 100,
      // },
      {
        title: 'uh oh? what the heck is going?',
        background: 'linear-gradient(180deg, #7AD3FF 0%, #4FBAF0 100%)',
        count: 272,
        percent: 83,
      },
      {
        title: 'On track, slowly getting there... What needs to be done? (175+ in the last 7 days)',
        background: 'linear-gradient(180deg, #FF9364 0%, #F25F33 100%)',
        count: 272,
        percent: 17,
      },
      {
        title: 'Growing OK (200+ in the last 7 days) - a small change in targeting or DM  could take it above target.',
        background: 'linear-gradient(180deg, #B09FFF 0%, #8D79F6 100%)',
        count: 272,
        percent: 37,
      },
      {
        title: 'Right on target (240+ in the last 7 days) - Could be higher... ',
        background: 'linear-gradient(180deg, #FE6C6C 0%, #FE464B 100%)',
        percent: 22,
      },
      {
        title: 'Killing it! (260+ in the last 7 days)',
        background: 'linear-gradient(180deg, #99FFA3 0%, #68EE76 100%)',
        count: 272,
        percent: 12,
      },
      {
        title: 'LETS GOOOOOOO! (300+ in the last 7 days)',
        background: 'linear-gradient(180deg, #FFD572 0%, #FEBD38 100%)',
        percent: 63,
      },
    ],
    [],
  )

  return (
    <>
      {isLoading ? (
        <Loader parentClassName="h-80" />
      ) : (
        <div className="flex flex-col gap-5">
          <div className="grid grid-cols-3 gap-6">
            <div className="flex flex-col justify-between bg-slate-50 dark:bg-secondary rounded-xl px-6 py-4">
              <h2 className="text-xl font-black text-center">Total clients recorded 🤝</h2>
              <h3 className="text-6xl font-black text-center mt-2 mb-3">
                <CountUp end={growthData?.totalClients || 0} />
              </h3>
            </div>
            <div className="flex flex-col justify-between bg-slate-50 dark:bg-secondary rounded-xl px-6 py-4">
              <h2 className="text-xl font-black flex items-center justify-center">
                <span>Phones running</span>&nbsp;
                <span className="block w-4 h-4 rounded-full bg-[#FF0000]" />
              </h2>
              <h3 className="text-6xl font-black text-center mt-2 mb-3">
                <CountUp end={0} />
              </h3>
            </div>
            <div className="flex flex-col justify-between bg-slate-50 dark:bg-secondary rounded-xl px-6 py-4">
              <h2 className="text-xl font-black text-center">Stopped phones 😢</h2>
              <p className="text-center text-xs font-bold">Fix them fast please</p>
              <h3 className="text-6xl font-black text-center mt-2 mb-3">
                <CountUp end={growthData?.stoppedPhones || 0} />
              </h3>
            </div>
          </div>
          <div className="bg-slate-50 dark:bg-background dark:border dark:border-secondary rounded-xl px-6 py-4">
            <h2 className="text-xl font-black text-center">Growth Meter 3.0</h2>
            {/* <div className="mt-8 flex items-center gap-5">
              {growthMeterRows.map((growthD: any, index) => (
                <div key={index} className="flex items-center gap-2">
                  <div className="w-4 h-4" style={{background: growthD.color}} />
                  <span className="text-sm font-semibold">{growthD.text}</span>
                </div>
              ))}
            </div>
            <PieChart
              data={growthMeterRows as any}
              totalValue={growthData?.totalClients || 0}
              style={{
                fontFamily: '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
                fontSize: '8px',
              }}
              label={({dataEntry}) => Math.round(dataEntry.percentage) + '%'}
              labelStyle={{
                fill: '#fff',
                opacity: 0.75,
                pointerEvents: 'none',
              }}
            /> */}
            <div className="flex flex-col gap-6">
              {growthMeterRows.map((growth, index) => (
                <TrackCard key={index} growth={growth} />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
