import React from 'react'
import {Props} from './types'
import {Modal} from '@/components/common'
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from '@/components/ui/table'

export const StatsModal: React.FC<Props> = ({open, close, growths, username}) => (
  <Modal open={open} close={close} title={`Growth Statistics (${username})`}>
    <div className="my-4">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Date</TableHead>
            <TableHead>Growths</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {Object.entries(growths || {}).map(([date, value]) => (
            <TableRow key={date}>
              <TableCell>{date}</TableCell>
              <TableCell>{value as any}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  </Modal>
)
