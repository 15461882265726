/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {Props} from './types'
import {Modal} from '@/components/common'
import {useLazyGetVPSScriptHistoryQuery} from '@/features/vps-scripts'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {Accordion, AccordionContent, AccordionItem, AccordionTrigger} from '@/components/ui/accordion'
import dayjs from 'dayjs'
import {AccounResult} from './components'

export const RunHistory: React.FC<Props> = ({open, close, script}) => {
  const [getScriptHistory, {data: {data: logs = []} = {}, isLoading}] = useLazyGetVPSScriptHistoryQuery()
  const [fetched, setFetched] = useState(false)
  const handleRequest = useHandleRequest()

  const fetchScriptHistory = async () => {
    await handleRequest({
      request: async () => {
        const result = await getScriptHistory({id: script._id || script.username})
        return result
      },
      onSuccess: () => {
        setFetched(true)
      },
    })
  }

  useEffect(() => {
    if (open && !fetched) {
      fetchScriptHistory()
    }
  }, [open])

  return (
    <Modal
      open={open}
      close={close}
      title={`Script running histories (${script.username})`}
      isLoading={isLoading}
      contentClassName="min-w-[700px]"
    >
      <Accordion type="multiple">
        {logs.length ? (
          logs.map(log => (
            <AccordionItem key={log.date} value={log.date} className="mb-3 border rounded">
              <AccordionTrigger className="px-6 hover:no-underline [&[data-state=open]]:border-b">
                {dayjs(log.date).format('DD.MM.YYYY - hh:mm')}
              </AccordionTrigger>
              <AccordionContent className="px-6 py-4">
                {/* <Input placeholder="Search..." parentClassName="mb-4" /> */}
                <Accordion type="multiple">
                  {log.results.map(result => (
                    <AccounResult key={result.id} result={result} />
                  ))}
                </Accordion>
              </AccordionContent>
            </AccordionItem>
          ))
        ) : (
          <div className="text-sm text-slate-600 text-center py-10">Script has not been run yet</div>
        )}
      </Accordion>
    </Modal>
  )
}
