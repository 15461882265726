import React from 'react'
import {Props} from './types'
import {BaseClientGroupForm} from '..'
import {useUpdateClientGroupMutation} from '@/features/client-groups'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {SubmitHandler} from 'react-hook-form'
import {ClientGroupFormData} from '../base-client-group-form/types'
import {useToast} from '@/components/ui/use-toast'

export const EditClientGroupForm: React.FC<Props> = ({open, close, clientGroup}) => {
  // const [getGroupProfiles, {data: {data: profiles = []} = {}, isLoading: isFetchingGroupProfiles}] =
  //   useLazyGetClientGroupProfilesQuery()
  const [updateClientGroup, {isLoading}] = useUpdateClientGroupMutation()
  // const [updateProfiles, isUpdatingProfiles] = useUpdateProfiles()
  // const profileIDs = useMemo(() => profiles.map(profile => profile._id), [profiles])
  const handleRequest = useHandleRequest()
  const {toast} = useToast()

  const onSubmit: SubmitHandler<ClientGroupFormData> = async formData => {
    await handleRequest({
      request: async () => {
        const result = await updateClientGroup({
          id: clientGroup._id,
          body: {
            expectedResult: formData.expectedResult,
            clientEmail: formData.clientEmail,
          },
        })
        return result
      },
      onSuccess: async () => {
        // await updateProfiles(
        //   'groupID',
        //   clientGroup._id,
        //   formData.profiles.filter(profileID => !profileIDs.includes(profileID)),
        //   profileIDs.filter(profileID => !formData.profiles.includes(profileID)),
        // )
        toast({title: 'Group successfully updated!'})
        close()
      },
    })
  }

  // const fetchGroupProfiles = async () => {
  //   await handleRequest({
  //     request: async () => {
  //       const result = await getGroupProfiles({id: clientGroup._id})
  //       return result
  //     },
  //   })
  // }

  // useEffect(() => {
  //   if (open && !profiles.length) {
  //     fetchGroupProfiles()
  //   }
  // }, [open])

  return (
    <BaseClientGroupForm
      open={open}
      close={close}
      title={`Edit group (${clientGroup.groupName})`}
      submitText="Update Group"
      onSubmit={onSubmit}
      isSubmitting={isLoading}
      defaultValues={{
        groupName: clientGroup.groupName,
        expectedResult: clientGroup.expectedResult,
        clientEmail: clientGroup.clientEmail,
      }}
      editing
    />
  )
}
