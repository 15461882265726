import {useUpdateProfileNicheMutation} from '@/features/profile-niche'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {Props} from './types'
import {SubmitHandler} from 'react-hook-form'
import {BaseProfileNicheFormData} from '../base-niche-modal/types'
import {BaseNicheModal} from '..'

export const EditNicheModal: React.FC<Props> = ({open, close, niche}) => {
  const [updateNiche, {isLoading}] = useUpdateProfileNicheMutation()
  const handleRequest = useHandleRequest()

  const onSubmit: SubmitHandler<BaseProfileNicheFormData> = async formData => {
    await handleRequest({
      request: async () => {
        const result = await updateNiche({id: niche._id, body: {nicheName: formData.nicheName}})
        return result
      },
      onSuccess: () => {
        close()
      },
    })
  }

  return (
    <BaseNicheModal
      open={open}
      close={close}
      title="Update Niche"
      submitText="Update"
      onSubmit={onSubmit}
      isSubmitting={isLoading}
      defaultValues={{nicheName: niche.nicheName}}
    />
  )
}
