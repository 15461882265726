import React, {useMemo} from 'react'
import {ColorSelectValue, Props} from './types'
import {cn} from '@/lib/utils'
import {Select as UISelect, SelectContent, SelectItem, SelectTrigger, SelectValue} from '@/components/ui/select'
import {SelectColors} from '@/constants/colors'

export const ColorSelect: React.FC<Props> = ({
  label,
  placeholder,
  value,
  defaultValue,
  onValueChange,
  labelClassName,
}) => {
  const valueProps = useMemo(() => {
    const props: ColorSelectValue = {}

    if (onValueChange) {
      props.value = value
      props.onValueChange = onValueChange
    }

    if (defaultValue) {
      props.defaultValue = defaultValue
    }

    return props
  }, [value, defaultValue, onValueChange])

  return (
    <label className={cn('w-16', labelClassName)}>
      {label && <span className="text-xs capitalize">{label}</span>}
      <UISelect {...valueProps}>
        <SelectTrigger className="w-16">
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent className="max-h-[200px] w-16 max-w-[64px] min-w-[64px]">
          {Object.entries(SelectColors).map(([key, color]) => (
            <SelectItem key={key} value={color} className="w-16">
              <div className="flex items-center gap-2">
                <div style={{background: color}} className="w-3 h-3 min-w-[12px] min-h-[12px]" />
              </div>
            </SelectItem>
          ))}
        </SelectContent>
      </UISelect>
    </label>
  )
}
