/* eslint-disable */
import React, { MouseEventHandler, useEffect, useState } from 'react'
import { Props } from './types'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import dayjs from 'dayjs'
import { DeleteModal, Loader } from '@/components/common'
import { useForceResumeVPSScriptsDailyRunMutation, useLazyGetVPSScriptsOfDailyRunQuery } from '@/features/vps-scripts'
import { useHandleRequest } from '@/hooks/use-handle-request'
import { DailyRunScriptStatus, DailyRunStatus } from '@/constants/status'
import { Check, Clock8, MailWarning } from 'lucide-react'
import { IoWarning } from 'react-icons/io5'
import { useToast } from '@/components/ui/use-toast'
import { Button } from '@/components/ui/button'

export const DailyRunCard: React.FC<Props> = ({ dailyRun, scriptNamesObject }) => {
  const [getVPSScriptsOfDailyRun, { data: { data: scripts = [] } = {}, isFetching }] = useLazyGetVPSScriptsOfDailyRunQuery()
  const [forceResumeVPSScriptsOfDailyRun, { isLoading: isForceResuming }] = useForceResumeVPSScriptsDailyRunMutation();
  const [fetched, setFetched] = useState(false)
  const [open, setOpen] = useState(false)
  const [openForceResumeModal, setOpenForceResumeModal] = useState(false);
  const handleRequest = useHandleRequest()
  const { toast } = useToast();

  const onForceResumeDailyRun = async () => {
    await handleRequest({
      request: async () => {
        const result = await forceResumeVPSScriptsOfDailyRun({});
        return result;
      },
      onSuccess: () => {
        toast({ title: "Daily run successfully resumed!" })
      }
    })
  }

  const onOpenForceResumeModal: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    setOpenForceResumeModal(true);
  }

  const fetchVPSScriptsOfDailyRun = async () => {
    await handleRequest({
      request: async () => {
        const result = await getVPSScriptsOfDailyRun({ id: dailyRun.dateName })
        return result
      },
      onSuccess: () => {
        setFetched(true)
      },
    })
  }

  useEffect(() => {
    if (open && !fetched) {
      fetchVPSScriptsOfDailyRun()
    }
  }, [open])

  return (
    <>
      <Accordion type="multiple" className="w-full">
        <AccordionItem onClick={() => setOpen(prev => !prev)} value="accounts" className="mb-3 border rounded">
          <AccordionTrigger
            rightContent={(dailyRun.running || dailyRun.status === DailyRunStatus.PAUSED) ? (
              <Button asChildTag="div" onClick={onOpenForceResumeModal} size="sm" className='text-xs px-5' variant="outline">Force resume</Button>
            ) : ""}
            className="px-6 hover:no-underline [&[data-state=open]]:border-b"
          >
            <div className="flex items-center gap-3">
              <span>{dayjs(dailyRun.date).format('HH:mm')}</span>
              {dailyRun.running && <Loader className="!w-5 !h-5 border-2" />}
            </div>
          </AccordionTrigger>
          <AccordionContent className="px-6 py-4">
            {isFetching ? (
              <Loader parentClassName="py-20" />
            ) : scripts.length ? (
              <div className="space-y-2">
                {scripts.map(script => (
                  <div key={script.scriptName} className="border rounded-sm px-4 py-2">
                    <div className="flex items-center justify-between">
                      <span>{scriptNamesObject[script.scriptName]}</span>
                      {
                        {
                          [DailyRunScriptStatus.PENDING]: (
                            <div className="flex items-center gap-1 text-xs bg-sky-600 px-3 py-1.5 rounded">
                              <Clock8 className="w-3.5 h-3.5" />
                              <span>Waiting</span>
                            </div>
                          ),
                          [DailyRunScriptStatus.RUNNING]: (
                            <div className="flex items-center gap-1.5 text-xs bg-blue-600 px-3 py-1.5 rounded">
                              <Loader className="!bg-white !w-3 !h-3 !p-1.5" parentClassName="!bg-transparent" />
                              <span>Running</span>
                            </div>
                          ),
                          [DailyRunScriptStatus.FAILED]: (
                            <div className="flex items-center gap-1.5 text-xs bg-red-600 px-3 py-1.5 rounded">
                              <IoWarning className="w-3.5 h-3.5" />
                              <span>Failed</span>
                            </div>
                          ),
                          [DailyRunScriptStatus.COMPLETED]: (
                            <div className="flex items-center gap-1.5 text-xs bg-green-600 px-3 py-1.5 rounded">
                              <Check className="w-3.5 h-3.5" />
                              <span>Completed</span>
                            </div>
                          ),
                        }[script.status]
                      }
                    </div>
                    {script.status === DailyRunScriptStatus.FAILED && script.message && (
                      <div className="flex items-center gap-2 text-xs text-red-600 font-bold mt-2">
                        <MailWarning className="w-3.5 h-3.5" />
                        <span>{script.message}</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-sm text-slate-500 py-16 text-center">No any daily running scripts found</div>
            )}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      {dailyRun.running && (
        <DeleteModal
          open={openForceResumeModal}
          close={() => setOpenForceResumeModal(false)}
          onDelete={onForceResumeDailyRun}
          isDeleting={isForceResuming}
          title="Are you really sure ?"
          description='We recommend to resume incase when daily run is stuck, if daily run is in progress, this will cause to have multiple  scripts performing same on completion of same daily run!'
          deleteButtonText="Force Resume"
          deleteButtonVariant="outline"
        />
      )}
    </>
  )
}
