import React from 'react'
import { Props } from './types'
import { cn } from '@/lib/utils'

export const SettingsFieldWrapper: React.FC<Props> = ({ label, children, className, contentClassName }) => (
  <div className={cn("bg-[#ebedf27b] dark:bg-background dark:border dark:border-secondary py-3 px-4 rounded-lg flex items-center justify-between", className)}>
    {label ? (
      <>
        <span className="block min-w-max text-sm text-black dark:text-white">{label}</span>
        <div className={cn("flex items-center gap-5 min-w-[270px]", contentClassName)}>{children}</div>
      </>
    ) : (
      children
    )}
  </div>
)
