/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {BlacklistFormData, Props} from './types'
import {useLazyGetClientsBlacklistQuery, useUpdateClientsBlacklistMutation} from '@/features/client-profiles'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {Field, Modal} from '@/components/common'
import {Form} from '@/components/ui/form'
import {SubmitHandler, useForm} from 'react-hook-form'
import {Button} from '@/components/ui/button'
import {GetClientBlacklistedProfilesResponse} from '@/features/client-profiles/types'
import {useToast} from '@/components/ui/use-toast'

export const BlacklistForm: React.FC<Props> = ({open, close}) => {
  const [updateBlacklist, {isLoading: isUpdating}] = useUpdateClientsBlacklistMutation()
  const [getBlacklist, {isLoading}] = useLazyGetClientsBlacklistQuery()
  const form = useForm<BlacklistFormData>()
  const handleRequest = useHandleRequest()
  const {toast} = useToast()

  const onSubmit: SubmitHandler<BlacklistFormData> = async formData => {
    await handleRequest({
      request: async () => {
        const result = await updateBlacklist({usernames: formData.blacklist})
        return result
      },
      onSuccess: () => {
        toast({title: 'Blacklist successfully updated!'})
        close()
      },
    })
  }

  const fetchBlacklist = async () => {
    await handleRequest({
      request: async () => {
        const result = await getBlacklist({})
        return result
      },
      onSuccess: ({data}: {data: GetClientBlacklistedProfilesResponse}) => {
        form.setValue('blacklist', data.data)
      },
    })
  }

  useEffect(() => {
    if (open) {
      fetchBlacklist()
    }
  }, [open])

  return (
    <Modal open={open} close={close} title="Clients Blacklist" isLoading={isLoading}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-2">
          <Field
            type="textarea"
            control={form.control}
            name="blacklist"
            componentProps={{
              label: 'Blacklist',
              placeholder: 'username1\nusername2',
              className: 'min-h-[200px] placeholder:text-slate-400',
            }}
          />
          <Button htmlType="submit" loading={isUpdating}>
            Update
          </Button>
        </form>
      </Form>
    </Modal>
  )
}
