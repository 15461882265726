import React, {ChangeEvent, useEffect, useRef, useState} from 'react'
import {Props} from './types'
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from '@/components/ui/table'
import {CSVRow} from './components'
import {FaPlus} from 'react-icons/fa'
import {Button} from '@/components/ui/button'
import {MdDriveFolderUpload} from 'react-icons/md'
import {useHandleError} from '@/hooks/use-handle-error'
import {getFileText} from '@/utils/get-file-text'
import {FaRegTrashCan} from 'react-icons/fa6'
import {useGetCSVQuery, useUploadCSVMutation} from '@/features/profile'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useToast} from '@/components/ui/use-toast'
import {Loader} from '@/components/common'

export const CSVEditor: React.FC<Props> = ({profile}) => {
  const {data: {data: csvData} = {}, isFetching} = useGetCSVQuery({id: profile._id})
  const [uploadCSV, {isLoading}] = useUploadCSVMutation()
  const [rows, setRows] = useState<string[][]>([[]])
  const tableRef = useRef<HTMLDivElement>(null)
  const handleError = useHandleError()
  const handleRequest = useHandleRequest()
  const {toast} = useToast()

  const onRowChange = (index: number, subIndex: number, value: string) => {
    setRows(prev => {
      const updatedRows = [...prev]
      updatedRows[index][subIndex] = value
      return updatedRows
    })
  }

  const onRemoveRow = (index: number) => {
    setRows(prev => {
      const updatedRows = [...prev]
      updatedRows.splice(index, 1)
      return updatedRows
    })
  }

  const onFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file?.type?.includes('csv')) {
      event.target.value = ''
      return handleError('Only CSV files accepted')
    }

    getFileText(file, (csv: string) => {
      setRows(
        csv.split('\n').map(fileRowString => {
          const fileRow = fileRowString.split(',')
          return [fileRow[0], fileRow[2], fileRow[1], fileRow[3]]
        }),
      )
      event.target.value = ''
    })
  }

  const onAddRow = () => {
    setRows(prev => [...prev, []])
    if (tableRef.current) {
      tableRef.current.scrollTo({top: tableRef.current.clientHeight * 1000})
    }
  }

  const onSubmit = async () => {
    if (!rows.length) {
      return handleError('At least 1 row required')
    }

    await handleRequest({
      request: async () => {
        const file = new Blob([rows.map(row => row.join(',')).join('\n')], {type: 'text/csv;charset=utf-8'})
        const formData = new FormData()
        formData.append('file', file)

        const result = await uploadCSV({id: profile._id, body: formData})
        return result
      },
      onSuccess: () => {
        toast({title: 'CSV successfully uploaded!'})
      },
    })
  }

  useEffect(() => {
    if (typeof csvData === 'string') {
      setRows(csvData.split('\n').map(csvRow => csvRow.split(',')))
    }
  }, [csvData])

  return (
    <div className="bg-white dark:bg-background dark:border dark:border-secondary px-9 py-7 rounded-lg mt-5 h-screen max-h-[71.5vh] overflow-y-auto">
      <div className="h-[86%]">
        <div ref={tableRef} className="max-h-[80%] overflow-y-auto border">
          <Table className="w-full border-collapse overflow-y-auto">
            <TableHeader>
              <TableRow>
                <TableHead className={thClassName}>Username</TableHead>
                <TableHead className={thClassName}>Password</TableHead>
                <TableHead className={thClassName}>Full Name</TableHead>
                <TableHead className={thClassName}>BIO</TableHead>
                <TableHead className={thClassName}>
                  <Button
                    onClick={() => setRows([[]])}
                    variant="outlined-destructive"
                    size="icon"
                    className="hover:text-white rounded h-7 w-7 text-xs"
                  >
                    <FaRegTrashCan />
                  </Button>
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {isFetching ? (
                <TableRow className="relative">
                  <TableCell className="h-52">
                    <Loader parentClassName="absolute pb-6" />
                  </TableCell>
                </TableRow>
              ) : (
                rows.map((row, index) => (
                  <CSVRow
                    key={index}
                    rowValue={row}
                    onChange={(subIndex, value) => onRowChange(index, subIndex, value)}
                    onRemove={() => onRemoveRow(index)}
                  />
                ))
              )}
            </TableBody>
          </Table>
        </div>
        <div className="flex items-center justify-end gap-4 mt-5">
          <Button onClick={onAddRow} variant="outline" size="sm" className="flex gap-2 text-xs">
            <FaPlus />
            <span>Add Row</span>
          </Button>
          <label className="cursor-pointer">
            <span className="inline-flex items-center justify-center font-medium transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 gap-2 text-xs border border-[#ebedf2] dark:border-secondary dark:text-white dark:hover:bg-secondary text-[#0d0e1c] hover:bg-[#ebedf2] h-9 rounded-md px-3">
              <MdDriveFolderUpload className="text-sm" />
              <span>Upload</span>
            </span>
            <input type="file" accept=".csv" onChange={onFileUpload} hidden />
          </label>
        </div>
      </div>
      <Button
        onClick={onSubmit}
        loading={isLoading}
        htmlType="submit"
        size="lg"
        className="w-full text-2xl py-8 gap-2"
        iconClassName="w-7 h-7"
      >
        Save
      </Button>
    </div>
  )
}

const thClassName = `sticky top-0 bg-white dark:bg-background border-b border border-gray-400 dark:border-secondary dark:text-white text-center text-black text-xs h-9`
