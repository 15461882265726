import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {SortableContext, arrayMove, sortableKeyboardCoordinates} from '@dnd-kit/sortable'
import {restrictToFirstScrollableAncestor, restrictToWindowEdges} from '@dnd-kit/modifiers'
import {IoAddOutline} from 'react-icons/io5'
import {TaskCard} from '..'
import React from 'react'
import {Props} from './types'
import {useUpdateRoadmapTaskOrderMutation} from '@/features/roadmap-tasks'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useToast} from '@/components/ui/use-toast'
import {Loader} from '@/components/common'

export const RoadmapTasks: React.FC<Props> = ({tasks, onOpenCreateForm, setTasks, fetchTasks}) => {
  const [updateTaskOrder] = useUpdateRoadmapTaskOrderMutation()
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )
  const handleRequest = useHandleRequest()
  const {toast} = useToast()

  const onUpdateTask = async (taskID: string, order: number) => {
    await handleRequest({
      request: async () => {
        toast({
          description: (
            <div className="flex items-center gap-2 min-w-max">
              <Loader className="!w-5 !h-5 border-2" />
              <span className="block min-w-max font-semibold">Orders Updating...</span>
            </div>
          ),
        })
        const result = await updateTaskOrder({
          id: taskID,
          body: {
            order,
          },
        })
        return result
      },
      onSuccess: () => {
        setTimeout(async () => {
          await fetchTasks()
          toast({title: 'Tasks order successfully updated!'})
        }, 2500)
      },
    })
  }

  const onDragEnd = ({active, over}: DragEndEvent) => {
    if (active.id === over?.id) {
      return
    }

    setTasks(((prev: any) => {
      const updatedTasks = [...prev]

      const originalPos = updatedTasks.findIndex(task => task._id === active.id)
      const newPos = updatedTasks.findIndex(task => task._id === over?.id)
      onUpdateTask(active.id as any, updatedTasks[newPos].order)

      return arrayMove(updatedTasks, originalPos, newPos)
    }) as any)
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={onDragEnd}
      modifiers={[restrictToFirstScrollableAncestor, restrictToWindowEdges]}
    >
      <SortableContext items={tasks.map(task => task._id)}>
        <div className="grid grid-cols-3 gap-6">
          {tasks.map((task, index) => (
            <TaskCard key={task._id} task={task} index={index} fetchTasks={fetchTasks} />
          ))}
          <button
            type="button"
            onClick={onOpenCreateForm}
            className="py-4 px-3 flex flex-col items-center justify-center gap-3 rounded-lg bg-slate-100 dark:bg-background dark:border dark:border-secondary hover:bg-slate-200/70 dark:hover:bg-secondary/30 duration-150 cursor-pointer"
          >
            <span className="w-20 h-20 grid place-content-center bg-white dark:bg-secondary rounded-full text-4xl">
              <IoAddOutline />
            </span>
            <span className="block font-semibold text-center">Add New Task</span>
          </button>
        </div>
      </SortableContext>
    </DndContext>
  )
}
