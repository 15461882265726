import {EndpointNameRoadmapNotes} from '@/constants/endpoints'
import {baseApi} from '..'
import {RTKTagName} from '@/constants/rtk-tag-name'
import {
  CreateRoadmapNoteRequest,
  CreateRoadmapNoteResponse,
  DeleteRoadmapNoteRequest,
  DeleteRoadmapNoteResponse,
  GetRoadmapNotesRequest,
  GetRoadmapNotesResponse,
  UpdateRoadmapNoteRequest,
  UpdateRoadmapNoteResponse,
} from './types'

const RoadmapNotesExtendedEndpoints = baseApi.injectEndpoints({
  endpoints: build => ({
    getRoadmapNotes: build.query<GetRoadmapNotesResponse, GetRoadmapNotesRequest>({
      query: () => ({
        url: EndpointNameRoadmapNotes.ROADMAP_NOTES,
        method: 'GET',
      }),
      providesTags: [RTKTagName.ROADMAP_NOTE],
    }),

    createRoadmapNote: build.mutation<CreateRoadmapNoteResponse, CreateRoadmapNoteRequest>({
      query: body => ({
        url: EndpointNameRoadmapNotes.ROADMAP_NOTES,
        method: 'POST',
        body,
      }),
      invalidatesTags: [RTKTagName.ROADMAP_NOTE],
    }),

    updateRoadmapNote: build.mutation<UpdateRoadmapNoteResponse, UpdateRoadmapNoteRequest>({
      query: ({id, body}) => ({
        url: EndpointNameRoadmapNotes.ROADMAP_NOTE + id,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [RTKTagName.ROADMAP_NOTE],
    }),

    deleteRoadmapNote: build.mutation<DeleteRoadmapNoteResponse, DeleteRoadmapNoteRequest>({
      query: ({id}) => ({
        url: EndpointNameRoadmapNotes.ROADMAP_NOTE + id,
        method: 'DELETE',
      }),
      invalidatesTags: [RTKTagName.ROADMAP_NOTE],
    }),
  }),
})

export const {
  useGetRoadmapNotesQuery,
  useCreateRoadmapNoteMutation,
  useUpdateRoadmapNoteMutation,
  useDeleteRoadmapNoteMutation,
} = RoadmapNotesExtendedEndpoints
