import {Accordion, AccordionContent, AccordionItem, AccordionTrigger} from '@/components/ui/accordion'
import {Button} from '@/components/ui/button'
import {CompletedTasks, CreateTaskModal, PendingTasks} from './components'
import React, {useMemo, useState} from 'react'
import {Props} from './types'
import {useGetVPSScraperTasksQuery} from '@/features/vps-scraper-tasks'
import {Loader} from '@/components/common'
import {VPSScraperTaskCompletedStatus} from '@/constants/status'

export const PKSScraper: React.FC<Props> = ({script}) => {
  const {data: {data: tasks = []} = {}, isFetching} = useGetVPSScraperTasksQuery({id: script._id || script.username})
  const [openCreateTaskModal, setOpenCreateTaskModal] = useState(false)
  const orderedTasks = useMemo(
    () => ({
      pending: tasks.filter(task => task.completed === VPSScraperTaskCompletedStatus.IN_PROGRESS),
      completed: tasks.filter(task => task.completed === VPSScraperTaskCompletedStatus.COMPLETED),
    }),
    [tasks],
  )

  return (
    <>
      <Accordion type="multiple" className="w-full">
        <AccordionItem value="accounts" className="mb-3 border rounded">
          <AccordionTrigger className="px-6 hover:no-underline [&[data-state=open]]:border-b">Scraper</AccordionTrigger>
          <AccordionContent className="px-6 py-4">
            <Button onClick={() => setOpenCreateTaskModal(true)} className="w-full">
              Create Task
            </Button>
            {isFetching ? (
              <Loader parentClassName="py-20" />
            ) : tasks.length ? (
              <div className="flex flex-col gap-4 py-4">
                <PendingTasks tasks={orderedTasks.pending} />
                <CompletedTasks tasks={orderedTasks.completed} />
              </div>
            ) : (
              <p className="py-20 text-center text-slate-400">No created tasks yet...</p>
            )}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <CreateTaskModal open={openCreateTaskModal} close={() => setOpenCreateTaskModal(false)} script={script} />
    </>
  )
}
