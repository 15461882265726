import {EndpointNameClientGroup} from '@/constants/endpoints'
import {baseApi} from '..'
import {RTKTagName} from '@/constants/rtk-tag-name'
import {
  CreateClientGroupRequest,
  CreateClientGroupResponse,
  DeleteClientGroupRequest,
  DeleteClientGroupResponse,
  GetAllClientGroupsRequest,
  GetAllClientGroupsResponse,
  GetClientGroupProfilesRequest,
  GetClientGroupProfilesResponse,
  SendClientGroupReportRequest,
  SendClientGroupReportResponse,
  UpdateClientGroupRequest,
  UpdateClientGroupResponse,
} from './types'

const ClientGroupsExtendedEndpoints = baseApi.injectEndpoints({
  endpoints: build => ({
    getAllClientGroups: build.query<GetAllClientGroupsResponse, GetAllClientGroupsRequest>({
      query: () => ({
        url: EndpointNameClientGroup.CLIENT_GROUPS,
        method: 'GET',
      }),
      providesTags: [RTKTagName.CLIENT_PROFILE],
    }),

    createClientGroups: build.mutation<CreateClientGroupResponse, CreateClientGroupRequest>({
      query: body => ({
        url: EndpointNameClientGroup.CLIENT_GROUPS,
        method: 'POST',
        body,
      }),
      invalidatesTags: [RTKTagName.CLIENT_PROFILE],
    }),

    updateClientGroup: build.mutation<UpdateClientGroupResponse, UpdateClientGroupRequest>({
      query: ({id, body}) => ({
        url: EndpointNameClientGroup.CLIENT_GROUP + id,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [RTKTagName.CLIENT_PROFILE],
    }),

    deleteClientGroup: build.mutation<DeleteClientGroupResponse, DeleteClientGroupRequest>({
      query: ({id}) => ({
        url: EndpointNameClientGroup.CLIENT_GROUP + id,
        method: 'DELETE',
      }),
      invalidatesTags: [RTKTagName.CLIENT_PROFILE],
    }),

    getClientGroupProfiles: build.query<GetClientGroupProfilesResponse, GetClientGroupProfilesRequest>({
      query: ({id}) => ({
        url: EndpointNameClientGroup.CLIENT_GROUP + id,
        method: 'GET',
      }),
      providesTags: [RTKTagName.CLIENT_PROFILE],
    }),

    sendClientGroupReport: build.mutation<SendClientGroupReportResponse, SendClientGroupReportRequest>({
      query: ({body, id}) => ({
        url: EndpointNameClientGroup.SEND_CLIENT_GROUP_REPORT + id,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useCreateClientGroupsMutation,
  useUpdateClientGroupMutation,
  useDeleteClientGroupMutation,
  useGetClientGroupProfilesQuery,
  useLazyGetClientGroupProfilesQuery,
  useLazyGetAllClientGroupsQuery,
  useSendClientGroupReportMutation,
} = ClientGroupsExtendedEndpoints
