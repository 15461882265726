import React, { MouseEventHandler } from 'react'
import { Props } from './types'
import { Button } from '@/components/ui/button'
import { ExternalLink } from 'lucide-react'

export const AccounResult: React.FC<Props> = ({ result }) => {
  const onRedirectResult: MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault()
    window.open(`http://157.90.215.58:5000/batch/${result.id}`)
  }

  return (
    <div className="flex items-center justify-between py-2 px-4 text-sm mb-3 border rounded">
      <span>
        {result.account}{' '}
        {result.success === undefined ? (
          ''
        ) : result.success ? (
          <span className="text-xs bg-green-600 ml-3 px-2 pt-0.5 pb-1 rounded">success</span>
        ) : (
          <span className="text-xs bg-red-600 ml-3 px-2 pt-0.5 pb-1 rounded">failed</span>
        )}
      </span>
      <Button asChildTag="div" onClick={onRedirectResult} size="sm" variant="outline" className='cursor-pointer'>
        <ExternalLink className="w-3.5 h-3.5" />
      </Button>
    </div>
  )
}
