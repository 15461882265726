import { RegisterOptions } from 'react-hook-form'

export const getValidations = (
  required: boolean,
  minLength?: boolean | number,
  maxLength?: boolean | number,
  extraValidatons = {},
): RegisterOptions<any> => {
  const validations: RegisterOptions<any> = extraValidatons

  if (required) {
    validations.required = 'Please fill out the field'
  }

  if (typeof minLength === 'number') {
    validations.minLength = {
      value: minLength,
      message: `Minimum ${minLength} characters required to enter`,
    }
  }

  if (typeof maxLength === 'number') {
    validations.maxLength = {
      value: maxLength,
      message: `Maximum ${maxLength} characters allowed to enter`,
    }
  }

  return validations
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) {
    return '0 Bytes'
  }

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const isValidURL = (url: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // Protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // Domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // Port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // Query string
    '(\\#[-a-z\\d_]*)?$',
    'i',
  ) // Fragment locator
  return Boolean(pattern.test(url))
}

export const chunk = (array: string[], size: number) => {
  const result = []
  let index = 0

  while (index < array.length) {
    result.push(array.slice(index, index + size))
    index += size
  }

  return result
}

export const sleep = async (time: number) => new Promise(res => {
  setTimeout(res, time)
})