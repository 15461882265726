/* eslint-disable react-hooks/exhaustive-deps */
import {Field, Modal} from '@/components/common'
import {useForm} from 'react-hook-form'
import {BaseProfileNicheFormData, Props} from './types'
import {Form} from '@/components/ui/form'
import {Button} from '@/components/ui/button'
import {useEffect} from 'react'

export const BaseNicheModal: React.FC<Props> = ({
  open,
  close,
  title,
  isModalLoading,
  isSubmitting,
  submitText,
  onSubmit,
  defaultValues,
}) => {
  const form = useForm<BaseProfileNicheFormData>({
    defaultValues,
  })

  useEffect(() => {
    if (!open) {
      form.reset()
    }
  }, [open])

  return (
    <Modal open={open} close={close} title={title || 'Create New Niche'} isLoading={isModalLoading}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-3 mt-3">
          <Field
            type="input"
            name="nicheName"
            control={form.control}
            componentProps={{label: 'Niche name', placeholder: 'Enter niche name...'}}
          />
          <Button loading={isSubmitting} htmlType="submit" className="mt-5">
            {submitText || 'Create'}
          </Button>
        </form>
      </Form>
    </Modal>
  )
}
