import {Button} from '@/components/ui/button'
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from '@/components/ui/table'
import {useGetNichesQuery} from '@/features/profile-niche'
import {cn} from '@/lib/utils'
import {CreateForm, NicheRow} from './components'
import {Loader, TableNotFoundRow} from '@/components/common'
import {useState} from 'react'

export const Niches = () => {
  const {data: {data: niches = []} = {}, isFetching} = useGetNichesQuery('')
  const [openCreateForm, setOpenCreateForm] = useState(false)

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-2xl font-semibold">Niches {niches.length ? `(${niches.length})` : ''}</h3>
        <Button size="sm" className="px-5 text-xs" onClick={() => setOpenCreateForm(true)}>
          Create New
        </Button>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className={tableTHClassNames}>Name</TableHead>
            <TableHead className={cn(tableTHClassNames, 'text-center')}>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {!isFetching ? (
            niches?.length ? (
              niches.map(niche => <NicheRow key={niche._id} niche={niche} />)
            ) : (
              <TableNotFoundRow text="Niches not found" />
            )
          ) : (
            <TableRow className="relative">
              <TableCell className="h-40">
                <Loader parentClassName="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <CreateForm open={openCreateForm} close={() => setOpenCreateForm(false)} />
    </>
  )
}

const tableTHClassNames = 'text-black dark:text-white dark:bg-secondary'
