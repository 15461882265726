import React, {useEffect, useMemo, useRef, useState} from 'react'
import {Props} from './types'
import {TableCell, TableRow} from '@/components/ui/table'
import {FaChevronRight} from 'react-icons/fa'
import {IoMdSettings} from 'react-icons/io'
import {DeleteModal, Dropdown, Loader} from '@/components/common'
import {cn} from '@/lib/utils'
import {ClientGroupProfiles} from './components'
import {EditClientGroupForm, StatsModal} from '..'
import {useDeleteClientGroupMutation, useSendClientGroupReportMutation} from '@/features/client-groups'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useToast} from '@/components/ui/use-toast'
import {Button} from '@/components/ui/button'
import {FaEye} from 'react-icons/fa6'

export const ClientGroupRow: React.FC<Props> = ({clientGroup}) => {
  const [sendReport] = useSendClientGroupReportMutation()
  const [deleteGroup, {isLoading: isDeleting}] = useDeleteClientGroupMutation()
  const [open, setOpen] = useState(false)
  const [openEditForm, setOpenEditForm] = useState(false)
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false)
  const [height, setHeight] = useState(0)
  const [profilesLoading, setProfilesLoading] = useState(false)
  const clientProfilesRef = useRef<HTMLDivElement>(null)
  const cellClassName = useMemo(
    () =>
      cn(
        'duration-150 cursor-pointer',
        open ? 'bg-slate-100 dark:bg-background' : 'group-hover:bg-slate-100 dark:group-hover:bg-secondary/30',
      ),
    [open],
  )
  const handleRequest = useHandleRequest()
  const {toast} = useToast()
  const [openStatsModal, setOpenStatsModal] = useState(false)

  const onSendReport = async () => {
    await handleRequest({
      request: async () => {
        toast({
          description: (
            <div className="flex items-center gap-2 min-w-max">
              <Loader className="!w-5 !h-5 border-2" />
              <span className="block min-w-max font-semibold">Emailing group reports to client...</span>
            </div>
          ),
        })
        const result = await sendReport({
          id: clientGroup._id,
          body: {
            profiles: clientGroup.profiles.map((profile: any) => profile.username),
          },
        })
        return result
      },
      onSuccess: () => {
        toast({title: 'Group reports successfully emailed to client!'})
      },
    })
  }

  const onDelete = async () => {
    await handleRequest({
      request: async () => {
        const result = await deleteGroup({id: clientGroup._id})
        return result
      },
      onSuccess: () => {
        toast({title: 'Profile successfully archived!'})
        setOpenDeleteAlert(false)
      },
    })
  }

  useEffect(() => {
    setHeight(clientProfilesRef.current?.clientHeight || 0)
  }, [open, profilesLoading])

  return (
    <TableRow className="relative group duration-150" style={{height: open ? `${height + 72}px` : '72px'}}>
      <TableCell
        onClick={() => setOpen(prev => !prev)}
        className={cn(
          'before:absolute before:w-full before:h-[72px] before:pointer-events-none before:top-0 before:left-0 before:border before:border-slate-100 dark:before:border-secondary',
          cellClassName,
        )}
      >
        <div className="flex items-center gap-2">
          <FaChevronRight className={cn('duration-100', open ? 'rotate-90' : '')} />
          <span>{clientGroup.groupName}</span>
        </div>
      </TableCell>
      <TableCell onClick={() => setOpen(prev => !prev)} className={cellClassName}>
        {clientGroup.clientEmail || <span className="text-sm text-slate-400">N/A</span>}
      </TableCell>
      <TableCell onClick={() => setOpen(prev => !prev)} className={cellClassName}>
        {clientGroup.last24hrs || <span className="text-sm text-slate-400">N/A</span>}
      </TableCell>
      <TableCell onClick={() => setOpen(prev => !prev)} className={cellClassName}>
        {clientGroup.last48hrs || <span className="text-sm text-slate-400">N/A</span>}
      </TableCell>
      <TableCell onClick={() => setOpen(prev => !prev)} className={cellClassName}>
        {clientGroup.last7days || <span className="text-sm text-slate-400">N/A</span>}
      </TableCell>
      <TableCell onClick={() => setOpen(prev => !prev)} className={cellClassName}>
        {clientGroup.last30days || <span className="text-sm text-slate-400">N/A</span>}
      </TableCell>
      <TableCell onClick={() => setOpen(prev => !prev)} className={cellClassName}>
        {clientGroup.last60days || <span className="text-sm text-slate-400">N/A</span>}
      </TableCell>
      <TableCell onClick={() => setOpen(prev => !prev)} className={cellClassName}>
        {clientGroup.totalFollowers || <span className="text-sm text-slate-400">N/A</span>}
      </TableCell>
      <TableCell className={cn(cellClassName, 'cursor-auto min-h-max')}>
        <div className="flex items-center gap-2">
          <Button onClick={() => setOpenStatsModal(true)} variant="outline" size="icon">
            <FaEye />
          </Button>
          <Dropdown
            triggerBtnProps={{
              size: 'icon',
            }}
            buttons={[
              ...(clientGroup.clientEmail ? [{label: 'Send Report', onClick: onSendReport}] : []),
              {label: 'Edit Details', onClick: () => setOpenEditForm(true)},
              // {
              //   label: 'Archive',
              //   className: 'border border-red-600 text-red-600 hover:!bg-red-600 hover:!text-white',
              //   onClick: () => setOpenDeleteAlert(true),
              // },
            ]}
            label={<IoMdSettings />}
          />
        </div>
        <div ref={clientProfilesRef} className="absolute top-[72px] left-0 w-full">
          {open && <ClientGroupProfiles clientGroup={clientGroup} setProfilesLoading={setProfilesLoading} />}
        </div>
        <EditClientGroupForm open={openEditForm} close={() => setOpenEditForm(false)} clientGroup={clientGroup} />
        <DeleteModal
          open={openDeleteAlert}
          close={() => setOpenDeleteAlert(false)}
          title={`Are you sure to delete this group (${clientGroup.groupName}) ?`}
          onDelete={onDelete}
          isDeleting={isDeleting}
        />
        <StatsModal
          open={openStatsModal}
          close={() => setOpenStatsModal(false)}
          growths={clientGroup.growths}
          username={clientGroup.groupName}
        />
      </TableCell>
    </TableRow>
  )
}
