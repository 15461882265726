import {EndpointNameAccount} from '@/constants/endpoints'
import {baseApi} from '..'
import {RTKTagName} from '@/constants/rtk-tag-name'
import {
  CreateAccountRequest,
  CreateAccountResponse,
  DeleteAccountRequest,
  DeleteAccountResponse,
  GetAccountsRequest,
  GetAccountsResponse,
  UpdateAccountRequest,
  UpdateAccountResponse,
} from './types'

const AuthExtendedEndpoints = baseApi.injectEndpoints({
  endpoints: build => ({
    getAccounts: build.query<GetAccountsResponse, GetAccountsRequest>({
      query: () => ({
        url: EndpointNameAccount.GET_ACCOUNTS,
        method: 'GET',
      }),
      providesTags: [RTKTagName.ACCOUNT],
    }),

    createAccount: build.mutation<CreateAccountResponse, CreateAccountRequest>({
      query: body => ({
        url: EndpointNameAccount.CREATE_ACCOUNT,
        method: 'POST',
        body,
      }),
      invalidatesTags: [RTKTagName.ACCOUNT],
    }),

    updateAccount: build.mutation<UpdateAccountResponse, UpdateAccountRequest>({
      query: ({id, body}) => ({
        url: EndpointNameAccount.ACCOUNT + id,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [RTKTagName.ACCOUNT],
    }),

    deleteAccount: build.mutation<DeleteAccountResponse, DeleteAccountRequest>({
      query: ({id}) => ({
        url: EndpointNameAccount.ACCOUNT + id,
        method: 'DELETE',
      }),
      invalidatesTags: [RTKTagName.ACCOUNT],
    }),
  }),
})

export const {useGetAccountsQuery, useCreateAccountMutation, useUpdateAccountMutation, useDeleteAccountMutation} =
  AuthExtendedEndpoints
