import {EndpointNameProfileGroup} from '@/constants/endpoints'
import {baseApi} from '..'
import {
  CreateProfileGroupRequest,
  CreateProfileGroupResponse,
  DeleteProfileGroupRequest,
  DeleteProfileGroupResponse,
  GetAllGroupsRequest,
  GetAllGroupsResponse,
  GetGroupProfilesRequest,
  GetGroupProfilesResponse,
  UpdateProfileGroupRequest,
  UpdateProfileGroupResponse,
} from './types'
import {RTKTagName} from '@/constants/rtk-tag-name'

const ProfileGroupExtendedEndpoints = baseApi.injectEndpoints({
  endpoints: build => ({
    getAllGroups: build.query<GetAllGroupsResponse, GetAllGroupsRequest>({
      query: params => ({
        url: EndpointNameProfileGroup.GET_ALL_GROUPS,
        method: 'GET',
        params,
      }),
      providesTags: [RTKTagName.GROUP],
    }),

    getGroupProfiles: build.query<GetGroupProfilesResponse, GetGroupProfilesRequest>({
      query: ({id, params}) => ({
        url: EndpointNameProfileGroup.GROUP + id,
        method: 'GET',
        params,
      }),
      providesTags: [RTKTagName.GROUP],
    }),

    createProfileGroup: build.mutation<CreateProfileGroupResponse, CreateProfileGroupRequest>({
      query: body => ({
        url: EndpointNameProfileGroup.CREATE_GROUP,
        method: 'POST',
        body,
      }),
      invalidatesTags: [RTKTagName.GROUP],
    }),

    updateProfileGroup: build.mutation<UpdateProfileGroupResponse, UpdateProfileGroupRequest>({
      query: ({id, body}) => ({
        url: EndpointNameProfileGroup.GROUP + id,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [RTKTagName.GROUP],
    }),

    deleteProfileGroup: build.mutation<DeleteProfileGroupResponse, DeleteProfileGroupRequest>({
      query: ({id}) => ({
        url: EndpointNameProfileGroup.GROUP + id,
        method: 'DELETE',
      }),
      invalidatesTags: [RTKTagName.GROUP],
    }),
  }),
})

export const {
  useLazyGetAllGroupsQuery,
  useLazyGetGroupProfilesQuery,
  useCreateProfileGroupMutation,
  useDeleteProfileGroupMutation,
  useUpdateProfileGroupMutation,
} = ProfileGroupExtendedEndpoints
