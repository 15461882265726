import React from 'react'
import {Props} from './types'
import {Modal} from '..'
import {Button} from '@/components/ui/button'

export const DeleteModal: React.FC<Props> = ({
  open,
  close,
  title,
  deleteButtonText,
  onDelete,
  isDeleting,
  description,
  deleteButtonVariant = 'destructive',
  contentClassName,
}) => (
  <Modal open={open} close={close} title={title} description={description} contentClassName={contentClassName}>
    <div className="flex gap-4">
      <Button onClick={close} className="w-1/2">
        Cancel
      </Button>
      <Button className="w-1/2" onClick={onDelete} loading={isDeleting} variant={deleteButtonVariant}>
        {deleteButtonText || 'Delete'}
      </Button>
    </div>
  </Modal>
)
