import {FormControl, FormField, FormItem, FormMessage} from '@/components/ui/form'
import {ColorSelect, Counter, DoubleCounter, IconSelect, Input, MultiSelect, RadioGroup, Switch, Textarea} from '..'
import {Props} from './types'
import {Select} from '../select'

export const Field = ({type, control, name, rules, componentProps, fieldClassName}: Props) => (
  <FormField
    control={control}
    name={name}
    rules={rules}
    render={({field: {value, onChange}, formState}) => (
      <FormItem className={fieldClassName}>
        <FormControl>
          {
            {
              input: <Input value={value || ''} onChange={onChange} {...componentProps} />,
              select: <Select value={value || ''} onValueChange={onChange} {...componentProps} />,
              'multi-select': <MultiSelect value={value || ''} onChange={onChange} {...componentProps} />,
              counter: <Counter value={value || ''} onChange={onChange} {...componentProps} />,
              switch: <Switch checked={value} onChange={onChange} {...componentProps} />,
              'double-counter': <DoubleCounter value={value || {}} onChange={onChange} {...componentProps} />,
              textarea: <Textarea error={formState.errors?.[name]} value={value || ''} onChange={onChange} {...componentProps} />,
              'radio-group': <RadioGroup defaultValue={value || ''} onChange={onChange} {...componentProps} />,
              'icon-select': <IconSelect value={value || ''} onValueChange={onChange} {...componentProps} />,
              'color-select': <ColorSelect value={value || ''} onValueChange={onChange} {...componentProps} />,
            }[type]
          }
        </FormControl>
        <FormMessage className="text-xs !mt-1 dark:text-red-600" />
      </FormItem>
    )}
  />
)
