import React from 'react'
import {Props} from './types'
import {BaseForm} from '..'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {SubmitHandler} from 'react-hook-form'
import {useToast} from '@/components/ui/use-toast'
import {AccountBaseFormData} from '../base-form/types'
import {Role} from '@/constants/role'
import {useUpdateSubaccountMutation} from '@/features/subaccounts'

export const EditForm: React.FC<Props> = ({open, close, account}) => {
  const [updateSubaccount, {isLoading}] = useUpdateSubaccountMutation()
  const handleRequest = useHandleRequest()
  const {toast} = useToast()

  const onSubmit: SubmitHandler<AccountBaseFormData> = async formData => {
    await handleRequest({
      request: async () => {
        const result = await updateSubaccount({
          id: account._id,
          body: {
            username: formData.username,
            password: formData.password,
            role: formData.isSuperSubaccount ? Role.SUPER_SUBACCOUNT : Role.SUBACCOUNT,
          },
        })
        return result
      },
      onSuccess: () => {
        toast({title: 'Subaccount successfully updated!'})
        close()
      },
    })
  }

  return (
    <BaseForm
      open={open}
      close={close}
      onSubmit={onSubmit}
      isSubmitting={isLoading}
      title={`Edit ${account.username}`}
      submitText="Update"
      defaultValues={{
        username: account.username,
        isSuperSubaccount: account.role === Role.SUPER_SUBACCOUNT,
      }}
    />
  )
}
