/* eslint-disable react-hooks/exhaustive-deps */
import { Field, Tabs } from '@/components/common'
import { AddsURLField, ProfileDescription, RowEditor, SettingsBlock, SettingsFieldWrapper } from './components'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Form } from '@/components/ui/form'
import React, { useEffect } from 'react'
import { Props, SettingsFormData } from './types'
import { Button } from '@/components/ui/button'
import { useHandleRequest } from '@/hooks/use-handle-request'
import { useSaveProfileAccountsMutation, useUpdateProfileDataMutation } from '@/features/profile'
import { useToast } from '@/components/ui/use-toast'
import { ProfileData, ProfileWithNiche } from '@/types/profile'

const extractProfileDataToFormData = (profileData: ProfileData, profile: ProfileWithNiche) => ({
  dayC3: {
    day: {
      int1: profileData.dayC3.int1.day,
      int2: profileData.dayC3.int2.day,
    },
    follow: {
      int1: profileData.dayC3.int1.follow,
      int2: profileData.dayC3.int2.follow,
    },
    numScrollsExplore: {
      int1: profileData.dayC3.int1.numScrollsExplore,
      int2: profileData.dayC3.int2.numScrollsExplore,
    },
    numScrollsHome: {
      int1: profileData.dayC3.int1.numScrollsHome,
      int2: profileData.dayC3.int2.numScrollsHome,
    },
    numDM: {
      int1: profileData.dayC3.int1.numDM,
      int2: profileData.dayC3.int2.numDM,
    },
  },
  static: {
    warm: profileData.static.warm,
    unfollow: {
      int1: profileData.static.unfollow1,
      int2: profileData.static.unfollow2,
    },
    dayDM: profileData.static.dayDM,
    maxFollow: profileData.static.maxFollow,
    proxy: profileData.static.proxy,
    dmContainer: profileData.static.dmContainer,
    shareStory: profileData.static.shareStory,
    postReelsEnable: profileData.static.PostReels?.enabled,
    postReels: {
      int1: profileData.static.PostReels?.min,
      int2: profileData.static.PostReels?.max,
    },
    Multiaccount: {
      status: profileData.static.Multiaccount?.status,
      creation: profileData.static.Multiaccount?.creation,
    },
    just_dm: {
      status: profileData.static.just_dm?.status,
    },
    adds_url: profileData.static.adds_url?.split(";").map(value => ({ value }))
  },
  logins: profile?.accounts,
})

export const SettingsMain: React.FC<Props> = ({ profile }) => {
  const [updateProfileData, { isLoading: isUpdating }] = useUpdateProfileDataMutation()
  const [saveProfileAccounts, { isLoading: isSavingAccounts }] = useSaveProfileAccountsMutation()
  const form = useForm<SettingsFormData>({
    defaultValues: extractProfileDataToFormData(profile.data, profile),
  })
  const handleRequest = useHandleRequest()
  const { toast } = useToast()

  const onSubmit: SubmitHandler<SettingsFormData> = async formData => {
    await handleRequest({
      request: async () => {
        const result = await saveProfileAccounts({
          id: profile._id,
          body: {
            accounts: formData.logins,
          },
        })
        return result
      },
      onSuccess: async () => {
        await handleRequest({
          request: async () => {
            const result = await updateProfileData({
              id: profile._id,
              body: {
                data: {
                  dayC3: {
                    int1: {
                      day: formData.dayC3.day.int1,
                      follow: formData.dayC3.follow.int1,
                      numScrollsHome: formData.dayC3.numScrollsHome.int1,
                      numScrollsExplore: formData.dayC3.numScrollsExplore.int1,
                      numDM: formData.dayC3.numDM.int1,
                    },
                    int2: {
                      day: formData.dayC3.day.int2,
                      follow: formData.dayC3.follow.int2,
                      numScrollsHome: formData.dayC3.numScrollsHome.int2,
                      numScrollsExplore: formData.dayC3.numScrollsExplore.int2,
                      numDM: formData.dayC3.numDM.int2,
                    },
                  },
                  static: {
                    ...profile.data.static,
                    warm: formData.static.warm,
                    unfollow1: formData.static.unfollow.int1,
                    unfollow2: formData.static.unfollow.int2,
                    dayDM: formData.static.dayDM,
                    maxFollow: formData.static.maxFollow,
                    proxy: formData.static.proxy,
                    dmContainer: formData.static.dmContainer,
                    shareStory: formData.static.shareStory,
                    PostReels: {
                      enabled: formData.static.postReelsEnable,
                      min: formData.static.postReels.int1,
                      max: formData.static.postReels.int2,
                    },
                    Multiaccount: {
                      status: formData.static.Multiaccount.status,
                      creation: formData.static.Multiaccount.creation,
                    },
                    just_dm: {
                      status: formData.static.just_dm.status,
                    },
                    adds_url: formData.static.adds_url.map(url => url.value).join(";")
                  },
                },
              },
            })

            return result
          },
          onSuccess: () => {
            toast({ title: 'Profile settings successfully updated!' })
          },
        })
      },
    })
  }

  useEffect(() => {
    const extractedData = extractProfileDataToFormData(profile.data, profile)
    form.setValue('dayC3.day.int1', extractedData.dayC3.day.int1)
    form.setValue('dayC3.day.int2', extractedData.dayC3.day.int2)
  }, [profile])

  return (
    <div>
      <div className="flex gap-5 mt-5">
        <ProfileDescription />
      </div>
      {/* SETTINGS */}
      <div className="relative">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit as any)}>
            <div className="flex gap-5 mb-5">
              <div className="w-1/2">
                <SettingsBlock label="Full Force (Day 99+)">
                  <SettingsFieldWrapper label="Daycount">
                    <Field type="double-counter" name="dayC3.day" control={form.control} />
                  </SettingsFieldWrapper>
                  <SettingsFieldWrapper label="Follow">
                    <Field type="double-counter" name="dayC3.follow" control={form.control} />
                  </SettingsFieldWrapper>
                  <SettingsFieldWrapper label="NumScrollsHomes">
                    <Field type="double-counter" name="dayC3.numScrollsHome" control={form.control} />
                  </SettingsFieldWrapper>
                  <SettingsFieldWrapper label="NumScrollsExplore">
                    <Field type="double-counter" name="dayC3.numScrollsExplore" control={form.control} />
                  </SettingsFieldWrapper>
                  <SettingsFieldWrapper label="NumDM">
                    <Field type="double-counter" name="dayC3.numDM" control={form.control} />
                  </SettingsFieldWrapper>
                  <div className="dark:text-white mt-5">
                    <Tabs
                      tabs={[
                        {
                          label: 'Text Editor',
                          value: 'text-editor',
                          children: (
                            <Field
                              type="textarea"
                              control={form.control}
                              name="logins"
                              componentProps={{
                                className: 'h-[300px]',
                                placeholder: 'Enter login & passwords in username:password format',
                              }}
                            />
                          ),
                        },
                        { label: 'Row Editor', value: 'row-editor', children: <RowEditor form={form} /> },
                      ]}
                    />
                  </div>
                </SettingsBlock>
              </div>
              <div className="w-1/2">
                <SettingsBlock label="Static values">
                  <SettingsFieldWrapper label="Activate Multi Accounts">
                    <Field type="switch" name="static.Multiaccount.status" control={form.control} />
                  </SettingsFieldWrapper>
                  <SettingsFieldWrapper label="Activate account creation">
                    <Field type="switch" name="static.Multiaccount.creation" control={form.control} />
                  </SettingsFieldWrapper>
                  <SettingsFieldWrapper label="DM only">
                    <Field type="switch" name="static.just_dm.status" control={form.control} />
                  </SettingsFieldWrapper>
                  <SettingsFieldWrapper label="Warm">
                    <Field type="switch" name="static.warm" control={form.control} />
                  </SettingsFieldWrapper>
                  <SettingsFieldWrapper label="Unfollow">
                    <Field type="double-counter" name="static.unfollow" control={form.control} />
                  </SettingsFieldWrapper>
                  <SettingsFieldWrapper label="MaxFollow">
                    <Field type="counter" name="static.maxFollow" control={form.control} />
                  </SettingsFieldWrapper>
                  <SettingsFieldWrapper label="DayDM">
                    <Field type="counter" name="static.dayDM" control={form.control} />
                  </SettingsFieldWrapper>
                  <SettingsFieldWrapper label="Proxy rotation link">
                    <Field
                      type="input"
                      name="static.proxy"
                      control={form.control}
                      componentProps={{ placeholder: 'Enter proxy rotation link...' }}
                      fieldClassName="w-full"
                    />
                  </SettingsFieldWrapper>
                  <SettingsFieldWrapper label="DM container">
                    <Field
                      type="input"
                      name="static.dmContainer"
                      control={form.control}
                      componentProps={{ placeholder: 'DM container...' }}
                      fieldClassName="w-full"
                    />
                  </SettingsFieldWrapper>
                  <SettingsFieldWrapper label="Share Story">
                    <Field
                      type="input"
                      name="static.shareStory"
                      control={form.control}
                      componentProps={{ placeholder: 'Share story...' }}
                      fieldClassName="w-full"
                    />
                  </SettingsFieldWrapper>
                  <SettingsFieldWrapper label="Post Reels Enabled">
                    <Field type="switch" name="static.postReelsEnable" control={form.control} />
                  </SettingsFieldWrapper>
                  <SettingsFieldWrapper label="Post Reels">
                    <Field type="double-counter" name="static.postReels" control={form.control} />
                  </SettingsFieldWrapper>
                  <SettingsFieldWrapper className="dark:text-white flex-col items-start gap-2" contentClassName="min-w-full" label="URLs">
                    <AddsURLField control={form.control} />
                  </SettingsFieldWrapper>
                </SettingsBlock>
              </div>
            </div>
            <div className="bg-white dark:bg-background px-6 py-4 rounded-lg dark:border dark:border-secondary">
              <Button
                loading={isUpdating || isSavingAccounts}
                htmlType="submit"
                size="lg"
                className="w-full text-2xl py-8 gap-2"
                iconClassName="w-7 h-7"
              >
                Save
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  )
}
