/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Props, VPSScraperTaskFormData } from './types'
import { Field, Modal, Switch, Textarea } from '@/components/common'
import { Form } from '@/components/ui/form'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { Button } from '@/components/ui/button'
import { getValidations } from '@/utils/util-functions'
import { useCreateVPSScraperTaskMutation } from '@/features/vps-scraper-tasks'
import { useHandleRequest } from '@/hooks/use-handle-request'
import { useToast } from '@/components/ui/use-toast'
import { UsernameCard } from './components'
import { cn } from '@/lib/utils'

export const CreateTaskModal: React.FC<Props> = ({ open, close, script }) => {
  const [createTask, { isLoading: isCreating }] = useCreateVPSScraperTaskMutation()
  const form = useForm<VPSScraperTaskFormData>({
    defaultValues: {
      taskUsernames: [{ value: '' }],
    },
  })
  const handleRequest = useHandleRequest()
  const { toast } = useToast()
  const {
    fields: usernames,
    append: appendUsername,
    remove: removeUsername,
  } = useFieldArray({ control: form.control, name: 'taskUsernames' as never })
  const [textEditor, setTextEditor] = useState(false)
  const taskUsernames = form.watch('taskUsernames') || []

  const onSubmit: SubmitHandler<VPSScraperTaskFormData> = async formData => {
    let i = 0
    let hasFaliure = false;
    for (const username of formData.taskUsernames) {
      await handleRequest({
        request: async () => {
          const result = await createTask({
            id: script._id || script.username,
            body: {
              apiKey: formData.apiKey,
              username: username.value,
            },
          })
          return result
        },
        onSuccess: () => {
          removeUsername(i);
          if (!hasFaliure) {
            toast({ title: 'Success ✅', description: 'Task successfully created!' })
            close()
          }
        },
        onError: () => {
          hasFaliure = true
        }
      })
      i++
    }
  }

  useEffect(() => {
    if (!open) {
      form.setValue('taskUsernames', [{ value: '' }])
    }
  }, [open])

  return (
    <Modal open={open} close={close} title={`Create task (${script.username})`}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-2">
          <Field
            type="input"
            name="apiKey"
            control={form.control}
            componentProps={{ label: 'API key', placeholder: 'Your API key' }}
            rules={getValidations(true)}
          />
          <div className="flex items-center justify-between mt-2">
            <Switch checked={textEditor} onChange={setTextEditor} label="Text Editor" />
            <button
              onClick={() => form.setValue('taskUsernames', [{ value: '' }])}
              type="button"
              className="text-red-600"
            >
              Empty
            </button>
          </div>
          <div className="flex flex-col gap-1 my-3">
            {textEditor ? (
              <Textarea
                placeholder="Enter usernames..."
                className="h-32"
                value={taskUsernames.map(username => username.value).join('\n')}
                onChange={event =>
                  form.setValue(
                    'taskUsernames',
                    event.target.value.split('\n').map(value => ({ value })),
                  )
                }
              />
            ) : (
              <>
                {usernames.map((username, index) => (
                  <UsernameCard
                    key={username.id}
                    form={form}
                    index={index}
                    onRemove={() => removeUsername(index)}
                    scriptName={script._id || script.username}
                  />
                ))}
              </>
            )}
          </div>
          <div className={cn('grid gap-2', taskUsernames.length > 1 ? 'grid-cols-2' : 'grid-cols-1')}>
            <Button variant="outline" onClick={() => appendUsername({ value: '' })}>
              Add Task
            </Button>
            {taskUsernames.length > 1 && (
              <Button loading={isCreating} htmlType="submit">
                Create All
              </Button>
            )}
          </div>
        </form>
      </Form>
    </Modal>
  )
}
