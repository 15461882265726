import {Button} from '@/components/ui/button'
import React, {useEffect, useState} from 'react'
import {FaSort, FaSortDown, FaSortUp} from 'react-icons/fa6'
import {Props} from './types'
import {SortByI} from '../../types'
import {Input, Loader, TableNotFoundRow} from '@/components/common'
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from '@/components/ui/table'
import {cn} from '@/lib/utils'
import {BlacklistForm, ClientGroupRow, ClientProfileRow, CreateClientGroupForm} from '..'
import {useDebounce} from 'use-debounce'

export const DashboardTable: React.FC<Props> = ({
  profilesAndGroups: profilesAndGroupsData,
  isFetchingProfilesAndGroups,
}) => {
  const [profilesAndGroups, setProfilesAndGroups] = useState<any>({
    groups: profilesAndGroupsData || [],
    totalProfilesCount: 0,
  })
  const [searchQuery, setSearchQuery] = useState('')
  const [openBlacklist, setOpenBlacklist] = useState(false)
  const [debouncedQuery] = useDebounce(searchQuery, 500)
  const [openCreateGroupForm, setOpenCreateGroupForm] = useState(false)
  const [sortBy, setSortBy] = useState<SortByI | undefined>()

  useEffect(() => {
    if (profilesAndGroupsData) {
      let filteredProfilesAndGroups = profilesAndGroupsData?.groups?.filter(
        (profile: any) =>
          (profile.groupName || profile.profileName).toLowerCase().includes(debouncedQuery.toLowerCase()) ||
          profile.profileNames.toLowerCase().includes(debouncedQuery.toLowerCase()),
      )

      if (sortBy) {
        filteredProfilesAndGroups = filteredProfilesAndGroups.sort((a: any, b: any) =>
          sortBy?.desc
            ? +b[sortBy.key as any] - +a[sortBy.key as any]
            : +a[sortBy?.key as any] - +b[sortBy?.key as any],
        )
      }

      setProfilesAndGroups({
        groups: filteredProfilesAndGroups,
        totalProfilesCount: profilesAndGroupsData.totalProfilesCount,
      })
    }
  }, [profilesAndGroupsData, debouncedQuery, sortBy])

  return (
    <div className="container mx-auto mt-16 mb-4 py-6 bg-white dark:bg-background rounded-xl dark:border dark:border-secondary">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-xl font-semibold">
          Dashboard <span className="text-xs">{profilesAndGroups.totalProfilesCount} Profiles</span>
        </h3>
        <div className="flex items-center gap-2">
          <Input
            type="search"
            placeholder="Search..."
            searchIconClassName="left-3 top-[9px]"
            parentClassName="w-52"
            value={searchQuery}
            onChange={event => setSearchQuery(event.target.value)}
          />
          {/* <Button
            disabled
            onClick={() => setOpenCreateGroupForm(true)}
            variant="outline"
            className="min-w-max text-xs h-[34px]"
            size="sm"
          >
            Create Group
          </Button> */}
          <Button
            onClick={() => setOpenBlacklist(true)}
            variant="outline"
            className="min-w-max text-xs h-[34px]"
            size="sm"
          >
            See Blacklist
          </Button>
        </div>
      </div>
      <div id="scrollableDiv2" className="h-[60vh] overflow-y-auto">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className={thClassName}>Username</TableHead>
              <TableHead className={thClassName}>📧 Client Email</TableHead>
              <TableHead
                onClick={() => setSortBy(prev => ({key: 'last24hrs', desc: !prev?.desc} as any))}
                className={cn(thClassName, 'cursor-pointer')}
              >
                <div className="flex items-center gap-1 !border-0">
                  <span>Last 24hrs</span>
                  {sortBy?.key === 'last24hrs' ? sortBy.desc ? <FaSortDown /> : <FaSortUp /> : <FaSort />}
                </div>
              </TableHead>
              <TableHead
                onClick={() => setSortBy(prev => ({key: 'last48hrs', desc: !prev?.desc} as any))}
                className={cn(thClassName, 'cursor-pointer')}
              >
                <div className="flex items-center gap-1 !border-0">
                  <span>Last 48hrs</span>
                  {sortBy?.key === 'last48hrs' ? sortBy.desc ? <FaSortDown /> : <FaSortUp /> : <FaSort />}
                </div>
              </TableHead>
              <TableHead
                onClick={() => setSortBy(prev => ({key: 'last7days', desc: !prev?.desc} as any))}
                className={cn(thClassName, 'cursor-pointer')}
              >
                <div className="flex items-center gap-1 !border-0">
                  <span>Last 7days</span>
                  {sortBy?.key === 'last7days' ? sortBy.desc ? <FaSortDown /> : <FaSortUp /> : <FaSort />}
                </div>
              </TableHead>
              <TableHead
                onClick={() => setSortBy(prev => ({key: 'last30days', desc: !prev?.desc} as any))}
                className={cn(thClassName, 'cursor-pointer')}
              >
                <div className="flex items-center gap-1 !border-0">
                  <span>Last 30days</span>
                  {sortBy?.key === 'last30days' ? sortBy.desc ? <FaSortDown /> : <FaSortUp /> : <FaSort />}
                </div>
              </TableHead>
              <TableHead
                onClick={() => setSortBy(prev => ({key: 'last60days', desc: !prev?.desc} as any))}
                className={cn(thClassName, 'cursor-pointer')}
              >
                <div className="flex items-center gap-1 !border-0">
                  <span>Last 60days</span>
                  {sortBy?.key === 'last60days' ? sortBy.desc ? <FaSortDown /> : <FaSortUp /> : <FaSort />}
                </div>
              </TableHead>
              <TableHead
                onClick={() => setSortBy(prev => ({key: 'totalFollowers', desc: !prev?.desc} as any))}
                className={cn(thClassName, 'cursor-pointer')}
              >
                <div className="flex items-center gap-1 !border-0">
                  <span>Total Followers</span>
                  {sortBy?.key === 'totalFollowers' ? sortBy.desc ? <FaSortDown /> : <FaSortUp /> : <FaSort />}
                </div>
              </TableHead>
              <TableHead className={cn('text-center', thClassName)}>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isFetchingProfilesAndGroups ? (
              <TableRow className="relative">
                <TableCell className="h-80">
                  <Loader parentClassName="absolute top-0 left-0 w-full h-full" />
                </TableCell>
              </TableRow>
            ) : !profilesAndGroups.groups.length ? (
              <TableNotFoundRow text="No any clients found" />
            ) : (
              <>
                {profilesAndGroups.groups.map((profileOrGroup: any, index: number) => (
                  <React.Fragment key={index}>
                    {profileOrGroup.groupName ? (
                      <ClientGroupRow clientGroup={profileOrGroup} />
                    ) : (
                      <ClientProfileRow clientProfile={profileOrGroup} />
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </div>
      <CreateClientGroupForm open={openCreateGroupForm} close={() => setOpenCreateGroupForm(false)} />
      <BlacklistForm open={openBlacklist} close={() => setOpenBlacklist(false)} />
    </div>
  )
}
const thClassName = 'sticky top-0 z-10 bg-white dark:bg-secondary'
