import React from 'react'
import {Props} from './types'

export const ProfileRow: React.FC<Props> = ({profile}) => (
  <div className="table-row relative overflow-hidden duration-200">
    <div className={`text-start ${tdClassName}`}>
      <a
        href={`/profiles-settings?phoneID=${profile.phoneID}`}
        target="_blank"
        className="hover:text-blue-600 duration-100"
      >
        {profile.phoneID}
      </a>
    </div>
    <div className={tdClassName}>
      {!isNaN(+profile.tasks) ? profile.tasks : <span className="text-muted-foreground italic">N/A</span>}
    </div>
    <div className={tdClassName}>
      {!isNaN(+profile.accounts) ? profile.accounts : <span className="text-muted-foreground italic">N/A</span>}
    </div>
    <div className={tdClassName}>{profile.FBR || <span className="text-muted-foreground italic">N/A</span>}</div>
    <div className={tdClassName}>
      {profile.percentUsed ? `${profile.percentUsed}%` : <span className="text-muted-foreground italic">N/A</span>}
    </div>
    <div className={`w-[300px] ${tdClassName}`}>
      <a
        href={`/manage?phoneID=${profile.phoneID}`}
        target="_blank"
        className="text-blue-600 pb-0.5 hover:underline duration-100"
      >
        Go to profile
      </a>
    </div>
  </div>
)

const tdClassName = 'table-cell p-5 border-y text-center'
