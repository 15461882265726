/* eslint-disable react-hooks/exhaustive-deps */
import {Modal} from '@/components/common'
import {Button} from '@/components/ui/button'
import React, {useEffect, useState} from 'react'
import {Props} from './types'
import {useLazyGetProfileDMsQuery} from '@/features/profile-dm'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {DMCard} from './components'
import {ObjectI} from '@/types/default'
import {GetProfileDMsResponse} from '@/features/profile-dm/types'
import {GrDocumentConfig} from 'react-icons/gr'

export const ConfigureDM: React.FC<Props> = ({profile}) => {
  const [profileDMs, setProfileDMs] = useState<any>({})
  const [getProfileDMs, {isLoading}] = useLazyGetProfileDMsQuery()
  const [open, setOpen] = useState(false)
  const handleRequest = useHandleRequest()

  const onCreate = () => {
    setProfileDMs((prev: any) => {
      const unsaveds = Object.keys(prev).filter(key => key.includes('unsaved'))
      if (!unsaveds.length) {
        return {...prev, unsaved: {}}
      }

      return {...prev, [`unsaved-${unsaveds.length}`]: {}}
    })
  }

  const onRemove = (id: string) => {
    setProfileDMs((prev: any) => {
      const updatedDMs = {...prev}
      delete updatedDMs[id]

      return updatedDMs
    })
  }

  const fetchProfileDMs = async () => {
    await handleRequest({
      request: async () => {
        const result = await getProfileDMs({id: profile._id})
        return result
      },
      onSuccess: ({data}: {data: GetProfileDMsResponse}) => {
        setProfileDMs(data.data || {})
      },
    })
  }

  useEffect(() => {
    if (open) {
      fetchProfileDMs()
    }
  }, [open])

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="outline" className="flex gap-2 rounded h-[34px] min-w-max text-xs">
        <GrDocumentConfig className="text-sm" />
        <span>Configure DM</span>
      </Button>
      <Modal isLoading={isLoading} open={open} close={() => setOpen(false)} title={`Configure DM (${profile.phoneID})`}>
        {Object.keys(profileDMs)?.length ? (
          <div className="flex flex-col gap-2">
            {Object.entries(profileDMs).map(([profileDMID, profileDM]) => (
              <DMCard
                key={profileDMID}
                profileDM={profileDM as ObjectI}
                profileDMID={profileDMID}
                profile={profile}
                onRemove={() => onRemove(profileDMID)}
                refetchDMs={fetchProfileDMs}
              />
            ))}
          </div>
        ) : (
          <div className="w-full h-32 grid place-content-center text-zinc-700">No any DMs yet...</div>
        )}
        <Button onClick={onCreate} className="w-full mt-5">
          Create New DM
        </Button>
      </Modal>
    </>
  )
}
