import React from 'react'
import {Sheet, SheetContent, SheetHeader, SheetTitle} from '@/components/ui/sheet'
import {cn} from '@/lib/utils'
import {Props} from './types'

export const SideMenu: React.FC<Props> = ({open, close, title, children, contentClassName}) => (
  <Sheet open={open} onOpenChange={close}>
    <SheetContent
      className={cn(
        'h-full overflow-auto !bg-white dark:!bg-background !text-black dark:!text-white',
        contentClassName,
      )}
    >
      <SheetHeader className="pb-6">
        <div className="absolute top-[70px] h-[1px] w-full bg-muted left-0" />
        {title && <SheetTitle className="">{title}</SheetTitle>}
      </SheetHeader>
      {children}
    </SheetContent>
  </Sheet>
)
