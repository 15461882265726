import React, {useState} from 'react'
import {Props} from './types'
import {TableCell, TableRow} from '@/components/ui/table'
import {Button} from '@/components/ui/button'
import {FaRegTrashCan} from 'react-icons/fa6'
import {FiEdit3} from 'react-icons/fi'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {DeleteModal} from '@/components/common'
import {useToast} from '@/components/ui/use-toast'
import {useDeleteAccountMutation} from '@/features/accounts'
import {EditForm} from '..'

export const AccountRow: React.FC<Props> = ({account, isCurrentAccount}) => {
  const [deleteAccount, {isLoading: isDeleting}] = useDeleteAccountMutation()
  const [openEditForm, setOpenEditForm] = useState(false)
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false)
  const handleRequest = useHandleRequest()
  const {toast} = useToast()

  const onDelete = async () => {
    await handleRequest({
      request: async () => {
        const result = deleteAccount({id: account._id})
        return result
      },
      onSuccess: () => {
        toast({title: 'Account successfully deleted!'})
      },
    })
  }

  return (
    <TableRow className="!bg-white dark:!bg-background">
      <TableCell>{account.username}</TableCell>
      <TableCell>{account.role}</TableCell>
      <TableCell>
        {isCurrentAccount ? (
          <i className="text-xs block text-center">You cannot edit/delete your account!</i>
        ) : (
          <>
            <div className="flex items-center justify-center gap-2">
              <Button onClick={() => setOpenEditForm(true)} size="icon" variant="outline">
                <FiEdit3 />
              </Button>
              <Button onClick={() => setOpenDeleteAlert(true)} size="icon" variant="outlined-destructive">
                <FaRegTrashCan />
              </Button>
            </div>
            <DeleteModal
              open={openDeleteAlert}
              close={() => setOpenDeleteAlert(false)}
              title={`Are you sure to delete this account (${account.username}) ?`}
              onDelete={onDelete}
              isDeleting={isDeleting}
            />
            <EditForm open={openEditForm} close={() => setOpenEditForm(false)} account={account} />
          </>
        )}
      </TableCell>
    </TableRow>
  )
}
