import {EndpointNameScrapingTask} from '@/constants/endpoints'
import {baseApi} from '..'
import {RTKTagName} from '@/constants/rtk-tag-name'
import {
  CloneTaskRequest,
  CloneTaskResponse,
  CompleteTaskRequest,
  CompleteTaskResponse,
  CreateTaskRequest,
  CreateTaskResponse,
  DeleteTaskFromBotServerRequest,
  DeleteTaskFromBotServerResponse,
  DeleteTaskFromScraperRequest,
  DeleteTaskFromScraperResponse,
  GetProfileAllTasksRequest,
  GetProfileAllTasksResponse,
  GetProfileTasksRequest,
  GetProfileTasksResponse,
  RestoreTaskRequest,
  RestoreTaskResponse,
  UploadFileToBlacklistRequest,
  UploadFileToBlacklistResponse,
} from './types'

const ScrapingTaskExtendedEndpoints = baseApi.injectEndpoints({
  endpoints: build => ({
    getProfileTasks: build.query<GetProfileTasksResponse, GetProfileTasksRequest>({
      query: ({id}) => ({
        url: EndpointNameScrapingTask.GET_PROFILE_TASKS + id,
        method: 'GET',
      }),
      providesTags: [RTKTagName.SCRAPING_TASK],
    }),

    createTask: build.mutation<CreateTaskResponse, CreateTaskRequest>({
      query: body => ({
        url: EndpointNameScrapingTask.CREATE_TASK,
        method: 'POST',
        body,
      }),
      invalidatesTags: [RTKTagName.SCRAPING_TASK],
    }),

    getAllTasks: build.query<GetProfileAllTasksResponse, GetProfileAllTasksRequest>({
      query: () => ({
        url: EndpointNameScrapingTask.GET_PROFILE_ALL_TASKS,
        method: 'GET',
      }),
      providesTags: [RTKTagName.SCRAPING_TASK],
    }),

    deleteFromScraper: build.mutation<DeleteTaskFromScraperResponse, DeleteTaskFromScraperRequest>({
      query: ({id}) => ({
        url: EndpointNameScrapingTask.DELETE_FROM_SCRAPER + id,
        method: 'DELETE',
      }),
      invalidatesTags: [RTKTagName.SCRAPING_TASK],
    }),

    deleteFromBotServer: build.mutation<DeleteTaskFromBotServerResponse, DeleteTaskFromBotServerRequest>({
      query: ({id, params}) => ({
        url: EndpointNameScrapingTask.DELETE_FROM_BOT_SERVER + id,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: [RTKTagName.SCRAPING_TASK],
    }),

    completeTask: build.mutation<CompleteTaskResponse, CompleteTaskRequest>({
      query: ({id}) => ({
        url: EndpointNameScrapingTask.COMPLETE_TASK + id,
        method: 'POST',
      }),
      invalidatesTags: [RTKTagName.SCRAPING_TASK],
    }),

    restoreTask: build.mutation<RestoreTaskResponse, RestoreTaskRequest>({
      query: ({id, body}) => ({
        url: EndpointNameScrapingTask.RESTORE_TASK + id,
        method: 'POST',
        body,
      }),
      invalidatesTags: [RTKTagName.SCRAPING_TASK],
    }),

    cloneTask: build.mutation<CloneTaskResponse, CloneTaskRequest>({
      query: ({id, body}) => ({
        url: EndpointNameScrapingTask.CLONE_TASK + id,
        method: 'POST',
        body,
      }),
      invalidatesTags: [RTKTagName.SCRAPING_TASK],
    }),

    uploadFileToBlacklist: build.mutation<UploadFileToBlacklistResponse, UploadFileToBlacklistRequest>({
      query: ({id, body}) => ({
        url: EndpointNameScrapingTask.UPLOAD_FILE_TO_BLACKLIST + id,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useLazyGetAllTasksQuery,
  useLazyGetProfileTasksQuery,
  useCreateTaskMutation,
  useDeleteFromBotServerMutation,
  useDeleteFromScraperMutation,
  useCompleteTaskMutation,
  useRestoreTaskMutation,
  useCloneTaskMutation,
  useUploadFileToBlacklistMutation,
} = ScrapingTaskExtendedEndpoints
