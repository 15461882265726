import React, {useMemo} from 'react'
import {Props, RadioGroupValues} from './types'
import {RadioGroup as RadioGroupUI, RadioGroupItem} from '@/components/ui/radio-group'
import {Label} from '@/components/ui/label'

export const RadioGroup: React.FC<Props> = ({options, defaultValue, onChange}) => {
  const valuesProps = useMemo(() => {
    const props: RadioGroupValues = {}

    if (onChange) {
      props.onChange = onChange
      props.defaultValue = defaultValue
    }

    return props
  }, [defaultValue, onChange])

  return (
    <RadioGroupUI {...valuesProps}>
      {options.map(option => (
        <div key={option.value} className="flex items-center space-x-2 cursor-pointer">
          <RadioGroupItem value={option.value} id={option.value} />
          <Label htmlFor={option.value}>{option.label}</Label>
        </div>
      ))}
    </RadioGroupUI>
  )
}
