/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from 'react'
import {CheckScraperTasksResultI, Props} from './types'
import {Modal} from '@/components/common'
import {useCheckVPSScraperTaskCompletionMutation, useLazyGetAllVPSScraperTasksQuery} from '@/features/vps-scraper-tasks'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useToast} from '@/components/ui/use-toast'
import {CheckVPSScraperTaskCompletionResponse} from '@/features/vps-scraper-tasks/types'
import {sleep} from '@/utils/util-functions'
import {ClosingWarningModal, TaskCard} from './components'
import {VPSScraperTask} from '@/types/vps-scraper-task'

export const CheckScraperTasksModal: React.FC<Props> = ({open, close}) => {
  const [checkScraperTask] = useCheckVPSScraperTaskCompletionMutation()
  const [getAllVPSScraperTasks, {data: {data: tasks = []} = {}, isFetching}] = useLazyGetAllVPSScraperTasksQuery()
  const [results, setResults] = useState<CheckScraperTasksResultI>({})
  const [activeTaskIdx, setActiveTaskIdx] = useState(0)
  const [openClosingWarning, setOpenClosingWarning] = useState(false)
  const openRef = useRef(open)
  const handleRequest = useHandleRequest()
  const {toast} = useToast()

  const checkScraperTaskCompletion = async (innerTasks: VPSScraperTask[], currScriptIdx: number) => {
    if (!openRef.current) {
      return
    }

    const currTask = innerTasks[currScriptIdx]

    await handleRequest({
      request: async () => {
        const result = await checkScraperTask({id: currTask.scriptName, params: {username: currTask.username}})
        return result
      },
      onSuccess: ({data}: {data: CheckVPSScraperTaskCompletionResponse}) => {
        setResults(prev => ({
          ...prev,
          [currTask.username]: {completed: data.data},
        }))
      },
      onError: () => {
        setResults(prev => ({
          ...prev,
          [currTask.username]: {failed: true},
        }))
      },
      onComplete: async () => {
        const nextScriptIdx = currScriptIdx + 1
        if (!innerTasks[nextScriptIdx] || !openRef.current) {
          if (openRef.current) {
            close()
            toast({title: 'Success 🎉', description: 'Checking scraper tasks successfully completed!'})
            setActiveTaskIdx(0)
            setResults({})
          } else {
            toast({title: 'Process stopped!', description: 'Checking scraper tasks successfully stopped!'})
          }
          return
        }

        setActiveTaskIdx(nextScriptIdx)
        await sleep(2 * 1000)
        await checkScraperTaskCompletion(innerTasks, nextScriptIdx)
      },
    })
  }

  const handleCheckScraperTasksCompletion = async () => {
    await checkScraperTaskCompletion(tasks, activeTaskIdx)
  }

  useEffect(() => {
    openRef.current = open
    if (open && tasks.length) {
      handleCheckScraperTasksCompletion()
    }
  }, [open, tasks])

  const fetchAllVPSScraperTasks = async () => {
    handleRequest({
      request: async () => {
        const result = await getAllVPSScraperTasks({})
        return result
      },
    })
  }

  useEffect(() => {
    if (open && !tasks.length) {
      fetchAllVPSScraperTasks()
    }
  }, [open])

  return (
    <>
      <Modal
        open={open}
        close={() => setOpenClosingWarning(true)}
        title={`Check scraper tasks${tasks.length ? `(${tasks.length} / ${activeTaskIdx})` : ''}`}
        isLoading={isFetching}
      >
        <div className="flex flex-col gap-2">
          {tasks.map((task, index) => (
            <TaskCard key={task._id} task={task} isRunning={activeTaskIdx === index} result={results[task.username]} />
          ))}
        </div>
      </Modal>
      <ClosingWarningModal open={openClosingWarning} close={() => setOpenClosingWarning(false)} onOk={close} />
    </>
  )
}
