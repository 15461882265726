import React, {useState} from 'react'
import {Props} from './types'
import {useCreateRoadmapTaskMutation} from '@/features/roadmap-tasks'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {SubmitHandler} from 'react-hook-form'
import {RoadmapTaskFormData} from '../base-task-form/types'
import {useToast} from '@/components/ui/use-toast'
import {BaseTaskForm} from '..'

export const CreateTaskForm: React.FC<Props> = ({open, close, fetchTasks}) => {
  const [createTask] = useCreateRoadmapTaskMutation()
  const [loading, setLoading] = useState(false)
  const handleRequest = useHandleRequest()
  const {toast} = useToast()

  const onSubmit: SubmitHandler<RoadmapTaskFormData> = async formData => {
    setLoading(true)
    await handleRequest({
      request: async () => {
        const result = await createTask(formData)
        return result
      },
      onSuccess: () => {
        setTimeout(async () => {
          fetchTasks()
          close()
          toast({title: 'Task successfully created!'})
          setLoading(false)
        }, 1000)
      },
    })
  }

  return <BaseTaskForm open={open} close={close} onSubmit={onSubmit} isSubmitting={loading} />
}
