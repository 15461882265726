/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from 'react'
import {BannedAccountsResultsI, Props} from './types'
import {Modal} from '@/components/common'
import {ClosingWarningModal, ScriptCard} from './components'
import {useToast} from '@/components/ui/use-toast'
import {useCheckScriptBannedAccountsMutation} from '@/features/vps-scripts'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {CheckScriptBannedAccountsResponse} from '@/features/vps-scripts/types'
import {sleep} from '@/utils/util-functions'

export const CheckBannedAccountsModal: React.FC<Props> = ({open, close, scripts}) => {
  const [checkBannedAccounts] = useCheckScriptBannedAccountsMutation()
  const [activeScriptIdx, setActiveScriptIdx] = useState(0)
  const [results, setResults] = useState<BannedAccountsResultsI>({})
  const [openClosingWarning, setOpenClosingWarning] = useState(false)
  const openRef = useRef(open)
  const handleRequest = useHandleRequest()
  const {toast} = useToast()

  const checkScriptBannedAccounts = async (currScriptIdx: number) => {
    if (!openRef.current) {
      return
    }

    const scriptName = scripts[currScriptIdx]._id || scripts[currScriptIdx].username

    await handleRequest({
      request: async () => {
        const result = await checkBannedAccounts({id: scriptName})
        return result
      },
      onSuccess: ({data}: {data: CheckScriptBannedAccountsResponse}) => {
        setResults(prev => ({
          ...prev,
          [scriptName]: data.data,
        }))
      },
      onError: () => {
        setResults(prev => ({
          ...prev,
          [scriptName]: {failed: true},
        }))
      },
      onComplete: async () => {
        const nextScriptIdx = currScriptIdx + 1
        if (!scripts[nextScriptIdx] || !openRef.current) {
          if (openRef.current) {
            close()
            toast({title: 'Success 🎉', description: 'Checking banned accounts successfully completed!'})
          } else {
            toast({title: 'Process stopped!', description: 'Checking banned accounts successfully stopped!'})
          }
          setActiveScriptIdx(0)
          return
        }

        setActiveScriptIdx(nextScriptIdx)
        await sleep(2 * 1000)
        await checkScriptBannedAccounts(nextScriptIdx)
      },
    })
  }

  const handleStartCheckingBannedAccounts = async () => {
    await checkScriptBannedAccounts(activeScriptIdx)
  }

  useEffect(() => {
    openRef.current = open
    if (open) {
      handleStartCheckingBannedAccounts()
      setResults({})
    }
  }, [open])

  return (
    <>
      <Modal
        open={open}
        close={() => setOpenClosingWarning(true)}
        title={`Checking banned accounts (${scripts.length} / ${activeScriptIdx})`}
      >
        <div className="flex flex-col gap-2">
          {scripts.map((script, index) => (
            <ScriptCard
              key={script._id || script.username}
              script={script}
              isRunning={activeScriptIdx === index}
              result={results[script._id || script.username]}
            />
          ))}
        </div>
      </Modal>
      <ClosingWarningModal open={openClosingWarning} close={() => setOpenClosingWarning(false)} onOk={close} />
    </>
  )
}
