import {Icon} from '@/components/common/icon'
import {IconProps} from '@/components/common/icon/types'

export const DebugIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 149 149">
    <circle cx="74.1898" cy="74.1898" r="64.1898" fill="#2CD776" stroke="white" strokeWidth="20" />
    <path
      d="M66.6846 97.4974C66.0726 98.1127 65.2379 98.4564 64.3706 98.4564C63.5032 98.4564 62.6686 98.1127 62.0565 97.4974L43.5767 79.0143C41.6587 77.0963 41.6587 73.9869 43.5767 72.0721L45.8908 69.758C47.8088 67.84 50.9149 67.84 52.833 69.758L64.3706 81.2956L95.5466 50.1196C97.4647 48.2015 100.574 48.2015 102.489 50.1196L104.803 52.4336C106.721 54.3517 106.721 57.4611 104.803 59.3758L66.6846 97.4974Z"
      fill="white"
    />
    <circle cx="74.1898" cy="74.1898" r="74.1898" fill="#FE4E9A" />
    <g clipPath="url(#clip0_2202_6)">
      <path
        d="M83.4819 37.3789L85.2648 33.3673C85.8018 32.1591 85.2576 30.7441 84.0493 30.2072C82.8407 29.6699 81.426 30.2143 80.8891 31.4227L78.3287 37.1834H68.6714L66.1111 31.4225C65.5741 30.2143 64.1591 29.6699 62.9509 30.207C61.7427 30.7439 61.1985 32.159 61.7354 33.3672L63.5183 37.3787C58.7521 38.3141 55.1445 42.5218 55.1445 47.5581V51.5484H91.8558V47.5581C91.8556 42.5221 88.2481 38.3141 83.4819 37.3789Z"
        fill="white"
      />
      <path
        d="M112.605 72.2984H98.2402V67.3203C105.468 66.169 111.009 59.8931 111.009 52.3466V49.1544C111.009 47.8322 109.937 46.7602 108.615 46.7602C107.293 46.7602 106.221 47.8322 106.221 49.1544V52.3466C106.221 57.2433 102.81 61.3555 98.2403 62.4397V60.3274C98.2403 58.1271 96.4503 56.3371 94.25 56.3371H75.8943V84.2695C75.8943 85.5917 74.8223 86.6637 73.5002 86.6637C72.1778 86.6637 71.106 85.5917 71.106 84.2695V56.3369H52.7503C50.55 56.3369 48.76 58.1269 48.76 60.3273V62.4395C44.1901 61.3554 40.7794 57.2431 40.7794 52.3465V49.1542C40.7794 47.832 39.7073 46.76 38.3852 46.76C37.063 46.76 35.991 47.832 35.991 49.1542V52.3465C35.991 59.893 41.5327 66.1687 48.7602 67.3202V72.2982H34.3945C33.0723 72.2982 32.0003 73.3703 32.0003 74.6924C32.0003 76.0146 33.0723 77.0866 34.3945 77.0866H48.7598V82.0647C41.5324 83.216 35.9906 89.4919 35.9906 97.0384V100.231C35.9906 101.553 37.0627 102.625 38.3848 102.625C39.707 102.625 40.779 101.553 40.779 100.231V97.0384C40.779 92.1417 44.1898 88.0295 48.7597 86.9453V88.2595C48.7598 101.902 59.8583 113 73.5 113C87.1417 113 98.2402 101.902 98.2402 88.2598V86.9456C102.81 88.0298 106.221 92.142 106.221 97.0387V100.231C106.221 101.553 107.293 102.625 108.615 102.625C109.937 102.625 111.009 101.553 111.009 100.231V97.0387C111.009 89.4922 105.467 83.2165 98.24 82.065V77.0869H112.605C113.927 77.0869 115 76.0149 115 74.6927C115 73.3706 113.928 72.2984 112.605 72.2984Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2202_6">
        <rect width="83" height="83" fill="white" transform="translate(32 30)" />
      </clipPath>
    </defs>
  </Icon>
)
