/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {Props} from './types'
import {useForm} from 'react-hook-form'
import {Field, Modal} from '@/components/common'
import {Form} from '@/components/ui/form'
import {Button} from '@/components/ui/button'
import {getValidations} from '@/utils/util-functions'

export const BaseScriptForm: React.FC<Props> = ({
  open,
  close,
  title,
  onSubmit,
  defaultValues,
  isSubmitting,
  submitText,
}) => {
  const form = useForm({defaultValues})

  useEffect(() => {
    if (!open) {
      form.reset()
    }
  }, [open])

  return (
    <Modal open={open} close={close} title={title || 'Create New Script'}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <Field
            type="input"
            control={form.control}
            name="username"
            rules={getValidations(true)}
            componentProps={{
              placeholder: 'Script name',
              label: 'Script name',
            }}
          />
          <Button htmlType="submit" className="text-xs" loading={isSubmitting}>
            {submitText || 'Create'}
          </Button>
        </form>
      </Form>
    </Modal>
  )
}
