import {EndpointNameProfileDM} from '@/constants/endpoints'
import {baseApi} from '..'
import {RTKTagName} from '@/constants/rtk-tag-name'
import {
  CreateDMRequest,
  CreateDMResponse,
  DeleteDMRequest,
  DeleteDMResponse,
  GetAllDMUsernamesRequest,
  GetAllDMUsernamesResponse,
  GetProfileDMsRequest,
  GetProfileDMsResponse,
  SearchDMRequest,
  SearchDMResponse,
  SetActiveDMRequest,
  SetActiveDMResponse,
  UpdateDMRequest,
  UpdateDMResponse,
} from './types'

const ProfileExtendedEndpoints = baseApi.injectEndpoints({
  endpoints: build => ({
    getProfileDMs: build.query<GetProfileDMsResponse, GetProfileDMsRequest>({
      query: ({id}) => ({
        url: EndpointNameProfileDM.PROFILE_DM + id,
        method: 'GET',
      }),
      providesTags: [RTKTagName.PROFILE_DM],
    }),

    createProfileDM: build.mutation<CreateDMResponse, CreateDMRequest>({
      query: ({id, body}) => ({
        url: EndpointNameProfileDM.CREATE_DM + id,
        method: 'POST',
        body,
      }),
      invalidatesTags: [RTKTagName.PROFILE_DM],
    }),

    updateProfileDM: build.mutation<UpdateDMResponse, UpdateDMRequest>({
      query: ({id, body}) => ({
        url: EndpointNameProfileDM.PROFILE_DM + id,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [RTKTagName.PROFILE_DM],
    }),

    deleteProfileDM: build.mutation<DeleteDMResponse, DeleteDMRequest>({
      query: ({id, params}) => ({
        url: EndpointNameProfileDM.PROFILE_DM + id,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: [RTKTagName.PROFILE_DM],
    }),

    setActiveDM: build.mutation<SetActiveDMResponse, SetActiveDMRequest>({
      query: ({id, params}) => ({
        url: EndpointNameProfileDM.ACTIVATE_DM + id,
        method: 'POST',
        params,
      }),
      invalidatesTags: [RTKTagName.PROFILE_DM],
    }),

    searchDM: build.query<SearchDMResponse, SearchDMRequest>({
      query: ({params}) => ({
        url: EndpointNameProfileDM.SEARCH,
        method: 'GET',
        params,
      }),
    }),

    getAllDMUsernames: build.query<GetAllDMUsernamesResponse, GetAllDMUsernamesRequest>({
      query: () => ({
        url: EndpointNameProfileDM.GET_ALL_USERNAMES,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useLazyGetProfileDMsQuery,
  useCreateProfileDMMutation,
  useUpdateProfileDMMutation,
  useDeleteProfileDMMutation,
  useSetActiveDMMutation,
  useLazySearchDMQuery,
  useLazyGetAllDMUsernamesQuery,
} = ProfileExtendedEndpoints
