import {Tooltip as UITooltip, TooltipContent, TooltipProvider, TooltipTrigger} from '@/components/ui/tooltip'
import React from 'react'
import {Props} from './types'

export const Tooltip: React.FC<Props> = ({trigger, children, contentClassName}) => (
  <TooltipProvider>
    <UITooltip>
      <TooltipTrigger asChild>{trigger}</TooltipTrigger>
      <TooltipContent className={contentClassName}>{children}</TooltipContent>
    </UITooltip>
  </TooltipProvider>
)
