import {Icon} from '@/components/common/icon'
import {IconProps} from '@/components/common/icon/types'

export const CodeIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 149 149">
    <circle cx="74.1898" cy="74.1898" r="74.1898" fill="#3784E9" />
    <path
      d="M24.9262 77.2404L43.2596 95.5738C43.6824 96.0115 44.1881 96.3607 44.7473 96.6009C45.3065 96.8411 45.9079 96.9676 46.5165 96.9728C47.1251 96.9781 47.7286 96.8622 48.2919 96.6317C48.8551 96.4013 49.3669 96.0609 49.7972 95.6306C50.2276 95.2002 50.5679 94.6885 50.7983 94.1252C51.0288 93.562 51.1448 92.9584 51.1395 92.3499C51.1342 91.7413 51.0077 91.1399 50.7675 90.5807C50.5273 90.0215 50.1782 89.5157 49.7404 89.0929L34.6475 74L49.7404 58.9071C50.1782 58.4843 50.5273 57.9786 50.7675 57.4194C51.0077 56.8602 51.1342 56.2588 51.1395 55.6502C51.1448 55.0416 51.0288 54.4381 50.7983 53.8748C50.5679 53.3115 50.2276 52.7998 49.7972 52.3695C49.3669 51.9391 48.8551 51.5988 48.2919 51.3683C47.7286 51.1379 47.1251 51.0219 46.5165 51.0272C45.9079 51.0325 45.3065 51.1589 44.7473 51.3991C44.1881 51.6394 43.6824 51.9885 43.2596 52.4263L24.9262 70.7596C24.067 71.6191 23.5843 72.7847 23.5843 74C23.5843 75.2154 24.067 76.3809 24.9262 77.2404Z"
      fill="white"
    />
    <path
      d="M104.74 52.4263C104.318 51.9885 103.812 51.6394 103.253 51.3991C102.694 51.1589 102.092 51.0325 101.484 51.0272C100.875 51.0219 100.271 51.1379 99.7081 51.3683C99.1449 51.5988 98.6331 51.9391 98.2028 52.3695C97.7724 52.7998 97.4321 53.3115 97.2017 53.8748C96.9712 54.4381 96.8552 55.0416 96.8605 55.6502C96.8658 56.2588 96.9923 56.8602 97.2325 57.4194C97.4727 57.9786 97.8218 58.4843 98.2596 58.9071L113.353 74L98.2596 89.0929C97.8218 89.5157 97.4727 90.0215 97.2325 90.5807C96.9923 91.1399 96.8658 91.7413 96.8605 92.3499C96.8552 92.9584 96.9712 93.562 97.2017 94.1252C97.4321 94.6885 97.7724 95.2002 98.2028 95.6306C98.6331 96.0609 99.1449 96.4013 99.7081 96.6317C100.271 96.8622 100.875 96.9781 101.484 96.9728C102.092 96.9676 102.694 96.8411 103.253 96.6009C103.812 96.3607 104.318 96.0115 104.74 95.5738L123.074 77.2404C123.933 76.3809 124.416 75.2154 124.416 74C124.416 72.7847 123.933 71.6191 123.074 70.7596L104.74 52.4263Z"
      fill="white"
    />
    <path
      d="M78.7621 40.6608L60.4288 104.827C60.095 105.996 60.2387 107.249 60.8283 108.311C61.4179 109.373 62.405 110.158 63.5729 110.492C63.9835 110.606 64.4074 110.664 64.8333 110.667C65.8294 110.666 66.798 110.341 67.5928 109.74C68.3875 109.14 68.965 108.297 69.2379 107.339L87.5713 43.1725C87.7623 42.5865 87.8333 41.968 87.7798 41.354C87.7263 40.74 87.5496 40.143 87.2601 39.5989C86.9706 39.0548 86.5744 38.5746 86.0951 38.1871C85.6157 37.7997 85.0632 37.5129 84.4705 37.3439C83.8777 37.1749 83.257 37.1272 82.6454 37.2036C82.0338 37.28 81.444 37.479 80.911 37.7887C80.3781 38.0983 79.9132 38.5123 79.5439 39.0058C79.1747 39.4993 78.9088 40.0622 78.7621 40.6608Z"
      fill="white"
    />
  </Icon>
)
