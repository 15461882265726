export const getRandomDM = (dmFormats: string | any) => {
  try {
    dmFormats = JSON.parse(dmFormats as string)

    const dmFormat = Array.isArray(dmFormats) ? dmFormats[Math.floor(Math.random() * dmFormats.length)] : dmFormats

    if (!dmFormat?.template) {
      return;
    }

    const messages = Object.entries(dmFormat).filter(([key]) => !isNaN(+key))

    let demoExample = dmFormat?.template

    for (let messageIdx = 0; messageIdx < messages.length; messageIdx++) {
      const messagesArr: any = messages[messageIdx][1]
      const randomMsg = messagesArr[Math.floor(Math.random() * messagesArr.length)]
      demoExample = demoExample.replaceAll(`{${messages[messageIdx][0]}}`, randomMsg)
    }

    return demoExample
  } catch (ex) {
    console.error("[error_get-random-dm]:", ex)
  }
}
