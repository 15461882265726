import {SubmitHandler} from 'react-hook-form'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {Props} from './types'
import {useUpdateProfileDetailsMutation} from '@/features/profile'
import {BaseProfileFormData} from '../base-profile-modal/types'
import {BaseProfileModal} from '..'
import {useNavigate, useSearchParams} from 'react-router-dom'

export const EditProfileModal: React.FC<Props> = ({open, close, profile}) => {
  const [updateProfile, {isLoading: isUpdating}] = useUpdateProfileDetailsMutation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const handleRequest = useHandleRequest()

  const onSubmit: SubmitHandler<BaseProfileFormData> = async formData => {
    await handleRequest({
      request: async () => {
        const result = await updateProfile({
          id: profile._id,
          body: {
            profileName: formData.profileName,
            phoneID: formData.phoneID,
            groupID: formData.groupID,
            nicheID: formData.nicheID,
            tags: formData.tags || [],
          },
        })

        return result
      },
      onSuccess: async () => {
        if (profile.phoneID === searchParams.get('phoneID')) {
          navigate(`/manage?phoneID=${formData.phoneID}`)
        }
        close()
      },
    })
  }

  return (
    <BaseProfileModal
      open={open}
      close={close}
      onSubmit={onSubmit}
      isSubmitting={isUpdating}
      defaultValues={{
        tags: profile.tags || [],
        profileName: profile.profileName,
        phoneID: profile.phoneID,
        groupID: profile.group,
        nicheID: profile.niche,
        _id: profile._id,
      }}
      title={`Edit profile (${profile.phoneID})`}
      submitText="Update"
      editing
    />
  )
}
