import React, {useMemo} from 'react'
import {Props, TabValueProps} from './types'
import {Tabs as UITabs, TabsContent, TabsList, TabsTrigger} from '@/components/ui/tabs'

export const Tabs: React.FC<Props> = ({tabs, value, defaultValue, onValueChange, contentClassName}) => {
  const valueProps = useMemo(() => {
    const props: TabValueProps = {}

    if (onValueChange) {
      props.onValueChange = onValueChange
      props.value = value
    }

    if (defaultValue || tabs?.[0]?.value) {
      props.defaultValue = defaultValue || tabs[0].value
    }

    return props
  }, [value, defaultValue, onValueChange, tabs])

  return (
    <UITabs {...valueProps}>
      <TabsList className="w-full gap-1 border border-[#ebedf2] dark:border-secondary p-0 h-max">
        {tabs.map(tab => (
          <TabsTrigger className="w-full py-2" key={tab.value} value={tab.value}>
            {tab.label}
          </TabsTrigger>
        ))}
      </TabsList>
      {tabs.map(tab => (
        <TabsContent key={tab.value} value={tab.value} className={contentClassName}>
          {tab.children}
        </TabsContent>
      ))}
    </UITabs>
  )
}
