import React, {useState} from 'react'
import {Props} from './types'
import {useUpdateRoadmapTaskMutation} from '@/features/roadmap-tasks'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {SubmitHandler} from 'react-hook-form'
import {RoadmapTaskFormData} from '../base-task-form/types'
import {useToast} from '@/components/ui/use-toast'
import {BaseTaskForm} from '..'

export const EditTaskForm: React.FC<Props> = ({open, close, task, contentClassName, fetchTasks}) => {
  const [updateTask] = useUpdateRoadmapTaskMutation()
  const handleRequest = useHandleRequest()
  const {toast} = useToast()
  const [updating, setUpdating] = useState(false)

  const onSubmit: SubmitHandler<RoadmapTaskFormData> = async formData => {
    setUpdating(true)
    await handleRequest({
      request: async () => {
        const result = await updateTask({
          id: task._id,
          body: formData,
        })
        return result
      },
      onSuccess: () => {
        setTimeout(async () => {
          close()
          toast({title: 'Task successfully updated!'})
          await fetchTasks()
          setUpdating(false)
        }, 1000)
      },
    })
  }

  return (
    <BaseTaskForm
      open={open}
      close={close}
      onSubmit={onSubmit}
      isSubmitting={updating}
      title="Edit Task"
      submitText="Update"
      contentClassName={contentClassName}
      defaultValues={{
        taskName: task.taskName,
        description: task.description,
        icon: task.icon,
      }}
    />
  )
}
