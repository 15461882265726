/* eslint-disable react-hooks/exhaustive-deps */
import {DeleteModal, Field, Modal} from '@/components/common'
import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {BaseGroupFormData, Props} from './types'
import {Form} from '@/components/ui/form'
import {useLazyGetAllProfilesQuery} from '@/features/profile'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {Button} from '@/components/ui/button'
import {FaRegTrashCan} from 'react-icons/fa6'
import {useToast} from '@/components/ui/use-toast'
import {useDeleteProfileGroupMutation} from '@/features/profile-group'

export const BaseGroupModal: React.FC<Props> = ({
  open,
  close,
  onSubmit,
  defaultValues,
  submitText,
  title,
  isSubmitting,
  isModalLoading,
  editing,
}) => {
  const [deleteGroup, {isLoading: isDeleting}] = useDeleteProfileGroupMutation()
  const [getAllProfiles, {data: {data: profiles = []} = {}, isLoading}] = useLazyGetAllProfilesQuery()
  const form = useForm<BaseGroupFormData>({
    defaultValues,
  })
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false)
  const handleRequest = useHandleRequest()
  const {toast} = useToast()

  const onDelete = async () => {
    await handleRequest({
      request: async () => {
        const result = await deleteGroup({id: defaultValues?._id as string})
        return result
      },
      onSuccess: async () => {
        toast({title: 'Group successfully deleted!'})
        setOpenDeleteAlert(false)
        close()
      },
    })
  }

  const fetchAllProfiles = async () => {
    await handleRequest({
      request: async () => {
        const result = await getAllProfiles({})
        return result
      },
    })
  }

  useEffect(() => {
    if (open) {
      fetchAllProfiles()
    } else {
      form.reset()
    }
  }, [open])

  useEffect(() => {
    if (defaultValues?.profiles) {
      form.setValue('profiles', (defaultValues?.profiles || []) as any)
    }
  }, [defaultValues])

  return (
    <>
      <Modal open={open} close={close} isLoading={isLoading || isModalLoading} title={title || 'Create New Group'}>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-3 mt-3">
            <Field
              type="input"
              name="groupName"
              control={form.control}
              componentProps={{label: 'Group name', placeholder: 'Enter group name...'}}
            />
            <Field
              type="multi-select"
              name="profiles"
              control={form.control}
              componentProps={{
                options: profiles.map(profile => ({label: profile.profileName, value: profile._id})),
                label: 'Profiles',
                placeholder: 'Select profiles',
              }}
            />
            <div className="flex items-center gap-4 mt-5">
              <Button loading={isSubmitting} htmlType="submit" className="w-full">
                {submitText || 'Create'}
              </Button>
              {editing && (
                <Button
                  onClick={() => setOpenDeleteAlert(true)}
                  variant="outlined-destructive"
                  size="icon"
                  className="hover:text-white !min-w-max w-12"
                >
                  <FaRegTrashCan />
                </Button>
              )}
            </div>
          </form>
        </Form>
      </Modal>
      <DeleteModal
        open={openDeleteAlert}
        close={() => setOpenDeleteAlert(false)}
        onDelete={onDelete}
        isDeleting={isDeleting}
        title={`Are you sure to delete this group (${defaultValues?.groupName})`}
      />
    </>
  )
}
