/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {Props} from './types'
import {Input, Loader, Modal} from '@/components/common'
import {CgProfile} from 'react-icons/cg'
import {Button} from '@/components/ui/button'
import {FaExternalLinkAlt} from 'react-icons/fa'
import {useLazySearchDMQuery} from '@/features/profile-dm'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useDebounce} from 'use-debounce'

export const DMSearchModal: React.FC<Props> = ({open, close}) => {
  const [searchDM, {data: {data: profiles = []} = {}, isFetching}] = useLazySearchDMQuery()
  const [searchQuery, setSearchQuery] = useState('')
  const [debouncedQuery] = useDebounce(searchQuery, 300)
  const handleRequest = useHandleRequest()

  const onSearch = async () => {
    await handleRequest({
      request: async () => {
        const result = await searchDM({params: {search: debouncedQuery}})
        return result
      },
    })
  }

  useEffect(() => {
    if (debouncedQuery.length >= 3) {
      onSearch()
    }
  }, [debouncedQuery])

  return (
    <Modal open={open} close={close} title="DM Search" contentClassName="min-w-[600px]">
      <Input
        label="Search"
        placeholder="Enter search query..."
        labelClassName="mb-1"
        value={searchQuery}
        onChange={event => setSearchQuery(event.target.value)}
      />
      <span className="block text-[8px] mt-1">Please type at least 3 characters to search</span>
      <div className="mt-4">
        {isFetching ? (
          <Loader parentClassName="py-20" />
        ) : profiles.length ? (
          <>
            <p className="text-[10px]">
              Total results: <b>{profiles.length}</b>
            </p>
            <div className="flex flex-col gap-2 mt-2">
              {profiles.map(phoneID => (
                <Button
                  onClick={() => window.open(`/manage?phoneID=${phoneID}`)}
                  key={phoneID}
                  variant="outline"
                  size="sm"
                  className="flex items-center justify-between px-4 text-xs"
                >
                  <div className="flex items-center gap-2">
                    <CgProfile className="text-sm" />
                    <p>{phoneID}</p>
                  </div>
                  <FaExternalLinkAlt />
                </Button>
              ))}
            </div>
          </>
        ) : (
          <div className="text-[10px] text-gray-400 py-12 text-center">
            {searchQuery.length < 3 ? 'Type to search profiles...' : 'No any profiles found for searched query'}
          </div>
        )}
      </div>
    </Modal>
  )
}
