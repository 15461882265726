import {Icon} from '@/components/common/icon'
import {IconProps} from '@/components/common/icon/types'

export const CheckIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 149 149">
    <circle cx="74.1898" cy="74.1898" r="64.1898" fill="#2CD776" stroke="white" strokeWidth="20" />
    <path
      d="M66.6846 97.4974C66.0726 98.1127 65.2379 98.4564 64.3706 98.4564C63.5032 98.4564 62.6686 98.1127 62.0565 97.4974L43.5767 79.0143C41.6587 77.0963 41.6587 73.9868 43.5767 72.0721L45.8908 69.758C47.8088 67.84 50.9149 67.84 52.833 69.758L64.3706 81.2956L95.5466 50.1195C97.4647 48.2015 100.574 48.2015 102.489 50.1195L104.803 52.4336C106.721 54.3516 106.721 57.4611 104.803 59.3758L66.6846 97.4974Z"
      fill="white"
    />
  </Icon>
)
