import React, {useMemo} from 'react'
import {Textarea as UITextarea} from '@/components/ui/textarea'
import {cn} from '@/lib/utils'
import {Props, TextareaValueProps} from './types'

export const Textarea: React.FC<Props> = ({
  label,
  placeholder,
  className,
  parentClassName,
  value,
  defaultValue,
  onChange,
  disabled,
  error,
}) => {
  const valueProps = useMemo(() => {
    const props: TextareaValueProps = {}

    if (onChange) {
      props.value = value
      props.onChange = onChange
    }

    if (defaultValue) {
      props.defaultValue = defaultValue
    }

    return props
  }, [value, defaultValue, onChange])

  return (
    <label className={cn('flex flex-col w-full', parentClassName)}>
      {label && <span className={cn('block text-xs mb-1.5 capitalize', error ? "text-red-600" : "")}>{label}</span>}
      <UITextarea disabled={disabled} className={cn(className, error ? "border-red-600" : "")} placeholder={placeholder} {...valueProps} />
    </label>
  )
}
