/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {Props, StoppedPhoneFormData} from './types'
import {Field, Modal} from '@/components/common'
import {Form} from '@/components/ui/form'
import {SubmitHandler, useFieldArray, useForm} from 'react-hook-form'
import {Button} from '@/components/ui/button'
import {FaPlus, FaRegTrashCan} from 'react-icons/fa6'
import {getValidations, isValidURL} from '@/utils/util-functions'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useUpdateStoppedPhoneMutation} from '@/features/stopped-phones'
import {useToast} from '@/components/ui/use-toast'
import {useUpdateProfileDetailsMutation} from '@/features/profile'

export const StoppedPhoneForm: React.FC<Props> = ({open, close, stoppedPhone}) => {
  const [updateProfile, {isLoading: isUpdatingProfile}] = useUpdateProfileDetailsMutation()
  const [updateStoppedPhone, {isLoading}] = useUpdateStoppedPhoneMutation()
  const form = useForm<StoppedPhoneFormData>({
    defaultValues: {
      reason: stoppedPhone.reason,
      tags: stoppedPhone.tags || [],
    },
  })
  const {fields: tags, append, remove} = useFieldArray({control: form.control, name: 'tags'})
  const handleRequest = useHandleRequest()
  const {toast} = useToast()

  const onSubmit: SubmitHandler<StoppedPhoneFormData> = async formData => {
    let success = true
    await handleRequest({
      request: async () => {
        const result = await updateStoppedPhone({
          id: stoppedPhone._id,
          body: {
            reason: formData.reason || '',
          },
        })
        return result
      },
      onError: () => {
        success = false
      },
    })

    await handleRequest({
      request: async () => {
        const result = await updateProfile({
          id: stoppedPhone.profileID as string,
          body: {
            tags: formData.tags || [],
          },
        })
        return result
      },
      onError: () => {
        success = false
      },
    })

    if (success) {
      close()
      toast({title: 'Phone successfully updated!'})
    }
  }

  useEffect(() => {
    if (stoppedPhone) {
      form.setValue('reason', stoppedPhone.reason)
      form.setValue('tags', stoppedPhone.tags || [])
    }
  }, [stoppedPhone])

  return (
    <Modal open={open} close={close} title={`Settings (${stoppedPhone.username})`} contentClassName="min-w-[600px]">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Field
            type="textarea"
            control={form.control}
            name="reason"
            componentProps={{label: 'Reason of stop', placeholder: 'Host unexpected issue...'}}
          />
          <div className="mt-3 mb-5 border rounded-md px-4 py-2">
            <h2>Tags</h2>
            <div className="my-3 flex flex-col gap-2">
              {tags.length ? (
                tags.map((tag, index) => (
                  <div key={tag.id} className="flex items-end gap-3">
                    <Field
                      type="input"
                      control={form.control}
                      name={`tags.${index}.label`}
                      componentProps={{label: 'Label', placeholder: 'Label...', inputClassName: 'h-10'}}
                      fieldClassName="w-1/2 min-h-[64px] flex items-end"
                      rules={getValidations(true)}
                    />

                    <Field
                      type="input"
                      control={form.control}
                      name={`tags.${index}.link`}
                      componentProps={{label: 'Link', placeholder: 'Link...', inputClassName: 'h-10'}}
                      fieldClassName="w-1/2 min-h-[64px] flex items-end"
                      rules={getValidations(false, false, false, {
                        validate: (value: string) =>
                          value ? (isValidURL(value) ? undefined : 'Please enter correct URL link') : undefined,
                      })}
                    />
                    <Field
                      type="color-select"
                      control={form.control}
                      name={`tags.${index}.color`}
                      componentProps={{label: 'Color', options: []}}
                      fieldClassName="min-w-max"
                      rules={getValidations(true)}
                    />
                    <Button
                      onClick={() => remove(index)}
                      size="icon"
                      variant="outlined-destructive"
                      className="h-10 min-w-[40px]"
                    >
                      <FaRegTrashCan />
                    </Button>
                  </div>
                ))
              ) : (
                <p className="my-3 text-xs text-slate-400 text-center">No any tags yet</p>
              )}
            </div>
            <Button
              onClick={() => append({label: '', color: ''})}
              variant="outline"
              size="sm"
              className="flex items-center gap-2 text-xs w-max ml-auto"
            >
              <FaPlus />
              <span>New Tag</span>
            </Button>
          </div>
          <Button loading={isLoading || isUpdatingProfile} htmlType="submit" className="w-full">
            Save
          </Button>
        </form>
      </Form>
    </Modal>
  )
}
