import React from 'react'
import {Props} from './types'
import {BaseForm} from '..'
import {useCreateProfileNicheMutation} from '@/features/profile-niche'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {SubmitHandler} from 'react-hook-form'
import {NicheBaseFormData} from '../base-form/types'
import {useToast} from '@/components/ui/use-toast'
import {useUpdateProfiles} from '@/hooks/use-update-profiles'
import {CreateNicheResponse} from '@/features/profile-niche/types'

export const CreateForm: React.FC<Props> = ({open, close}) => {
  const [createNiche, {isLoading}] = useCreateProfileNicheMutation()
  const handleRequest = useHandleRequest()
  const {toast} = useToast()
  const [updateProfiles, isUpdatingProfiles] = useUpdateProfiles()

  const onSubmit: SubmitHandler<NicheBaseFormData> = async formData => {
    await handleRequest({
      request: async () => {
        const result = await createNiche({
          nicheName: formData.nicheName,
        })
        return result
      },
      onSuccess: ({data}: {data: CreateNicheResponse}) => {
        toast({title: 'Niche successfully created!'})
        updateProfiles('nicheID', data.data, formData.profiles)
        close()
      },
    })
  }

  return <BaseForm open={open} close={close} onSubmit={onSubmit} isSubmitting={isLoading || isUpdatingProfiles} />
}
