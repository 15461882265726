import React, {useState} from 'react'
import {Props} from './types'
import {TableCell, TableRow} from '@/components/ui/table'
import dayjs from 'dayjs'
import {Button} from '@/components/ui/button'
import {FaCheck, FaExternalLinkAlt} from 'react-icons/fa'
import {FaRegTrashCan} from 'react-icons/fa6'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useArchiveVPSScraperTaskMutation, useCheckVPSScraperTaskCompletionMutation} from '@/features/vps-scraper-tasks'
import {useToast} from '@/components/ui/use-toast'
import {DeleteModal} from '@/components/common'
import {useHandleError} from '@/hooks/use-handle-error'

export const PendingTaskRow: React.FC<Props> = ({task}) => {
  const [checkCompletion, {isLoading: isChecking}] = useCheckVPSScraperTaskCompletionMutation()
  const [archiveTask, {isLoading: isDeleting}] = useArchiveVPSScraperTaskMutation()
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false)
  const handleRequest = useHandleRequest()
  const handleError = useHandleError()
  const {toast} = useToast()

  const onCheckCompletion = async () => {
    await handleRequest({
      request: async () => {
        const result = await checkCompletion({id: task.scriptName, params: {username: task.username}})
        return result
      },
      onSuccess: () => {
        toast({title: 'Success ✅', description: 'Task completion successfully checked'})
      },
      onError: () => {
        handleError('Task failed from parser!')
      },
    })
  }

  const onDelete = async () => {
    await handleRequest({
      request: async () => {
        const result = await archiveTask({id: task.scriptName, params: {username: task.username}})
        return result
      },
      onSuccess: () => {
        toast({title: 'Success ✅', description: 'Task successfully deleted!'})
      },
    })
  }

  return (
    <>
      <TableRow key={task._id}>
        <TableCell className={tdClassName}>{task.username}</TableCell>
        <TableCell className={tdClassName}>{task.taskID}</TableCell>
        <TableCell className={tdClassName}>{dayjs(task.createdAt).format('MMM DD, YYYY hh:mm A')}</TableCell>
        <TableCell className={tdClassName}>
          <div className="flex items-center justify-center gap-3">
            <Button onClick={onCheckCompletion} loading={isChecking} size="icon" className="w-8 h-8">
              <FaCheck className="text-xs" />
            </Button>
            <Button
              onClick={() => window.open(`https://parser.im/api.php/?key=${task.apiKey}&tid=${task.taskID}`)}
              size="icon"
              variant="outline"
              className="w-8 h-8"
            >
              <FaExternalLinkAlt className="text-xs" />
            </Button>
            <Button
              onClick={() => setOpenDeleteAlert(true)}
              size="icon"
              variant="outlined-destructive"
              className="w-8 h-8"
            >
              <FaRegTrashCan className="text-xs" />
            </Button>
          </div>
        </TableCell>
      </TableRow>
      <DeleteModal
        open={openDeleteAlert}
        close={() => setOpenDeleteAlert(false)}
        onDelete={onDelete}
        isDeleting={isDeleting}
        title="Are you sure?"
        description={`Are you sure to delete this task (${task.username}) ?`}
      />
    </>
  )
}

const tdClassName = `!border !border-gray-400 dark:!border-secondary text-center text-gray-800 dark:text-white bg-white dark:bg-background !p-1`
