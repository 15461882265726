import {useState} from 'react'
import {Subaccount} from '@/types/subaccount'
import {AccountLogin, SubaccountLogin} from './components'

export const LoginPage = () => {
  const [subaccounts, setSubaccounts] = useState<Subaccount[] | undefined>()

  return (
    <div className="h-screen overflow-y-auto min-w-max w-full grid place-content-center">
      {subaccounts ? (
        <SubaccountLogin subaccounts={subaccounts} setSubaccounts={setSubaccounts} />
      ) : (
        <AccountLogin setSubaccounts={setSubaccounts} />
      )}
    </div>
  )
}
