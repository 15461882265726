import {Accordion} from '@/components/ui/accordion'
import {CHARTS} from './constants'
import {ChartSection} from './components'

export const ChartsPage = () => (
  <div className="container max-w-[1100px] mx-auto mt-16 mb-4 py-6 bg-white dark:bg-background dark:border dark:border-secondary rounded-xl">
    <h1 className="text-4xl font-bold mb-5">Statistics</h1>
    <Accordion type="multiple" defaultValue={Object.keys(CHARTS).map(chartKey => chartKey)}>
      {Object.keys(CHARTS).map(chartKey => (
        <ChartSection
          key={chartKey}
          value={chartKey}
          label={(chartKey as any).replaceAll('_', ' ')}
          images={(CHARTS as any)[chartKey]}
        />
      ))}
    </Accordion>
  </div>
)
