import {MdManageAccounts} from 'react-icons/md'
import {SiCodereview} from 'react-icons/si'
import {FaChartSimple} from 'react-icons/fa6'
import {RxDashboard} from 'react-icons/rx'
import {IoSettings} from 'react-icons/io5'
import {BsTools} from 'react-icons/bs'

export const HEADER_LINKS = [
  {
    label: 'Manage',
    path: '/manage',
    icon: <MdManageAccounts className="text-lg" />,
  },
  {
    label: 'Dashboard',
    path: '/dashboard',
    icon: <RxDashboard className="text-base" />,
  },
  {
    label: 'Stopped phones',
    path: '/stopped-phones',
    icon: <RxDashboard className="text-base" />,
  },
  {
    label: 'Review',
    path: '/review',
    icon: <SiCodereview className="text-base" />,
  },
  // {
  //   label: 'Dashboard',
  //   path: '/dashboard',
  // },
  // {
  //   label: 'Niches',
  //   path: '/niches',
  //   icon: <HiMiniCheckBadge className="text-lg" />,
  // },
  // {
  //   label: 'Accounts',
  //   path: '/accounts',
  //   roles: [Role.SUPER_ACCOUNT, Role.SUPER_SUBACCOUNT],
  //   icon: <MdAccountCircle className="text-lg" />,
  // },
  // {
  //   label: 'Subaccounts',
  //   path: '/subaccounts',
  //   roles: [Role.SUPER_ACCOUNT, Role.SUPER_SUBACCOUNT],
  //   icon: <FaUserFriends className="text-lg" />,
  // },
  // {
  //   label: 'Blocks',
  //   path: '/blocks',
  // },
  {
    label: 'Statistics',
    path: '/statistics',
    icon: <FaChartSimple />,
  },
  // {
  //   label: 'Roadmap',
  //   path: '/roadmap',
  //   icon: <RiRoadMapFill />,
  // },
  // {
  //   label: 'Growths Tracker',
  //   path: '/growth-tracker',
  //   icon: <FaChartLine />,
  // },
  {
    label: 'More settings',
    path: '/more-settings',
    icon: <IoSettings />,
  },
  {
    label: 'Tools',
    path: '/tools',
    icon: <BsTools />,
  },
]
