import {Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle} from '@/components/ui/dialog'
import {cn} from '@/lib/utils'
import {Props} from './types'
import {Loader} from '..'

export const Modal: React.FC<Props> = ({
  title,
  description,
  children,
  open,
  close,
  className,
  childrenWrapperClassName,
  footer,
  contentClassName,
  actionable = true,
  isLoading,
}) => (
  <Dialog open={open} onOpenChange={close}>
    <DialogContent className={cn('sm:max-w-[425px]', className, contentClassName)} actionable={actionable}>
      {actionable && (
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription className="break-all line-clamp-3">{description}</DialogDescription>
        </DialogHeader>
      )}
      {isLoading ? (
        <Loader parentClassName="h-32" />
      ) : (
        <>
          <div className={cn('max-h-[60vh] overflow-auto p-1', childrenWrapperClassName)}>{children}</div>
          {footer}
        </>
      )}
    </DialogContent>
  </Dialog>
)
