/* eslint-disable react-hooks/exhaustive-deps */
import {cn} from '@/lib/utils'
import React, {useEffect, useState} from 'react'
import {Props} from './types'
import {Spinner} from '..'

export const Switch: React.FC<Props> = ({
  label,
  className,
  onChange,
  checked,
  defaultChecked,
  labelClassname,
  loading,
  buttonClassName,
}) => {
  const [value, setValue] = useState(defaultChecked || checked || false)

  const onSwitch = () => {
    const updatedValue = !value
    setValue(updatedValue)
    if (onChange) {
      onChange(updatedValue)
    }
  }

  useEffect(() => {
    if (typeof checked !== 'undefined' && value !== checked) {
      setValue(checked)
    }
  }, [checked])

  return (
    <button type="button" onClick={onSwitch} className={cn('flex items-center gap-3', buttonClassName)}>
      {loading ? (
        <Spinner className="max-w-[20px] max-h-[20px] !border-4" />
      ) : (
        <span
          className={cn(
            'block relative w-9 h-5 rounded-full duration-150',
            value ? 'bg-blue' : 'bg-blue-300 dark:bg-secondary',
            className,
          )}
        >
          <span
            className={cn(
              'absolute w-4 h-4 top-0.5 bg-white rounded-full duration-150',
              value ? 'left-[18px]' : 'left-0.5',
            )}
          />
        </span>
      )}
      <span className={labelClassname}>{label}</span>
    </button>
  )
}
