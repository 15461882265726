/* eslint-disable react-hooks/exhaustive-deps */
import {SubmitHandler, useFieldArray, useForm} from 'react-hook-form'
import {BaseProfileFormData, Props} from './types'
import {DeleteModal, Field, Modal} from '@/components/common'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {Button} from '@/components/ui/button'
import {useLazyGetAllGroupsQuery} from '@/features/profile-group'
import {useEffect, useState} from 'react'
import {Form} from '@/components/ui/form'
import {SelectNicheTasks} from '../../..'
import {cn} from '@/lib/utils'
import {useLazyGetNichesQuery} from '@/features/profile-niche'
import {useToast} from '@/components/ui/use-toast'
import {useDeleteProfileMutation} from '@/features/profile'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {FaPlus, FaRegTrashCan} from 'react-icons/fa6'
import {getValidations, isValidURL} from '@/utils/util-functions'

export const BaseProfileModal: React.FC<Props> = ({
  open,
  close,
  title,
  submitText,
  onSubmit,
  isSubmitting,
  isModalLoading,
  nicheFunctions,
  defaultValues,
  editing,
}) => {
  const [getGroups, {data: {data: groups = []} = {}, isLoading}] = useLazyGetAllGroupsQuery()
  const [getNiches, {data: {data: niches = []} = {}, isLoading: isFetchingNiches}] = useLazyGetNichesQuery()
  const form = useForm<BaseProfileFormData>({defaultValues})
  const {fields: tags, append, remove} = useFieldArray({control: form.control, name: 'tags'})
  const [selectedNicheID, setSelectedNicheID] = useState<undefined | string>()
  const [selectedBlocks, setSelectedBlocks] = useState<any[]>([])
  const [searchParams] = useSearchParams()
  const [deleteProfile, {isLoading: isDeleting}] = useDeleteProfileMutation()
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false)
  const [showPhoneIDConfirm, setShowPhoneIDConfirm] = useState(false)
  const {toast} = useToast()
  const navigate = useNavigate()
  const handleRequest = useHandleRequest()

  const onDelete = async () => {
    await handleRequest({
      request: async () => {
        const result = await deleteProfile({id: defaultValues?._id as string})
        return result
      },
      onSuccess: async () => {
        if (defaultValues?.phoneID === searchParams.get('phoneID')) {
          navigate('/manage')
        }
        toast({title: 'Profile successfully deleted!'})
        setOpenDeleteAlert(false)
        close()
      },
    })
  }

  const onEditProfile = async () => {
    onSubmit(form.getValues())
    setShowPhoneIDConfirm(false)
  }

  const onFormSubmit: SubmitHandler<BaseProfileFormData> = formData => {
    if (defaultValues?.phoneID !== formData.phoneID) {
      setShowPhoneIDConfirm(true)
      return
    }

    onSubmit(form.getValues())
  }

  const fetchGroups = async () => {
    await handleRequest({
      request: async () => {
        const result = await getGroups({})
        return result
      },
    })
  }

  const fetchNiches = async () => {
    await handleRequest({
      request: async () => {
        const result = await getNiches({})
        return result
      },
    })
  }

  useEffect(() => {
    form.setValue('nicheID', selectedNicheID as string)
  }, [selectedNicheID])

  useEffect(() => {
    form.setValue('selectedBlocks', selectedBlocks)
  }, [selectedBlocks])

  useEffect(() => {
    if (open) {
      fetchGroups()

      if (!nicheFunctions) {
        fetchNiches()
      }
    } else {
      form.reset()
    }
  }, [open])

  return (
    <>
      <Modal
        open={open}
        close={close}
        isLoading={isLoading || isFetchingNiches || isModalLoading}
        title={title || 'Create New Profile'}
        contentClassName={nicheFunctions ? 'sm:max-w-[70vw]' : 'min-w-[700px]'}
      >
        <Form {...form}>
          <form onSubmit={form.handleSubmit(editing ? onFormSubmit : onSubmit)} className="flex flex-col gap-3 mb-5">
            <div className={cn('flex', !nicheFunctions ? 'flex-col gap-2' : 'items-center gap-5')}>
              <Field
                type="input"
                rules={{required: true}}
                name="profileName"
                control={form.control}
                componentProps={{label: 'Profile name', placeholder: 'Enter profile name...', labelClassName: 'mt-1'}}
              />
              <Field
                type="input"
                rules={getValidations(true, 3, 30)}
                name="phoneID"
                control={form.control}
                componentProps={{label: 'Phone ID', placeholder: 'Enter phone id...', labelClassName: 'mt-1'}}
              />
              <Field
                type="select"
                name="groupID"
                control={form.control}
                componentProps={{
                  label: 'Groups',
                  placeholder: 'Select group',
                  options: groups.map(group => ({label: group.groupName, value: group._id})),
                  labelClassName: cn('block', nicheFunctions && 'w-[200px]'),
                }}
              />
              {!nicheFunctions && (
                <Field
                  type="select"
                  name="nicheID"
                  control={form.control}
                  componentProps={{
                    label: 'Niches',
                    placeholder: 'Select niche',
                    options: niches.map(niche => ({label: niche.nicheName, value: niche._id})),
                    labelClassName: 'block',
                  }}
                />
              )}
              {editing && (
                <div className="mt-3 mb-5 border rounded-md px-4 py-2">
                  <h2>Tags</h2>
                  <div className="my-3 flex flex-col gap-2">
                    {tags.length ? (
                      tags.map((badge, index) => (
                        <div key={badge.id} className="flex items-end gap-3">
                          <Field
                            type="input"
                            control={form.control}
                            name={`tags.${index}.label`}
                            componentProps={{label: 'Label', placeholder: 'Label...', inputClassName: 'h-10'}}
                            fieldClassName="w-1/2 min-h-[64px] flex items-end"
                            rules={getValidations(true)}
                          />

                          <Field
                            type="input"
                            control={form.control}
                            name={`tags.${index}.link`}
                            componentProps={{label: 'Link', placeholder: 'Link...', inputClassName: 'h-10'}}
                            fieldClassName="w-1/2 min-h-[64px] flex items-end"
                            rules={getValidations(false, false, false, {
                              validate: (value: string) =>
                                value ? (isValidURL(value) ? undefined : 'Please enter correct URL link') : undefined,
                            })}
                          />
                          <Field
                            type="color-select"
                            control={form.control}
                            name={`tags.${index}.color`}
                            componentProps={{label: 'Color', options: []}}
                            fieldClassName="min-w-max"
                            rules={getValidations(true)}
                          />
                          <Button
                            onClick={() => remove(index)}
                            size="icon"
                            variant="outlined-destructive"
                            className="h-10 min-w-[40px]"
                          >
                            <FaRegTrashCan />
                          </Button>
                        </div>
                      ))
                    ) : (
                      <p className="my-3 text-xs text-slate-400 text-center">No any tags yet</p>
                    )}
                  </div>
                  <Button
                    onClick={() => append({label: '', color: ''})}
                    variant="outline"
                    size="sm"
                    className="flex items-center gap-2 text-xs w-max ml-auto"
                  >
                    <FaPlus />
                    <span>New Tag</span>
                  </Button>
                </div>
              )}

              <div className="flex items-center gap-4 mt-5">
                <Button
                  htmlType="submit"
                  loading={isSubmitting}
                  className={cn(nicheFunctions ? 'w-[200px]' : 'w-full')}
                >
                  {submitText || 'Create Profile'}
                </Button>
                {editing && (
                  <Button
                    onClick={() => setOpenDeleteAlert(true)}
                    variant="outlined-destructive"
                    size="icon"
                    className="hover:text-white !min-w-max w-12"
                  >
                    <FaRegTrashCan />
                  </Button>
                )}
              </div>
            </div>
          </form>
          {nicheFunctions && (
            <div>
              <SelectNicheTasks
                selectedNicheID={selectedNicheID}
                selectedBlocks={selectedBlocks}
                setSelectedNicheID={setSelectedNicheID}
                setSelectedBlocks={setSelectedBlocks}
              />
            </div>
          )}
        </Form>
      </Modal>
      {editing && (
        <DeleteModal
          open={openDeleteAlert}
          close={() => setOpenDeleteAlert(false)}
          onDelete={onDelete}
          isDeleting={isDeleting}
          title={`Are you sure to delete this profile (${defaultValues?.phoneID}) ?`}
        />
      )}
      {editing && (
        <DeleteModal
          open={showPhoneIDConfirm}
          close={() => setShowPhoneIDConfirm(false)}
          title="Are you sure ?"
          description={`You're changing phoneID of this profile, phoneID changes might effect to loose all data of host for this profile (${defaultValues?.profileName})`}
          onDelete={onEditProfile}
          isDeleting={false}
          deleteButtonVariant="outline"
          deleteButtonText="I'm 100% sure!"
        />
      )}
    </>
  )
}
