/* eslint-disable react-hooks/exhaustive-deps */
import {Loader} from '@/components/common'
import {RTKTagName} from '@/constants/rtk-tag-name'
import {baseApi} from '@/features'
import {useAppDispatch} from '@/store/hooks'
import {storage} from '@/utils/storage'
import {useEffect} from 'react'

export const LogoutPage = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    storage.removeAccessToken()
    dispatch(baseApi.util.invalidateTags([RTKTagName.USER]))
    window.location.pathname = '/auth/login'
  }, [])

  return <Loader parentClassName="fixed top-0 left-0 w-full h-full" />
}
