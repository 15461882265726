import React, {useState} from 'react'
import {Props} from './types'
import {TableCell, TableRow} from '@/components/ui/table'
import {Button} from '@/components/ui/button'
import {IoMdEye, IoMdEyeOff} from 'react-icons/io'
import {IoSettings} from 'react-icons/io5'
import {useUpdateStoppedPhoneMutation} from '@/features/stopped-phones'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useToast} from '@/components/ui/use-toast'
import {StoppedPhoneForm} from './components'
import {Link} from 'react-router-dom'
import {cn} from '@/lib/utils'

export const StoppedPhoneRow: React.FC<Props> = ({stoppedPhone}) => {
  const [updateStoppedPhone, {isLoading}] = useUpdateStoppedPhoneMutation()
  const handleRequest = useHandleRequest()
  const {toast} = useToast()
  const [openSettingsModal, setOpenSettingsModal] = useState(false)

  const onHide = async () => {
    await handleRequest({
      request: async () => {
        const result = await updateStoppedPhone({id: stoppedPhone._id, body: {hidden: !stoppedPhone.hidden}})
        return result
      },
      onSuccess: () => {
        toast({title: 'Phone status successfully updated'})
      },
    })
  }

  const onClickBadge = (link?: string) => {
    if (link) {
      window.open(link)
    }
  }

  return (
    <TableRow>
      <TableCell>
        <Link to={`/manage?phoneID=${stoppedPhone.username}`} className="hover:underline">
          {stoppedPhone.username || <span className="text-sm text-slate-400">N/A</span>}
        </Link>
      </TableCell>
      <TableCell>
        {stoppedPhone.tags?.length ? (
          <div className="flex flex-wrap gap-x-2 gap-y-1 max-w-[300px]">
            {stoppedPhone.tags.map((tag, index) => (
              <div
                onClick={() => onClickBadge(tag.link)}
                key={index}
                style={{background: tag.color}}
                className={cn(
                  'px-4 py-1 rounded-sm w-max text-white',
                  tag.link ? 'cursor-pointer hover:underline' : '',
                )}
              >
                {tag.label}
              </div>
            ))}
          </div>
        ) : (
          <span className="text-sm text-slate-400">N/A</span>
        )}
      </TableCell>
      <TableCell>{stoppedPhone.reason || <span className="text-sm text-slate-400">N/A</span>}</TableCell>
      <TableCell>
        <div className="flex items-center justify-center gap-2">
          <Button onClick={onHide} loading={isLoading} variant="outline" size="sm" className="flex items-center gap-2">
            {stoppedPhone.hidden ? <IoMdEye /> : <IoMdEyeOff />}
            <span>{stoppedPhone.hidden ? 'Unhide' : 'Hide'}</span>
          </Button>
          <Button
            onClick={() => setOpenSettingsModal(true)}
            variant="outline"
            size="icon"
            className="flex items-center gap-2"
          >
            <IoSettings />
          </Button>
        </div>
        <StoppedPhoneForm
          open={openSettingsModal}
          close={() => setOpenSettingsModal(false)}
          stoppedPhone={stoppedPhone}
        />
      </TableCell>
    </TableRow>
  )
}
