import React, {useMemo} from 'react'
import {cn} from '@/lib/utils'
import {DropdownOpenProps, Props} from './types'
import {Button} from '@/components/ui/button'
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from '@/components/ui/dropdown-menu'

export const Dropdown: React.FC<Props> = ({
  open,
  onOpenChange,
  label,
  contentClassName,
  buttonClassName,
  triggerBtnProps = {},
  buttons = [],
}) => {
  const openProps = useMemo(() => {
    const props: DropdownOpenProps = {}

    if (onOpenChange) {
      props.open = open
      props.onOpenChange = onOpenChange
    }

    return props
  }, [open, onOpenChange])

  return (
    <DropdownMenu {...openProps}>
      {label ? (
        <DropdownMenuTrigger asChild>
          <Button variant="outline" {...triggerBtnProps}>
            {label}
          </Button>
        </DropdownMenuTrigger>
      ) : (
        ''
      )}
      <DropdownMenuContent className={cn('w-56', contentClassName)}>
        {buttons?.map((button, idx) => (
          <DropdownMenuItem
            key={idx}
            onClick={button.onClick}
            className={cn(`cursor-pointer ${idx !== buttons.length - 1 && 'mb-2'}`, buttonClassName, button.className)}
          >
            {button.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
