/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {Props} from './types'
import {useCompleteTaskMutation} from '@/features/scraping-task'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {Spinner} from '@/components/common'
import {BsFillCheckCircleFill} from 'react-icons/bs'
import {MdError} from 'react-icons/md'

export const TaskRow: React.FC<Props> = ({start, onComplete, task, index}) => {
  const [completeTask, {isError, isSuccess, isLoading}] = useCompleteTaskMutation()
  const handleRequest = useHandleRequest()

  const fetchCompleteTask = async () => {
    await handleRequest({
      request: async () => {
        const result = await completeTask({id: task._id})
        return result
      },
      onSuccess: onComplete,
      onError: onComplete,
    })
  }

  useEffect(() => {
    if (start && !isSuccess) {
      fetchCompleteTask()
    }
  }, [start])

  return (
    <div className="flex items-center justify-between">
      <span>
        {index + 1}. {task.username}
      </span>
      {isLoading ? (
        <Spinner className="!border-blue !w-4 !h-4 !border-[3px]" />
      ) : isError ? (
        <MdError className="text-red-600" />
      ) : isSuccess ? (
        <BsFillCheckCircleFill className="text-green-600 bg-white rounded-full" />
      ) : (
        ''
      )}
    </div>
  )
}
