export const CHARTS = {
  line_graphs: [
    '/LineGraphs/follow_block.png',
    '/LineGraphs/accounts_bans.png',
    '/LineGraphs/DMS_count_linegraph.png',
    '/LineGraphs/following_count_linegraph.png',
  ],
  heat_map: ['/HeatMap/CorrelationGraph.png'],
  histogram_box_plot: [
    '/HistogramAndBoxplot/HBP_of_account_age_with_outliers.png',
    '/HistogramAndBoxplot/HBP_of_current_session_followings_with_outliers.png',
    '/HistogramAndBoxplot/HBP_of_dms_sent_count_with_outliers.png',
    '/HistogramAndBoxplot/HBP_of_explorer_scrolls_count_with_outliers.png',
    '/HistogramAndBoxplot/HBP_of_followers_count_with_outliers.png',
    '/HistogramAndBoxplot/HBP_of_following_count_with_outliers.png',
    '/HistogramAndBoxplot/HBP_of_home_scrolls_count_with_outliers.png',
    '/HistogramAndBoxplot/HBP_of_posts_count_with_outliers.png',
  ],
  scratter_plots: [
    '/ScatterPlots/scatterplot_account_age.png',
    '/ScatterPlots/scatterplot_current_session_followings.png',
    '/ScatterPlots/scatterplot_dms_sent_count.png',
    '/ScatterPlots/scatterplot_explorer_scrolls_count.png',
    '/ScatterPlots/scatterplot_followers_count.png',
    '/ScatterPlots/scatterplot_following_count.png',
    '/ScatterPlots/scatterplot_home_scrolls_count.png',
    '/ScatterPlots/scatterplot_posts_count.png',
  ],
  bar_graphs: [
    '/BarsGraphs/dm_container_1.png',
    '/BarsGraphs/dm_container_2.png',
    '/BarsGraphs/dm_container_3.png',
    '/BarsGraphs/dm_container_4.png',
    '/BarsGraphs/dm_container_5.png',
    '/BarsGraphs/dm_container_6.png',
    '/BarsGraphs/dm_container_7.png',
    '/BarsGraphs/dm_container_8.png',
    '/BarsGraphs/dm_container_9.png',
    '/BarsGraphs/dm_container_10.png',
    '/BarsGraphs/dm_container_11.png',
  ],
  banned_accounts_scratter_plots: [
    '/ScatterPlots/ban_scatterplot_account_age.png',
    '/ScatterPlots/ban_scatterplot_current_session_followings.png',
    '/ScatterPlots/ban_scatterplot_dms_sent_count.png',
    '/ScatterPlots/ban_scatterplot_explorer_scrolls_count.png',
    '/ScatterPlots/ban_scatterplot_followers_count.png',
    '/ScatterPlots/ban_scatterplot_following_count.png',
    '/ScatterPlots/ban_scatterplot_home_scrolls_count.png',
    '/ScatterPlots/ban_scatterplot_posts_count.png',
  ],
  banned_accounts_histogram_box_plot: [
    '/HistogramAndBoxplot/ban_HBP_of_account_age_with_outliers.png',
    '/HistogramAndBoxplot/ban_HBP_of_current_session_followings_with_outliers.png',
    '/HistogramAndBoxplot/ban_HBP_of_dms_sent_count_with_outliers.png',
    '/HistogramAndBoxplot/ban_HBP_of_explorer_scrolls_count_with_outliers.png',
    '/HistogramAndBoxplot/ban_HBP_of_followers_count_with_outliers.png',
    '/HistogramAndBoxplot/ban_HBP_of_following_count_with_outliers.png',
    '/HistogramAndBoxplot/ban_HBP_of_home_scrolls_count_with_outliers.png',
    '/HistogramAndBoxplot/ban_HBP_of_posts_count_with_outliers.png',
  ],
}
