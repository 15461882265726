import {EndpointNameRoadmapTasks} from '@/constants/endpoints'
import {baseApi} from '..'
import {RTKTagName} from '@/constants/rtk-tag-name'
import {
  CreateRoadmapTaskRequest,
  CreateRoadmapTaskResponse,
  DeleteRoadmapTaskRequest,
  DeleteRoadmapTaskResponse,
  GetRoadmapTasksRequest,
  GetRoadmapTasksResponse,
  UpdateRoadmapTaskRequest,
  UpdateRoadmapTaskResponse,
  UpdateRoadmapTasksOrderRequest,
  UpdateRoadmapTasksOrderResponse,
} from './types'

const RoadmapTasksExtendedEndpoints = baseApi.injectEndpoints({
  endpoints: build => ({
    getRoadmapTasks: build.query<GetRoadmapTasksResponse, GetRoadmapTasksRequest>({
      query: () => ({
        url: EndpointNameRoadmapTasks.ROADMAP_TASKS,
        method: 'GET',
      }),
      providesTags: [RTKTagName.ROADMAP_TASK],
    }),

    createRoadmapTask: build.mutation<CreateRoadmapTaskResponse, CreateRoadmapTaskRequest>({
      query: body => ({
        url: EndpointNameRoadmapTasks.ROADMAP_TASKS,
        method: 'POST',
        body,
      }),
      invalidatesTags: [RTKTagName.ROADMAP_TASK],
    }),

    updateRoadmapTask: build.mutation<UpdateRoadmapTaskResponse, UpdateRoadmapTaskRequest>({
      query: ({id, body}) => ({
        url: EndpointNameRoadmapTasks.ROADMAP_TASK + id,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [RTKTagName.ROADMAP_TASK],
    }),

    updateRoadmapTaskOrder: build.mutation<UpdateRoadmapTasksOrderResponse, UpdateRoadmapTasksOrderRequest>({
      query: ({id, body}) => ({
        url: EndpointNameRoadmapTasks.ROADMAP_TASK_ORDER + id,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [RTKTagName.ROADMAP_TASK],
    }),

    deleteRoadmapTask: build.mutation<DeleteRoadmapTaskResponse, DeleteRoadmapTaskRequest>({
      query: ({id}) => ({
        url: EndpointNameRoadmapTasks.ROADMAP_TASK + id,
        method: 'DELETE',
      }),
      invalidatesTags: [RTKTagName.ROADMAP_TASK],
    }),
  }),
})

export const {
  useLazyGetRoadmapTasksQuery,
  useCreateRoadmapTaskMutation,
  useUpdateRoadmapTaskMutation,
  useDeleteRoadmapTaskMutation,
  useUpdateRoadmapTaskOrderMutation,
} = RoadmapTasksExtendedEndpoints
