import {EndpointNameClientProfile} from '@/constants/endpoints'
import {baseApi} from '..'
import {RTKTagName} from '@/constants/rtk-tag-name'
import {
  DeleteClientProfileRequest,
  DeleteClientProfileResponse,
  GetAllClientProfilesRequest,
  GetAllClientProfilesResponse,
  GetClientBlacklistedProfilesRequest,
  GetClientBlacklistedProfilesResponse,
  GetClientProfilesAndGroupsRequest,
  GetClientProfilesAndGroupsResponse,
  SendClientReportRequest,
  SendClientReportResponse,
  UpdateClientBlacklistedProfilesRequest,
  UpdateClientBlacklistedProfilesResponse,
  UpdateClientProfileRequest,
  UpdateClientProfileResponse,
} from './types'

const AuthExtendedEndpoints = baseApi.injectEndpoints({
  endpoints: build => ({
    getClientProfilesAndGroups: build.query<GetClientProfilesAndGroupsResponse, GetClientProfilesAndGroupsRequest>({
      query: params => ({
        url: EndpointNameClientProfile.GET_CLIENT_PROFILES_AND_GROUPS,
        method: 'GET',
        params,
      }),
      providesTags: [RTKTagName.CLIENT_PROFILE],
    }),

    updateClientProfile: build.mutation<UpdateClientProfileResponse, UpdateClientProfileRequest>({
      query: ({id, body}) => ({
        url: EndpointNameClientProfile.CLIENT_PROFILE + id,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [RTKTagName.CLIENT_PROFILE],
    }),

    deleteClientProfile: build.mutation<DeleteClientProfileResponse, DeleteClientProfileRequest>({
      query: ({id}) => ({
        url: EndpointNameClientProfile.CLIENT_PROFILE + id,
        method: 'DELETE',
      }),
      invalidatesTags: [RTKTagName.CLIENT_PROFILE],
    }),

    getAllClientProfiles: build.query<GetAllClientProfilesResponse, GetAllClientProfilesRequest>({
      query: () => ({
        url: EndpointNameClientProfile.ALL_CLIENT_PROFILES,
        method: 'GET',
      }),
      providesTags: [RTKTagName.CLIENT_PROFILE],
    }),

    getClientsBlacklist: build.query<GetClientBlacklistedProfilesResponse, GetClientBlacklistedProfilesRequest>({
      query: () => ({
        url: EndpointNameClientProfile.BLACKLIST,
        method: 'GET',
      }),
      providesTags: [RTKTagName.CLIENT_PROFILE],
    }),

    updateClientsBlacklist: build.mutation<
      UpdateClientBlacklistedProfilesResponse,
      UpdateClientBlacklistedProfilesRequest
    >({
      query: body => ({
        url: EndpointNameClientProfile.BLACKLIST,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [RTKTagName.CLIENT_PROFILE],
    }),

    sendClientReport: build.mutation<SendClientReportResponse, SendClientReportRequest>({
      query: ({id}) => ({
        url: EndpointNameClientProfile.SEND_CLIENT_REPORT + id,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useGetClientProfilesAndGroupsQuery,
  useUpdateClientProfileMutation,
  useDeleteClientProfileMutation,
  useLazyGetAllClientProfilesQuery,
  useLazyGetClientsBlacklistQuery,
  useUpdateClientsBlacklistMutation,
  useSendClientReportMutation,
} = AuthExtendedEndpoints
