import {useUpdateClientProfileMutation} from '@/features/client-profiles'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useState} from 'react'

export const useUpdateProfiles = (): [
  (key: string, value: string, addingProfileIDs: string[], removingProfileIDs: string[]) => Promise<void>,
  boolean,
] => {
  const [updateProfileData] = useUpdateClientProfileMutation()
  const [isLoading, setIsLoading] = useState(false)
  const handleRequest = useHandleRequest()

  const updateProfile = async (profileID: string, key: string, value: string) => {
    await handleRequest({
      request: async () => {
        const result = await updateProfileData({id: profileID, body: {[key]: value}})
        return result
      },
    })
  }

  return [
    async (key: string, value: string, addingProfileIDs: string[] = [], removingProfileIDs: string[] = []) => {
      setIsLoading(true)

      for (const addingProfileID of addingProfileIDs) {
        await updateProfile(addingProfileID, key, value)
      }

      for (const removingProfileID of removingProfileIDs) {
        await updateProfile(removingProfileID, key, 'false')
      }

      setIsLoading(false)
    },
    isLoading,
  ]
}
