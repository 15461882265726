import {cn} from '@/lib/utils'
import {useState} from 'react'
import {AiFillDelete, AiOutlineClose} from 'react-icons/ai'
import {BiEditAlt} from 'react-icons/bi'
import {BsCheckLg} from 'react-icons/bs'
import {Props} from './types'
import {EditNicheModal} from '..'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useDeleteProfileNicheMutation} from '@/features/profile-niche'
import {useToast} from '@/components/ui/use-toast'
import {DeleteModal} from '@/components/common'

export const NicheCard: React.FC<Props> = ({niche, isActive, isSelected, onClick, selectNiche, unselectNiche}) => {
  const [deleteNiche, {isLoading: isDeleting}] = useDeleteProfileNicheMutation()
  const [openEditForm, setOpenEditForm] = useState(false)
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false)
  const handleRequest = useHandleRequest()
  const {toast} = useToast()

  const onDelete = async () => {
    await handleRequest({
      request: async () => {
        const result = await deleteNiche({id: niche._id})
        return result
      },
      onSuccess: () => {
        toast({title: 'Niche successfully deleted!'})
        setOpenDeleteAlert(false)
      },
    })
  }

  return (
    <div
      key={niche._id}
      className="group flex justify-between border border-muted-foreground dark:border-secondary rounded-lg px-3 py-2"
    >
      <button
        type="button"
        onClick={onClick}
        className={cn(
          'border border-main-blue w-full text-start rounded-md mr-4 py-1 px-2',
          isActive && 'bg-main-blue text-white',
        )}
      >
        {niche.nicheName}
        {/* {niche.profiles?.length ? `- (${niche.profiles?.length} profile${niche.profiles?.length < 2 ? '' : 's'})` : ''} */}
      </button>
      <div className="flex items-center gap-2">
        {isSelected ? (
          <button
            onClick={() => unselectNiche()}
            type="button"
            className="w-8 h-8 rounded-lg border border-destructive bg-destructive text-white hover:bg-destructive/90 duration-150 grid place-content-center"
          >
            <AiOutlineClose />
          </button>
        ) : (
          <button
            onClick={() => selectNiche(niche._id)}
            type="button"
            className={`w-8 h-8 rounded-lg border border-main-blue text-white bg-main-blue hover:bg-main-blue/80 duration-150 grid place-content-center ${'opacity-0 scale-90 group-hover:scale-100 group-hover:opacity-100'}`}
          >
            <BsCheckLg />
          </button>
        )}
        <button
          onClick={() => setOpenEditForm(true)}
          type="button"
          className="w-8 h-8 rounded-lg border border-main-blue text-main-blue hover:bg-main-blue hover:text-white duration-150 grid place-content-center"
        >
          <BiEditAlt />
        </button>
        <button
          onClick={() => setOpenDeleteAlert(true)}
          type="button"
          className="w-8 h-8 rounded-lg border border-destructive text-destructive hover:bg-destructive hover:text-white duration-150 grid place-content-center"
        >
          <AiFillDelete />
        </button>
      </div>
      <EditNicheModal open={openEditForm} close={() => setOpenEditForm(false)} niche={niche} />
      <DeleteModal
        open={openDeleteAlert}
        close={() => setOpenDeleteAlert(false)}
        title={`Are you sure to delete this niche (${niche.nicheName}) ?`}
        onDelete={onDelete}
        isDeleting={isDeleting}
      />
    </div>
  )
}
