import {TableCell, TableRow} from '@/components/ui/table'
import React from 'react'
import {Props} from './types'
import {FaRegTrashCan} from 'react-icons/fa6'
import {Button} from '@/components/ui/button'

export const CSVRow: React.FC<Props> = ({rowValue, onChange, onRemove}) => (
  <TableRow>
    <TableCell className={tableCellClassName}>
      <input
        value={rowValue[0] || ''}
        onChange={event => onChange(0, event.target.value)}
        className={inputClassName}
        placeholder="Username"
      />
    </TableCell>
    <TableCell className={tableCellClassName}>
      <input
        value={rowValue[1] || ''}
        onChange={event => onChange(1, event.target.value)}
        className={inputClassName}
        placeholder="Password"
      />
    </TableCell>
    <TableCell className={tableCellClassName}>
      <input
        value={rowValue[2] || ''}
        onChange={event => onChange(2, event.target.value)}
        className={inputClassName}
        placeholder="Bio"
      />
    </TableCell>
    <TableCell className={tableCellClassName}>
      <input
        value={rowValue[3] || ''}
        onChange={event => onChange(3, event.target.value)}
        className={inputClassName}
        placeholder="Country"
      />
    </TableCell>
    <TableCell className={tableCellClassName}>
      <div className="flex items-center justify-center">
        <Button
          onClick={onRemove}
          variant="outlined-destructive"
          size="icon"
          className="hover:text-white rounded h-7 w-7 text-xs"
        >
          <FaRegTrashCan />
        </Button>
      </div>
    </TableCell>
  </TableRow>
)

const inputClassName =
  'bg-white dark:bg-background border border-transparent px-3 py-2 focus:border-blue dark:focus w-full text-xs'
const tableCellClassName = '!border dark:border-secondary border-zinc-400 !p-0 bg-white dark:bg-background'
