import {Icon} from '@/components/common/icon'
import {IconProps} from '@/components/common/icon/types'

export const GlobalIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 149 149">
    <circle cx="74.1898" cy="74.1898" r="74.1898" fill="#2CD776" />
    <g clipPath="url(#clip0_2202_4)">
      <mask
        id="mask0_2202_4"
        style={{maskType: 'luminance'}}
        maskUnits="userSpaceOnUse"
        x="31"
        y="30"
        width="85"
        height="85"
      >
        <path d="M31 30H116V115H31V30Z" fill="white" />
      </mask>
      <g mask="url(#mask0_2202_4)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M90.0877 64C90.3428 66.6733 90.5 69.491 90.5 72.5C90.5 73.6271 90.052 74.7083 89.2552 75.5052C88.4583 76.3021 87.3771 76.75 86.25 76.75C85.1229 76.75 84.0417 76.3021 83.2448 75.5052C82.448 74.7083 82 73.6271 82 72.5C82 69.4698 81.8343 66.6435 81.5538 64H65.391C64.8003 69.651 64.7946 75.3479 65.374 81H73.5C74.6271 81 75.7083 81.448 76.5052 82.2448C77.302 83.0417 77.75 84.1229 77.75 85.25C77.75 86.3771 77.302 87.4583 76.5052 88.2552C75.7083 89.0521 74.6271 89.5 73.5 89.5H66.768C68.7613 98.34 71.8765 103.993 73.5043 106.5C73.5043 106.5 76.8575 106.555 77.4398 106.509C78.5622 106.436 79.668 106.807 80.5189 107.543C81.3697 108.278 81.8971 109.319 81.9872 110.44C82.0693 111.563 81.7017 112.674 80.9651 113.527C80.2286 114.379 79.1839 114.905 78.0602 114.987C77.6608 115.017 73.5 115 73.5 115C50.0655 115 31 95.9345 31 72.5C31 49.0613 50.0655 30 73.5 30C96.9345 30 116 49.0613 116 72.5C116 74.1108 115.847 75.6833 115.664 77.243C115.496 78.331 114.912 79.3111 114.035 79.9775C113.159 80.6434 112.058 80.9443 110.965 80.816C109.871 80.6876 108.87 80.1407 108.172 79.2898C107.473 78.439 107.132 77.3501 107.219 76.2528C107.368 75.016 107.5 73.775 107.5 72.5C107.5 69.559 107.083 66.72 106.382 64H90.0877ZM73.449 38.653C71.8085 41.2285 68.774 46.8598 66.802 55.5H80.1343C78.1538 46.8598 75.098 41.2285 73.449 38.653ZM40.6178 81H56.8485C56.602 78.3268 56.4447 75.5175 56.4447 72.517C56.4475 69.6732 56.5836 66.8311 56.8528 64H40.6178C39.9123 66.72 39.5 69.559 39.5 72.5C39.5106 75.3692 39.8862 78.2256 40.6178 81ZM44.0985 89.5C48.2954 96.7407 55.0063 102.187 62.9557 104.804C60.7412 99.9095 59.1039 94.7734 58.0767 89.5H44.0985ZM58.0853 55.5C59.3645 49.1208 61.209 44.0123 62.9727 40.1873C55.0154 42.8024 48.2976 48.2525 44.0985 55.5H58.0853ZM88.851 55.5H102.897C98.6808 48.2184 91.9229 42.7514 83.921 40.149C86.1624 45.0542 87.8174 50.2069 88.851 55.5Z"
          fill="white"
        />
        <path
          d="M95.6986 110.518L84.8607 86.6742C84.0481 84.8866 85.8871 83.0481 87.6746 83.8602L111.518 94.6986C113.145 95.4381 113.188 97.7344 111.589 98.5338L104.186 102.235C103.774 102.441 103.441 102.774 103.236 103.185L99.5338 110.589C98.7344 112.188 96.4385 112.145 95.6986 110.518Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2202_4">
        <rect width="85" height="85" fill="white" transform="translate(31 30)" />
      </clipPath>
    </defs>
  </Icon>
)
