/* eslint-disable react-hooks/exhaustive-deps */
import {useLazyGetProfilesAndGroupsQuery} from '@/features/profile'
import {Settings, Sidebar} from './components'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useEffect, useState} from 'react'
import {useAppDispatch} from '@/store/hooks'
import {GetProfilesAndGroupsResponseData} from '@/features/profile/types'
import {ProfileType} from '@/constants/status'
import {RTKTagName} from '@/constants/rtk-tag-name'
import {baseApi} from '@/features'
import {useNavigate, useSearchParams} from 'react-router-dom'

export const ManagePage = () => {
  const [getProfilesAndGroups, {data: {data: profilesData = undefined} = {}, isLoading, isFetching}] =
    useLazyGetProfilesAndGroupsQuery()
  const handleRequest = useHandleRequest()
  const [profiles, setProfiles] = useState<GetProfilesAndGroupsResponseData>({
    groupsCount: 0,
    profilesCount: 0,
    ungroupedProfilesCount: 0,
    profiles: [],
    groups: [],
  })
  const [currentPage, setCurrentPage] = useState(1)
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [filters, setFilters] = useState({
    accountCreation: false,
    multiAccounts: false,
    dmOnly: false,
  })

  const fetchProfilesAndGroups = async (
    page: number = currentPage,
    search: string = searchQuery,
    fetchFilters: any = filters,
  ) => {
    await handleRequest({
      request: async () => {
        const result = await getProfilesAndGroups({
          search,
          page,
          profileType: ProfileType.NORMAL,
          ...fetchFilters,
        })
        return result
      },
    })
  }

  const refetchProfiles = (pageNum = 1, fetchFilters = filters) => {
    if (pageNum === 1) {
      setLoading(true)
      dispatch(baseApi.util.invalidateTags([RTKTagName.PROFILES]))
    }
    setCurrentPage(pageNum)
    setTimeout(() => {
      fetchProfilesAndGroups(pageNum, searchQuery, fetchFilters)
    }, 1000)
  }

  useEffect(() => {
    if (profilesData) {
      setProfiles(
        currentPage === 1
          ? profilesData
          : prev => ({
              ...prev,
              ...profilesData,
              groups: [...prev.groups, ...(profilesData as any).groups],
              profiles: [...prev.profiles, ...(profilesData as any).profiles],
            }),
      )
      if ((profilesData as any).profiles[0]?.phoneID && !searchParams.get('phoneID')) {
        navigate(`/manage?phoneID=${(profilesData as any).profiles[0]?.phoneID}`)
      }

      setLoading(false)
    }
  }, [profilesData])

  return (
    <div className="flex gap-5 py-[15px] px-[22px] h-screen">
      <Sidebar
        refetchProfiles={refetchProfiles}
        isLoading={isLoading || loading}
        isFetching={isFetching}
        profiles={profiles}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        currentPage={currentPage}
        filters={filters}
        setFilters={setFilters}
      />
      <div className="ml-[370px] w-full">
        <Settings />
      </div>
    </div>
  )
}
