import {LogoIcon} from '@/icons'
import {useGetUserQuery} from '@/features/auth'
import {Link, useLocation} from 'react-router-dom'
import {HEADER_LINKS} from './constants'
import {cn} from '@/lib/utils'
import React from 'react'
import {Button} from '@/components/ui/button'
import {LuLogOut} from 'react-icons/lu'
import {MdDarkMode, MdLightMode} from 'react-icons/md'
import {useTheme} from '@/components/ui/theme-provider'

export const Header = () => {
  const location = useLocation()
  const {data: {data: user} = {}} = useGetUserQuery('')
  const {isDarkMode, setTheme} = useTheme()

  return (
    <>
      <div className="bg-white dark:bg-background rounded-2xl px-8 py-4 z-20 border flex items-center justify-between fixed top-[15px] left-[22px] w-[calc(100%-44px)]">
        <Link to={location.pathname.includes('/manage') ? location.pathname + location.search : '/manage'}>
          <LogoIcon className="w-40 h-10" />
        </Link>
        <div className="flex items-center gap-1 text-sm">
          {HEADER_LINKS.map(link => (
            <React.Fragment key={link.path}>
              {/* {(link
                ? link.roles?.includes(user?.account.role) && link.roles?.includes(user?.subaccount.role)
                : true) && ( */}
              <Link
                key={link.path}
                to={link.path}
                className={cn(
                  'duration-150 px-3 py-2 rounded-md font-medium flex items-center gap-2',
                  location.pathname === link.path
                    ? 'bg-[#ebedf2] dark:bg-secondary text-blue dark:text-white'
                    : 'hover:bg-[#ebedf2] dark:hover:bg-secondary hover:text-blue dark:hover:text-white',
                )}
              >
                {link.icon}
                {link.label}
              </Link>
              {/* )} */}
            </React.Fragment>
          ))}
        </div>
        <div className="flex items-center gap-5">
          <div>
            <span>
              Hey <b>{user?.subaccount.username}</b>
            </span>
            <span className="text-lg">👋🏻</span>
          </div>
          <Button
            variant="outline"
            onClick={() => setTheme(isDarkMode ? 'light' : 'dark')}
            className="h-9 px-1 w-9 text-sm"
          >
            {isDarkMode ? <MdDarkMode /> : <MdLightMode />}
          </Button>
          <Link to="/auth/logout">
            <Button size="icon" variant="outlined-destructive" className="h-9 px-1 w-9 text-sm">
              <LuLogOut />
            </Button>
          </Link>
        </div>
      </div>
      <div className="h-[89px]" />
    </>
  )
}
