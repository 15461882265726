import {Table, TableBody, TableHead, TableHeader, TableRow} from '@/components/ui/table'
import React from 'react'
import {Props} from './types'
import {ScrapingTaskRow} from './components'

export const ScrapingTasksSection: React.FC<Props> = ({label, tasks}) => (
  <div>
    <h2 className="text-xl font-bold mb-2">{label}</h2>
    <div className="max-h-[500px] overflow-y-auto">
      <Table className="border-collapse">
        <TableHeader>
          <TableRow>
            <TableHead className={thClassName}>Username</TableHead>
            <TableHead className={thClassName}>Type</TableHead>
            <TableHead className={thClassName}>ID</TableHead>
            <TableHead className={thClassName}>Progress on Parser</TableHead>
            <TableHead className={thClassName}>Date and Time (upload on Parser)</TableHead>
            <TableHead className={thClassName}>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {tasks.map(task => (
            <ScrapingTaskRow key={task._id} task={task} />
          ))}
        </TableBody>
      </Table>
    </div>
  </div>
)

const thClassName = `sticky top-0 bg-white dark:bg-background border-b border border-gray-400 dark:border-secondary text-center text-black dark:text-white font-bold`
