import {Routes, Route, Navigate} from 'react-router-dom'
import {LoginPage, LogoutPage} from './components/pages/auth'
import {
  ChartsPage,
  DashboardPage,
  HomePage,
  ManagePage,
  MoreSettingsPage,
  ReviewPage,
  StoppedPhones,
  ToolsPage,
} from './components/pages'

export const AppRouter = () => (
  <Routes>
    <Route path="/" Component={HomePage} />
    <Route path="/auth">
      <Route path="login" Component={LoginPage} />
      <Route path="logout" Component={LogoutPage} />
    </Route>

    <Route path="/manage" Component={ManagePage} />
    <Route path="/dashboard" Component={DashboardPage} />
    <Route path="/stopped-phones" Component={StoppedPhones} />
    <Route path="/review" Component={ReviewPage} />
    <Route path="/statistics" Component={ChartsPage} />
    <Route path="/more-settings" Component={MoreSettingsPage} />
    <Route path="/tools" Component={ToolsPage} />
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
)
