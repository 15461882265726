import {useCreateProfileNicheMutation} from '@/features/profile-niche'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {Props} from './types'
import {SubmitHandler} from 'react-hook-form'
import {BaseProfileNicheFormData} from '../base-niche-modal/types'
import {BaseNicheModal} from '..'

export const CreateNicheModal: React.FC<Props> = ({open, close}) => {
  const [createNiche, {isLoading}] = useCreateProfileNicheMutation()
  const handleRequest = useHandleRequest()

  const onSubmit: SubmitHandler<BaseProfileNicheFormData> = async formData => {
    await handleRequest({
      request: async () => {
        const result = await createNiche({nicheName: formData.nicheName})
        return result
      },
      onSuccess: () => {
        close()
      },
    })
  }

  return (
    <BaseNicheModal open={open} close={close} title="Create New Niche" onSubmit={onSubmit} isSubmitting={isLoading} />
  )
}
