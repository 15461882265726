import {IconProps} from '@/components/common/icon/types'
import {
  CodeIcon,
  DebugIcon,
  FilesIcon,
  GlobalIcon,
  HeartIcon,
  PartyIcon,
  PencilIcon,
  ResizeIcon,
  TranslatorIcon,
} from '.'
import {CheckIcon} from './check'
import {RoadmapTaskIcon} from '@/types/roadmap-task'
import React from 'react'

interface Props extends IconProps {
  type: RoadmapTaskIcon
}

export const RoadmapIcon: React.FC<Props> = ({type, ...props}) => {
  switch (type) {
    case RoadmapTaskIcon.CHECK:
      return <CheckIcon {...props} />
    case RoadmapTaskIcon.CODE:
      return <CodeIcon {...props} />
    case RoadmapTaskIcon.DEBUG:
      return <DebugIcon {...props} />
    case RoadmapTaskIcon.FILES:
      return <FilesIcon {...props} />
    case RoadmapTaskIcon.GLOBAL:
      return <GlobalIcon {...props} />
    case RoadmapTaskIcon.HEART:
      return <HeartIcon {...props} />
    case RoadmapTaskIcon.PARTY:
      return <PartyIcon {...props} />
    case RoadmapTaskIcon.PENCIL:
      return <PencilIcon {...props} />
    case RoadmapTaskIcon.RESIZE:
      return <ResizeIcon {...props} />
    case RoadmapTaskIcon.TRANSLATOR:
      return <TranslatorIcon {...props} />
    default:
      return ''
  }
}
