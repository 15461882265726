import {Loader} from '@/components/common'
import {Accordion} from '@/components/ui/accordion'
import {Button} from '@/components/ui/button'
import {useGetNichesQuery, useLazyGetNicheProfilesTasksQuery} from '@/features/profile-niche'
import {useHandleRequest} from '@/hooks/use-handle-request'
import React, {useState} from 'react'
import {CreateNicheModal, NicheBlock, NicheCard} from './components'
import {Props} from './types'
import {GetNicheProfilesTasksResponse} from '@/features/profile-niche/types'

export const SelectNicheTasks: React.FC<Props> = ({
  selectedNicheID,
  setSelectedNicheID,
  selectedBlocks,
  setSelectedBlocks,
  profile,
  onClone,
  isCloning,
}) => {
  const {data: {data: niches = []} = {}, isFetching: isFetchingNiches} = useGetNichesQuery('')
  const [getNicheTasks, {isFetching: isFetchingNicheTasks}] = useLazyGetNicheProfilesTasksQuery()
  const [searchQuery, setSearchQuery] = useState('')
  const [activeNicheID, setActiveNicheID] = useState('')
  const handleRequest = useHandleRequest()
  const [nicheBlocks, setNicheBlocks] = useState<any[]>([])
  const [openCreateModal, setOpenCreateModal] = useState(false)

  const fetchNicheProfileTasks = async (nicheID: string) => {
    await handleRequest({
      request: async () => {
        const result = await getNicheTasks({id: nicheID})
        return result
      },
      onSuccess: ({data}: {data: GetNicheProfilesTasksResponse}) => {
        if (data.data?.length) {
          const tasksPhones = []

          for (const task of data.data) {
            const existedIdx = tasksPhones.findIndex(taskEl => taskEl.phoneID === task.phoneID)
            if (existedIdx === -1) {
              tasksPhones.push({
                phoneID: task.phoneID,
                tasks: [task],
              })
            } else {
              tasksPhones[existedIdx].tasks.push(task)
            }
          }

          const currPhoneBlockIdx = tasksPhones.findIndex(block => block.phoneID === profile?.phoneID)
          if (currPhoneBlockIdx !== -1) {
            tasksPhones.splice(currPhoneBlockIdx, 1)
          }
          setNicheBlocks(tasksPhones)
        }
      },
    })
  }

  const onNicheClick = (nicheID: string) => {
    setActiveNicheID(nicheID)
    fetchNicheProfileTasks(nicheID)
  }

  const selectNicheTask = (phoneID: string, nicheTask: any) => {
    setNicheBlocks(prev => {
      const updatedBlocks = [...prev] as any

      const currPhoneBlockIdx = updatedBlocks.findIndex((block: any) => block.phoneID === phoneID)
      if (updatedBlocks[currPhoneBlockIdx]) {
        const currNicheTaskIdx = updatedBlocks[currPhoneBlockIdx].tasks.findIndex(
          (task: any) => task.id === nicheTask.id,
        )
        if (currNicheTaskIdx !== -1) {
          updatedBlocks[currPhoneBlockIdx].tasks.splice(currNicheTaskIdx, 1)

          return updatedBlocks.filter((block: any) => block.tasks.length)
        }
      }

      return prev
    })
    setSelectedBlocks(prev => {
      const updatedBlocks = [...prev] as any

      const currPhoneBlockIdx = updatedBlocks.findIndex((block: any) => block.phoneID === phoneID)
      if (currPhoneBlockIdx !== -1) {
        updatedBlocks[currPhoneBlockIdx].tasks.push(nicheTask)
      } else {
        updatedBlocks.push({
          tasks: [nicheTask],
          phoneID,
        })
      }

      return updatedBlocks
    })
  }

  const unselectNicheTask = (phoneID: string, nicheTask: any) => {
    setNicheBlocks(prev => {
      const updatedBlocks = [...prev] as any

      const currPhoneBlockIdx = updatedBlocks.findIndex((block: any) => block.phoneID === phoneID)
      if (currPhoneBlockIdx !== -1) {
        updatedBlocks[currPhoneBlockIdx].tasks.push(nicheTask)
      } else {
        updatedBlocks.push({
          tasks: [nicheTask],
          phoneID,
        })
      }

      return updatedBlocks
    })
    setSelectedBlocks(prev => {
      const updatedBlocks = [...prev] as any

      const currPhoneBlockIdx = updatedBlocks.findIndex((block: any) => block.phoneID === phoneID)
      if (updatedBlocks[currPhoneBlockIdx]) {
        const currNicheTaskIdx = updatedBlocks[currPhoneBlockIdx].tasks.findIndex(
          (task: any) => task.id === nicheTask.id,
        )
        if (currNicheTaskIdx !== -1) {
          updatedBlocks[currPhoneBlockIdx].tasks.splice(currNicheTaskIdx, 1)

          return updatedBlocks.filter((block: any) => block.tasks.length)
        }
      }

      return prev
    })
  }

  return (
    <div className="max-h-full w-full flex gap-10">
      <div className="w-2/5">
        <h4 className="mb-2 text-lg font-medium">Total Niches ({niches?.length || 0})</h4>
        <div className="relative border border-muted-foreground dark:border-secondary rounded-xl h-44 overflow-auto">
          <div className="sticky top-0 z-10 bg-white dark:bg-background border-b border-b-slate-400 dark:border-secondary">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              className="w-full px-5 py-3 dark:bg-background"
            />
          </div>
          <div className="px-5 py-3">
            {isFetchingNiches ? (
              <Loader parentClassName="h-full" />
            ) : niches?.length ? (
              <div className="flex flex-col gap-2">
                {niches
                  .filter(niche => niche.nicheName.toLowerCase().trim().includes(searchQuery.toLowerCase().trim()))
                  .map(niche => (
                    <NicheCard
                      key={niche._id}
                      niche={niche}
                      isActive={activeNicheID === niche._id}
                      isSelected={selectedNicheID === niche._id}
                      selectNiche={() => setSelectedNicheID(niche._id)}
                      unselectNiche={() => setSelectedNicheID('')}
                      onClick={() => onNicheClick(niche._id)}
                    />
                  ))}
              </div>
            ) : (
              <div className="font-medium grid place-content-center absolute top-0 left-0 w-full h-full text-muted-foreground">
                Niches not existed
              </div>
            )}
          </div>
        </div>
        <Button
          onClick={() => setOpenCreateModal(true)}
          className="w-full mt-4 bg-[#1677ff] border-[#1677ff] hover:bg-[#046afa] text-white duration-150"
        >
          Create Niche
        </Button>
      </div>
      <div className="w-3/5 flex gap-3">
        <div className="w-1/2">
          <h4 className="mb-2 text-lg font-medium">Completed tasks ({nicheBlocks?.length || 0})</h4>
          <div className="relative border border-muted-foreground dark:border-secondary rounded-xl px-5 py-1 h-44 overflow-auto">
            {isFetchingNicheTasks ? (
              <Loader parentClassName="h-full" />
            ) : nicheBlocks?.length ? (
              <Accordion type="multiple" defaultValue={nicheBlocks.map((nicheBlock: any) => nicheBlock.phoneID)}>
                {nicheBlocks?.map((nicheBlock: any) => (
                  <NicheBlock key={nicheBlock.phoneID} nicheBlock={nicheBlock} onClick={selectNicheTask} />
                ))}
              </Accordion>
            ) : (
              <div className="font-medium grid place-content-center absolute top-0 left-0 w-full h-full text-muted-foreground">
                Completed tasks not existed
              </div>
            )}
          </div>
        </div>
        <div className="w-1/2">
          <h4 className="mb-2 text-lg font-medium">Selected tasks ({selectedBlocks?.length || 0})</h4>
          <div className="relative border border-muted-foreground dark:border-secondary rounded-xl px-5 py-1 h-44 overflow-auto">
            {selectedBlocks?.length ? (
              <Accordion type="multiple" defaultValue={nicheBlocks.map((nicheBlock: any) => nicheBlock.phoneID)}>
                {selectedBlocks?.map((nicheBlock: any) => (
                  <NicheBlock key={nicheBlock.phoneID} nicheBlock={nicheBlock} onClick={unselectNicheTask} isAdded />
                ))}
              </Accordion>
            ) : (
              <div className="font-medium grid place-content-center absolute top-0 left-0 w-full h-full text-muted-foreground">
                Selected tasks not existed
              </div>
            )}
          </div>
          {onClone && (
            <Button onClick={onClone} loading={isCloning} className="w-full mt-4">
              Clone Tasks
            </Button>
          )}
        </div>
      </div>
      <CreateNicheModal open={openCreateModal} close={() => setOpenCreateModal(false)} />
    </div>
  )
}
