import React, {useState} from 'react'
import {Props} from './types'
import {useUserData} from '@/hooks/use-user-data'
import dayjs from 'dayjs'
import {cn} from '@/lib/utils'
import {BiDotsVerticalRounded} from 'react-icons/bi'
import {DeleteModal, SmallDropdown} from '@/components/common'
import {FaEdit} from 'react-icons/fa'
import {FaRegTrashCan} from 'react-icons/fa6'
import {useDeleteRoadmapNoteMutation} from '@/features/roadmap-notes'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useToast} from '@/components/ui/use-toast'
import {EditRoadmapNoteForm} from './components'

export const RoadmapNoteCard: React.FC<Props> = ({note}) => {
  const [deleteNote, {isLoading: isDeleting}] = useDeleteRoadmapNoteMutation()
  const [openEditForm, setOpenEditForm] = useState(false)
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)
  const user = useUserData()
  const handleRequest = useHandleRequest()
  const {toast} = useToast()

  const onDelete = async () => {
    await handleRequest({
      request: async () => {
        const result = await deleteNote({id: note._id})
        return result
      },
      onSuccess: () => {
        toast({title: 'Note successfully deleted!'})
      },
    })
  }

  return (
    <div
      className={cn(
        'px-4 py-2 rounded-lg w-max max-w-[80%]',
        user.subaccount.username === note.subaccount.username
          ? 'ml-auto bg-blue text-white'
          : 'bg-[#ebedf2] dark:bg-secondary',
      )}
    >
      <div
        className={cn(
          'flex items-center justify-between gap-4 text-[8px] font-semibold',
          user.subaccount.username === note.subaccount.username ? '' : 'text-slate-400',
        )}
      >
        <span>{note.subaccount.username}</span>
        <div className="flex items-center gap-1">
          <span>{dayjs(note.createdAt).format('MM.DD HH:mm')}</span>
          {user.subaccount.username === note.subaccount.username && (
            <SmallDropdown
              label={
                <button type="button" className="text-lg">
                  <BiDotsVerticalRounded />
                </button>
              }
              buttons={[
                {label: 'Edit Note', icon: <FaEdit />, onClick: () => setOpenEditForm(true)},
                {
                  label: 'Delete Note',
                  icon: <FaRegTrashCan />,
                  className:
                    'text-red-600 hover:!bg-red-100 dark:hover:!bg-red-900 hover:!text-red-600 dark:hover:!text-white',
                  onClick: () => setOpenDeleteConfirm(true),
                },
              ]}
            />
          )}
        </div>
      </div>
      <p className="mt-1 text-sm break-words">{note.description}</p>
      <DeleteModal
        open={openDeleteConfirm}
        close={() => setOpenDeleteConfirm(false)}
        title={`Are you sure to delete this note ?`}
        description={note.description}
        onDelete={onDelete}
        isDeleting={isDeleting}
      />
      <EditRoadmapNoteForm open={openEditForm} close={() => setOpenEditForm(false)} note={note} />
    </div>
  )
}
