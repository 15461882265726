import React, {useMemo, useState} from 'react'
import {Props} from './types'
import {Table, TableBody, TableHead, TableHeader, TableRow} from '@/components/ui/table'
import {ArrowDownIcon} from '@radix-ui/react-icons'
import {cn} from '@/lib/utils'
import {ServerTaskRow} from './components'

export const ServerTasksSection: React.FC<Props> = ({label, tasks, restorable, profile}) => {
  const [fbrSortingMethod, setFBRSortingMethod] = useState(1)

  const sortedTasks = useMemo(
    () =>
      [...tasks].sort((a, b) => {
        if (fbrSortingMethod === 1) {
          if (+a.FBR < +b.FBR) {
            return -1
          }
          if (+a.FBR > +b.FBR) {
            return 1
          }
        } else {
          if (+a.FBR > +b.FBR) {
            return -1
          }
          if (+a.FBR < +b.FBR) {
            return 1
          }
        }

        return 0
      }),
    [tasks, fbrSortingMethod],
  )
  return (
    <div>
      <h2 className="text-xl font-bold mb-2">{label}</h2>
      <div className="max-h-[500px] overflow-y-auto">
        <Table className="border-collapse">
          <TableHeader>
            <TableRow>
              <TableHead className={thClassName}>File name</TableHead>
              <TableHead className={thClassName}>Source Used </TableHead>
              <TableHead className={thClassName}>Followed Users</TableHead>
              <TableHead className={thClassName}>DMed Users</TableHead>
              <TableHead className={thClassName}>Users in File</TableHead>
              <TableHead className={thClassName}>
                <button
                  type="button"
                  onClick={() => setFBRSortingMethod(prev => (prev === 1 ? -1 : 1))}
                  className="flex items-center justify-center gap-2 w-full"
                >
                  <span>FBR</span>
                  <ArrowDownIcon className={cn('duration-150', fbrSortingMethod === 1 ? '-rotate-180' : '')} />
                </button>
              </TableHead>
              <TableHead className={thClassName}>Created At</TableHead>
              <TableHead className={thClassName}>Created By</TableHead>
              <TableHead className={thClassName}>Action</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sortedTasks.map(task => (
              <ServerTaskRow key={task.filename + task.id} task={task} restorable={restorable} profile={profile} />
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

const thClassName = `sticky top-0 bg-white dark:bg-background border-b border border-gray-400 dark:border-secondary text-center text-black dark:text-white font-bold`
