export const PlusIcon = (props: any) => (
  <svg width="33" height="33" viewBox="0 0 33 33" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M24.8749 17.3125H8.67495C8.12145 17.3125 7.66245 16.8535 7.66245 16.3C7.66245 15.7465 8.12145 15.2875 8.67495 15.2875H24.8749C25.4284 15.2875 25.8874 15.7465 25.8874 16.3C25.8874 16.8535 25.4284 17.3125 24.8749 17.3125Z"
      fill="currentColor"
    />
    <path
      d="M16.775 25.4125C16.2215 25.4125 15.7625 24.9535 15.7625 24.4V8.2C15.7625 7.6465 16.2215 7.1875 16.775 7.1875C17.3285 7.1875 17.7875 7.6465 17.7875 8.2V24.4C17.7875 24.9535 17.3285 25.4125 16.775 25.4125Z"
      fill="currentColor"
    />
  </svg>
)
