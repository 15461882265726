/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {Button} from '@/components/ui/button'
import {IoMdSettings} from 'react-icons/io'
import {FcFolder, FcOpenedFolder} from 'react-icons/fc'
import {Props} from './types'
import {Loader} from '@/components/common'
import {useLazyGetGroupProfilesQuery} from '@/features/profile-group'
import {EditGroupModal, ProfileCard} from '..'
import {useHandleRequest} from '@/hooks/use-handle-request'

export const GroupCard: React.FC<Props> = ({group, filters}) => {
  const [openGroup, setOpenGroup] = useState(false)
  const [getGroupProfiles, {data: {data: profiles = []} = {}, isFetching}] = useLazyGetGroupProfilesQuery()
  const [openEditForm, setOpenEditForm] = useState(false)
  const handleRequest = useHandleRequest()

  const fetchGroupProfiles = async () => {
    await handleRequest({
      request: async () => {
        const result = await getGroupProfiles({id: group._id, params: filters})
        return result
      },
    })
  }

  useEffect(() => {
    if (openGroup) {
      fetchGroupProfiles()
    }
  }, [openGroup])

  return (
    <div className="mt-2 border border-[#ebedf2] dark:border-secondary rounded-md">
      <div className="flex items-center justify-between gap-2">
        <Button
          onClick={() => setOpenGroup(prev => !prev)}
          variant="outline"
          className={`text-black relative flex items-center justify-start h-9 w-full border-0 text-xs ${
            openGroup && ''
          }`}
        >
          <span className="text-lg mr-2">{openGroup ? <FcOpenedFolder /> : <FcFolder />}</span> {group.groupName}
        </Button>
        <div className="flex gap-2">
          <Button onClick={() => setOpenEditForm(true)} variant="outline" className="h-9 border-0" size="icon">
            <IoMdSettings />
          </Button>
          {/* <Dropdown
            label={}
            triggerBtnProps={{
              size: 'icon',
              variant: 'outline',
              className: cn('w-9 h-8', 'bg-white text-[#1677ff] border-[#1677ff] hover:bg-[#1677ff]'),
            }}
            contentClassName="bg-white border-white text-[#1677ff]"
            buttonClassName="hover:!bg-[#1677ff] hover:!text-white"
            buttons={[
              {label: 'Edit', onClick: () => setOpenEditForm(true)},
              {
                label: 'Delete',
                className: 'bg-destructive hover:!bg-red-600 !text-white',
                onClick: () => setOpenDeleteAlert(true),
              },
            ]}
          /> */}
        </div>
      </div>
      <div
        className="overflow-hidden duration-150 px-4"
        style={{
          height:
            (openGroup ? (isFetching ? 110 : (profiles?.length || 1) * (profiles?.length > 1 ? 44 : 36) + 40) : 0) +
            'px',
        }}
      >
        {isFetching ? (
          <div className="h-[110px] relative">
            <Loader parentClassName="absolute" />
          </div>
        ) : (
          <>
            {profiles?.length ? (
              <p className="text-[10px] mt-2 mb-1 text-black dark:text-white">Profiles: {profiles?.length}</p>
            ) : (
              ''
            )}
            <div className="space-y-2">
              {profiles?.length ? (
                profiles.map(profile => <ProfileCard key={profile._id} profile={profile} inGroup />)
              ) : (
                <div className="grid place-content-center text-muted-foreground h-20 dark:text-white">No profiles</div>
              )}
            </div>
          </>
        )}
      </div>
      <EditGroupModal open={openEditForm} close={() => setOpenEditForm(false)} group={group} />
    </div>
  )
}
