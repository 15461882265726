/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {BaseAccountsFormData, Props} from './types'
import {Field, Loader} from '@/components/common'
import {Accordion, AccordionContent, AccordionItem, AccordionTrigger} from '@/components/ui/accordion'
import {Button} from '@/components/ui/button'
import {useLazyGetVPSScriptAccountsFileDataQuery, useUpdateVPSScriptFileDataMutation} from '@/features/vps-scripts'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {Form} from '@/components/ui/form'
import {SubmitHandler, useForm} from 'react-hook-form'
import {GetVPSScriptAccountsFileDataResponse} from '@/features/vps-scripts/types'
import {useToast} from '@/components/ui/use-toast'

export const CustomScriptAccountsCard: React.FC<Props> = ({script}) => {
  const [updateFileData, {isLoading}] = useUpdateVPSScriptFileDataMutation()
  const [getAccounts, {isFetching}] = useLazyGetVPSScriptAccountsFileDataQuery()
  const form = useForm<BaseAccountsFormData>({
    defaultValues: {
      accounts: '',
    },
  })
  const [fetched, setFetched] = useState(false)
  const [open, setOpen] = useState(false)
  const handleRequest = useHandleRequest()
  const {toast} = useToast()

  const onSubmit: SubmitHandler<BaseAccountsFormData> = async formData => {
    await handleRequest({
      request: async () => {
        const file = new Blob([formData.accounts], {type: 'text/plain'})
        const body = new FormData()
        body.append('file', file)

        const result = await updateFileData({
          id: script._id || script.username,
          body,
          params: {key: 'accounts', ...(script.converted ? {converted: true} : {})},
        })
        return result
      },
      onSuccess: () => {
        toast({title: 'Accounts successfully saved!'})
      },
    })
  }

  const fetchAccounts = async () => {
    await handleRequest({
      request: async () => {
        const result = await getAccounts({
          id: script._id || script.username,
          params: script.converted ? {converted: true} : {},
        })
        return result
      },
      onSuccess: ({data}: {data: GetVPSScriptAccountsFileDataResponse}) => {
        form.setValue('accounts', data.data)
        setFetched(true)
      },
    })
  }

  useEffect(() => {
    if (open && !fetched) {
      fetchAccounts()
    }
  }, [open])

  return (
    <>
      <Accordion type="multiple" className="w-full">
        <AccordionItem value="accounts" className="mb-3 border rounded">
          <AccordionTrigger
            onClick={() => setOpen(prev => !prev)}
            className="px-6 hover:no-underline [&[data-state=open]]:border-b"
          >
            Accounts
          </AccordionTrigger>
          <AccordionContent className="px-6 py-4 overflow-auto">
            {isFetching ? (
              <Loader parentClassName="py-10" />
            ) : (
              <>
                {/* <Button onClick={() => setAPILinks(true)} variant="outline" className="mb-4 ml-auto block text-xs">
                  See API Links
                </Button> */}
                <Form {...form}>
                  <form onSubmit={form.handleSubmit(onSubmit)}>
                    <Field
                      type="textarea"
                      control={form.control}
                      name="accounts"
                      componentProps={{
                        placeholder: `Account1:Password1\nAccount2:Password2`,
                        className: 'h-[200px]',
                      }}
                    />
                    <Button loading={isLoading} htmlType="submit" className="w-full mt-3">
                      Save
                    </Button>
                  </form>
                </Form>
              </>
            )}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      {/* <APILinksForm open={apiLinks} close={() => setAPILinks(false)} script={script} form={form} onSave={onSubmit} /> */}
    </>
  )
}
