import React, {useState} from 'react'
import {Props} from './types'
import {
  useDeleteProfileImagesMutation,
  useGetProfileImagesQuery,
  useUploadProfileImagesZIPMutation,
} from '@/features/profile'
import {DeleteModal, Loader} from '@/components/common'
import {Button} from '@/components/ui/button'
import {FaCheck, FaRegTrashCan} from 'react-icons/fa6'
import {cn} from '@/lib/utils'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useToast} from '@/components/ui/use-toast'
import {MdDriveFolderUpload} from 'react-icons/md'
import {useHandleError} from '@/hooks/use-handle-error'
import {IoClose, IoSend} from 'react-icons/io5'

export const ProfilePhotos: React.FC<Props> = ({profile}) => {
  const [uploadZIP, {isLoading: isUploading}] = useUploadProfileImagesZIPMutation()
  const [deleteImages, {isLoading: isDeleting}] = useDeleteProfileImagesMutation()
  const {data: {data: profileImages = []} = {}, isFetching} = useGetProfileImagesQuery({id: profile._id})
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false)
  const [selecteds, setSelecteds] = useState<string[]>([])
  const [zipFile, setZIPFile] = useState<File | undefined>()
  const handleRequest = useHandleRequest()
  const handleError = useHandleError()
  const {toast} = useToast()

  const onSelect = (imageURL: string) => {
    setSelecteds(prev => {
      const updatedSelecteds = [...prev]
      const existedIndex = prev.findIndex(image => image === imageURL)
      if (existedIndex === -1) {
        updatedSelecteds.push(imageURL)
        return updatedSelecteds
      }

      updatedSelecteds.splice(existedIndex, 1)
      return updatedSelecteds
    })
  }

  const onDelete = async () => {
    await handleRequest({
      request: async () => {
        const result = await deleteImages({
          id: profile._id,
          params: {
            images: selecteds,
          },
        })
        return result
      },
      onSuccess: () => {
        toast({title: 'Images successfully deleted!'})
        setOpenDeleteAlert(false)
        setSelecteds([])
      },
    })
  }

  const onUpload = async () => {
    if (zipFile) {
      await handleRequest({
        request: async () => {
          const formData = new FormData()
          formData.append('file', zipFile)

          const result = await uploadZIP({id: profile._id, body: formData})
          return result
        },
        onSuccess: () => {
          toast({title: 'ZIP successfully uploaded!'})
        },
      })
      setZIPFile(undefined)
    }
  }

  const onFileUpload = (event: any) => {
    const file = event.target.files?.[0]
    if (!file?.type?.includes('zip')) {
      event.target.value = ''
      return handleError('Only CSV files accepted')
    }

    setZIPFile(file)
  }

  return (
    <div className="bg-white dark:bg-background dark:border dark:border-secondary px-9 py-7 rounded-lg mt-5 h-screen max-h-[69.5vh] relative">
      {isFetching ? (
        <Loader parentClassName="absolute top-0 left-0 w-full h-full" />
      ) : (
        <div>
          <div className="flex items-center justify-between mb-5">
            <h2 className="text-xl font-semibold">Uploaded Images ({profileImages.length})</h2>
            <div className="flex items-center gap-2">
              {zipFile ? (
                <>
                  <Button loading={isUploading} size="sm" className="gap-1.5 px-3 h-8 text-xs" onClick={onUpload}>
                    <span>Send {zipFile.name}</span>
                    <IoSend />
                  </Button>
                  {!isUploading && (
                    <button type="button" onClick={() => setZIPFile(undefined)}>
                      <IoClose className="text-xl" />
                    </button>
                  )}
                </>
              ) : (
                <label>
                  <span className="text-xs bg-blue text-primary-foreground hover:bg-blue/90 inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 text-white gap-1.5 px-3 h-8">
                    <MdDriveFolderUpload className="text-sm" />
                    <span>Upload ZIPed Images</span>
                  </span>
                  <input type="file" accept=".zip" hidden onChange={onFileUpload} />
                </label>
              )}
              <Button
                onClick={() => setOpenDeleteAlert(true)}
                disabled={!selecteds.length}
                size="sm"
                variant="outlined-destructive"
                className="hover:text-white flex items-center gap-1.5 text-xs px-3 h-8 rounded"
              >
                <FaRegTrashCan />
                <span>Delete Selecteds ({selecteds.length})</span>
              </Button>
            </div>
          </div>
          <div className="max-h-[55vh] overflow-y-auto grid grid-cols-5 gap-3 border border-[#ebedf2] dark:border-secondary p-4 rounded-lg">
            {profileImages?.length ? (
              profileImages.map(imagePath => (
                <div
                  key={imagePath}
                  onClick={() => onSelect(imagePath)}
                  className={cn(
                    'relative border rounded-md h-64 cursor-pointer duration-150',
                    selecteds.includes(imagePath)
                      ? 'border-blue border-[8px]'
                      : 'border-[#ebedf2] dark:border-secondary',
                  )}
                >
                  <img
                    src={`https://myinstaapi.lat/Images%20Folder%20Container/${profile.phoneID}/${imagePath}`}
                    alt={imagePath}
                    className="w-full h-full object-cover rounded-md"
                  />
                  <span
                    className={cn(
                      'absolute -top-2 -right-2 text-white bg-blue rounded-full w-7 h-7 grid place-content-center duration-150',
                      selecteds.includes(imagePath) ? 'opacity-100 scale-100' : 'opacity-0 scale-90',
                    )}
                  >
                    <FaCheck />
                  </span>
                </div>
              ))
            ) : (
              <div className="grid col-span-5 place-content-center h-40 w-full text-sm text-slate-400">
                No Images Uploaded yet
              </div>
            )}
          </div>
        </div>
      )}
      <DeleteModal
        open={openDeleteAlert}
        close={() => setOpenDeleteAlert(false)}
        onDelete={onDelete}
        isDeleting={isDeleting}
        title={`Are you sure to delete these ${selecteds.length} images ?`}
      />
    </div>
  )
}
