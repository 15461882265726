import {Button} from '@/components/ui/button'
import {useHandleError} from '@/hooks/use-handle-error'
import {cn} from '@/lib/utils'
import {formatBytes} from '@/utils/util-functions'
import {ChangeEvent, useState} from 'react'
import {FaCloudDownloadAlt} from 'react-icons/fa'
import {GiCancel} from 'react-icons/gi'

export const ComparingTool = () => {
  const [file1, setFile1] = useState<File | undefined>(undefined)
  const [file2, setFile2] = useState<File | undefined>(undefined)
  const [comparing, setComparing] = useState(false)
  const [comparingResult, setComparingResult] = useState<
    undefined | {removedUsernames: string; comparedFileContent: string}
  >(undefined)
  const handleError = useHandleError()

  const onFile1Change = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (!file?.name.includes('.txt')) {
      return handleError('Only TXT file required to upload')
    }

    event.target.value = ''
    setFile1(file)
  }

  const onFile2Change = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (!file?.name.includes('.txt')) {
      return handleError('Only TXT file required to upload')
    }

    setFile2(file)
  }

  const onCompare = async () => {
    if (file1 && file2) {
      let removedUsernames = ''
      setComparing(true)
      const file1ContentSet = new Set((await file1.text()).split('\n'))
      const file2ContentArray = (await file2.text()).split('\n')
      for (const fileUsername of file2ContentArray) {
        if (file1ContentSet.has(fileUsername)) {
          file1ContentSet.delete(fileUsername)
          if (!removedUsernames) {
            removedUsernames = fileUsername as string
          } else {
            removedUsernames += `\n${fileUsername}`
          }
        }
      }

      const comparedFileContent = Array.from(file1ContentSet).join('\n')

      setComparingResult({removedUsernames, comparedFileContent})
      setComparing(false)
    }
  }

  const onDownloadFileResult = () => {
    if (comparingResult) {
      const aTag = window.document.createElement('a')
      aTag.href = window.URL.createObjectURL(
        new Blob([comparingResult.comparedFileContent || ''], {type: 'plain/text'}),
      )
      aTag.download = 'final-result.txt'

      document.body.appendChild(aTag)
      aTag.click()

      document.body.removeChild(aTag)
    }
  }

  const onDownloadRemovedUsernames = () => {
    if (comparingResult) {
      const aTag = window.document.createElement('a')
      aTag.href = window.URL.createObjectURL(new Blob([comparingResult.removedUsernames || ''], {type: 'plain/text'}))
      aTag.download = 'removed-usernames.txt'

      document.body.appendChild(aTag)
      aTag.click()

      document.body.removeChild(aTag)
    }
  }

  const onCancel = () => {
    setFile1(undefined)
    setFile2(undefined)
    setComparingResult(undefined)
  }

  return (
    <div>
      <div className="flex items-center gap-5 mb-6">
        <label className="block w-1/2">
          <span>Base File:</span>
          <div
            className={cn(
              'h-[250px] border-2 border-dashed rounded-md grid place-content-center text-sm cursor-cell',
              file1 ? '' : 'text-slate-400',
            )}
          >
            <span>{file1 ? `${file1.name} - ${formatBytes(file1.size)}` : 'Click to upload base TXT file'}</span>
            <input disabled={Boolean(comparingResult)} type="file" accept=".txt" onChange={onFile1Change} hidden />
          </div>
        </label>
        <label className="block w-1/2">
          <span>Removing Usernames File:</span>
          <div
            className={cn(
              'h-[250px] border-2 border-dashed rounded-md grid place-content-center text-sm cursor-cell',
              file2 ? '' : 'text-slate-400',
            )}
          >
            <span>
              {file2 ? `${file2.name} - ${formatBytes(file2.size)}` : 'Click to upload removing username TXT file'}
            </span>
            <input disabled={Boolean(comparingResult)} type="file" accept=".txt" onChange={onFile2Change} hidden />
          </div>
        </label>
      </div>
      {comparingResult ? (
        <div className="flex items-center gap-3">
          <Button onClick={onDownloadFileResult} className="flex items-center gap-2 w-1/2" size="lg">
            <FaCloudDownloadAlt />
            <span>Download Final Result</span>
          </Button>
          <Button onClick={onDownloadRemovedUsernames} className="flex items-center gap-2 w-1/2" size="lg">
            <FaCloudDownloadAlt />
            <span>Download Removed Usernames</span>
          </Button>
          <Button onClick={onCancel} className="flex items-center gap-2" size="lg" variant="outline">
            <GiCancel />
            <span>Cancel</span>
          </Button>
        </div>
      ) : (
        <Button loading={comparing} onClick={onCompare} disabled={!file1 || !file2} className="w-full" size="lg">
          Compare Base File Contents
        </Button>
      )}
    </div>
  )
}
