/* eslint-disable react-hooks/exhaustive-deps */
import {useLazyGetGroupProfilesQuery, useUpdateProfileGroupMutation} from '@/features/profile-group'
import {Props} from './types'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useEffect, useMemo} from 'react'
import {BaseGroupModal} from '..'
import {SubmitHandler} from 'react-hook-form'
import {BaseGroupFormData} from '../base-group-modal/types'
import {useUpdateProfiles} from '@/hooks/use-update-profiles'

export const EditGroupModal: React.FC<Props> = ({open, close, group}) => {
  const [updateGroup, {isLoading: isUpdating}] = useUpdateProfileGroupMutation()
  const [getGroupProfiles, {data: {data: profiles = []} = {}, isLoading}] = useLazyGetGroupProfilesQuery()
  const handleRequest = useHandleRequest()
  const [updateProfilesGroup, isUpdatingProfilesGroup] = useUpdateProfiles()
  const profileIDs = useMemo(() => profiles.map(profile => profile._id), [profiles])

  const onSubmit: SubmitHandler<BaseGroupFormData> = async formData => {
    if (formData.groupName !== group.groupName) {
      await handleRequest({
        request: async () => {
          const result = await updateGroup({id: group._id, body: {groupName: formData.groupName}})
          return result
        },
      })
    }
    await updateProfilesGroup(
      'groupID',
      group._id,
      formData.profiles.filter(profileID => !profileIDs.includes(profileID)),
      profileIDs.filter(profileID => !formData.profiles.includes(profileID)),
    )
    close()
  }

  const fetchGroupProfiles = async () => {
    await handleRequest({
      request: async () => {
        const result = await getGroupProfiles({id: group._id})
        return result
      },
    })
  }

  useEffect(() => {
    if (open) {
      fetchGroupProfiles()
    }
  }, [open])

  return (
    <BaseGroupModal
      open={open}
      close={close}
      onSubmit={onSubmit}
      isSubmitting={isUpdating || isUpdatingProfilesGroup}
      isModalLoading={isLoading}
      defaultValues={{groupName: group.groupName, profiles: profileIDs, _id: group._id}}
      title="Update group"
      submitText="Update"
      editing
    />
  )
}
