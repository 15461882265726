import React from 'react'
import {EditRoadmapNoteFormData, Props} from './types'
import {Field, Modal} from '@/components/common'
import {Form} from '@/components/ui/form'
import {SubmitHandler, useForm} from 'react-hook-form'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useUpdateRoadmapNoteMutation} from '@/features/roadmap-notes'
import {useToast} from '@/components/ui/use-toast'
import {Button} from '@/components/ui/button'

export const EditRoadmapNoteForm: React.FC<Props> = ({open, close, note}) => {
  const [updateNote, {isLoading: isUpdating}] = useUpdateRoadmapNoteMutation()
  const form = useForm<EditRoadmapNoteFormData>({
    defaultValues: {
      description: note.description,
    },
  })
  const handleRequest = useHandleRequest()
  const {toast} = useToast()

  const onSubmit: SubmitHandler<EditRoadmapNoteFormData> = async formData => {
    await handleRequest({
      request: async () => {
        const result = await updateNote({id: note._id, body: formData})
        return result
      },
      onSuccess: () => {
        close()
        toast({title: 'Note successfully updated!'})
      },
    })
  }

  return (
    <Modal open={open} close={close} title="Edit Note">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-3">
          <Field
            type="textarea"
            control={form.control}
            name="description"
            componentProps={{label: 'Note', placeholder: 'Urgent change!'}}
          />
          <Button htmlType="submit" loading={isUpdating}>
            Update Note
          </Button>
        </form>
      </Form>
    </Modal>
  )
}
