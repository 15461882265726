import React from "react";
import { Props } from "./types";
import { useFieldArray } from "react-hook-form";
import { Field } from "@/components/common";
import { Button } from "@/components/ui/button";
import { FaRegTrashCan } from "react-icons/fa6";
import { getValidations } from "@/utils/util-functions";

export const AddsURLField: React.FC<Props> = ({ control }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "static.adds_url" as never,
  });

  return <div className="flex flex-col gap-1.5 w-full">
    {fields.length ? fields.map((field, index) => <div key={field.id} className="flex items-center gap-2 w-full">
      <Field type="input" control={control} name={`static.adds_url.${index}.value`} componentProps={{ placeholder: "https://instagram.com/..." }} fieldClassName="w-full" rules={getValidations(true, 19, false, { validate: (value: string) => value.startsWith("https://instagram.com/") ? undefined : "URL has to start with 'https://instagram.com/'" })} />
      <Button variant="outlined-destructive" size="sm" onClick={() => remove(index)}>
        <FaRegTrashCan />
      </Button>
    </div>) : <div className="my-4 text-center text-slate-500 text-xs">No URLs added yet</div>}
    <Button variant='outline' className="w-full mt-3" onClick={() => append({ value: "" })}>Add URL</Button>
  </div>
}