/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {Props} from './types'
import {Modal} from '@/components/common'
import {TaskRow} from './components'
import {useLazyGetAllTasksQuery, useLazyGetProfileTasksQuery} from '@/features/scraping-task'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {GetProfileTasksResponse} from '@/features/scraping-task/types'

export const CompletionModal: React.FC<Props> = ({open, close, isLoading, profileID, tasks: defaultTasks = []}) => {
  const [getAllTasks, {isLoading: isFetchingAllTasks}] = useLazyGetAllTasksQuery()
  const [getProfileTasks, {isLoading: isFetchingProfileTasks}] = useLazyGetProfileTasksQuery()
  const [activeTaskIndex, setActiveTaskIndex] = useState(0)
  const [tasks, setTasks] = useState(defaultTasks || [])
  const handleRequest = useHandleRequest()

  const onComplete = (nextIndex: number) => {
    if (!tasks[nextIndex]) {
      close()
    }
    setActiveTaskIndex(nextIndex)
  }

  const fetchTasks = async () => {
    await handleRequest({
      request: async () => {
        if (profileID === 'all') {
          const result = await getAllTasks({})
          return result
        }

        const result = await getProfileTasks({id: profileID as string})
        return result
      },
      onSuccess: ({data}: {data: GetProfileTasksResponse}) => {
        setTasks(data.data)
      },
    })
  }

  useEffect(() => {
    if (open && profileID) {
      setActiveTaskIndex(0)
      fetchTasks()
    }
  }, [open])

  useEffect(() => {
    if (!tasks.length && !profileID) {
      setTasks(defaultTasks)
    }
  }, [defaultTasks])

  return (
    <Modal
      open={open}
      close={tasks[activeTaskIndex] ? () => {} : close}
      title="Checking Completions"
      isLoading={isLoading || isFetchingAllTasks || isFetchingProfileTasks}
    >
      <div className="flex items-center gap-2 mb-3 font-semibold border-b pb-2">
        <p>Done: {activeTaskIndex}</p>
        <p>Left: {tasks.length - activeTaskIndex}</p>
      </div>
      <div className="flex flex-col gap-2 max-h-[50vh] overflow-y-auto">
        {tasks.length ? (
          tasks.map((task, index) => (
            <TaskRow
              key={task._id}
              start={activeTaskIndex === index}
              onComplete={() => onComplete(activeTaskIndex + 1)}
              index={index}
              task={task}
            />
          ))
        ) : (
          <div className="text-slate-400 h-20 grid place-content-center">No any tasks...</div>
        )}
      </div>
    </Modal>
  )
}
