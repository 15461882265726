import React, {useState} from 'react'
import {Props} from './types'
import {TableCell, TableRow} from '@/components/ui/table'
import {Button} from '@/components/ui/button'
import {FaRegTrashCan} from 'react-icons/fa6'
import {FiEdit3} from 'react-icons/fi'
import {EditForm} from '..'
import {useDeleteProfileNicheMutation} from '@/features/profile-niche'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {DeleteModal} from '@/components/common'
import {useToast} from '@/components/ui/use-toast'

export const NicheRow: React.FC<Props> = ({niche}) => {
  const [deleteNiche, {isLoading: isDeleting}] = useDeleteProfileNicheMutation()
  const [openEditForm, setOpenEditForm] = useState(false)
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false)
  const handleRequest = useHandleRequest()
  const {toast} = useToast()

  const onDelete = async () => {
    await handleRequest({
      request: async () => {
        const result = deleteNiche({id: niche._id})
        return result
      },
      onSuccess: () => {
        toast({title: 'Niche successfully deleted!'})
      },
    })
  }

  return (
    <TableRow className="!bg-white dark:!bg-background">
      <TableCell>{niche.nicheName}</TableCell>
      <TableCell>
        <div className="flex items-center justify-center gap-2">
          <Button onClick={() => setOpenEditForm(true)} size="icon" variant="outline">
            <FiEdit3 />
          </Button>
          <Button onClick={() => setOpenDeleteAlert(true)} size="icon" variant="outlined-destructive">
            <FaRegTrashCan />
          </Button>
        </div>
        <EditForm open={openEditForm} close={() => setOpenEditForm(false)} niche={niche} />
        <DeleteModal
          open={openDeleteAlert}
          close={() => setOpenDeleteAlert(false)}
          title={`Are you sure to delete this niche (${niche.nicheName}) ?`}
          onDelete={onDelete}
          isDeleting={isDeleting}
        />
      </TableCell>
    </TableRow>
  )
}
