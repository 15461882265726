export const QuitIcon = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" {...props}>
    <g>
      <g>
        <path
          fill="currentColor"
          d="M142.1,139.8c0,7.8-6.3,14.1-14.1,14.1c-7.8,0-14.1-6.3-14.1-14.1V17.7c0-7.8,6.3-14.1,14.1-14.1c7.8,0,14.1,6.3,14.1,14.1V139.8L142.1,139.8z M10,134.5c0-42.1,22.2-79.1,55.5-99.9c6.5-4.1,15.2-2.2,19.3,4.4c4.1,6.6,2.2,15.3-4.4,19.4C55,74.3,38.2,102.3,38.2,134.4c0.1,49.6,40.2,89.7,89.8,89.8c49.6-0.1,89.7-40.2,89.8-89.8c0-32.9-17.7-61.5-44.1-77.2c-6.7-4-8.9-12.6-5-19.3c4-6.7,12.6-8.9,19.3-4.9c34.6,20.4,58,58.2,58,101.4c0,65.1-52.8,118-118,118C62.8,252.5,10,199.7,10,134.5"
        />
      </g>
    </g>
  </svg>
)
