import {TableCell, TableRow} from '@/components/ui/table'
import React from 'react'
import {Props} from './types'

export const TableNotFoundRow: React.FC<Props> = ({text}) => (
  <TableRow className="relative">
    <TableCell className="h-48">
      <p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-slate-400">
        {text || 'Nothing found...'}
      </p>
    </TableCell>
  </TableRow>
)
