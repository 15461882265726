/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useEffect, useState} from 'react'
import {useHandleError} from '@/hooks/use-handle-error'
import {BsFillCaretDownFill} from 'react-icons/bs'
import {cn} from '@/lib/utils'
import {useLazyGetReviewProfilesQuery} from '@/features/profile'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {GetReviewProfilesResponse} from '@/features/profile/types'
import {Loader, Switch} from '@/components/common'
import {ProfileRow} from './components'

export const ReviewPage = () => {
  const [getReviewProfiles, {isFetching}] = useLazyGetReviewProfilesQuery()
  const [filters, setFilters] = useState({
    tasks: true,
    accounts: false,
    fbr: false,
    rate: false,
  })
  const [profiles, setProfiles] = useState<any[]>([])
  const [sortMethod, setSortMethod] = useState<any>({})
  const [getAllProfiles, setGetAllProfiles] = useState(false)
  const sortProfiles = useCallback(
    (profilesData: any[]) => {
      let key: any

      if (sortMethod.tasks) {
        key = 'tasks'
      } else if (sortMethod.accounts) {
        key = 'accounts'
      } else if (sortMethod.FBR) {
        key = 'FBR'
      } else if (sortMethod.percentUsed) {
        key = 'percentUsed'
      }

      return profilesData.sort((a: any, b: any) => {
        if (sortMethod[key] === 1) {
          if (+a[key] < +b[key]) {
            return -1
          }
          if (+a[key] > +b[key]) {
            return 1
          }
        } else {
          if (+a[key] > +b[key]) {
            return -1
          }
          if (+a[key] < +b[key]) {
            return 1
          }
        }

        return 0
      })
    },
    [sortMethod],
  )
  const handleError = useHandleError()
  const handleRequest = useHandleRequest()

  const fetchProfiles = async () => {
    try {
      await handleRequest({
        request: async () => {
          const result = await getReviewProfiles({
            ...filters,
            onlyReported: !getAllProfiles,
          })
          return result
        },
        onSuccess: ({data}: {data: GetReviewProfilesResponse}) => {
          const results = data?.data.filter(phone => {
            if (filters.fbr && (!phone.FBR || +phone.FBR > 3)) {
              return false
            }

            if (filters.tasks && (!phone.tasks || +phone.tasks > 5)) {
              return false
            }

            if (filters.accounts && (!phone.accounts || +phone.accounts > 70)) {
              return false
            }

            if (filters.rate && (!phone.percentUsed || +phone.percentUsed > 70)) {
              return false
            }

            return true
          })
          setProfiles(results)
        },
      })
    } catch (ex) {
      handleError(ex)
    }
  }

  useEffect(() => {
    fetchProfiles()
  }, [filters, getAllProfiles])

  useEffect(() => {
    setProfiles(prev => sortProfiles([...prev]))
  }, [sortMethod])

  return (
    <div className="container mx-auto mt-16 mb-4 py-6 bg-white dark:bg-background dark:border dark:border-secondary rounded-xl">
      <div className="flex gap-8 items-center">
        <Switch
          checked={filters.tasks}
          onChange={checked => setFilters(prev => ({...prev, tasks: checked}))}
          label="Less than 5 targets in queue"
        />
        <Switch
          checked={filters.accounts}
          onChange={checked => setFilters(prev => ({...prev, accounts: checked}))}
          label="Less than 70 accounts"
        />
        <Switch
          checked={filters.fbr}
          onChange={checked => setFilters(prev => ({...prev, fbr: checked}))}
          label="Low FBR"
        />
        <Switch
          checked={filters.rate}
          onChange={checked => setFilters(prev => ({...prev, rate: checked}))}
          label="Rate < 85%"
        />
      </div>
      <div className="flex items-center justify-between mt-8 mb-2">
        <h2>
          Profiles count: <b>{profiles.length}</b>
        </h2>
        <button onClick={() => setGetAllProfiles(prev => !prev)}>
          {getAllProfiles ? 'Hide report not existed profiles' : 'Show All'}
        </button>
      </div>
      <div className="border-2 border-muted rounded-2xl overflow-hidden h-max">
        <div className="max-h-[calc(100vh-220px)] overflow-y-auto">
          <div className="table w-full">
            <div className="table-header-group">
              <div className="table-row">
                <div className={cn('!text-start', thClassName)}>
                  <span>Name</span>
                </div>
                <div
                  onClick={() => setSortMethod((prev: any) => ({tasks: prev.tasks ? (prev.tasks === 1 ? -1 : 1) : 1}))}
                  className={cn('cursor-pointer hover:bg-stone-800 duration-150', thClassName)}
                >
                  <div className="flex items-center gap-2">
                    <span>Targets in queue</span>
                    <BsFillCaretDownFill className={cn('duration-100', sortMethod.tasks === -1 && 'rotate-180')} />
                  </div>
                </div>
                <div
                  onClick={() =>
                    setSortMethod((prev: any) => ({accounts: prev.accounts ? (prev.accounts === 1 ? -1 : 1) : 1}))
                  }
                  className={cn('cursor-pointer hover:bg-stone-800 duration-150', thClassName)}
                >
                  <div className="flex items-center gap-2">
                    <span>Accounts</span>
                    <BsFillCaretDownFill className={cn('duration-100', sortMethod.accounts === -1 && 'rotate-180')} />
                  </div>
                </div>
                <div
                  onClick={() => setSortMethod((prev: any) => ({FBR: prev.FBR ? (prev.FBR === 1 ? -1 : 1) : 1}))}
                  className={cn('cursor-pointer hover:bg-stone-800 duration-150', thClassName)}
                >
                  <div className="flex items-center gap-2">
                    <span>FBR</span>
                    <BsFillCaretDownFill className={cn('duration-100', sortMethod.FBR === -1 && 'rotate-180')} />
                  </div>
                </div>
                <div
                  onClick={() =>
                    setSortMethod((prev: any) => ({
                      percentUsed: prev.percentUsed ? (prev.percentUsed === 1 ? -1 : 1) : 1,
                    }))
                  }
                  className={cn('cursor-pointer hover:bg-stone-800 duration-150', thClassName)}
                >
                  <div className="flex items-center gap-2">
                    <span>Rate</span>
                    <BsFillCaretDownFill
                      className={cn('duration-100', sortMethod.percentUsed === -1 && 'rotate-180')}
                    />
                  </div>
                </div>
                <div className={thClassName}>Actions</div>
              </div>
            </div>
            <div className="relative table-row-group">
              {isFetching ? (
                <div className="table-row">
                  <div className="table-cell h-96">
                    <Loader parentClassName="absolute h-full" />
                  </div>
                </div>
              ) : profiles.length ? (
                profiles.map(profile => <ProfileRow key={profile.phoneID} profile={profile} />)
              ) : (
                <div className="table-row relative h-40">
                  <div className="absolute top-0 left-0 grid place-content-center w-full h-full text-lg text-muted-foreground font-semibold text-center">
                    No Profiles...
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const thClassName =
  'table-cell text-center text-xl bg-white dark:!bg-secondary py-4 px-8 font-semibold hover:bg-slate-50 sticky top-0 z-10'
